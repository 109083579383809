let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}
console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://a46h2qt2v3.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta = "https://0s9d2n4gl7.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd = "https://zvahlhrit3.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL2;
export var awsUrl2 =
  "https://lab708php8.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta2 = "https://xib6yeddq5.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd2 = "https://b5j73nhga2.execute-api.us-east-1.amazonaws.com/prod";

// server_coreSetURL3;
export let awsUrl3 =
  "https://9fbh1obn45.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlBeta3 = "https://qpns6wogva.execute-api.us-east-1.amazonaws.com/beta";
var awsUrlProd3 = "https://f2xht6iv11.execute-api.us-east-1.amazonaws.com/prod";

// dashboard URL
export var dashboardUrl =
  "https://dmuwt3vd0e.execute-api.us-east-1.amazonaws.com/dev";
var dashboardUrlProd = "";
// mode = "local1";
if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "prod") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  awsUrl3 = awsUrlProd3;
  dashboardUrl = dashboardUrlProd;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
  awsUrl3 = awsUrlBeta3;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "1.0.3 (m)";

let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};

export const handleUWCommentHistoryButtonClick = (
  data,
  comments,
  submittedDate,
  setModalBody,
  setOpenModal
) => {
  if (comments !== undefined) {
    if (typeof comments[0] === "string" && comments[0] !== "") {
      let updatedComment = [
        {
          ...initialcomment,
          comment: comments[0],
          commentTimestamp: submittedDate,
        },
      ];
      setModalBody(updatedComment);
    } else {
      setModalBody(comments);
    }
  } else {
    setModalBody([initialcomment]);
  }
  setOpenModal(true);
};

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
const DialogBox = ({ openModal, handleCloseModal, modalBody }) => {
  return (
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>
        <p style={{ textAlign: "center" }}>
          {modalBody.length > 0 &&
          modalBody[0] !== "" &&
          modalBody?.[0]?.comments !== "" || modalBody?.[0]?.previousComment
            ? "Comment History"
            : "No Comment History"}
        </p>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          style={{ position: "absolute", right: "5px", top: "8px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {modalBody.length > 0 &&
          modalBody[0] !== "" &&
          modalBody.map((item, index) => {
            let date = moment(item.commentTimestamp)
              .format("M/D/YY h:mm A")
              .split(" ")[0];
            let time = moment(item.commentTimestamp)
              .format("M/D/YY h:mm A")
              .split(" ")[1];
            if (item.comment !== "") {
              if (item.commentTimestamp || item.email || item) {
                return (
                  <div>
                    <div>
                      <span>
                        <b>{date}</b>
                      </span>
                      <span
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <b>{time}</b>
                      </span>
                      <span>{item.email ? "- " : ""}</span>
                      <span>
                        <b>{item.email?.split("@")[0]}</b>
                      </span>
                    </div>
                    <div>
                      <p>
                        {item?.comment
                          ? item.comment
                          : modalBody[index + 1]?.comment
                          ? modalBody[index + 1].comment
                          : item}
                      </p>
                    </div>
                    <br />
                  </div>
                );
              }
            } else {
              return;
            }
          })}
      </DialogContent>
    </Dialog>
  );
};

export default DialogBox;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import styles from "./Header.module.scss";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import { startNewQuote, logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import { RxDashboard } from "react-icons/rx";
import { IoPersonSharp } from "react-icons/io5";
import { LuDownload } from "react-icons/lu";
import axios from "axios";

class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userName: "user",
      showDashboardLink: false,
      showSalesDashboardLink: false,
      showPreUwDashboardLink: false,
      showBrdDashboradLink: false,
      showSalesManagerLink: true,
      ispreUnderwriter: false
    };
  }
  capitalizeWords(str) {
    return str
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  }

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      let ispreUW = sessionStorage.getItem('preUnderwriter')
      this.setState({ ispreUnderwriter: ispreUW })
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        userName = this.capitalizeWords(userName);
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          this.setState({ showDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
          this.setState({ showSalesManagerLink: false });
        } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          this.setState({ showSalesDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          this.setState({ showPreUwDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_BRD)) {
          this.setState({ showBrdDashboradLink: true });
        }
        this.setState({ isLoggedIn: true, userName });
      }
    } catch (error) { }
  };

  onClickDownloadAcord = async () => {
    try {
      const response = await axios.get(
        "https://engage-dashboard-files-dev.s3.amazonaws.com/static/Acord 130 - Fillable.pdf",
        {
          responseType: "blob",
        }
      );

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Engage Acord.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };



  render() {
    const {
      isLoggedIn,
      userName,
      showDashboardLink,
      showSalesDashboardLink,
      showPreUwDashboardLink,
      showBrdDashboradLink,
      showSalesManagerLink,
      ispreUnderwriter
    } = this.state;




    return (
      <div className="home-login">
        {/* {isLoggedIn && showSalesDashboardLink && showSalesManagerLink && (
          <li onClick={startNewQuote}>
            <Link to={"/Quote"} className="start_new_quote_btn">
              Start New Quote
            </Link>
          </li>
        )} */}
        {isLoggedIn && !ispreUnderwriter &&
          (window.location.pathname.toLowerCase().includes("quote") ||
            window.location.pathname.includes("SalesDashboard")) && (
            <div className="Dashboard__menucontent menustyle">
              <LuDownload />
              <div
                onClick={this.onClickDownloadAcord}
                style={{ fontFamily: "Montserrat, sans-serif" }}
              >
                Acord
              </div>
            </div>
          )}
        {isLoggedIn && (
          <>
            {window.location.pathname.toLowerCase().includes("quote") && (
              <div className="Dashboard__menucontent menustyle">
                <Link
                  to={
                    showDashboardLink
                      ? "/AdminDashboard"
                      : showSalesDashboardLink
                        ? "/SalesDashboard"
                        : showPreUwDashboardLink
                          ? "/PanelUW"
                          : showBrdDashboradLink
                            ? "/brdDashboard"
                            : "/"
                  }
                >
                  <div>Dashboard</div>
                </Link>
              </div>
            )}
            <div
              id="Dropprofile"
              className="d-flex align-items-center text-dark   logout_menu"
            >
              <span
                className="dropdown-toggle d-flex justify-content-center align-items-center user-dropdown"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "white", fontFamily: "Montserrat, sans-serif" }}
              >
                <div className="pb-1 mr-1">
                  <IoPersonSharp color="white" />
                </div>
                {userName}
              </span>
              <div className="dropdown-menu mt-md-2  logoutstyle">
                <a
                  style={{ fontFamily: "Montserrat, sans-serif" }}
                  className="dropdown-item logout-link"
                  onClick={logout}
                >
                  Logout
                </a>
              </div>
              <div className="dropdown-menu mt-md-2"></div>
              {/* <DropUserProfile username={this.state.userName} /> */}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default HeaderSubPart;

import React from "react";
import twitterIcon from "../../../images/instalogo.png";
import { SiLinkedin } from "react-icons/si";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import linkedinLogo from "../../../images/linkedinLogo.png";
import affinityLogo from "../../../images/affinity-new.png";
import insurecompimg from "../../../images/insurecomp_new.png";
import ipro from "../../../images/logo-iPEO-new.png";

const HomeFooter = (props) => {
  return (
    <div className="homeFooterContainer">
      <div className="footer_logo_section">
        <div className="footer_logos">
          <div>
            <img src={props.engageLogo} alt="Engage Logo" />{" "}
          </div>
          <div>
            <img src={affinityLogo} alt="Engage Logo" />
          </div>
        </div>
      </div>
      <div className="footer_contact_section">
        <div className="footerParts">
          <div className="footer_email">
            <p>
              <b>Get In Touch</b>
            </p>
            <p style={{ cursor: "pointer" }}>jan@affinitypeo.com</p>
          </div>
        </div>
        <div className="vertical-divider"></div>
        <div className="footerParts">
          <div className="footer_icons">
            <p>
              <b>Social Media</b>
            </p>
            <div className="icons">
              <div>
                <a
                  href="https://www.instagram.com/affinitygrouppeo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare size={35} color="black" />
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/engage-peo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin size={35} color="black" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_partners_section">
        <div className="text">
          <b>Our Partners</b>
        </div>
        <div className="partnersLogo">
          <div>
            <img src={insurecompimg} alt="insurecompimg" />
          </div>
          <div>
            <img className="mt-3" src={ipro} alt="iproimg" />
          </div>
        </div>
      </div>

      {/* <div className="footer_contact_section">
        <div className="footerParts">
          <div className="footer_icons">
            <p>
              <b>Social Media</b>
            </p>
            <div className="icons">
              <a
                href="https://x.com/EngagePEO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitterIcon}
                  alt="Twitter Icon"
                  className="twitterIcon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/engage-peo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedinLogo}
                  alt="LinkedIn Icon"
                  className="linkedinIcon"
                />
              </a>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HomeFooter;

import React, { Component } from "react";

const SutaFavourites = (props) => {
  const tableData = [
    { state: "GEORGIA", percentage: "2.70%" },
    { state: "FLORIDA", percentage: "2.70%" },
    { state: "TEXAS", percentage: "2.70%" },
    { state: "INDIANA", percentage: "2.50%" },
    { state: "KANSAS", percentage: "2.70%" },
    { state: "ALABAMA", percentage: "2.70%" },
    { state: "ARIZONA", percentage: "2.00%" },
    { state: "COLORADO", percentage: "3.05%" },
    { state: "IDAHO", percentage: "1.23%" },
    { state: "OREGON", percentage: "2.40%" },
    { state: "TENNESSEE", percentage: "2.70%" },
    { state: "VIRGINIA", percentage: "2.50%" },
  ];
  return (
    <div className="sutaFavouritesContainer">
      <h1 style={{ fontWeight: "1000" }}>SUTA FAVORABLE STATES</h1>
      <div
        className="sutaFavorableTable"
        style={{ backgroundImage: `url(${props.sutaFavorablebg})` }}
      >
        <div className="grid-container">
          {tableData.map((item, index) => (
            <div key={index} className="grid-item">
              <div>
                <b>{item.state}</b>
              </div>
              <div style={{ marginTop: "7px" }}>{item.percentage}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SutaFavourites;

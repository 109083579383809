import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";
import LoadingOverlay from "react-loading-overlay";
import CustomDropZoneDashborad from "./CustompDropZoneDash";

export default class DropZoneDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: this.props.fileList || {},
            uploadingInProgress: false,
            uploadMsg: undefined,
            errMsg: undefined,
            docUploaded: false
        };
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
        ) {
            this.setState({ fileList: this.props.fileList });
        }
        if (
            this.props.docUploaded !== prevProps.docUploaded &&
            this.props.docUploaded === true &&
            prevProps.docUploaded === false
        ) {
            this.handleUpload();
        }
    }

    updateFileList = (newFile) => {
        let { fileList } = this.state;
        if (fileList[newFile.name] === "#empty") {
            this.setState({ errMsg: "File already exist", uploadMsg: undefined });
            return;
        }
        fileList[newFile.name] = newFile;
        this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
    };

    removeFile = (fileName) => {
        let { fileList } = this.state;
        delete fileList[fileName];
        this.setState({ fileList });
    };

    uploadFile = (fileData, postDataS3) => {
        return new Promise((resolve, reject) => {
            axios
                .post(awsUrl + "/api/uploadLossForm", postDataS3)
                .then((response) => {
                    let returnData = response.data;
                    let lossSignedURL = returnData.lossSignedURL;
                    let options = { header: { "Content-Type": postDataS3.fileType } };
                    axios
                        .put(lossSignedURL, fileData, options)
                        .then((result) => {
                            console.log("file upload res: ", result);
                            resolve();
                        })
                        .catch((error) => {
                            console.log("error in uploading", error);
                            reject();
                        });
                })
                .catch((error) => {
                    console.log("error in uploadLossForm ", error);
                    reject();
                });
        });
    };

    updateUserCampaignTable = (dataToSend) => {
        return new Promise((resolve, reject) => {
            axios
                .post(awsUrl2 + "/api/preUwData/updateFileDetails", dataToSend)
                .then((res) => {
                    resolve();
                })
                .catch((error) => {
                    console.log("error in API-preUwFlow-file_update", error);
                    reject();
                });
        });
    };

    updateUserTrackingTable = (dataToSend) => {
        return new Promise((resolve, reject) => {
            axios
                .post(awsUrl2 + "/api/preUwData/updateTrackingFileDetails", dataToSend)
                .then((res) => {
                    resolve();
                })
                .catch((error) => {
                    console.log("error in API-preUwFlow-file_update", error);
                    reject();
                });
        });
    };

    handleUpload = () => {
        let { updatePopUpData, setDocUploaded, domainName } = this.props;
        try {
            let { fileList } = this.state;
            if (Object.keys(fileList).length <= 0) {
                this.setState({ errMsg: "Please upload some files to continue" });
                return
            }
            this.setState({ uploadingInProgress: true });
            let domain = domainName;

            if (!domain) return;

            const folder1 = `engage/${domain}`;
            let dataToSendPromise = [];

            let promiseList = [],
                fileListToStore = [];
            let fileKey = [];


            for (let fileName in fileList) {
                if (fileList[fileName] === "#empty") continue;

                let fileType = fileName.split(".").reverse()[0];
                const folder2 = "preUwUpload";

                fileListToStore.push({
                    key: `${folder1}/${folder2}/${fileName}`,
                    fileType,
                    keyToFile: fileName,
                });
                fileKey.push(`${folder1}/${folder2}/${fileName}`)


                const postDataS3 = {
                    email: "john@doe.com",
                    folder1,
                    folder2,
                    fileName,
                    fileType,
                };

                promiseList.push(
                    this.uploadFile(fileList[fileName], postDataS3)
                );
            }

            setDocUploaded(false);

            Promise.all(promiseList)
                .then((res) => {
                    dataToSendPromise.push(
                        this.updateUserTrackingTable({
                            domain: domain,
                            preUwFileToUpload: fileListToStore
                        })
                    );

                    Promise.all(dataToSendPromise)
                        .then((res) => {
                            for (let fileName in fileList) {
                                fileList[fileName] = "#empty";
                            }
                            this.setState({
                                uploadingInProgress: false, docUploaded: true,
                                uploadMsg: "files uploaded",
                                errMsg: undefined,
                                fileList
                            });
                        })
                        .catch((error) => console.log("error in API", error));

                    this.props.updateDomainMessageidFiles(
                        this.props.domainName,
                        "dashboardFiles",
                        fileKey
                    );
                })
                .catch((error) => {
                    console.log("error: ", error);
                    this.setState({ errMsg: "Error", uploadMsg: undefined, uploadingInProgress: false });
                });
        } catch (error) {
            console.log("error: ", error);
            this.setState({ errMsg: "Error", uploadMsg: undefined, uploadingInProgress: false });
        }
        //   },
        // });
    };

    render() {
        let { fileList, uploadingInProgress, uploadMsg, errMsg, docUploaded } = this.state;
        let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
        return (
            <LoadingOverlay active={uploadingInProgress} spinner>
                <div className="dropZonesContainer">
                    <div className="row mb-3">
                        <div className="col-sm-1 col-md-12 col-lg-6">
                            <CustomDropZoneDashborad
                                name="first"
                                fileList={fileList}
                                updateFileList={this.updateFileList}
                                removeFile={this.removeFile}
                                uploadMsg={uploadMsg}
                            />
                        </div>
                    </div>
                    {true && <div className="d-flex flex-column">
                        <pre
                            className={
                                "text-center text-capitalize " +
                                (uploadMsg ? "text-success" : "text-danger")
                            }
                        >
                            {uploadMsg || errMsg}
                        </pre>
                        <button
                            className="btn btn-warning btn-sm"
                            style={{
                                width: "min-content",
                                color: "white",
                                alignSelf: "center",
                                backgroundColor: "#feae01",
                                boxShadow: "0px 5px 12px grey",
                            }}
                            onClick={(event) => {
                                if (hideButtons) return;
                                event.preventDefault();
                                this.handleUpload();
                            }}
                            disabled={uploadingInProgress}
                        >
                            Upload
                        </button>
                    </div>}
                </div>
            </LoadingOverlay>
        );
    }
}

import React, { Component } from "react";

const StarRating = (props) => {
  return (
    <div className="ratingContainer">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
      <div>
        <div className="fivestar_imgbox">
          <img
            src={props.fiveStarImage}
            alt="Five Star"
            className="fivestarImage"
          />
          <p>
            <b>
              Our Client satisfaction ratings are among the highest in the
              Industry.
            </b>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default StarRating;

import React, { Component } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SmallLoader from "./SmallLoader";
import Clamp from "react-multiline-clamp";
import engagePeoLogo from "../../images/engagePeoLogo.jpg";
import { roundValues } from "../../utils/math_functions";
import axios from "axios";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import downlaodlogoone from "../../images/downloadlogoone.png";

import { Auth } from "aws-amplify";
import { awsUrl2 } from "../../config";

const { mergePdf } = require("./MergePdf");

const carrierMap = {
  paygo: "PAYGO",
  peo: "PEO",
};

class QuoteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedCarrier: this.props.selectedCarrier,
      isDisabled: true,
      errorStatements: {
        peo:
          "Thank you for your interest. We are not able to generate the quote. Our team will get in touch with you in 48 hours.",
        peo:
          "Thank you for your interest. We are not able to generate the quote. Our team will get in touch with you in 48 hours.",
        paygo:
          "Thank you for your interest. We are not able to generate the quote. Our team will get in touch with you in 48 hours.",
      },
      download_data: [],

      requestedPricing: undefined,
      companyProfileAddQuestion: undefined,
    };
  }

  componentDidMount = async () => {
    let currProspectDetails = {};
    try {
      currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));

      for (let key of currProspectDetails["companyProfileAddQuestions"]) {
        if (key["name"] === "qc2" && key["response"] === "Y") {
          this.setState({ isDisabled: false });
        }
      }

      let requestedPricing = currProspectDetails?.requestedPricing;

      // let companyProfileAddQues =
      //   currProspectDetails.companyProfileAddQuestions;

      // // Set the WC rate of the paygo quote as the response selected for the
      // // paygo type question in the company profile page
      // let paygoTypeCompanyProfileAddQues = companyProfileAddQues.filter(
      //   (e) => e.name === "qc3-1"
      // );
      // let paygoWcRate = "C/O";
      // if (
      //   paygoTypeCompanyProfileAddQues.length > 0 &&
      //   paygoTypeCompanyProfileAddQues[0].response === "N"
      // ) {
      //   paygoWcRate = "Cert";
      // }

      let companyProfile = currProspectDetails.companyProfile;
      this.setState({
        companyProfile: companyProfile,
        requestedPricing: requestedPricing,
        // companyProfileAddQuestion: companyProfileAddQues,
        // paygoWcRate,
      });
    } catch (error) {
      console.log(error);
    }

    let sessionData = await Auth.currentAuthenticatedUser();
    let email = sessionData?.attributes?.email;
    if (email === "rishabhladge@insurepro20.com") {
      this.setState({ displayPaygoEligibility: true });
    }
  };

  componentDidUpdate(prevProps) {
    let {
      selectedCarrier: selcarr,
      loading,
      clientEligibility,
      payoEligibility,
      adminType,
      data,
      reCalculateData,
      carrierSubmittedWith,
    } = this.props;
    let errorStatements = { ...this.state.errorStatements };
    if (selcarr !== prevProps.selectedCarrier) {
      this.setState({ selectedCarrier: this.props.selectedCarrier });
    }

    // check for the industry type once the API has received data
    if (loading !== prevProps.loading) {
      let declinedStatement =
        "Thank you for your interest. Our team will get in touch with you in 48 hours.";

      if (clientEligibility === "declined") {
        errorStatements.peo = declinedStatement;
      }
      if (payoEligibility?.Eligibility === "Decline") {
        errorStatements.peo = declinedStatement;
        errorStatements.paygo = declinedStatement;
      }
      this.setState({ errorStatements });
    }

    // Update the indication document in s3 if the admin has updated the rates.
    // This document is shared with salesforce when the UW decides to sync.
    if (reCalculateData && prevProps.data !== data) {
      let indicationCarrier = carrierSubmittedWith || selcarr;
      let dataToShow =
        data && indicationCarrier && data[indicationCarrier]
          ? data[indicationCarrier]
          : [];
      let isQuoteAvailable = {
        paygo: () => this.isPaygoGenerated(payoEligibility),
        peo: () => this.isPeoGenerated(data["peo"], payoEligibility),
      };
      let isQuoteAvailablePEO = isQuoteAvailable["peo"]();

      this.uploadIndicationToS3(
        dataToShow,
        indicationCarrier,
        isQuoteAvailablePEO,
        adminType
      );
    }
  }

  Questions = () => {
    let { companyProfileAddQuestion } = this.state;
    let e_verifyQuestionResponse = companyProfileAddQuestion.filter((item) => {
      return item.ques === "Are you Interested in E-verification:";
    });
    let additionalFein = companyProfileAddQuestion.filter((item) => {
      return item.ques === `Are there additional FEIN #’s tied to this entity?`;
    });
    let additionalAddress = companyProfileAddQuestion.filter((item) => {
      return (
        item.ques ===
        "Are there additional physical addresses tied to this entity?"
      );
    });

    return (
      <div id="pdf-questions">
        <div className="e-verify-services pt-2 pb-3 ">
          <h3 className="e-verify font-weight-bold pl-5 pt-5 ">
            E-Verify Services
          </h3>
        </div>

        <div id="overViewSubContainer">
          <p id="overviewSub" className="pr-3">
            Our platform will remove the manual responsibility and process of
            administering E-verify, and ensure compliance. It also provides
            additional audits, real time data confirmation from Social Security
            Administration & Department of Homeland Security, and alerts on
            documents that require re-verification. (E-verify is a requirement
            in some, but not all states.)
          </p>
        </div>

        <div className="Free_breakdownBox pt-4">
          <div className="d-flex" style={{ width: "800px" }}>
            <div
              className=" font-weight-bold p-2 "
              style={{
                backgroundColor: "#8DC63F",
                color: "white",
                width: "400px",
              }}
            >
              Free Breakdown
            </div>
            <div
              className="font-weight-bold p-2"
              style={{
                backgroundColor: "#00B6DE",
                color: "white",
                width: "400px",
              }}
            >
              Client Election
            </div>
          </div>
          <div className="d-flex" style={{ width: "800px" }}>
            <div
              className="p-3"
              style={{
                borderBottom: "solid 1.5px #8dc63f",
                borderLeft: "solid 1.5px #8dc63f",
                width: "400px",
              }}
            >
              <b>$4</b> per hire
            </div>
            <div
              className=""
              style={{
                borderBottom: "solid 1.5px #00B6DE",
                borderRight: "solid 1.5px #00B6DE",
                borderLeft: "solid 1.5px #00B6DE",
                width: "400px",
              }}
            >
              <div className="row" style={{ height: "55px" }}>
                <div className="col-6" style={{ margin: "auto" }}>
                  {" "}
                  E-verify:
                </div>
                <div
                  className="col-6"
                  style={{ borderLeft: "solid 1.5px #00B6DE" }}
                >
                  <p className="pl-3" style={{ margin: "revert" }}>
                    <input
                      type="checkbox"
                      id="e-verifyCheckYes"
                      name="feinCheckYes"
                      checked={
                        e_verifyQuestionResponse[0]?.response === "Y"
                          ? true
                          : false
                      }
                    />
                    <span className="pl-2 pr-2">Yes</span>
                    <input
                      type="checkbox"
                      id="e-verifyCheckNO"
                      name="feinCheckYes"
                      checked={
                        e_verifyQuestionResponse[0].response === "N"
                          ? true
                          : false
                      }
                    />
                    <span className="pl-2">No </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Acknowledgecontiner">
          <p id="Acknowledge">ACKNOWLEDGEMENT</p>
        </div>

        <div id="acknoQuestioncontiner">
          <p id="acknoQuestion">{`Are there additional FEIN #’s or physical addresses tied to this entity?`}</p>
        </div>

        <div className="pdfForm">
          <div className="federalId row">
            <p className="pl-3 pr-5">Federal ID Number(s):</p>
            <p className="pl-3">
              <input
                type="checkbox"
                id="feinCheckYes"
                name="feinCheckYes"
                checked={additionalFein[0].response == "Y" ? true : false}
              />
              <span className="pl-1">Yes</span>{" "}
            </p>
            <p className="pl-4">
              <input
                type="checkbox"
                id="feinCheckNo"
                name="feinCheckNo"
                checked={additionalFein[0].response == "N" ? true : false}
              />
              <span className="pl-2">No</span>{" "}
            </p>
          </div>
          <div className="physicalAddress row">
            <p className="pl-3 pr-5">Physical Address(es):</p>
            <p className="pl-4">
              {" "}
              <input
                type="checkbox"
                id="physicalAddressCheckYes"
                name="physicalAddressCheckYes"
                checked={additionalAddress[0].response == "Y" ? true : false}
              />
              <span className="pl-1">Yes</span>{" "}
            </p>
            <p className="pl-4">
              {" "}
              <input
                type="checkbox"
                id="physicalAddressCheckNo"
                name="physicalAddressCheckNo"
                checked={additionalAddress[0].response == "N" ? true : false}
              />
              <span className="pl-2">No</span>{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };

  RatesWeekly = () => {
    let { requestedPricing } = this.state;
    let { admin_type_values } = this.props;

    return (
      <div id="Rates-Weekly" className="d-flex  list-unstyled pt-5">
        <span className="d-flex align-items-end pl-5 pr-3">
          <li className="font-weight-bold">Weekly Rate</li>
          <input
            type="text"
            id="Weekly_Rate"
            name="Weekly_Rate"
            // value={`${requestedPricing?.["WeeklySelect"] == "a" ? "✔️" : ""}`}
            value={"$" + roundValues(admin_type_values?.["weekly_rate"]?.fee)}
            style={{
              border: "none",
              borderBottom: "1px solid black",
              width: "100px",
              alignItems: "center",
              boxSizing: "border-box",
              height: "40px",
              padding: "20px 0px 0px 8px",
            }}
          />
        </span>
        <span className="d-flex align-items-end pl-5 pr-3">
          <li className="font-weight-bold">Bi-Weekly </li>
          <input
            type="text"
            id="Bi-Weekly"
            name="Bi-Weekly"
            // value={`${requestedPricing?.["WeeklySelect"] == "b" ? "✔️" : ""}`}
            value={"$" + roundValues(admin_type_values?.["bi_weekly"]?.fee)}
            style={{
              border: "none",
              borderBottom: "1px solid black",
              width: "100px",
              alignItems: "center",
              boxSizing: "border-box",
              height: "40px",
              padding: "20px 0px 0px 8px",
            }}
          />
        </span>
        <span className="d-flex align-items-end pl-5 pr-3">
          <li className="font-weight-bold">Semi-Monthly</li>
          <input
            type="text"
            id="Semi-Monthly"
            name="Semi-Monthly"
            // value={`${requestedPricing?.["WeeklySelect"] == "c" ? "✔️" : ""}`}
            value={"$" + roundValues(admin_type_values?.["semi_monthly"]?.fee)}
            style={{
              border: "none",
              borderBottom: "1px solid black",
              width: "100px",
              alignItems: "center",
              boxSizing: "border-box",
              height: "40px",
              padding: "20px 0px 0px 8px",
            }}
          />
        </span>
        <span className="d-flex align-items-end pl-5 pr-3">
          <li className="font-weight-bold">Monthly</li>
          <input
            type="text"
            id="Monthly"
            name="Monthly"
            // value={`${requestedPricing?.["WeeklySelect"] == "d" ? "✔️" : ""}`}
            value={"$" + roundValues(admin_type_values?.["monthly"]?.fee)}
            style={{
              border: "none",
              borderBottom: "1px solid black",
              width: "100px",
              alignItems: "center",
              boxSizing: "border-box",
              height: "40px",
              padding: "20px 0px 0px 8px",
            }}
          />
        </span>
      </div>
    );
  };

  PdfFormData = () => {
    let { companyProfile } = this.state;
    console.dir(companyProfile);

    return (
      <div id="pdf-form-data">
        <div className="netRateformdetails">
          <div className="row compAndFein">
            <div className="d-flex flex-column pr-5">
              <input
                type="text"
                id="companyname"
                name="companyname"
                value={companyProfile?.companyName?.value}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  width: "500px",
                }}
              />
              <label className="compName pl-4">
                Company Name/dba
                <span style={{ color: "#C91351" }}>{` (Please Confirm)`}</span>
              </label>
            </div>

            <div className="d-flex flex-column pl-5 ">
              <input
                type="text"
                id="feinfdf"
                name="feinfdf"
                value={companyProfile?.fein?.value}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              />
              <label className="FeinPdf pl-5"> FEIN</label>
            </div>
          </div>

          <div className="row compAndAddress">
            <div className="d-flex flex-column pr-5">
              <input
                type="text"
                id="compAddress"
                name="compAddress"
                value={companyProfile?.street1?.value}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  width: "500px",
                }}
              />
              <label className="compAddress pl-4">
                Company Address
                <span style={{ color: "#C91351" }}>{` (Please Confirm)`}</span>
              </label>
            </div>

            <div className="d-flex flex-column pl-5 ">
              <input
                type="text"
                id="cityAndState"
                name="cityAndState"
                value={
                  companyProfile?.cityName1?.value +
                  " " +
                  companyProfile?.state1?.value +
                  " " +
                  companyProfile?.zipCode1?.value
                }
                style={{
                  border: "none",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                }}
              />
              <label className="cityAndState pl-5">{`City, State, Postal Code`}</label>
            </div>
          </div>

          <div className="row OwnerOfficeAndPropsedate">
            <div className="d-flex flex-column pr-3">
              <input
                type="text"
                id="officeName"
                name="officeName"
                style={{
                  border: "none",
                  borderBottom: "1px solid black",
                  width: "300px",
                }}
              />
              <label className="officeName pl-4">
                Owner/Office Name
                <span style={{ color: "#C91351" }}>{` (Print)`}</span>
              </label>
            </div>

            <div className="d-flex flex-column pl-2 pr-5">
              <input
                type="text"
                id="cityAndState"
                name="cityAndState"
                // value={moment(companyProfile?.effectiveDate?.value).format(
                //   "MM-DD-YYYY"
                // )}
                value={""}
                style={{
                  border: "none",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  width: "180px",
                }}
              />
              <label
                className="cityAndState pl-4"
                style={{
                  textAlign: "center",
                }}
              >{`Date`}</label>
            </div>

            <div className="d-flex flex-column pl-5 ">
              <input
                type="text"
                id="cityAndState"
                name="cityAndState"
                style={{
                  border: "none",
                  borderBottom: "1px solid black",
                }}
              />
              <label className="cityAndState pl-5">{`Owner/Officer Signature`}</label>
            </div>
          </div>
        </div>
        <div id="Disclaimercontiner">
          <p id="Disclaimer">
            Disclaimer: All pricing and proposals are contingent upon final
            underwriting approval, additional underwriting information may be
            required. Do not cancel any insurance related products or payroll
            processing relationships until final approvals have been granted and
            all required agreements have been executed.
            <span id="subDisclaimer">
              This document is not binding, it will proceed to act as the
              Exhibit “A” and Exhibit “B” of the Fourth service agreement in the
              event full underwriting authority is granted and required setup
              documentation and process is complete.
            </span>
            <span id="subDisclaimer1">
              Proposed rates are based on the payroll information provided;
              rates can change if gross wages fall significantly lower than the
              provided gross wages. Fourth reserves the right to modify pricing
              should proposal surpass thirty days.
            </span>
          </p>
        </div>
      </div>
    );
  };

  MainContent = () => {
    let { companyProfile } = this.state;
    console.dir(companyProfile);
    let formatDate = (timestamp) => {
      if (!timestamp) return "Invalid Date";
      const date = new Date(timestamp);
      return date.toLocaleDateString(); // Customize the format if needed
    };
    return (
      <div id="pdf-main-content">
        <div id="bannercontiner">
          {process.env.REACT_APP_MODE === "beta" ? (
            <span id="peo-header_in_pdf">
              Engage
              <br /> PEO
            </span>
          ) : (
            <img id="bannerid" src={engagePeoLogo} />
          )}
        </div>
        <div style={{ marginLeft: "50px" }}>
          <div className="d-flex flex-column pr-5">
            <p>Company Name : {companyProfile?.companyName?.value}</p>
          </div>
          <div className="d-flex flex-column pr-5">
            <p>FEIN : {companyProfile?.fein?.value}</p>
          </div>
          <div className="d-flex flex-column pr-5">
            <p>
              Effective Date :{" "}
              {formatDate(companyProfile?.effectiveDate?.value)}
            </p>
          </div>
          <div id="Pricingcontiner ">
            <p id="Pricing">INITIAL PRICING </p>
          </div>
          <div className="d-flex flex-column pr-5">
            <p>
              The below pricing is only an Indication and is subject to change
              based on documents submitted.
            </p>
          </div>
        </div>
        {/* <div id="headercontiner">
          <p id="Peoheader">
            PEO :
            <span id="Peoheadersub"> Proposed Fees & Optional Services</span>
          </p>
        </div>

        <div id="overviewcontiner">
          <p id="overviewRow">Overview</p>
        </div> */}

        {/* <div id="overViewSubContainer">
          <p id="overviewSub">
            Thank you for partnering with Engage PEO. We greatly appreciate the
            opportunity and look forward to establishing a mutually valued &
            lasting relationship. The following is a detailed overview of our
            proposed fees and optional services - designed to enhance the
            “traditional” PEO & Payroll Vendor experience.
          </p>
        </div> */}
      </div>
    );
  };

  FooterContent = () => {
    let { companyProfile, requestedPricing } = this.state;
    console.dir(companyProfile);
    console.dir(requestedPricing);
    let Pay_Cycle = requestedPricing.WeeklySelect;
    let payCycleDisplay =
      Pay_Cycle === "a"
        ? "Weekly"
        : Pay_Cycle === "b"
        ? "Bi-Weekly"
        : "Semi-Monthly";
    return (
      <div id="pdf-main-contents">
        <div
          className="d-flex flex-column pr-5"
          style={{ marginLeft: "50px", marginTop: "10px" }}
        >
          <p>
            <p>
              Please note: The rates indicated above are subjected to FUTA, SUTA
              and security wage thresholds.
            </p>
            <p>*The Admin 'per check' rate shown above is {payCycleDisplay}</p>
            <p>
              For more info please contact :
              <a href="mailto:affinity@engagepeo.com">
                {" "}
                affinity@engagepeo.com
              </a>
            </p>
          </p>
        </div>
      </div>
    );
  };

  downloadPdf = (
    dataToShow,
    selectedCarrier,
    isQuoteAvailablePEO,
    adminType,
    saveToSystem = true
  ) => {
    return new Promise((resolve, reject) => {
      try {
        let download_data = [];
        download_data.push(this.MainContent());
        download_data.push(this.FooterContent());
        console.log(dataToShow);

        let len = dataToShow.length;
        let indx = 0;
        let quoteTableIdList = [];
        const firstIncrement = 13;
        const generalIncrement = 40;
        let increment;
        let pageCount = 0;
        while (len > 0) {
          if (indx === 0) {
            increment = firstIncrement;
          } else {
            increment = generalIncrement;
          }
          let dataToShowTemp = dataToShow.slice(indx, indx + increment);
          let id = selectedCarrier + "_program" + pageCount;

          download_data.push(
            <DataTable
              style={{ width: "960px", marginLeft: "50px" }}
              data={dataToShowTemp}
              selectedCarrier={selectedCarrier}
              id={id}
              isQuoteAvailablePEO={isQuoteAvailablePEO}
              adminType={adminType}
              tableName={"first"}
              requestedPricing={this.state.requestedPricing}
              paygoWcRate={this.state.paygoWcRate}
            />
          );

          quoteTableIdList.push(id);
          indx += increment;
          len -= increment;
          pageCount += 1;
        }

        // download_data.push(
        //   this.RatesWeekly(),
        //   this.Questions(),
        //   this.PdfFormData()
        // );

        this.setState({ download_data }, async () => {
          let pdfBlob = await this.exportToPdf(
            quoteTableIdList,
            selectedCarrier,
            saveToSystem
          ).catch((e) => console.log("error in etp", e));

          this.setState({ download_data: [] });

          resolve(pdfBlob);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  downloadPdfWrapper = async ({
    dataToShow,
    selectedCarrier,
    isQuoteAvailablePEO,
    adminType,
  }) => {
    try {
      this.setState({ displayRatesLoader: true });
      await this.downloadPdf(
        dataToShow,
        selectedCarrier,
        isQuoteAvailablePEO,
        adminType
      );
    } catch (error) {
      console.log("error: ", error);
      this.setState({ download_data: [] }, () => {
        this.setState({ displayRatesLoader: false });
      });
    }
  };

  exportToPdf = async (
    quoteTableIdList,
    selectedCarrier,
    saveToSystem = true
  ) => {
    let { requestedPricing, companyProfile } = this.state;
    return new Promise(async (resolve, reject) => {
      let listToShow = [];
      try {
        console.log("quoteTableIdList: ", quoteTableIdList);
        for (let index in quoteTableIdList) {
          let mainElement = document.createElement("div");
          mainElement.setAttribute("id", `temp-div-download`);

          mainElement.setAttribute("type", "hidden");
          document.body.append(mainElement);

          let pdfMainContent = document
            .getElementById("pdf-main-content")
            .cloneNode(true);

          let pdfMainContents = document
            .getElementById("pdf-main-contents")
            .cloneNode(true);

          // let RatesWeekly;
          // if (!requestedPricing["checked"]) {
          //   RatesWeekly = document
          //     .getElementById("Rates-Weekly")
          //     .cloneNode(true);
          // }

          // let pdfQuestions = document
          //   .getElementById("pdf-questions")
          //   .cloneNode(true);

          // let pdfFormContent = document
          //   .getElementById("pdf-form-data")
          //   .cloneNode(true);

          const pdf = new jsPDF("p", "mm", "a4");

          if (index == 0) {
            mainElement.appendChild(pdfMainContent);
          }

          let input = document
            .getElementById(quoteTableIdList[index])
            .cloneNode(true);

          mainElement.appendChild(input);
          mainElement.appendChild(pdfMainContents);
          // if (!requestedPricing["checked"]) {
          //   if (index == quoteTableIdList.length - 1) {
          //     mainElement.appendChild(RatesWeekly);
          //   }
          // }
          console.log("mainElement", mainElement);
          let canvas = await html2canvas(mainElement, {
            width: 1080,
            height: 1527,
          });

          const imgData = canvas.toDataURL("image/jpeg");
          const margin = 8;
          const documentHeight = pdf.internal.pageSize.getHeight();
          const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;

          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
          let heightLeft = pdfHeight;
          let position = 5;

          pdf.addImage(imgData, "JPEG", margin, position, pdfWidth, pdfHeight);

          heightLeft -= documentHeight;
          console.log(heightLeft);

          // while (heightLeft >= 0) {
          //   position = heightLeft - pdfHeight + 5;
          //   pdf.addPage();
          //   pdf.addImage(
          //     imgData,
          //     "JPEG",
          //     margin,
          //     position,
          //     pdfWidth,
          //     pdfHeight
          //   );
          //   heightLeft -= documentHeight;
          // }

          // if (index == quoteTableIdList.length - 1) {
          //   pdf.addPage();
          //   let acknowledgeElement = document.createElement("div");
          //   acknowledgeElement.setAttribute("id", `temp-div-download-ack`);

          //   acknowledgeElement.setAttribute("type", "hidden");
          //   document.body.append(acknowledgeElement);

          //   // acknowledgeElement.appendChild(pdfQuestions);
          //   // acknowledgeElement.appendChild(pdfFormContent);

          //   let ackCanvas = await html2canvas(acknowledgeElement, {
          //     width: 1080,
          //     height: 1527,
          //   });

          //   const ackImgData = ackCanvas.toDataURL("image/jpeg");
          //   let heightLeft = pdfHeight;
          //   let position = 5;

          //   pdf.addImage(
          //     ackImgData,
          //     "JPEG",
          //     margin,
          //     position,
          //     pdfWidth,
          //     pdfHeight
          //   );

          //   heightLeft -= documentHeight;
          //   console.log(heightLeft);

          //   while (heightLeft >= 0) {
          //     position = heightLeft - pdfHeight + 5;
          //     pdf.addPage();
          //     pdf.addImage(
          //       ackImgData,
          //       "JPEG",
          //       margin,
          //       position,
          //       pdfWidth,
          //       pdfHeight
          //     );
          //     heightLeft -= documentHeight;
          //   }
          //   try {
          //     acknowledgeElement.remove();
          //   } catch (e) {}
          // }

          let blobPdf = new Blob([pdf.output("blob")], {
            type: "application/pdf",
          });

          listToShow.push(blobPdf);

          try {
            mainElement.remove();
          } catch (e) {}
        }

        let finalPdf = await mergePdf(listToShow);
        console.log("finalPdf: ", finalPdf);
        if (saveToSystem) {
          var url = URL.createObjectURL(finalPdf);
          const link = document.createElement("a");
          // create a blobURI pointing to our Blob
          link.href = url;
          link.download = `${companyProfile?.companyName?.value}.pdf`;
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        }

        resolve(finalPdf);
      } catch (error) {
        console.log("error in pdf", error);
        reject(error);
      }
    });
  };

  isPaygoGenerated = (payoEligibility) => {
    let response = false;
    try {
      let amtrustAccountDetails = JSON.parse(
        sessionStorage.getItem("amtrustAccountDetails")
      );
      if (
        amtrustAccountDetails.QuoteId &&
        payoEligibility?.Eligibility !== "Decline"
      ) {
        response = true;
      }
    } catch (error) {
      response = false;
    }

    return response;
  };

  isPeoGenerated = (dataToShow, payoEligibility) => {
    let response = dataToShow?.length > 0;
    if (payoEligibility?.Eligibility === "Decline") {
      response = false;
    }

    return response;
  };

  getPaygoEligibilityStyle = (data) => {
    let { Eligibility } = data;
    let colorMap = {
      BindEligible: "rgb(138, 185, 63)",
      // BindEligible: "rgb(135, 250, 32)",
      Refer: "rgb(255, 215, 55)",
      Decline: "red",
    };
    let response = {};

    let color = colorMap?.[Eligibility];

    if (color) {
      response.color = color;
    }

    return response;
  };

  uploadIndicationToS3 = (
    dataToShow,
    selectedCarrier,
    isQuoteAvailablePEO,
    adminType
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
        let key = `${quoteData.date}@${quoteData.uuid}`;

        let blob = await this.downloadPdf(
          dataToShow,
          selectedCarrier,
          isQuoteAvailablePEO,
          adminType,
          false
        );

        let apiResponse = await axios
          .post(awsUrl2 + "/api/uploadIndication", { key })
          .catch((e) => console.log(e));

        let signedUrl = apiResponse.data.signedURL;

        let s3Response = await axios
          .put(signedUrl, blob)
          .catch((e) => console.log(e));

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  updateUserStatusTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/updateUserStatusTable", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateUserStatusTable", error);
          reject();
        });
    });
  };

  onClickDownloadQuestionnaire = async (email, sortKeyList) => {
    try {
      const response = await axios.get(
        "https://engage-dashboard-files-dev.s3.amazonaws.com/static/engagePDF.pdf",
        {
          responseType: "blob",
        }
      );

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `Engage Group Health Questionare.pdf`); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
    try {
      sessionStorage.setItem("downloadGroupHealth", "true");

      let dataToSendPromise = [];

      for (let sortKey in sortKeyList) {
        dataToSendPromise.push(
          this.updateUserStatusTable({
            user_email_id: email,
            uuid_carrier: sortKeyList[sortKey],
            downloadGroupHealth: "true",
          })
        );
      }

      Promise.all(dataToSendPromise);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    let {
      selectedCarrier,
      errorStatements,
      displayPaygoEligibility,
      isDisabled,
      download_data,
      requestedPricing,
    } = this.state;

    let {
      payoEligibility,
      adminType,
      disablePeoSubmitToUw,
      disablePaygoSubmitToUw,
      disablePaygoBindOnline,
      isSalesforce,
      sendToSalesforce,
      admin_type_values,
      data,
    } = this.props;
    let dataToShow =
      data && selectedCarrier && data[selectedCarrier]
        ? data[selectedCarrier]
        : [];

    let isQuoteAvailable = {
      paygo: () => this.isPaygoGenerated(payoEligibility),
      peo: () => this.isPeoGenerated(data["peo"], payoEligibility),
    };

    let perCheckFrequencyMap = {
      a: "Weekly",
      b: "Bi-Weekly",
      c: "Semi-Monthly",
    };
    let perCheckFrequency = "";

    try {
      perCheckFrequency = perCheckFrequencyMap[requestedPricing?.WeeklySelect];
    } catch (error) {}

    let isPaygoBindEligible =
      payoEligibility?.Eligibility === "BindEligible" ? true : false;

    let isQuoteAvailablePEO = isQuoteAvailable["peo"]();

    let salesID = sessionStorage.getItem("salesID");
    let domain = sessionStorage.getItem("domain");

    let primay_key = domain || salesID || "";
    let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));

    return (
      <div className="quote-table">
        <div className="headingboxstyle">
          <div className="font-family-montserrat-bold">Initial Pricing</div>
          <div className="companyProfileheadertwo"></div>
        </div>

        {/* <div className="d-flex align-items-center justify-content-center">
          <div
            className="font-family-montserrat-semi-bold d-flex align-items-center mb-3"
            style={{ color: "gray", fontSize: "1.2rem" }}
          >
            Select your preferred Option
          </div>
        </div> */}

        {!this.props.loading ? (
          <>
            <div className="paygo-and-peo-container">
              <div className="text-left" style={{ marginBottom: "10px" }}>
                The below pricing is only an Indication and is subject to change
                based on documents submitted.
              </div>
              {/* <div className="d-flex align-items-center justify-content-center mb-4 paygoandpeo w-0">
              <button
                className="btn peo-btn"
                style={
                  selectedCarrier === "peo"
                    ? { backgroundColor: "#8ab93f" }
                    : { backgroundColor: "grey" }
                }
                disabled={!this.props.isPeoRequired}
                onClick={() => this.props.setSelectedCarrier("peo")}
              >
                PEO
              </button>
              <button
                className="btn paygo-btn"
                style={
                  selectedCarrier === "paygo"
                    ? { backgroundColor: "#8ab93f" }
                    : { backgroundColor: "grey" }
                }
                disabled={!this.props.isPaygoRequired}
                onClick={() => this.props.setSelectedCarrier("paygo")}
              >
                PAYGO
              </button>
            </div> */}

              <div
                // className="container-fluid quote-table-box"
                id="quote-table-box"
              >
                {isQuoteAvailable[selectedCarrier]() ? (
                  <>
                    <DataTable
                      data={dataToShow}
                      selectedCarrier={selectedCarrier}
                      isQuoteAvailablePEO={isQuoteAvailablePEO}
                      adminType={adminType}
                      tableName={"first"}
                      requestedPricing={this.state.requestedPricing}
                      paygoWcRate={this.state.paygoWcRate}
                    />
                    {/* <div
                    className="text-left font-family-montserrat-semi-bold quotePageTableContent"
                    style={{ fontSize: "14px", marginInline: "2rem" }}
                  >
                    *Billing rates will be reduced, per employee, by the
                    percentages set forth aside after FUTA, SUTA, and Social
                    Security limits have been met.
                  </div> */}
                    {/* <div
                    className="text-left font-family-montserrat-semi-bold quotePageTableContent"
                    style={{ fontSize: "14px", marginInline: "2rem" }}
                  >
                    *Workers' Compensation will not be charged on tipped wages,
                    nor will it be charged on the premium portion of overtime
                    wages.
                  </div> */}
                  </>
                ) : (
                  <div
                    className="text-left font-family-montserrat-semi-bold"
                    style={{ fontSize: "14px", marginInline: "2rem" }}
                  >
                    {errorStatements[selectedCarrier]}
                  </div>
                )}
                {selectedCarrier === "paygo" &&
                  displayPaygoEligibility &&
                  payoEligibility?.Details?.length > 0 && (
                    <div className="paygo-eligibility-container d-flex justify-content-between mt-3 font-family-montserrat-semi-bold">
                      {payoEligibility.Details.map((e) => (
                        <div key={e.Type}>
                          <div>{e.Type}</div>
                          <div style={this.getPaygoEligibilityStyle(e)}>
                            {e.Eligibility}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
              <div className="text-left mt-3" style={{ marginBottom: "10px" }}>
                Please note: The rates indicated above are subjected to FUTA,
                SUTA and security wage thresholds.
              </div>
              <div className="text-left mt-3" style={{ marginBottom: "10px" }}>
                *The Admin 'per check' rate shown above is{" "}
                <span style={{ color: "#427cbc" }}>{perCheckFrequency}</span>.
              </div>
              {isQuoteAvailable[selectedCarrier]() && (
                <>
                  <div className="row">
                    <div className="quote-button-container col">
                      {/* <button
                    className="btn export-btn button_color"
                    disabled={this.props.loading}
                    id="export-button"
                    onClick={() =>
                      this.downloadPdfWrapper({
                        dataToShow,
                        selectedCarrier,
                        isQuoteAvailablePEO,
                        adminType,
                      })
                    }
                  >
                    Download Indication
                  </button> */}
                      <div
                        class="downloadbtnstyle"
                        disabled={this.props.loading}
                        onClick={() =>
                          this.downloadPdfWrapper({
                            dataToShow,
                            selectedCarrier,
                            isQuoteAvailablePEO,
                            adminType,
                          })
                        }
                      >
                        <div>
                          <img
                            className="downlaodnewlogo"
                            src={downlaodlogoone}
                            alt="downlaodnewlogo"
                          />
                        </div>
                        <div>
                          <b>Download Price Indication</b>
                        </div>
                      </div>
                    </div>
                    {selectedCarrier === "paygo" && (
                      <>
                        <div className="quote-button-container col">
                          <button
                            className="btn button_color export-btn"
                            disabled={disablePaygoSubmitToUw}
                            onClick={async (e) => {
                              if (disablePaygoSubmitToUw) return;
                              await this.uploadIndicationToS3(
                                dataToShow,
                                selectedCarrier,
                                isQuoteAvailablePEO,
                                adminType
                              );
                              this.props.onClickProceedToBind("paygo");
                            }}
                          >
                            Submit to Underwriter (PAYGO)
                          </button>
                        </div>
                        {isPaygoBindEligible && (
                          <div className="quote-button-container col">
                            <button
                              className="btn button_color export-btn"
                              disabled={disablePaygoBindOnline}
                              onClick={async (e) => {
                                if (disablePaygoBindOnline) return;
                                await this.uploadIndicationToS3(
                                  dataToShow,
                                  selectedCarrier,
                                  isQuoteAvailablePEO,
                                  adminType
                                );
                                this.props.onClickProceedToBind("paygo", true);
                              }}
                            >
                              Proceed to bind online (PAYGO)
                            </button>
                          </div>
                        )}
                      </>
                    )}
                    {/* {selectedCarrier === "peo" && (
                  <div className="quote-button-container col">
                    <button
                      className="btn button_color export-btn"
                      disabled={disablePeoSubmitToUw}
                      onClick={async (e) => {
                        if (disablePeoSubmitToUw) return;
                        await this.uploadIndicationToS3(
                          dataToShow,
                          selectedCarrier,
                          isQuoteAvailablePEO,
                          adminType
                        );
                        this.props.onClickProceedToBind("peo");
                      }}
                    >
                      Submit to Underwriter (PEO)
                    </button>
                  </div>
                )} */}
                  </div>
                </>
              )}

              {selectedCarrier === "paygo" && isQuoteAvailable["paygo"]() && (
                <div className="container-fluid quote-table-box mt-5">
                  <DataTable
                    data={dataToShow}
                    selectedCarrier={selectedCarrier}
                    isQuoteAvailablePEO={isQuoteAvailablePEO}
                    adminType={adminType}
                    tableName={"second"}
                    requestedPricing={this.state.requestedPricing}
                  />
                </div>
              )}

              {download_data.length > 0 && (
                <div
                  className="create-html-container"
                  style={{ visibility: "hidden", position: "absolute" }}
                >
                  {download_data}
                </div>
              )}
            </div>

            <div className="bindquotebtnstyle">
              <div className="bindquotebtnstyletwo">
                <div className="bindquotebtn">
                  <button onClick={this.props.handleShowUW}>
                    {" "}
                    Click here to get Bindable Quote
                    <MdKeyboardDoubleArrowRight size={30} />
                  </button>
                </div>

                {!isDisabled && (
                  <div className="bindquotebtn">
                    <button
                      onClick={(e) => {
                        this.props.downloadGroupHealthClicked(true);
                        this.onClickDownloadQuestionnaire(
                          primay_key,
                          sortKeyList
                        );
                      }}
                    >
                      Download Group Health Questionnaire
                      <MdKeyboardDoubleArrowRight size={30} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <SmallLoader />
          </div>
        )}
      </div>
    );
  }
}

let firstCaps = (st) => {
  try {
    return st
      .split(" ")
      .map((e) => {
        if (e && e.trim()) {
          e = e.trim().toLowerCase();
          return e[0].toUpperCase() + e.substring(1);
        }
      })
      .join(" ");
  } catch (error) {
    console.log(error);
    return st;
  }
};

const DataTable = (props) => {
  console.log("props", props);
  let rowData = props.data.map((obj, indx) => {
    let admin;
    if (isNaN(obj.admin)) {
      admin = obj.admin;
    } else {
      admin = roundValues(obj.admin);
      if (props.adminType === "percent") {
        admin += "%";
      } else {
        admin = "$" + admin;
      }
    }

    return (
      <tr key={indx}>
        <td className="font-family-montserrat-semi-bold">
          {obj.state.toUpperCase()}
        </td>
        <td>{String(obj.wc_code).padStart(4, "0")}</td>
        <td
          className="desc-td"
          style={{ overflow: "hidden", textAlign: "inherit", width: "30%" }}
          title={obj.wc_desc}
        >
          <Clamp lines={1}>{firstCaps(obj.wc_desc)}</Clamp>
        </td>
        {props.isQuoteAvailablePEO &&
          !(
            props.selectedCarrier === "paygo" && props.tableName === "second"
          ) && (
            <>
              <td>{roundValues(obj.fica) + "%"}</td>
              <td>{roundValues(obj.futa) + "%"}</td>
              <td>
                {obj.suta === "client" ? (
                  <>
                    Client<br></br>SUTA
                  </>
                ) : isNaN(obj.suta) ? (
                  obj.suta
                ) : Number(obj.suta) === 0 ? (
                  "TBD"
                ) : (
                  roundValues(obj.suta) + "%"
                )}
              </td>
            </>
          )}
        {/* <td>
          {!(props.selectedCarrier === "paygo" && props.tableName === "first")
            ? obj.net_rate && !isNaN(obj.net_rate)
              ? roundValues(obj.net_rate) + "%"
              : `${obj.net_rate}**`
            : props.paygoWcRate}
        </td> */}
        <td>{obj?.wcRate ? (roundValues(obj.wcRate) + "%") : ""}</td>
        {props.isQuoteAvailablePEO &&
          !(
            props.selectedCarrier === "paygo" && props.tableName === "second"
          ) && <td>{admin}</td>}
        {/* <td>{roundValues(obj.base_rate) + "%"}</td>
        <td>{roundValues(obj.limit) + "%"}</td> */}
      </tr>
    );
  });

  let tableData = (
    <table
      className="table table-sm table-bordered text-center"
      id="quote-table"
    >
      <thead>
        <tr>
          <th
            style={{
              // maxWidth: "40px",
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            State
          </th>
          <th
            style={{
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC<br></br>Code
          </th>
          <th
            style={{
              // maxWidth: "150px",
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC Description
          </th>
          {props.isQuoteAvailablePEO &&
            !(
              props.selectedCarrier === "paygo" && props.tableName === "second"
            ) && (
              <>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  FICA
                </th>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  FUTA
                </th>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  SUTA
                </th>
              </>
            )}
          <th
            style={{
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC<br></br>
          </th>
          {props.isQuoteAvailablePEO &&
            !(
              props.selectedCarrier === "paygo" && props.tableName === "second"
            ) && (
              <>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  Admin*
                </th>
              </>
            )}
          {/* <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            Base<br></br>Rate
          </th> */}
          {/* <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            <sup>*</sup>After<br></br>
            FUTA/SUTA <br></br>
            Limit
          </th> */}
        </tr>
      </thead>
      <tbody
        id="GetQutoeTablebody"
        style={{
          verticalAlign: "middle",
        }}
      >
        {rowData}
      </tbody>
    </table>
  );

  return (
    <div
      style={props.style}
      className="table-responsive table-wrapper"
      id={props.id}
    >
      {tableData}
    </div>
  );
};

const AdminTypeValues = ({ admin_type_values }) => {
  let admin_values_list = [];
  for (let key in admin_type_values) {
    admin_values_list.push(
      <div className="col text-center admin_type_value" key={key}>
        <span>
          <b>{firstCaps(key?.split("_").join(" ")) + ": "}</b>
        </span>
        <span style={{ fontSize: "16px", paddingLeft: "5px" }}>
          {"$" + roundValues(admin_type_values?.[key]?.fee)}
        </span>
      </div>
    );
  }
  return (
    <div className="container-fluid quote-table-box mt-5 mb-3">
      <div className="containter">
        <div className="row">{admin_values_list}</div>
      </div>
    </div>
  );
};

export default QuoteTable;

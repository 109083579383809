import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import LoadingOverlay from "react-loading-overlay";
import $ from "jquery";
import Clamp from "react-multiline-clamp";
import { roundValues } from "../../utils/math_functions";

const headingMap = {
  first: " Upload loss run",
  second: "Upload a file showing SUTA rates by state",
  third: "Upload number of beds by state and location.",
  fourth: "Upload the completed Group Health Questionnaire",
  fifth: "Upload any other documents",
};

const folderMap = {
  first: "LossRun",
  second: "OtherDocuments",
};

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      fileList: this.props.fileList,
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      docUploaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
    ) {
      this.setState({ fileList: this.props.fileList });
    }
    if (
      this.props.docUploaded !== prevProps.docUploaded &&
      this.props.docUploaded === true &&
      prevProps.docUploaded === false
    ) {
      this.handleUpload();
    }
  }

  updateFileList = (name, newFile) => {
    let { fileList } = this.state;
    if (fileList[name][newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[name][newFile.name] = newFile;
    this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
  };

  removeFile = (name, fileName) => {
    let { fileList } = this.state;
    delete fileList[name][fileName];
    this.setState({ fileList });
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl + "/api/uploadLossForm", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  updateUserStatusTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/updateUserStatusTable", dataToSend)
        .then((res) => {
          console.log("dataToSend", dataToSend);
          let tempQuesList = JSON.parse(JSON.stringify(this.props.quesList));
          this.props.updateSubmitQuesList(tempQuesList);
          resolve();
        })
        .catch((error) => {
          console.log("error in API-updateUserStatusTable", error);
          reject();
        });
    });
  };

  updateUserTracking = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
          JSON.stringify({
            domain: dataToSend.domain,
            fileListToStore: dataToSend.fileListToStore,
          })
        )
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  handleUpload = async () => {
    let { updatePopUpData, handleDocUploaded } = this.props;
    let { fileList } = this.state;

    $("#loader").css("display", "block");

    let domain = sessionStorage.getItem("domain");

    console.log("inside handle upload");
    try {
      let { fileList } = this.state;
      // this.setState({ uploadingInProgress: true });
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
      let domain = sessionStorage.getItem("domain");

      let { salespersonCheck } = await this.props.salesPersonLoginCheck();
      let salesID;
      try {
        salesID = sessionStorage.getItem("salesID");
      } catch (error) {
        console.log(error);
      }

      let primay_key = domain
        ? domain
        : salespersonCheck && salesID
        ? salesID.toLowerCase()
        : currProspect &&
          currProspect?.companyProfile?.companyName &&
          currProspect?.companyProfile?.companyName?.value
        ? currProspect?.companyProfile?.companyName?.value?.toLowerCase()
        : "";
      let formatDate = (timestamp) => {
        if (!timestamp) return "Invalid Date";
        const date = new Date(timestamp);
        return date.toLocaleDateString(); // Customize the format if needed
      };
      let comp_name = currProspect?.companyProfile?.companyName?.value || "";
      let fein = currProspect?.companyProfile?.fein?.value || "";
      let effective_date =
        formatDate(currProspect?.companyProfile?.effectiveDate?.value) || "";
      let opertaions =
        currProspect?.companyProfile?.descriptionOfOperations?.value || "";
      let Pay_Cycle = currProspect?.requestedPricing?.WeeklySelect || "";
      let payCycleDisplay =
        Pay_Cycle === "a"
          ? "Weekly"
          : Pay_Cycle === "b"
          ? "Bi-Weekly"
          : "Semi-Monthly";

      let email = primay_key;
      let email2 = email
        .split(" ")
        .join("")
        .toLowerCase();
      const folder1 = `engage/${email2}/${quoteData.uuid}_${quoteData.date}`;
      let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
      let dataToSendPromise = [];

      let promiseList = [],
        fileListToStore = [];

      for (let key in fileList) {
        if (fileList[key]) {
          for (let fileName in fileList[key]) {
            let fileType = fileName.split(".").reverse()[0];
            const folder2 = folderMap[key];
            fileListToStore.push({
              key: `${folder1}/${folder2}/${fileName}`,
              fileType,
              keyToFile: key,
            });

            const postDataS3 = {
              email,
              folder1,
              folder2,
              fileName,
              fileType,
            };
            if (fileList[key][fileName] !== "#empty")
              promiseList.push(
                this.uploadFile(fileList[key][fileName], postDataS3)
              );
          }
        }
      }

      Promise.all(promiseList)
        .then((res) => {
          for (let sortKey in sortKeyList) {
            dataToSendPromise.push(
              this.updateUserStatusTable({
                user_email_id: email,
                uuid_carrier: sortKeyList[sortKey],
                fileListToStore,
                doc_status: "uploaded",
                quesList: this.props.quesList,
                showUW: this.props.showUW,
              })
            );
          }

          if (domain) {
            dataToSendPromise.push(
              this.updateUserTracking({
                domain: domain,
                fileListToStore: fileListToStore,
              }).catch((err) => console.log("can't update user tracking data"))
            );
          }

          axios
            .post(
              awsUrl + `/api/sendSubmissionEmail`,
              JSON.stringify({
                company_name: comp_name,
                effective_date: effective_date,
                fein: fein,
                opertaions: opertaions,
                data: this.props.data,
                pay_cycle: payCycleDisplay,
              })
            )
            .then((res) => {
              console.log("email sent");
            })
            .catch((err) => {
              console.log("error in email");
            });

          handleDocUploaded(false);

          Promise.all(dataToSendPromise)
            .then((res) => {
              $("#loader").css("display", "none");

              this.setState({
                uploadingInProgress: false,
                docUploaded: true,
              });
              this.setState({
                uploadMsg:
                  fileList.first && Object.keys(fileList.first).length > 0
                    ? "files uploaded"
                    : "",
                errMsg: undefined,
              });
            })
            .catch((error) => {
              console.log("error in API", error);
              $("#loader").css("display", "none");
            });
        })
        .catch((error) => {
          console.log("error: ", error);
          this.setState({
            uploadingInProgress: false,
            errMsg: "Error",
            uploadMsg: undefined,
          });
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error: ", error);
    }
    // updatePopUpData({
    //   show: true,
    //   title:
    //     "Thank you for your submission! Our team will reach out within 24 hours. Alternatively, you can contact us at affinity@engagepeo.com.",
    //   children: (
    //     <>
    //       <br></br>
    //       <span style={{ fontWeight: "bold" }}>
    //         Files once uploaded cannot be deleted
    //       </span>
    //     </>
    //   ),
    //   acceptBtn: "Yes",

    //   acceptBtnCallback: async () => {
    //     try {
    //       let { fileList } = this.state;
    //       // this.setState({ uploadingInProgress: true });
    //       let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    //       let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    //       let domain = sessionStorage.getItem("domain");

    //       let { salespersonCheck } = await this.props.salesPersonLoginCheck();
    //       let salesID;
    //       try {
    //         salesID = sessionStorage.getItem("salesID");
    //       } catch (error) {
    //         console.log(error);
    //       }

    //       let primay_key = domain
    //         ? domain
    //         : salespersonCheck && salesID
    //         ? salesID.toLowerCase()
    //         : currProspect &&
    //           currProspect?.companyProfile?.companyName &&
    //           currProspect?.companyProfile?.companyName?.value
    //         ? currProspect?.companyProfile?.companyName?.value?.toLowerCase()
    //         : "";

    //       let comp_name =
    //         currProspect?.companyProfile?.companyName?.value || "";

    //       let email = primay_key;
    //       let email2 = email
    //         .split(" ")
    //         .join("")
    //         .toLowerCase();
    //       const folder1 = `engage/${email2}/${quoteData.uuid}_${quoteData.date}`;
    //       let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
    //       let dataToSendPromise = [];

    //       let promiseList = [],
    //         fileListToStore = [];

    //       for (let key in fileList) {
    //         if (fileList[key]) {
    //           for (let fileName in fileList[key]) {
    //             let fileType = fileName.split(".").reverse()[0];
    //             const folder2 = folderMap[key];
    //             fileListToStore.push({
    //               key: `${folder1}/${folder2}/${fileName}`,
    //               fileType,
    //               keyToFile: key,
    //             });

    //             const postDataS3 = {
    //               email,
    //               folder1,
    //               folder2,
    //               fileName,
    //               fileType,
    //             };
    //             if (fileList[key][fileName] !== "#empty")
    //               promiseList.push(
    //                 this.uploadFile(fileList[key][fileName], postDataS3)
    //               );
    //           }
    //         }
    //       }

    //       Promise.all(promiseList)
    //         .then((res) => {
    //           for (let sortKey in sortKeyList) {
    //             dataToSendPromise.push(
    //               this.updateUserStatusTable({
    //                 user_email_id: email,
    //                 uuid_carrier: sortKeyList[sortKey],
    //                 fileListToStore,
    //                 doc_status: "uploaded",
    //                 quesList: this.props.quesList,
    //                 showUW: this.props.showUW,
    //               })
    //             );
    //           }

    //           if (domain) {
    //             dataToSendPromise.push(
    //               this.updateUserTracking({
    //                 domain: domain,
    //                 fileListToStore: fileListToStore,
    //               }).catch((err) =>
    //                 console.log("can't update user tracking data")
    //               )
    //             );
    //           }

    //           axios
    //             .post(
    //               awsUrl + `/api/sendSubmissionEmail`,
    //               JSON.stringify({
    //                 company_name: comp_name,
    //               })
    //             )
    //             .then((res) => {
    //               console.log("email sent");
    //             })
    //             .catch((err) => {
    //               console.log("error in email");
    //             });

    //           handleDocUploaded(false);

    //           Promise.all(dataToSendPromise)
    //             .then((res) => {
    //               $("#loader").css("display", "none");

    //               this.setState({
    //                 uploadingInProgress: false,
    //                 docUploaded: true,
    //               });
    //               this.setState({
    //                 uploadMsg:
    //                   fileList.first && Object.keys(fileList.first).length > 0
    //                     ? "files uploaded"
    //                     : "",
    //                 errMsg: undefined,
    //               });
    //             })
    //             .catch((error) => {
    //               console.log("error in API", error);
    //               $("#loader").css("display", "none");
    //             });
    //         })
    //         .catch((error) => {
    //           console.log("error: ", error);
    //           this.setState({
    //             uploadingInProgress: false,
    //             errMsg: "Error",
    //             uploadMsg: undefined,
    //           });
    //           $("#loader").css("display", "none");
    //         });
    //     } catch (error) {
    //       console.log("error: ", error);
    //     }
    //   },
    // });
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      docUploaded,
    } = this.state;
    let { data } = this.props;
    console.log("firsttt", data);
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );
    let healthQuestionIsSelected = false;

    for (let key of currProspectDetails?.["companyProfileAddQuestions"] || []) {
      if (key["name"] === "qc2" && key["response"] === "Y") {
        healthQuestionIsSelected = true;
      }
    }

    let hasDownloadGroupHealth =
      sessionStorage.getItem("downloadGroupHealth") ||
      this.props.downloadGroupHealth
        ? true
        : false;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className="dropZonesContainer">
          <div>
            {this.props.showUW && (
              <div className="row d-flex justify-content-between ">
                {/* <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                  <CustomDropZone
                    name="first"
                    className="firstBoxUplaod"
                    fileList={fileList}
                    updateFileList={this.updateFileList}
                    removeFile={this.removeFile}
                    headingMap={headingMap}
                    uploadMsg={uploadMsg}
                  />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                  <CustomDropZone
                    name="second"
                    fileList={fileList}
                    updateFileList={this.updateFileList}
                    removeFile={this.removeFile}
                    headingMap={headingMap}
                    uploadMsg={uploadMsg}
                  />
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center ">
                  <CustomDropZone
                    name="third"
                    fileList={fileList}
                    updateFileList={this.updateFileList}
                    removeFile={this.removeFile}
                    headingMap={headingMap}
                    uploadMsg={uploadMsg}
                  />
                </div> */}
              </div>
            )}
            {/* <div className="row  d-flex justify-content-center my-4 ">
              {healthQuestionIsSelected && hasDownloadGroupHealth && (
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                  <CustomDropZone
                    name="fourth"
                    fileList={fileList}
                    updateFileList={this.updateFileList}
                    removeFile={this.removeFile}
                    headingMap={headingMap}
                    uploadMsg={uploadMsg}
                  />
                </div>
              )}
              {this.props.showUW && (
                <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center">
                  <CustomDropZone
                    name="fifth"
                    fileList={fileList}
                    updateFileList={this.updateFileList}
                    removeFile={this.removeFile}
                    headingMap={headingMap}
                    uploadMsg={uploadMsg}
                  />
                </div>
              )}
            </div> */}
          </div>

          <div className="d-flex flex-column">
            {/* <pre
              className={
                "text-center text-capitalize " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg || errMsg}
            </pre> */}
            {/* <button
              className="btn btn-warning btn-sm"
              style={{
                width: "min-content",
                color: "white",
                alignSelf: "center",
                backgroundColor: "#feae01",
                boxShadow: "0px 5px 12px grey",
              }}
              onClick={(event) => {
                if (hideButtons) return;
                event.preventDefault();
                this.handleUpload();
              }}
              disabled={
                this.props.brdCheck || uploadingInProgress || hideButtons
              }
            >
              Upload
            </button> */}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

let firstCaps = (st) => {
  try {
    return st
      .split(" ")
      .map((e) => {
        if (e && e.trim()) {
          e = e.trim().toLowerCase();
          return e[0].toUpperCase() + e.substring(1);
        }
      })
      .join(" ");
  } catch (error) {
    console.log(error);
    return st;
  }
};

const DataTable = (props) => {
  console.log("props", props);
  let rowData = props.data.map((obj, indx) => {
    let admin;
    if (isNaN(obj.admin)) {
      admin = obj.admin;
    } else {
      admin = roundValues(obj.admin);
      if (props.adminType === "percent") {
        admin += "%";
      } else {
        admin = "$" + admin;
      }
    }

    return (
      <tr key={indx}>
        <td className="font-family-montserrat-semi-bold">
          {obj.state.toUpperCase()}
        </td>
        <td>{String(obj.wc_code).padStart(4, "0")}</td>
        <td
          className="desc-td"
          style={{ overflow: "hidden", textAlign: "inherit", width: "30%" }}
          title={obj.wc_desc}
        >
          <Clamp lines={1}>{firstCaps(obj.wc_desc)}</Clamp>
        </td>
        {props.isQuoteAvailablePEO &&
          !(
            props.selectedCarrier === "paygo" && props.tableName === "second"
          ) && (
            <>
              <td>{roundValues(obj.fica) + "%"}</td>
              <td>{roundValues(obj.futa) + "%"}</td>
              <td>
                {obj.suta === "client" ? (
                  <>
                    Client<br></br>SUTA
                  </>
                ) : isNaN(obj.suta) ? (
                  obj.suta
                ) : Number(obj.suta) === 0 ? (
                  "TBD"
                ) : (
                  roundValues(obj.suta) + "%"
                )}
              </td>
            </>
          )}
        <td>
          {!(props.selectedCarrier === "paygo" && props.tableName === "first")
            ? obj.net_rate && !isNaN(obj.net_rate)
              ? roundValues(obj.net_rate) + "%"
              : `${obj.net_rate}**`
            : props.paygoWcRate}
        </td>
        {props.isQuoteAvailablePEO &&
          !(
            props.selectedCarrier === "paygo" && props.tableName === "second"
          ) && <td>{admin}</td>}
        {/* <td>{roundValues(obj.base_rate) + "%"}</td>
        <td>{roundValues(obj.limit) + "%"}</td> */}
      </tr>
    );
  });

  let tableData = (
    <table
      className="table table-sm table-bordered text-center"
      id="quote-table"
    >
      <thead>
        <tr>
          <th
            style={{
              // maxWidth: "40px",
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            State
          </th>
          <th
            style={{
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC<br></br>Code
          </th>
          <th
            style={{
              // maxWidth: "150px",
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC Description
          </th>
          {props.isQuoteAvailablePEO &&
            !(
              props.selectedCarrier === "paygo" && props.tableName === "second"
            ) && (
              <>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  FICA
                </th>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  FUTA
                </th>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  SUTA
                </th>
              </>
            )}
          <th
            style={{
              backgroundColor: "#427cbc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            WC<br></br>
          </th>
          {props.isQuoteAvailablePEO &&
            !(
              props.selectedCarrier === "paygo" && props.tableName === "second"
            ) && (
              <>
                <th
                  style={{
                    backgroundColor: "#427cbc",
                    color: "#ffffff",
                    verticalAlign: "middle",
                  }}
                >
                  Admin*
                </th>
              </>
            )}
          {/* <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            Base<br></br>Rate
          </th> */}
          {/* <th
            style={{
              backgroundColor: "#3e9ccc",
              color: "#ffffff",
              verticalAlign: "middle",
            }}
          >
            <sup>*</sup>After<br></br>
            FUTA/SUTA <br></br>
            Limit
          </th> */}
        </tr>
      </thead>
      <tbody
        id="GetQutoeTablebody"
        style={{
          verticalAlign: "middle",
        }}
      >
        {rowData}
      </tbody>
    </table>
  );

  return (
    <div
      style={props.style}
      className="table-responsive table-wrapper"
      id={props.id}
    >
      {tableData}
    </div>
  );
};

const AdminTypeValues = ({ admin_type_values }) => {
  let admin_values_list = [];
  for (let key in admin_type_values) {
    admin_values_list.push(
      <div className="col text-center admin_type_value" key={key}>
        <span>
          <b>{firstCaps(key?.split("_").join(" ")) + ": "}</b>
        </span>
        <span style={{ fontSize: "16px", paddingLeft: "5px" }}>
          {"$" + roundValues(admin_type_values?.[key]?.fee)}
        </span>
      </div>
    );
  }
  return (
    <div className="container-fluid quote-table-box mt-5 mb-3">
      <div className="containter">
        <div className="row">{admin_values_list}</div>
      </div>
    </div>
  );
};

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";
class CustomDropZone extends React.Component {
  render() {
    let { fileList, updateFileList, removeFile, uploadMsg } = this.props;

    let listToShow = [];

    for (let fileName in fileList) {
      listToShow.push(
        <div className="d-flex justify-content-center">
          <li
            className="m-3 d-flex justify-content-center"
            style={{ width: "100%" }}
            key={fileName}
          >
            <span>{fileName}</span>
          </li>
          <div className="d-flex justify-content-center align-items-center">
            {fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded" && (
              <span
                className="fw-bold"
                style={{ cursor: "pointer" }}
                onClick={() => removeFile(fileName)}
              >
                <MdDelete size={30} color="red" />
              </span>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="customDropZone-uw">
        <DropZone updateFileList={updateFileList} />
        {listToShow?.length > 0 ? (
          <ul style={{ fontSize: "12px" }}>{listToShow}</ul>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(file);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className="dropZoneText">Drag &amp; drop or browse to select file</p>
    </div>
  );
};

export default CustomDropZone;

import React from "react";
import { useState } from "react";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../../config";
import Swal from "sweetalert2";

export default function LearnMoreAboutUs(props) {
  const [first_name, setFirst_name] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();

  const formSubmit = async () => {
    if (first_name && email && phone && company) {
      axios
        .post(
          awsUrl2 + `/api/landingEmailTrigger`,
          JSON.stringify({
            first_name: first_name,
            email: email,
            phone: phone,
            company: company,
          })
        )
        .then((res) => {
          console.log("email sent");
          Swal.fire({
            position: "center",
            icon: "success",
            title:
              "Thank You!! Someone from our team will get in touch with you soon!!!!",
            showConfirmButton: false,
            timer: 2500,
            customClass: {
              popup: "my-popup-class",
              title: "my-title-class",
              icon: "my-icon-class",
            },
          });
        })
        .catch((err) => {
          console.log("error in email");
        });
    } else {
      alert("please fill all mandatory fields!!");
    }

    // let requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // await axios
    //   .post(
    //     "https://lh8add1jfj.execute-api.us-east-1.amazonaws.com/prod/mail-form/fourthhr",
    //     raw
    //   )
    //   .then(async (res) => {
    //     console.log(res);
    //   })
    //   .catch((error) => {
    //     console.log("error in sendign the data", error);
    //   });

    // console.log(raw);
  };

  return (
    <>
      <div className="learn-more-about-us">
        {/* <div className="row row-1 row-no-margin">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="learn-text">Learn more about us</div>
      </div> */}

        <div
        // onClick={async (e) => {
        // e.defaultPrevented();
        // if (props.domain && props.user) {
        //   await props.handleHomeGetQuoteClicked(props.domain, props.user);
        //   sessionStorage.clear();
        //   sessionStorage.setItem("homeCampaign", "true");
        //   window.location.href = `/Quote/?user=${props.user}&campaignName=${props.campaignName}`;
        // } else {
        // window.location.href = "/Quote";
        // }
        // }}
        >
          <div className="get-quote-now">
            <div className="get-quote-now-two">
              <span
                className="get-quote-now-text"
                //   style={{ textAlign: "center", color: "white" }}
              >
                <b>
                  SCHEDULE <br /> MEETING
                </b>
              </span>
            </div>
          </div>
        </div>
        <div className="form-col">
          <input
            type="text"
            placeholder="First Name"
            value={first_name}
            onChange={(e) => {
              setFirst_name(e.target.value);
            }}
          />
          <input
            type="email"
            placeholder="Email ID"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Company Name"
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
          <div className="btn-container">
            <button className="btn btn-sm " onClick={formSubmit}>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

const WhyPeo = (props) => {
  return (
    <div className="whypeoContainer">
      <div className="whypeobg">
        <div className="whyPeoBox">
          <h1 style={{ margin: "20px 0 20px 0", fontWeight: "1000" }}>
            WHY PEO?
          </h1>
          <p style={{ padding: "0px 50px 0 50px" }}>
            <b>
              The ROI of using a PEO (in cost savings alone) is 27.3% 10% Lower
              employee turnover Access to employee benefits including health
              insurance, retirement plans, pet insurance and moreMore time to
              focus on business goals and mission.
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyPeo;

import { window } from "d3";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function HomePageCarousel(props) {
  const [deviceType, setDeviceType] = useState("desktop");

  function updateDeviveType() {
    let _deviceType = "";
    let _width = window.innerWidth;

    if (_width >= 0 && _width <= 464) _deviceType = "mobile";
    else if (_width >= 465 && _width <= 1024) _deviceType = "tablet";
    else if (_width > 1024) _deviceType = "desktop";

    setDeviceType(_deviceType);
  }

  useEffect(() => {
    updateDeviveType();
  }, []);

  useEffect(() => {
    updateDeviveType();
  }, [window.innerWidth]);

  return (
    <div className="brand-carousel mb-4" style={{ marginTop: "50px" }}>
      <div style={{ marginInline: "auto", width: "85%" }}>
        <Carousel
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          keyBoardControl={false}
          // customTransition="all .5"
          responsive={responsive}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          deviceType={deviceType}
        >
          {props.brandsList.map((brand, index) => (
            <div
              style={{ width: "70%", height: "70%" }}
              className="d-flex align-items-center"
              key={index}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                className="d-block w-100"
                src={brand.src}
                alt={brand.title}
                title={brand.title}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

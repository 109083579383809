import React from "react";
import DashboardAdmin from "./DashboardAdmin";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import HeaderMainSubPart from "../common/HeaderMainSubPart";
import { logout } from "../../utils/common";
import { Check } from "@material-ui/icons";
import { Prev } from "react-bootstrap/esm/PageItem";

const peoTabName = {
  peo: "With PEO",
  peoW: "Without PEO",
};
class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab2",
      program: "PEO",
      source: "Affinity",
      dealMap: {},
      confirmDealMap: {},
      engagedList: [],
      campaignedList: [],
      salesForceList: [],
      wonList: [],
      lostList: [],
      allTabCheck: {
        tab1: false,
        tab2: false,
        tab3: false,
      },
      isLoading: true,
      lostListCommentsHistory: {},
      wonListCommentsHistory: {},
      selectedPeo: "peo",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  updateDealMap = (key, value) => {
    let { dealMap } = this.state;
    dealMap[key] = value;
    this.setState({ dealMap });
  };

  updateConfirmDealMap = (key, value) => {
    let { confirmDealMap } = this.state;
    confirmDealMap[key] = value;
    this.setState({ confirmDealMap });
  };

  componentDidMount = () => {
    sessionStorage.clear();
  };

  componentDidUpdate(prevProps, prevState) {
    let _lostListCommentsHistory = {};
    let _wonListCommentsHistory = {};
    const {
      allTabCheck,
      engagedList,
      wonList,
      lostList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));
    if (JSON.stringify(allTabCheck) !== JSON.stringify(prevState.allTabCheck)) {
      if (
        allTabCheck["tab1"] === true &&
        allTabCheck["tab2"] === true &&
        allTabCheck["tab3"] === true
      ) {
        let engagedIndicesToRemove = [];
        let campaignedIndicesToRemove = [];
        let salesforceIndicesToRemove = [];

        // shift engaged data to won/lost tabs
        let engagedDomainSet = new Set();
        for (let index in engagedList) {
          index = Number(index);
          let item = engagedList[index];
          if (item.dealConfirmed === "true") {
            if (item.dealFlag === "closed") {
              wonList.push(item);
            } else {
              lostList.push(item);
            }
            engagedIndicesToRemove.unshift(index);
            engagedDomainSet.add(item.domain);
          }
        }

        for (let index of engagedIndicesToRemove) {
          engagedList.splice(index, 1);
        }

        // shift campaigned data to won/lost tabs
        for (let index in campaignedList) {
          index = Number(index);
          let item = campaignedList[index];
          if (item.dealConfirmed === "true") {
            if (!engagedDomainSet.has(item.domain)) {
              if (item.dealFlag === "closed") {
                wonList.push(item);
              } else {
                lostList.push(item);
              }
            }
            campaignedIndicesToRemove.unshift(index);
          }
        }

        for (let index of campaignedIndicesToRemove) {
          campaignedList.splice(index, 1);
        }

        // shift salesforce data to won/lost tabs
        for (let index in salesForceList) {
          index = Number(index);
          let item = salesForceList[index];
          if (item.dealConfirmed === "true") {
            if (item.dealFlag === "closed") {
              wonList.push(item);
            } else {
              lostList.push(item);
            }
            salesforceIndicesToRemove.unshift(index);
          }
        }

        for (let index of salesforceIndicesToRemove) {
          salesForceList.splice(index, 1);
        }

        console.log("lost list", lostList);
        for (let index in wonList) {
          index = Number(index);
          let item = wonList[index];

          _wonListCommentsHistory[item.salesID] = item.admincomment;
        }

        for (let index in lostList) {
          index = Number(index);
          let item = lostList[index];

          _lostListCommentsHistory[item.salesID] = item.admincomment;
        }

        let campaignedObject = {
          peo: [],
          peoW: [],
        };
        let salesForceObject = {
          peo: [],
          peoW: [],
        };
        for (let i = 0; i < campaignedList.length; i++) {
          if (campaignedList[i]?.ispeowith &&
            campaignedList[i]?.ispeowith.toLowerCase() !== "no") {
            campaignedObject.peo.push(campaignedList[i]);
          } else {
            campaignedObject.peoW.push(campaignedList[i]);
          }
        }


        for (let i = 0; i < salesForceList.length; i++) {
          if (
            salesForceList[i]?.ispeowith &&
            salesForceList[i]?.ispeowith.toLowerCase() !== "no"
          ) {
            salesForceObject.peo.push(salesForceList[i]);
          } else {
            salesForceObject.peoW.push(salesForceList[i]);
          }
        }

        console.log("Suraj 123", salesForceList);

        this.setState({
          wonList,
          lostList,
          engagedList,
          campaignedList: campaignedObject,
          salesForceList: salesForceObject,
          isLoading: false,
          wonListCommentsHistory: _wonListCommentsHistory,
          lostListCommentsHistory: _lostListCommentsHistory,
        });
      }
    }
  }

  handleDealConfirmAll = (toMoveList) => {
    let {
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));

    toMoveList.sort((a, b) => a.index - b.index).reverse();

    for (let data of toMoveList) {
      let { index, dealFlag, rowData, tab } = data;

      delete rowData?.tableData?.checked;

      if (dealFlag === "closed") wonList.unshift(rowData);
      else if (dealFlag === "lost") lostList.unshift(rowData);

      if (tab === "tab1") {
        engagedList.splice(index, 1);
      } else if (tab === "tab2") {
        campaignedList.splice(index, 1);
      } else if (tab === "tab3") {
        salesForceList.splice(index, 1);
      }
    }

    this.setState({
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    });
  };

  handleDealConfirm = (tab, index, dealFlag, rowData) => {
    console.log(tab, index, dealFlag, rowData);
    let {
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));
    if (dealFlag === "closed") wonList.unshift(rowData);
    else if (dealFlag === "lost") lostList.unshift(rowData);
    this.setState({ wonList, lostList });

    if (tab === "tab1") {
      engagedList.splice(index, 1);
      this.setState({ engagedList });
    } else if (tab === "tab2") {
      campaignedList.splice(index, 1);
      this.setState({ campaignedList });
    } else if (tab === "tab3") {
      salesForceList.splice(index, 1);
      this.setState({ salesForceList });
    }
  };

  setRowDataList = (tab, list) => {
    let { allTabCheck } = JSON.parse(JSON.stringify(this.state));
    if (tab === "tab1") this.setState({ engagedList: list });
    else if (tab === "tab2") this.setState({ campaignedList: list });
    else if (tab === "tab3") this.setState({ salesForceList: list });
    allTabCheck[tab] = true;
    this.setState({ allTabCheck });
  };

  prepareEngagedList = () => {
    let { engagedList } = this.state;
    let engagedFilterList = {
      peo: [],
      peoW: [],
    };

    for (let rowData of engagedList) {
      if (rowData?.ispeowith &&
        rowData?.ispeowith.toLowerCase() !== "no") {
        engagedFilterList.peo.push(rowData);
      } else {
        engagedFilterList.peoW.push(rowData);
      }
    }

    return engagedFilterList;
  };

  getList = (tab) => {
    let { campaignedList, salesForceList } = this.state;

    let tableDataByTab = {
      tab1: () => this.prepareEngagedList(),
      tab2: () => campaignedList,
      tab3: () => salesForceList,
      // tab4: () => wonList,
      // tab5: () => lostList,
    };

    return tableDataByTab[tab]() || [];
  };

  updatewonAdminHistoryComments = (id, comment) => {
    let { wonListCommentsHistory } = JSON.parse(JSON.stringify(this.state));
    wonListCommentsHistory[id] = comment;
    this.setState({ wonListCommentsHistory });
  };

  updateLostAdminHistoryComments = (id, comment) => {
    let { lostListCommentsHistory } = JSON.parse(JSON.stringify(this.state));
    lostListCommentsHistory[id] = comment;
    this.setState({ lostListCommentsHistory });
  };

  render() {
    let {
      selected,
      program,
      source,
      dealMap,
      confirmDealMap,
      allTabCheck,
      wonList,
      lostList,
      isLoading,
      wonListCommentsHistory,
      lostListCommentsHistory,
      selectedPeo,
    } = this.state;
    const tabsMap = {
      tab2: { name: ">500", enabled: true, visible: true },
      tab1: { name: "<500", enabled: true, visible: true },
      tab3: { name: "Uploaded", enabled: true, visible: true },
      tab2: { name: ">500", enabled: true, visible: true },
      tab1: { name: "<500", enabled: true, visible: true },
    };

    return (
      <div className="dashboard-container">
        <div className="d-flex" style={{ borderBottomStyle: "groove" }}>
          <Header />
        </div>
        <HeaderMainSubPart />
        <div className="tabs p-4">
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px", fontFamily: "Montserrat" }}
          >
            {Object.keys(tabsMap).map((tab) =>
              tabsMap[tab].visible ? (
                <Nav.Item className={tabsMap[tab].enabled ? "" : "pl-1"}>
                  <Nav.Link
                    className={tabsMap[tab].enabled ? "" : "btn  text-grey"}
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                    disabled={!tabsMap[tab].enabled}
                  >
                    {tabsMap[tab].name}
                  </Nav.Link>
                </Nav.Item>
              ) : null
            )}
          </Nav>
          <Nav
            variant="pills"
            defaultActiveKey={selectedPeo}
            style={{
              fontSize: "16px",
              margin: "5px",
              fontFamily: "Montserrat",
              marginTop: "30px",
            }}
          >
            {Object.keys(peoTabName).map((tab) => (
              <Nav.Item>
                <Nav.Link
                  eventKey={tab}
                  onClick={() => this.setState({ selectedPeo: tab })}
                >
                  {peoTabName[tab]}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {
                <DashboardAdmin
                  tabName={tab}
                  selected={selected}
                  program={program}
                  source={source}
                  dealMap={dealMap}
                  updateDealMap={this.updateDealMap}
                  confirmDealMap={confirmDealMap}
                  updateConfirmDealMap={this.updateConfirmDealMap}
                  wonList={wonList}
                  lostList={lostList}
                  selectedPeo={selectedPeo}
                  setRowDataList={this.setRowDataList}
                  updatewonAdminHistoryComments={
                    this.updatewonAdminHistoryComments
                  }
                  updateLostAdminHistoryComments={
                    this.updateLostAdminHistoryComments
                  }
                  wonListCommentsHistory={wonListCommentsHistory}
                  lostListCommentsHistory={lostListCommentsHistory}
                  rowDataList={this.getList(tab)}
                  isLoading={isLoading}
                  allTabCheck={allTabCheck}
                  handleDealConfirm={this.handleDealConfirm}
                  handleDealConfirmAll={this.handleDealConfirmAll}
                />
              }
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

export default Tabs;

import React from "react";
import { Link } from "react-router-dom";
import LearnMoreAboutUs from "./LearnMoreAboutUs";
let HotScheduleAffinity = require("../../../images/affinity-new.png");
let mobilebackground = require("../../../images/mobilebackground.png");
let HotSchedulesLogo = require("../../../images/HotSchedulesLogo.png");
let EngageLogo = require("../../../images/Engage-PEO_logo_Final.png");
let ismobile = window.innerWidth < 992;
let ismobile1 = window.innerWidth < 700;
export default function TopImage(props) {
  return (
    <div className="top-image">
      <div className="d-flex justify-content-between first-row bringingcolumn">
        <div className="d-flex justify-content-center align-items-center engageLogostyle">
          {process.env.REACT_APP_MODE !== "beta" && (
            <Link
              to={"/"}
              // style={{ height: "150px", width: "265px" }}
              className="navbar-brand d-flex align-items-center justify-content-center hotlogotop"
            >
              <img
                src={EngageLogo}
                alt="affinity logo1"
                className="logoreduce"
              />
            </Link>
          )}
        </div>
        <div className="logo-2 d-flex justify-content-end align-items-center hotscheduleaffinitystyle">
          <Link to={"/"} className="navbar-brand d-flex align-items-center">
            {
              <img
                src={HotScheduleAffinity}
                alt="affinity logo1"
                className="logoreducing "
              />
            }
          </Link>
        </div>
      </div>
    </div>
  );
}

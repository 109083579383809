import React, { Component } from "react";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import { Auth } from "aws-amplify";
import { logout } from "../../utils/common";
import $ from "jquery";
import moment from "moment";

import TopImage from "../subcompo/sections-home/TopImage";
import OurServices from "../subcompo/sections-home/OurServices";
import HomePageCarousel from "../subcompo/sections-home/HomePageCarousel";
import FixedBall from "../subcompo/sections-home/FixedBall";
import StarRating from "../subcompo/sections-home/StarRating";
import SutaFavourites from "../subcompo/sections-home/SutaFavourites";
import WhyPeo from "../subcompo/sections-home/WhyPeo";
import HomeFooter from "../subcompo/sections-home/HomeFooter";
import TopImageTwo from "../subcompo/sections-home/TopImageTwo";
import { IoPersonSharp } from "react-icons/io5";

let imageURL = require("../../images/mask_group_small.png");
let fourthLogo = require("../../images/fourth-new.webp");
let twoPeopleImage = require("../../images/EngageLPimage.png");
let fourthpdflogo = require("../../images/fourth-new.webp");
let rectange2 = require("../../images/Rectangle 2.png");
let rectange5 = require("../../images/Rectangle 5.png");
let rectange7 = require("../../images/Rectangle 7.png");
let rectange13 = require("../../images/Rectangle 13.png");
let rectange10 = require("../../images/Rectangle 10.png");
let rectange11 = require("../../images/Rectangle 11.png");

let cityBuilding = require("../../images/city-building.png");
let timer = require("../../images/timer.png");
let file = require("../../images/file.png");
let technology = require("../../images/technology.png");
let whyEngageImageURL = require("../../images/whyEngageImage.png");
let fiveStarImage = require("../../images/StarRating.png");
let sutaFavorablebg = require("../../images/sutaFavorableBG.png");
let engageLogo = require("../../images/engageorangeLogo.png");

let ourServiceList = [
  { img: rectange2, text: "Unemployment Claims Administration" },
  { img: rectange5, text: "Workers’ Compensation & Risk Management" },
  { img: rectange7, text: "Employee Benefit Administration" },
  { img: rectange13, text: "Compliance & Healthcare Reform Consulting" },
  { img: rectange10, text: "Employee Benefit Programs" },
  { img: rectange11, text: "Employee Discounts" },
];

let whyUsList = [
  {
    img: cityBuilding,
    text: `In 2011, ${
      process.env.REACT_APP_MODE == "beta" ? "We" : "ChoiceHR"
    } became full-service Professional Employer Organization (PEO).`,
  },
  { img: timer, text: "Generate quotes within minutes with this platform." },
  { img: file, text: "Our extensive range of services and flexible approach." },
  {
    img: technology,
    text:
      "Unique combination of technology and services to restaurants and hospitality business across the country.",
  },
];

const brandsList = [
  { title: "AIG", src: require("../../images/aig.png") },
  { title: "Key Risk", src: require("../../images/keyrisk-logo.png") },
  { title: "Synergytext", src: require("../../images/synergytext.png") },
  {
    title: "Protective",
    src: require("../../images/NJM_Insurance_Group_logo.png"),
  },
  {
    title: "NJM",
    src: require("../../images/protective-logo-website-2020.png"),
  },
];

const numeral = require("numeral");
let landingImage = require("../../images/landing-image.jpg");

class Home2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailCampaignUserCheck: false,
      loadingData: false,
      isLoggedIn: false,
      brdLogin: false,
    };
  }

  validateStateCityAndZip = async (childrenLoc) => {
    try {
      function fetchStateAndCity(currObj) {
        new Promise((resolve, reject) => {
          let _zipCode = currObj.zipCode.value;
          let _cityName = currObj.cityName.value;
          let _state = currObj.state.value;

          if (!_zipCode) {
            resolve();
          }

          axios
            .get(awsUrl2 + "/api/getStateAndCity/" + _zipCode)
            .then((res) => {
              let { state, city } = res.data;

              if (_state !== state) {
                currObj.zipCode.error = true;
                currObj.cityName.error = true;
              } else if (_cityName !== city) {
                currObj.cityName.error = true;
              }

              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      }

      let stateAndCityPromiseList = [];

      for (let key in childrenLoc) {
        let currObj = childrenLoc[key];

        stateAndCityPromiseList.push(fetchStateAndCity(currObj));
      }

      await Promise.allSettled(stateAndCityPromiseList)
        .then((res) => {
          console.log("details checked ", res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  componentDidMount = async () => {
    // let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    // if (currProspect) this.setInputData(currProspect);
    let mailCampaignUserCheck = checkMailCampaignUser();

    $("#loader").css("display", "block");

    Auth.currentAuthenticatedUser().then((userTemp) => {
      if (userTemp) {
        let userName =
          userTemp?.attributes?.name || userTemp.attributes.email.split("@")[0];
        sessionStorage.setItem("isLoggedIn", true);
        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_SALES_GROUP)
        ) {
          sessionStorage.setItem("otpVerified", true);
          this.setState({ otpVerified: true, salesLogin: true });
        }

        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_ADMIN_GROUP)
        ) {
          this.setState({ adminLogin: true });
        }
        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_PREUW_GROUP)
        ) {
          this.setState({ PreuwLogin: true });
        }

        if (
          userTemp.signInUserSession !== null &&
          userTemp.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].includes(process.env.REACT_APP_BRD)
        ) {
          this.setState({ brdLogin: true });
        }

        this.setState({ userName });
      }
    });

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let campaignName = urlParams.get("campaignName");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    let viewButton = urlParams.get("viewButton");
    let preUnderwriter = sessionStorage.getItem("preUnderwriter");
    if (user && !mailCampaignUserCheck) {
      $("#loader").css("display", "block");
      user = user.toLowerCase();
      let tempBusinessName = user.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = user.split("@")[0];
      } else {
        domain = business_name;
      }
      let currDomain = sessionStorage.getItem("domain");
      let timestamp = Math.floor(Date.now()).toString();
      sessionStorage.setItem("visitTimestamp", timestamp);
      sessionStorage.setItem("landingEmail", user);
      sessionStorage.setItem("campaignName", campaignName);

      let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
      if (domainResp.data && domainResp.data.domain) {
        domain = domainResp.data.domain;
      }
      sessionStorage.setItem("domain", domain);

      axios
        .get(awsUrl + "/api/getUserDataStatus/" + domain + "/quote")
        .then(async (response) => {
          let requestBody = {};

          if (response && response.data) {
            sessionStorage.setItem("emailCampaignUser", true);
            let carrierBasedData = response.data.carrierBasedData;
            console.log("**inside here");
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];

                let currProspectDetails = lineObj.currProspectDetails;
                let preUwData = lineObj.preUwData;

                if (lineObj.preUwFiles)
                  sessionStorage.setItem(
                    "preUwFiles",
                    JSON.stringify(lineObj.preUwFiles)
                  );

                if (lineObj.dashboardFiles)
                  sessionStorage.setItem(
                    "dashboardFiles",
                    JSON.stringify(lineObj.dashboardFiles)
                  );

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let indicationCost = lineObj.carrier_location_data.total;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                let amtrustAccountDetails = lineObj.amtrustAccountDetails;

                if (amtrustAccountDetails) {
                  sessionStorage.setItem(
                    "amtrustAccountDetails",
                    JSON.stringify(amtrustAccountDetails)
                  );
                }

                sessionStorage.setItem("preUwData", JSON.stringify(preUwData));
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspectDetails)
                );
                if (currProspectDetails.childrenLoc)
                  sessionStorage.setItem(
                    "childrenLoc",
                    JSON.stringify(currProspectDetails.childrenLoc)
                  );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem("domain", domain);
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
                requestBody = {
                  email: user.toLowerCase(),
                  domain: domain.toLowerCase(),
                  timestamp: timestamp,
                  payrollData: currProspectDetails.childrenLoc,
                  companyProfile: currProspectDetails.companyProfile,
                  indicationCost,
                  emodStatesData: currProspectDetails.emodStatesData,
                  campaignName,
                  preventCompanyProfileUpdate: true,
                };

                if (user && !viewButton) {
                  axios
                    .post(
                      awsUrl2 + "/api/updateSolveTrackingData",
                      JSON.stringify(requestBody)
                    )
                    .then((res) => {
                      axios
                        .post(
                          awsUrl2 +
                            "/api/updateEngagedWithEmailCampaign/" +
                            domain,
                          JSON.stringify(domain)
                        )
                        .finally(() => {
                          $("#loader").css("display", "none");
                          // window.location.reload();
                        });
                    })
                    .catch((err) => {
                      $("#loader").css("display", "none");
                    });
                } else {
                  // window.location.reload();
                  $("#loader").css("display", "none");
                }
              }
            }
          } else {
            if (currDomain !== domain) {
              axios
                .get(awsUrl2 + "/api/getDomainInputData/" + domain)
                .then(async (response) => {
                  if (response.status === 200 && response.data.email_specific) {
                    sessionStorage.setItem("emailCampaignUser", true);
                    // this.props.updateMailCampaignUser(true);

                    let {
                      companyProfile,
                      payrollData,
                      emodStatesData,
                      indicationCost,
                      email_specific,
                      client_source,
                      onlyPaygo,
                      fein,
                      campaignMap,
                      zywaveCompanyProfile,
                      source,
                      formStage,
                      amtrustAccountDetails,
                      uwAmtrustQuesList,
                      uwQuesList,
                      uwExpectedResponses,
                      preUwData,
                      preUwFiles,
                      dashboardFiles,
                      requestedPricing,
                    } = response.data;

                    if (preUwFiles)
                      sessionStorage.setItem(
                        "preUwFiles",
                        JSON.stringify(preUwFiles)
                      );

                    if (dashboardFiles)
                      sessionStorage.setItem(
                        "dashboardFiles",
                        JSON.stringify(dashboardFiles)
                      );

                    if (amtrustAccountDetails) {
                      sessionStorage.setItem(
                        "amtrustAccountDetails",
                        JSON.stringify(amtrustAccountDetails)
                      );
                    }

                    let childrenLoc = payrollData;

                    for (let loc in childrenLoc) {
                      if (childrenLoc[loc].classCodesInfo) {
                        for (let cc in childrenLoc[loc].classCodesInfo) {
                          if (
                            !childrenLoc[loc].classCodesInfo[cc].ft ||
                            (childrenLoc[loc].classCodesInfo[cc].ft &&
                              !Number(
                                childrenLoc[loc].classCodesInfo[cc].ft.value
                              ))
                          ) {
                            childrenLoc[loc].classCodesInfo[cc] = {
                              ...childrenLoc[loc].classCodesInfo[cc],
                              ft: {
                                value:
                                  zywaveCompanyProfile.employees &&
                                  zywaveCompanyProfile.employees.value
                                    ? zywaveCompanyProfile.employees.value
                                    : 0,
                              },
                            };
                          }
                        }
                      }
                    }

                    await this.validateStateCityAndZip(childrenLoc);

                    let compName;

                    if (
                      companyProfile &&
                      companyProfile.company_name &&
                      companyProfile.company_name.value
                    ) {
                      compName = companyProfile.company_name.value;
                    }

                    if (companyProfile.state.value) {
                      companyProfile.state1 = {
                        value: companyProfile.state.value,
                      };
                      companyProfile.state2 = {
                        value: companyProfile.state.value,
                      };
                    }
                    if (companyProfile.zip.value) {
                      companyProfile.zipCode1 = {
                        value: companyProfile.zip.value,
                      };
                      companyProfile.zipCode2 = {
                        value: companyProfile.zip.value,
                      };
                    }
                    if (companyProfile.city.value) {
                      companyProfile.cityName1 = {
                        value: companyProfile.city.value,
                      };
                      companyProfile.cityName2 = {
                        value: companyProfile.city.value,
                      };
                    }
                    if (companyProfile.address.value) {
                      companyProfile.street1 = {
                        value: companyProfile.address.value,
                      };
                      companyProfile.street2 = {
                        value: companyProfile.address.value,
                      };
                    }

                    if (email_specific && !campaignMap) {
                      if (user in email_specific) {
                        companyProfile = {
                          ...companyProfile,
                          ...email_specific[user].companyProfile,
                        };
                      } else {
                        companyProfile.emailId.value = user;
                      }
                    }

                    if (user in email_specific) {
                      companyProfile = {
                        ...companyProfile,
                        contact_number:
                          email_specific[user].companyProfile.contact_number,
                        contact_name:
                          email_specific[user].companyProfile.contact_name,
                      };
                    }

                    if (compName) {
                      companyProfile = {
                        ...companyProfile,
                        companyName: {
                          value: compName,
                        },
                        company_name: {
                          value: compName,
                        },
                      };
                    }

                    companyProfile = {
                      ...companyProfile,
                    };

                    if (
                      companyProfile &&
                      (!companyProfile.companyName ||
                        !companyProfile.companyName.value) &&
                      companyProfile.company_name &&
                      companyProfile.company_name.value
                    ) {
                      companyProfile.companyName = companyProfile.company_name;
                    }

                    if (
                      companyProfile &&
                      (!companyProfile.phoneNumber ||
                        !companyProfile.phoneNumber.value) &&
                      companyProfile.contact_number &&
                      companyProfile.contact_number.value
                    ) {
                      companyProfile.phoneNumber =
                        companyProfile.contact_number;
                    }

                    if (
                      companyProfile &&
                      companyProfile.contact_name &&
                      companyProfile.contact_name.value
                    ) {
                      let nameList = companyProfile.contact_name.value.split(
                        " "
                      );
                      let lastName = nameList.pop();
                      let firstName = nameList.join(" ");
                      if (lastName) {
                        companyProfile.lastName = {
                          value: lastName,
                        };
                      }
                      if (firstName) {
                        companyProfile.firstName = {
                          value: firstName,
                        };
                      }
                    }

                    if (
                      companyProfile &&
                      (!companyProfile.descriptionOfOperations ||
                        !companyProfile.descriptionOfOperations.value) &&
                      companyProfile.description_of_operations &&
                      companyProfile.description_of_operations.value
                    ) {
                      companyProfile.descriptionOfOperations =
                        companyProfile.description_of_operations;
                    }

                    if (
                      companyProfile &&
                      (!companyProfile.fein || !companyProfile.fein.value) &&
                      fein
                    ) {
                      companyProfile.fein = {
                        value: fein,
                      };
                    }

                    if (
                      companyProfile &&
                      companyProfile.fein &&
                      companyProfile.fein.value
                    ) {
                      let value = companyProfile.fein.value + "";
                      value = value.split("-").join("");
                      var len = value.length,
                        dummystr = "000000000";

                      if (len > 0 && len < 9) {
                        value = dummystr.slice(0, 9 - len) + "" + value;
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      } else if (len > 9) {
                        companyProfile.fein.errMsg =
                          "FEIN needs to be 9 digits";
                        companyProfile.fein.error = true;
                      } else if (len === 0) {
                        companyProfile.fein.value = undefined;
                        companyProfile.fein.errMsg = "";
                      } else {
                        companyProfile.fein.value =
                          value[0] + value[1] + "-" + value.slice(2);
                        companyProfile.fein.errMsg = "";
                      }
                    }

                    if (
                      companyProfile &&
                      (!companyProfile.effectiveDate ||
                        (companyProfile.effectiveDate &&
                          !companyProfile.effectiveDate.value))
                    ) {
                      companyProfile = {
                        ...companyProfile,
                        effectiveDate: {
                          value:
                            moment().date() === 1
                              ? moment()
                              : moment(
                                  moment().subtract(moment().date() - 1, "days")
                                ).add(1, "month"),
                        },
                      };
                    }

                    if (
                      companyProfile &&
                      (!companyProfile.expectedStartDate ||
                        (companyProfile.expectedStartDate &&
                          !companyProfile.expectedStartDate.value))
                    ) {
                      companyProfile = {
                        ...companyProfile,
                        expectedStartDate: {
                          value:
                            moment().date() === 1
                              ? moment()
                              : moment(
                                  moment().subtract(moment().date() - 1, "days")
                                ).add(1, "month"),
                        },
                      };
                    }

                    let currProspect = {
                      childrenLoc,
                      companyProfile,
                      emodStatesData,
                      requestedPricing,
                    };

                    if (preUwData) {
                      sessionStorage.setItem(
                        "preUwData",
                        JSON.stringify(preUwData)
                      );
                    }

                    if (uwAmtrustQuesList) {
                      sessionStorage.setItem(
                        "uwAmtrustQuesList",
                        JSON.stringify(uwAmtrustQuesList)
                      );
                    }

                    if (uwQuesList) {
                      sessionStorage.setItem(
                        "uwQuesList",
                        JSON.stringify(uwQuesList)
                      );
                    }

                    if (uwExpectedResponses) {
                      currProspect.uwExpectedResponses = uwExpectedResponses;
                    }

                    if (!("healthQues" in companyProfile)) {
                      companyProfile.healthQues = { value: true };
                    }

                    sessionStorage.setItem(
                      "currProspect",
                      JSON.stringify(currProspect)
                    );
                    sessionStorage.setItem("formStage", formStage);
                    // let sameAsPrimary = this.checkSameAsPrimary();
                    // this.setState({ sameAsPrimary });

                    requestBody = {
                      email: user.toLowerCase(),
                      domain: domain.toLowerCase(),
                      timestamp: timestamp,
                      payrollData: childrenLoc,
                      companyProfile,
                      indicationCost,
                      emodStatesData,
                      campaignName,
                      emailSpecific: email_specific,
                      client_source,
                      onlyPaygo,
                      source,
                    };

                    if (user && !viewButton && !preUnderwriter) {
                      axios
                        .post(
                          awsUrl2 + "/api/updateSolveTrackingData",
                          JSON.stringify(requestBody)
                        )
                        .then((res) => {
                          axios
                            .post(
                              awsUrl2 +
                                "/api/updateEngagedWithEmailCampaign/" +
                                domain,
                              JSON.stringify(domain)
                            )
                            .finally(() => {
                              this.setState({ homePageLandingTracking: true });
                            });
                        })
                        .catch((err) => {
                          $("#loader").css("display", "none");
                        });
                    } else {
                      // window.location.reload();
                      $("#loader").css("display", "none");
                    }
                  } else {
                    $("#loader").css("display", "none");
                  }
                })
                .catch((err) => {
                  console.log("error: ", err);
                  $("#loader").css("display", "none");
                });
            } else {
              $("#loader").css("display", "none");
            }
          }
        })
        .catch((err) => {
          $("#loader").css("display", "none");
        });
    }
  };

  handleHomeGetQuoteClicked = async (domain, user) => {
    $("#loader").css("display", "block");

    let requestBody = {
      domain,
      email: user,
    };
    await axios
      .post(
        awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateHomeGetQuote",
        JSON.stringify(requestBody)
      )
      .then((res) => {
        console.log("updated clicked home get Quote");
        $("#loader").css("display", "none");
      })
      .catch((error) => {
        console.log("error", error);
        $("#loader").css("display", "none");
      });
  };

  handleLogout = () => {
    logout();
    this.setState({ isLoggedIn: false });
  };

  render() {
    let {
      isLoggedIn,
      loadingData,
      groups,
      userName,
      salesLogin,
      adminLogin,
      PreuwLogin,
      brdLogin,
    } = this.state;
    let domain = sessionStorage.getItem("domain");
    let user = sessionStorage.getItem("landingEmail");
    let campaignName = sessionStorage.getItem("campaignName");
    let loggedIn = sessionStorage.getItem("isLoggedIn") ? true : false;
    if (loggedIn != isLoggedIn) {
      this.setState({ isLoggedIn: loggedIn });
    }

    return (
      <>
        <div className="home-login"></div>

        <div className="home-landing-page">
          <TopImage
            imageURL={imageURL}
            logo1={fourthLogo}
            logo2={fourthpdflogo}
            // twoPeopleImage={twoPeopleImage}
            domain={domain}
            user={user}
            campaignName={campaignName}
            handleHomeGetQuoteClicked={this.handleHomeGetQuoteClicked}
          />
          <TopImageTwo
            twoPeopleImage={twoPeopleImage}
            domain={domain}
            user={user}
            campaignName={campaignName}
            handleHomeGetQuoteClicked={this.handleHomeGetQuoteClicked}
          />
          <OurServices
            ourServiceList={ourServiceList}
            whyEngageImageURL={whyEngageImageURL}
          />
          <StarRating fiveStarImage={fiveStarImage} />
          <SutaFavourites sutaFavorablebg={sutaFavorablebg} />
          <WhyPeo />
          <HomePageCarousel brandsList={brandsList} />
          {/* <ScrollIconBanner scrollIconBanner={scrollIconBanner} /> */}
          <HomeFooter engageLogo={engageLogo} />

          {/* <FixedBall
            domain={domain}
            user={user}
            campaignName={campaignName}
            handleHomeGetQuoteClicked={this.handleHomeGetQuoteClicked}
          /> */}
          <div className="login-container">
            {!isLoggedIn ? (
              <div className="login-button">
                <a
                  onClick={() => {
                    window.location.href = "/Login";
                  }}
                  className="login-logout-link"
                >
                  Login
                </a>
              </div>
            ) : (
              <div>
                <span
                  className="dropdown-toggle text-capitalize fw-bolder login-logout-link"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="pb-1">
                    <IoPersonSharp color="white" />
                  </div>
                  <div>{userName}</div>
                </span>
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      window.location.href = adminLogin
                        ? "/AdminDashboard"
                        : salesLogin
                        ? "/SalesDashboard"
                        : PreuwLogin
                        ? "/PanelUW"
                        : brdLogin
                        ? "/brdDashboard"
                        : "/";
                    }}
                  >
                    Dashboard
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      this.handleLogout();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Home2;

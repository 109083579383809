import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import styles from "./Header.module.scss";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import { startNewQuote, logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import HeaderSubPart from "./HeaderSubPart";

let fourthlogo = require("../../images/fourth.png");
let affinitylogo = require("../../images/affinity-new1.png");
let engagepdflogo = require("../../images/engagePeoLogo1.jpg");
let isMobile = window.innerWidth < 992;
// const windowPath = window.location.pathname.toLowerCase().includes("/quote");
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userName: "user",
      showDashboardLink: false,
      showSalesDashboardLink: false,
    };
  }

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          this.setState({ showDashboardLink: true });
        } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          this.setState({ showSalesDashboardLink: true });
        }
        this.setState({ isLoggedIn: true, userName });
      }
    } catch (error) {}
  };

  render() {
    const {
      isLoggedIn,
      userName,
      showDashboardLink,
      showSalesDashboardLink,
    } = this.state;
    return (
      <>
        <nav
          id="navbarMain headernav"
          className="new-navbar container-fluid"
          style={{
            backgroundColor: "white",
            height: "auto",
            fontSize: "16px",
            padding: "0px",
          }}
        >
          <div style={{ width: "100vw" }}>
            <HeaderSubPart />
            <div className="header-logo-style" id="brand-container">
              {process.env.REACT_APP_MODE === "beta" ? (
                <span className="peo-header">
                  Professional
                  <br /> Employer
                  <br /> Organization
                </span>
              ) : (
                <Link
                  to={"/"}
                  className="navbar-brand d-flex align-items-center hotscheduleslogo"
                >
                  {
                    <a
                      className="navbar-brand pride-logo d-flex align-items-center"
                      href="/"
                    >
                      <img
                        // style={{ height: "125px", marginLeft: "-1rem" }}
                        src={engagepdflogo}
                        alt="solve-insurecomp logo"
                        className="hotscheduleslogowidth"
                      />
                      {/* <span className="peo-header">
                      Professional
                      <br /> Employer
                      <br /> Organisation
                    </span> */}
                    </a>
                  }
                </Link>
              )}
              <Link
                to={"/"}
                className="navbar-brand d-flex align-items-center  affinitylogo"
                // style={{ margin: "20px", marginRight: "40px" }}
              >
                <img
                  //style={{ height: "105px" }}
                  src={affinitylogo}
                  alt="affinity logo"
                  className="affinitylogo1"
                />
              </Link>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;

import React from "react";
import PropTypes from "prop-types";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import { IoPersonSharp } from "react-icons/io5";

import HeaderSubPart from "./HeaderSubPart";
let isMobile = window.innerWidth < 992;

const HeaderMainSubPart = (props) => {
  return (
    <div>
      {" "}
      <div className="pb-2 subnav">
        {/* <hr className="mt-0" style={{ borderWidth: "2px" }} style={{ borderBottomStyle: "groove" }} /> */}
        <ul
          className="text-right nav h-100 justify-content-end text-dark"
          style={{ fontFamily: "Montserrat", alignItems: "center" }}
        >
          {isMobile &&
          window.location.pathname.toLowerCase().includes("quote") ? (
            ""
          ) : (
            <></>
            // <div className="logo-style">
            //   <IoPersonSharp color="#427cbc" />
            // <HeaderSubPart />
            // {/* </div> */}
          )}
        </ul>
      </div>
    </div>
  );
};

HeaderMainSubPart.propTypes = {};

export default HeaderMainSubPart;

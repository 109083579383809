import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { Auth } from "aws-amplify";
import { isDeepEquals } from "../../utils/isDeepEquals";
import style from "./DealClosedLostButtons.module.css";
import { awsUrl } from "../../config";

function DealClosedLostButtons(props) {
  const [isClosed, setIsClosed] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [recordDetails, setRecordDetails] = useState({});

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res?.attributes?.email) {
          setLoggedInUser(res.attributes.email);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props?.isClosed !== isClosed) {
      setIsClosed(props.isClosed);
    }
  }, [props.isClosed]);

  useEffect(() => {
    if (props?.isLost !== isLost) {
      setIsLost(props.isLost);
    }
  }, [props.isLost]);

  useEffect(() => {
    if (!isDeepEquals(props.recordDetails, recordDetails)) {
      setRecordDetails(props.recordDetails);
    }
  }, [props.recordDetails]);

  const handleButtonClicked = async (button_type) => {
    let api_body = {
      salesId: recordDetails?.salesId || "",
      domain: recordDetails?.domain || "",
      userId: recordDetails?.userId || "",
      uuidList: recordDetails.uuidList || [],
      isMailCmpn: recordDetails.isMailCmpn || false,
      flag: "",
      loggedInUser,
    };

    // setIsLoading(true);

    const handleApiCall = () => {
      // API call here
      axios
        .post(awsUrl + "/api/setDealLostClosed", JSON.stringify(api_body))
        .then((res) => {
          setIsLoading(false);
          // Handle response here
        })
        .catch((err) => {
          console.log("error in API: ", err);
          setIsLoading(false);
        });
    };

    if (button_type === "closed") {
      api_body.flag = isClosed ? "" : "true";
      // setIsClosed(!isClosed);

      props.updatePopUpData({
        show: true,
        title: "Are You Sure ?",
        acceptBtn: "Yes",
        acceptBtnCallback: () => {
          handleApiCall();
          setIsClosed(!isClosed);
        },
        rejectBtnCallback: () => {
          // Reset state if needed
          setIsClosed(isClosed);
          // props.updatePopUpData(EMTY_OBJ);
        },
      });
    } else if (button_type === "lost") {
      api_body.flag = isLost ? "" : "false";
      // setIsLost(!isLost);

      props.updatePopUpData({
        show: true,
        title: "Are You Sure ?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Files once uploaded cannot be deleted
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: () => {
          handleApiCall();
          setIsLost(!isLost);
        },
        rejectBtnCallback: () => {
          // Reset state if needed
          setIsLost(isLost);
          //props.updatePopUpData(EMTY_OBJ);
        },
      });
    }

    // updating the state in dashboard so as to update all the tabs
    if (props?.updateDealMap) {
      props.updateDealMap(api_body.salesId || api_body.domain, api_body.flag);
    }
  };

  return (
    <LoadingOverlay active={isLoading}>
      <div className={style.deal_closed_lost_container}>
        {!isClosed && !isLost && (
          <>
            <button
              className={style.button_closed}
              disabled={props.disabled}
              onClick={() => handleButtonClicked("closed")}
            >
              Sold
            </button>
            <button
              className={style.button_lost}
              disabled={props.disabled}
              onClick={() => handleButtonClicked("lost")}
            >
              Not Sold
            </button>
          </>
        )}
        {isClosed && (
          <button
            className={style.button_closed}
            disabled={props.disabled}
            onClick={() => handleButtonClicked("closed")}
          >
            Sold
          </button>
        )}
        {isLost && (
          <button
            className={style.button_lost}
            disabled={props.disabled}
            onClick={() => handleButtonClicked("lost")}
          >
            Not Sold
          </button>
        )}
      </div>
    </LoadingOverlay>
  );
}

export default DealClosedLostButtons;

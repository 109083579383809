import React, {
  Component,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Loader from "../common/Loader";
import Header from "../common/Header";
import HeaderMainSubPart from "../common/HeaderMainSubPart.jsx";
import Stats2 from "../subcompo/sections-home/Stats2";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import clipboard from "../../images/clipboard.svg";
import toast, { Toaster } from "react-hot-toast";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { alpha } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SlExclamation } from "react-icons/sl";
import { Modal } from "react-bootstrap";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TextField as TField,
} from "@material-ui/core";

import parse from "html-react-parser";
import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";
import { createCommentForDownload } from "../common/dashboard_helper.js";
import { isDeepEquals } from "../../utils/isDeepEquals.js";
import { CiLinkedin } from "react-icons/ci";
import NumberFormat from "react-number-format";
import { FaRegHandshake } from "react-icons/fa6";
import Popup from "../common/Popup.jsx";
import { values } from "d3";
import { MdEdit } from "react-icons/md";
const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");
let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <></>,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const statusNameMapping = {
  "Opened Email": "Opened Email",
  "Quote Generated": "Initial Pricing",
  "UW Questions": "Locations and Payroll",
  "Company Profile": "Company Information",
};

const statusPriorityMap = {
  Submitted: 2,
  "User Submitted": 1,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  "Opened Email": 90,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Company Information": 9,
  "Initial Pricing": 3,
  "Locations and Payroll": 5,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  // MQL: 23,
  "Not Campaigned": 24,
  "-": 999,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Tab2DetailPanel(props) {
  const {
    rowData,
    parentAdditionalRows,
    showAddContact,
    prim_key,
    saveContact,
    updateRowDataList,
  } = props;
  const prev_props = usePrevious({
    rowData,
    parentAdditionalRows,
    showAddContact,
  });

  console.log(rowData, "prince111");
  const [additionalRows, setAdditionalRows] = useState([]);
  const [editedRows, setEditedRows] = useState([]);

  const [additionalRowId, setAdditionalRowId] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [firstAdd, setFirstAdd] = useState(false);
  const [popupData, setPopupData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });
  const [_rowData, _setRowData] = useState(rowData);
  const [_rowData1, _setRowData1] = useState(rowData);

  const EMPTY_ROW_PANEL = {
    contact_name: "",
    phone_number: "",
    email: "",
    job_description: "",
    visit_count: 0,
    status: "-",
    isSaved: false,
    additionalRowKey: additionalRowId,
  };

  useEffect(() => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));

    if (
      (parentAdditionalRows?.length && !_additionalRows?.length) ||
      (parentAdditionalRows?.length ===
        prev_props?.parentAdditionalRows?.length &&
        !isDeepEquals(parentAdditionalRows, prev_props?.parentAdditionalRows))
    ) {
      setAdditionalRows(parentAdditionalRows);
    }
  }, [parentAdditionalRows]);

  const onAddContact = () => {
    if (!firstAdd) {
      setFirstAdd(true);
    }
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows.push(JSON.parse(JSON.stringify(EMPTY_ROW_PANEL)));
    setAdditionalRowId((prev) => prev + 1);
    setAdditionalRows(_additionalRows);
  };
  const onDeleteContact = (key) => {
    const newRows = additionalRows.filter(
      (item) => item.additionalRowKey !== key
    );
    setAdditionalRows(newRows);
  };
  const handleEditClick = (element, email) => {
    // _rowData.email_specific
    // console.log("element=>", element, _rowData);
    const tempRowData = JSON.parse(JSON.stringify(_rowData));
    const tempRowData2 = JSON.parse(JSON.stringify(_rowData));
    const tempVar = tempRowData?.email_specific?.[email]?.companyProfile;
    // console.log("iiiiii", Object.keys(tempVar), tempVar);

    for (let i of Object.keys(tempVar)) {
      // console.log("iiiiii", i, tempVar[i]);
      const tempObj = JSON.parse(JSON.stringify(tempVar[i]));
      tempVar[i] = tempObj.value;
    }

    if (!tempRowData2?.email_specific?.[email]?.isEdit) {
      tempRowData2.email_specific[email].isEdit = true;

      setFirstAdd(true);
      setEditedRows((prev) => [
        ...prev,
        { ...tempRowData?.email_specific?.[email]?.companyProfile },
      ]);
    }
    _setRowData(tempRowData2);
    // _rowData.email_specific;
  };

  const handleEditValueChange = (value, email, field) => {
    // console.log("handleEditValueChange", value, email, field);
    // setEditedRows((prev)=>{prev.find((item)=>item.emailId === email)[field]=value},...prev)
    _setRowData1((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };
      console.log("newState", newState);
      // Loop through all keys in the state object
      // for (const key in newState.email_specific) {
      // if (newState.email_specific[key].isEdit === true) {
      newState.email_specific[email]["companyProfile"][field].value = value;
      // }
      // }

      return newState;
    });
    setEditedRows((prev) => {
      // Make a shallow copy of the array
      const newRows = [...prev];
      // console.log("prev=>", prev);
      // Find the index of the item to update
      const index = newRows.findIndex((item) => item?.emailId === email);
      // If the item is found, update the specific field
      // console.log("index=>", index);
      if (index !== -1) {
        newRows[index] = {
          ...newRows[index], // Make a shallow copy of the item
          [field]: value, // Update the specific field
        };
      }
      // console.log("newRows", newRows);
      return newRows;
    });
  };
  const checkAndUpdateAll = () => {
    _setRowData((prevState) => {
      // Create a copy of the previous state
      const newState = { ...prevState };

      // Loop through all keys in the state object
      for (const key in newState.email_specific) {
        if (newState.email_specific[key].isEdit === true) {
          newState.email_specific[key].isEdit = false;
        }
      }

      return newState;
    });
  };

  console.log("additional rows", editedRows); // Log previous state for debugging

  const onChangeValue = (key, val, indx, error) => {
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    _additionalRows[indx][key] = val;

    if (error) _additionalRows[indx][`${key}_error`] = true;
    else _additionalRows[indx][`${key}_error`] = false;

    setAdditionalRows(_additionalRows);
  };

  const validateEmail = (email) => {
    //check if email address is valid
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = reg.test(email);
    if (!isValid) return false;

    //check if email already exists in email_specific
    let existing_emails = _rowData.email_specific
      ? Object.keys(_rowData.email_specific)
      : [];
    if (existing_emails.includes(email)) return false;

    //check if email already exists in additional_rows
    let _additionalRows = JSON.parse(JSON.stringify(additionalRows));
    for (let _row of _additionalRows) if (_row.email === email) return false;

    return true;
  };

  const validateData = () => {
    console.log("parentData", _rowData, Object.keys(_rowData.email_specific));

    let hasIncompleteRow = additionalRows.some(
      (row) =>
        !(
          (
            row.contact_name &&
            // row.phone_number &&
            row.email
          )
          // row.job_description
        )
    );

    let invalidEmail = additionalRows.some((row) => row.email_error);
    let invalidPhone = additionalRows.some((row) => row.phone_number_error);

    if (hasIncompleteRow) {
      return { msg: "Please enter all fields", valid: false };
    }
    console.log("invalidEmail", invalidEmail);
    if (invalidEmail) {
      return { msg: "Please enter valid email id", valid: false };
    }
    if (invalidPhone) {
      return { msg: "Please enter valid phone number", valid: false };
    }

    return { msg: "", valid: true };
  };
  const updatePopUpData = (pData) => {
    setPopupData(pData);
  };

  return (
    <div style={{ padding: "25px" }}>
      <TableContainer component={Paper}>
        <Table size="small" className="sub_tab2">
          <TableHead
            style={{
              background: "#00000066",
              color: "whitesmoke",
            }}
          >
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Description</TableCell>
              <TableCell>Visit Count</TableCell>
              <TableCell>Status</TableCell>
              {/* <TableCell>Campaign Name</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_rowData.email_specific ? (
              Object.entries(_rowData.email_specific).map(
                ([email, element], index) => {
                  let step;

                  // if (element.isUserSubmitted) {
                  //   step = "User Submitted";
                  // }else
                  if (element.companyProfile) {
                    if (element.isUserSubmitted) {
                      step = "User Submitted";
                    } else if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Initial Pricing";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "Locations and Payroll";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Information";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? statusNameMapping[
                              element?.companyProfile?.status?.value || ""
                            ] || element.companyProfile.status.value
                          : step;
                    }
                    const campaignMapEntries = Object.entries(
                      element.campaignMap || {}
                    );
                    const lastCampaignEntry =
                      campaignMapEntries.length > 0
                        ? campaignMapEntries[campaignMapEntries.length - 1]
                        : [null, ""];
                    const lastCampaignName = lastCampaignEntry[1];
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="contact_name"
                              defaultValue={
                                element.companyProfile &&
                                element.companyProfile.contact_name
                                  ? element.companyProfile.contact_name.value
                                  : ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "contact_name")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "contact_name"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element.companyProfile &&
                              element.companyProfile.contact_name ? (
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    {element.companyProfile.contact_name.value}
                                  </div>
                                  {element?.companyProfile?.LinkedinURL
                                    ?.value && (
                                    <button
                                      onClick={() =>
                                        window.open(
                                          `https://www.${element?.companyProfile?.LinkedinURL?.value}`,
                                          "_blank"
                                        )
                                      }
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        padding: 0,
                                      }}
                                    >
                                      <CiLinkedin size={15} color="#427cbc" />
                                    </button>
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="phoneNumber"
                              defaultValue={
                                element?.companyProfile?.phoneNumber?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "phoneNumber")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "phoneNumber"
                                )
                              }
                            />
                          ) : (
                            <>
                              {element?.companyProfile?.phoneNumber?.value?.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "($1) $2-$3"
                              ) ||
                                element?.companyProfile?.contact_number?.value?.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "($1) $2-$3"
                                ) ||
                                ""}
                            </>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          <a
                            href={`mailto:${element?.companyProfile?.emailId?.value}`}
                          >
                            {element?.companyProfile?.emailId?.value}
                          </a>
                        </TableCell>
                        <TableCell scope="row">
                          {element?.isEdit ? (
                            <TField
                              name="job_title"
                              defaultValue={
                                element?.companyProfile?.job_title?.value
                              }
                              // onChange={(e) =>
                              //   handleEditValueChange(e.target.value, email, "job_title")
                              // }
                              onBlur={(e) =>
                                handleEditValueChange(
                                  e.target.value,
                                  email,
                                  "job_title"
                                )
                              }
                            />
                          ) : (
                            <>{element?.companyProfile?.job_title?.value}</>
                          )}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                        {/* <TableCell scope="row">
                          {lastCampaignName
                            ? lastCampaignName
                            : _rowData.latestCampaign}
                        </TableCell> */}
                        {/* <TableCell scope="row">-</TableCell> */}
                        <TableCell>
                          <button
                            className="button-delete-row"
                            onClick={() => {
                              // onDeleteContact(ele?.additionalRowKey);

                              handleEditClick(element, email);
                            }}
                          >
                            <MdEdit />
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
              )
            ) : (
              <TableRow key={0}>
                <TableCell scope="row">{_rowData.contactName}</TableCell>
                <TableCell scope="row">
                  {_rowData.phoneNumber
                    ? _rowData.phoneNumber.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "-"}
                </TableCell>
                <TableCell scope="row">{_rowData.userEmail}</TableCell>
                <TableCell scope="row">{""}</TableCell>
                <TableCell scope="row">{_rowData.visitCount}</TableCell>
                <TableCell scope="row">{_rowData.step || ""}</TableCell>
                <TableCell scope="row">{_rowData.campaignMap}</TableCell>
                <TableCell scope="row">-</TableCell>
              </TableRow>
            )}

            {additionalRows?.map((ele, indx) => {
              return (
                <TableRow key={ele.additionalRowKey}>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.contact_name
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "contact_name",
                            e.target.value?.trim(),
                            indx
                          )
                        }
                        defaultValue={ele.contact_name}
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.phone_number
                    ) : (
                      <NumberFormat
                        defaultValue={ele.phone_number}
                        onChange={(e) => {
                          const value = e.target.value?.replace(/[^\d]/g, ""); // Remove non-digit characters
                          if (value && value.length === 10) {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              false
                            );
                          } else {
                            onChangeValue(
                              "phone_number",
                              e.target.value?.trim(),
                              indx,
                              true
                            );
                          }
                        }}
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                        style={
                          ele.phone_number_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.email
                    ) : (
                      <input
                        type="text"
                        onChange={(e) => {
                          let val = e.target.value.trim();
                          if (val && validateEmail(val)) {
                            onChangeValue("email", val, indx, false);
                          } else {
                            onChangeValue("email", val, indx, true);
                          }
                        }}
                        defaultValue={ele.email}
                        style={
                          ele.email_error
                            ? {
                                border: "none",
                                borderBottom: "1px solid red",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                            : {
                                border: "none",
                                borderBottom: "1px solid #1890ff",
                                padding: "5px 10px",
                                outline: "none",
                                textAlign: "center",
                              }
                        }
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {ele.isSaved ? (
                      ele.job_description
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "job_description",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.job_description}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.visit_count
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue(
                            "visit_count",
                            e.target.value.trim(),
                            indx
                          )
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.visit_count}
                      ></input>
                    )}
                  </TableCell>
                  <TableCell>
                    {true ? (
                      ele.status
                    ) : (
                      <input
                        type="text"
                        onChange={(e) =>
                          onChangeValue("status", e.target.value.trim(), indx)
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid #1890ff",
                          padding: "5px 10px",
                          outline: "none",
                          textAlign: "center",
                        }}
                        defaultValue={ele.status}
                      ></input>
                    )}
                  </TableCell>
                  {/* <TableCell>-</TableCell> */}
                  <TableCell>
                    {ele.isSaved ? (
                      <>-</>
                    ) : (
                      <button
                        className="button-delete-row"
                        onClick={() => {
                          onDeleteContact(ele?.additionalRowKey);
                        }}
                      >
                        <img
                          style={{ height: "16px" }}
                          src={require("../../images/delete4.png")}
                        />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {showAddContact && (
              <TableRow key={1000}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {errMsg && <div style={{ color: "red" }}>{errMsg}</div>}

                  <button
                    className="btn mx-2 button-save-style"
                    onClick={(e) => onAddContact()}
                  >
                    {/* <img
                      style={{ height: "16px" }}
                      src={require("../../images/plus4.png")}
                    /> */}
                    Add Contact
                  </button>

                  {firstAdd && (
                    <>
                      <button
                        className="btn button-save-style"
                        onClick={(e) => {
                          if (
                            validateData().valid ||
                            additionalRows.length === 0
                          ) {
                            // checkAndUpdateAll();
                            setErrMsg("");
                            updatePopUpData({
                              show: true,
                              title: firstAdd
                                ? "Contacts once added cannot be deleted. Would you like to continue?"
                                : "Would you like to continue?",
                              //"Would you like to continue"
                              acceptBtn: "Yes",
                              rejectBtn: "No",
                              acceptBtnCallback: () => {
                                // _setRowData(_rowData1);

                                saveContact(
                                  prim_key,
                                  additionalRows,
                                  editedRows
                                );
                                console.log("editedRows", editedRows.length);
                                if (editedRows?.length > 0) {
                                  updateRowDataList(prim_key, editedRows);
                                }
                              },
                              rejectBtnCallback: () => {
                                checkAndUpdateAll();
                              },
                            });
                          } else {
                            setErrMsg(validateData().msg);
                          }
                        }}
                      >
                        Save
                      </button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {popupData.show && (
        <Popup
          dashboardstyle={true}
          popupData={popupData}
          updatePopUpData={updatePopUpData}
        />
      )}
    </div>
  );
}

const peoTabName = {
  peo: "With PEO",
  peoW: "Without PEO",
};

class DashboardSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      dataToDisplay: {},
      isLoading: {},
      dealMap: {},
      globalAddContact: {},
      stateSpecificCheck: false,
      dashboradSpecific: false,
      loginSpecificCheck: false,
      dashboardView: [],
      selectedPeo: "peo",
      // popupData: {
      //   title: "",
      //   children: <></>,
      //   acceptBtn: "",
      //   acceptBtnCallback: () => {},
      //   rejectBtnCallback: () => {},
      //   show: false,
      // },
    };
  }

  async componentDidMount() {
    await this.fetchData();
    sessionStorage.clear();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      localStorage.setItem("dashboardState", JSON.stringify(this.state));
    }
  }

  saveContact = async (key, add_rows, edit_rows) => {
    const rows = add_rows.concat(edit_rows);
    console.log("key=>", key, add_rows, edit_rows, "rows=>", rows);
    this.setState((prevState) => {
      let _globalAddContact = JSON.parse(
        JSON.stringify(prevState.globalAddContact)
      );
      axios
        .post(
          awsUrl + "/api/addContact",
          JSON.stringify({
            domain: key,
            rows: rows,
          })
        )
        .then((res) => {
          //api call to save email in email_specific and domain_mapping
          for (let row of add_rows) {
            if (!_globalAddContact[key]) _globalAddContact[key] = [];
            row.isSaved = true;
            _globalAddContact[key].push(row);
          }
          this.setState({ globalAddContact: _globalAddContact });
        })
        .catch((err) => console.log(err));
    });
  };

  updateDealMap = (key, value) => {
    let { dealMap } = this.state;
    dealMap[key] = value;
    this.setState({ dealMap });
  };

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  getsalespersonName = (userData) => {
    let name = "";
    console.log("first", userData);
    if (userData?.salespersonName) {
      name = userData?.salespersonName;
    } else if (userData?.default_saleperson) {
      name = userData?.default_saleperson;
    } else if (userData?.default_saleperson && userData?.salespersonName) {
      name = userData?.salespersonName;
    } else {
      name = userData?.salespersonName;
    }
    return name;
  };

  processSalesData = (data, sessionData) => {
    let {
      dashboradSpecific,
      stateSpecificCheck,
      loginSpecificCheck,
    } = this.state;
    let list = [];
    let wonList = [];
    let lostList = [];
    let _salescommentsHistory = {};
    let _salesManagerCommentHistory = {};
    let newData = [];
    let dashboradView = [];

    if (sessionData?.attributes?.["custom:dashboard"]) {
      dashboradView = sessionData?.attributes?.["custom:dashboard"].split(",");
    }

    if (
      dashboradSpecific &&
      stateSpecificCheck &&
      dashboradView.includes("salesforce")
    ) {
      if (sessionData) {
        let specifiedState = sessionData?.attributes?.["custom:State"].split(
          ","
        );
        for (let i = 0; i < data.length; i++) {
          let state = data[i]?.companyProfile?.state1?.value || "";
          if (state) {
            if (specifiedState.includes(state)) {
              newData.push(data[i]);
            }
          }
        }
      }
    } else if (dashboradSpecific && loginSpecificCheck) {
      if (sessionData) {
        let stateSpeficEmail = sessionData?.attributes?.email;
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].salespersonNameAttr === stateSpeficEmail ||
            data[i].salespersonName === stateSpeficEmail
          ) {
            newData.push(data[i]);
          }
        }
      }
    } else {
      newData = data;
    }

    for (let userDataRow in newData) {
      try {
        let userData = newData[userDataRow];
        let step;

        if (userData.formStage) {
          switch (userData.formStage) {
            case "two":
              step = "UW Questions";
              break;
            case "three":
              step = "Quote Generated";
              break;
            default:
              break;
          }
        }
        let currProspectDetails = {};
        if (userData.currProspect) {
          currProspectDetails = userData.currProspect;
        } else {
          currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
            companyProfileAddQuestions: userData.companyProfileAddQuestions,
            uwQues: userData.uwQues,
            requestedPricing: userData.requestedPricing,
          };
        }

        currProspectDetails = {
          ...currProspectDetails,
          brokerDetails: userData.brokerDetails,
        };

        let total_emp = 0,
          payroll = 0;

        if (userData.payrollData) {
          let childrenLoc = userData.payrollData;
          for (let locIndex in childrenLoc) {
            let loc = childrenLoc[locIndex];
            for (let ccIndex in loc.classCodesInfo) {
              let cc = loc.classCodesInfo[ccIndex];
              total_emp =
                total_emp +
                Number(cc?.ft?.value || 0) +
                Number(cc?.pt?.value || 0);
              let payrollVlue = cc?.payroll?.value?.replace(/\D/g, "") || 0;
              payroll = payroll + Number(payrollVlue || 0);
            }
          }
        }

        let rowData = {
          uploadedTimeStamp: userData.created_timestamp
            ? moment(userData.created_timestamp, "x").format("M/D/YY")
            : "-",
          visitDate:
            userData.updated_timestamp &&
            moment(Number(userData.updated_timestamp))
              .utcOffset("-0500")
              .format("M/D/YY - hh:mm A"),
          phoneNumber:
            userData.companyProfile && userData.companyProfile.phoneNumber
              ? userData.companyProfile.phoneNumber.value
              : "",
          effectiveDate:
            userData.companyProfile &&
            userData.companyProfile.effectiveDate &&
            userData.companyProfile.effectiveDate.value
              ? moment(userData.companyProfile.effectiveDate.value).format("x")
              : "",
          contactName:
            userData.companyProfile && userData.companyProfile.firstName
              ? userData.companyProfile.firstName.value
              : "",
          companyName:
            userData.companyProfile && userData.companyProfile.companyName
              ? userData.companyProfile.companyName.value
              : "",
          step: userData.isSubmitted ? "Submitted" : step,
          currProspectDetails,
          salesID: userData.id,
          formStage: userData.formStage,
          amtrustAccountDetails: userData.amtrustAccountDetails,
          amtrustAccountDetailsCopy: userData.amtrustAccountDetailsCopy,
          salespersonName: this.getsalespersonName()
            ? this.getsalespersonName()
            : "",
          total_emp,
          state: userData?.companyProfile?.state1?.value || "",
          city: userData?.companyProfile?.cityName1?.value || "",
          quoteData: userData.quoteData,
          address: userData.address,
          payroll,
          visitCount: "0",
          latestCampaign: "-",
          sales: "true",
          salescomment: userData?.comment
            ? typeof userData?.comment === "string"
              ? [userData?.comment]
              : userData?.comment
            : [""],
          sortKeyList: userData.sortKeyList,
          companyProfile: userData.companyProfile,
          isSalesforce: userData.isSalesforce,
          sfQuoteId: userData.sfQuoteId,
          uploadedDate: userData.uploadedDate,
          isPreUnderwritten: userData?.preUwData?.preUwStatus ? true : "na",
          source: userData.origin,
          quoteOwner: userData.sf_quote_owner,
          ispeowith: userData?.ispeowith || "",
          campaignDate: userData?.campaignDate || "",
          smcomment: userData?.smcomment
            ? typeof userData?.smcomment === "string"
              ? [userData?.smcomment]
              : userData?.smcomment
            : [""],
          admincomment: userData?.admincomment
            ? typeof userData?.admincomment === "string"
              ? [userData?.admincomment]
              : userData?.admincomment
            : [""],
          dealFlag:
            userData?.dealData?.status === "true"
              ? "closed"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealFlagFilter:
            userData?.dealData?.status === "true"
              ? "won"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealConfirmed: userData?.dealConfirmed,
          recordDetails: {
            salesId: userData.id,
          },
          latestQuoteKey: userData?.latestQuoteKey || "",
        };

        if (rowData.salesID) {
          _salescommentsHistory[rowData.salesID] = rowData.salescomment;
        }
        if (rowData.salesID) {
          _salesManagerCommentHistory[rowData.salesID] = rowData.smcomment;
        }

        createCommentForDownload(rowData, "comment");
        createCommentForDownload(rowData, "admincomment");
        createCommentForDownload(rowData, "salescomment");
        createCommentForDownload(rowData, "smcomment");

        if (rowData.dealConfirmed === "true") {
          if (rowData.dealFlag === "closed") {
            wonList.push(rowData);
          } else {
            lostList.push(rowData);
          }
        } else {
          list.push(rowData);
        }
      } catch (error) {
        console.log("error in processing: ", data[userDataRow].id);
      }
    }

    return {
      value: list,
      won: wonList,
      lost: lostList,
      domain_messageid_files: {},
      salescommentsHistory: _salescommentsHistory,
      salesManagerCommentHistory: _salesManagerCommentHistory,
    };
  };

  processEngagedData = (userDataList, sessionData) => {
    let { dashboradSpecific, stateSpecificCheck } = this.state;
    let rowDataList = [];
    let wonList = [];
    let lostList = [];
    let domain_messageid_files = {};
    let salescommentsHistory = {};
    let salesManagerCommentHistory = {};
    let rowData;
    let newUserDataList = [];

    // if (dashboradSpecific && stateSpecificCheck) {
    //   if (sessionData) {
    //     let specifiedState = sessionData?.attributes?.["custom:State"].split(
    //       ","
    //     );
    //     for (let i = 0; i < userDataList.length; i++) {
    //       let state = userDataList[i].state;
    //       if (state) {
    //         if (specifiedState.includes(state)) {
    //           newUserDataList.push(userDataList[i]);
    //         }
    //       }
    //     }
    //   }
    // } else {
    // }
    newUserDataList = userDataList;

    for (let userData of newUserDataList) {
      try {
        let quote_status;
        let step;
        let excluded_status;
        let email_specific_status;
        let landing_email_status, landing_email_get_quote_clicked;

        // patch code to not display entries with the following campaign names
        let incorrectCampaignNames = new Set([
          "AEMheybtb",
          "TfgDhbgf",
          "BAybtb",
          "AEybtb",
        ]);
        if (incorrectCampaignNames.has(userData?.latestCampaign)) continue;

        let contactInfoAvailable = false;

        for (let email in userData.email_specific) {
          contactInfoAvailable = true;
          let db_quote_status = userData.email_specific[email].quote_status;
          let db_excluded = userData.email_specific[email].nonEligibilityList;
          if (db_excluded && db_excluded.length > 0) {
            excluded_status = true;
          }

          if (
            userData.email_specific[email].companyProfile &&
            userData.email_specific[email].companyProfile.status &&
            userData.email_specific[email].companyProfile.status.value
          ) {
            let emailStatus =
              userData.email_specific[email].companyProfile.status.value;
            if (!email_specific_status)
              email_specific_status =
                statusNameMapping?.[emailStatus || ""] || emailStatus;
            else if (
              statusPriorityMap[emailStatus] <
              statusPriorityMap[email_specific_status]
            ) {
              email_specific_status =
                statusNameMapping?.[emailStatus || ""] || emailStatus;
            }
          }

          if (!landing_email_status) {
            landing_email_status = userData.email_specific[email].landingPage
              ? userData.email_specific[email].landingPage
              : null;
          }

          if (!landing_email_get_quote_clicked) {
            landing_email_get_quote_clicked = userData.email_specific[email]
              .homeGetQuote
              ? userData.email_specific[email].homeGetQuote
              : null;
          }

          if (
            (!quote_status && db_quote_status) ||
            (quote_status === "reject" && db_quote_status) ||
            db_quote_status === "proceed_uw"
          ) {
            quote_status = db_quote_status;
          }
        }

        if (userData.isSalespersonSubmitted) {
          step = "Submitted";
        } else if (userData.isUserSubmitted) {
          step = "User Submitted";
        } else if (userData.isSubmitted) {
          step = "Submitted";
        } else if (Number(userData.proposalGeneratedCount) > 0) {
          step = "Proposal Generated";
        } else if (quote_status) {
          if (quote_status === "uwportal") {
            step = "Locations & Payroll";
          } else if (quote_status === "proceed_uw") {
            step = "Approved";
          } else if (quote_status === "reject") {
            step = "Rejected";
          }
        } else if (Number(userData.quoteGeneratedCount) > 0) {
          step = "Initial Pricing";
        } else if (userData.formStage) {
          if (userData.formStage === "one") {
            step = "Company Information";
            if (landing_email_get_quote_clicked) {
              step += " (Get Quote)";
            }
          } else {
            if (excluded_status) {
              step = "Not Eligible";
            } else {
              step = "Locations and Payroll";
            }
          }
        } else if (userData.visitCount && Number(userData.visitCount) > 0) {
          step = "Landing Page";
        } else if (landing_email_status) {
          step =
            landing_email_status === "affinityLogo"
              ? "Affinity Logo Clicked"
              : "Fourth Logo Clicked";
        } else {
          step = "-";
        }

        if (email_specific_status) {
          step =
            statusPriorityMap[email_specific_status] < statusPriorityMap[step]
              ? email_specific_status
              : step;
        }

        let currProspectDetails = {
          companyProfile: userData.companyProfile,
          childrenLoc: userData.payrollData,
          emodStatesData: userData.emodStatesData,
        };

        // let total_emp = 0,
        //   payroll = 0;

        // if (userData.payrollData) {
        //   let childrenLoc = userData.payrollData;
        //   for (let locIndex in childrenLoc) {
        //     let loc = childrenLoc[locIndex];
        //     for (let ccIndex in loc.classCodesInfo) {
        //       let cc = loc.classCodesInfo[ccIndex];
        //       total_emp =
        //         total_emp +
        //         Number(cc?.ft?.value || 0) +
        //         Number(cc?.pt?.value || 0);
        //       let payrollVlue = cc?.payroll?.value?.replace(/\D/g, "") || 0;
        //       payroll = payroll + Number(payrollVlue || 0);
        //     }
        //   }
        // }

        rowData = {
          visitDate:
            userData.timestamp &&
            moment(Number(userData.timestamp))
              .utcOffset("-0500")
              .format("M/D/YY - hh:mm A"),
          userEmail: userData.email,
          quoteGeneratedCount: userData.quoteGeneratedCount || 0,
          proposalGeneratedCount: userData.proposalGeneratedCount || 0,
          uuid: userData.uuid,
          phoneNumber: userData.phoneNumber,
          payroll: userData.payroll,
          indicationCost: userData.indicationCost,
          emod:
            userData.emod && Number(userData.emod)
              ? parseFloat(userData.emod).toFixed(2)
              : "-",
          uwEmail: userData.uwEmail,
          effectiveDate: userData.effectiveDate
            ? moment(userData.effectiveDate).format("x")
            : "",
          contactName: userData.contactName || "",
          companyName:
            userData?.companyProfile?.company_name?.value ||
            userData?.companyProfile?.companyName?.value ||
            "",
          comment: userData?.comment
            ? typeof userData?.comment === "string"
              ? [userData?.comment]
              : userData?.comment
            : [""],
          salescomment: userData?.salescomment
            ? typeof userData?.salescomment === "string"
              ? [userData?.salescomment]
              : userData?.salescomment
            : [""],
          admincomment: userData?.admincomment
            ? typeof userData?.admincomment === "string"
              ? [userData?.admincomment]
              : userData?.admincomment
            : [""],
          smcomment: userData?.smcomment
            ? typeof userData?.smcomment === "string"
              ? [userData?.smcomment]
              : userData?.smcomment
            : [""],
          domain: userData.domain,
          visitCount: userData.visitCount || 0,
          step,
          latestCampaign: userData.latestCampaign || "-",
          email_specific: userData.email_specific,
          messageID: userData.messageID,
          dashboardFiles: userData.dashboardFiles,
          uploadedTimeStamp: userData.UploadedDate
            ? moment(userData.UploadedDate, "x").format("M/D/YY")
            : userData.zywave_uploaded_date
            ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                "M/D/YY"
              )
            : "-",
          currProspectDetails,
          salesID: userData.id,
          formStage: userData.formStage,
          address: userData.address,
          quoteData: userData.quoteData,
          total_emp: userData.total_emp || 0,
          payroll: userData.payroll || 0,
          // sales: "true",
          state: userData?.state || "",
          city:
            userData?.companyProfile?.cityName1?.value ||
            userData?.companyProfile?.city1?.value ||
            userData?.companyProfile?.city?.value,
          salespersonName:
            userData?.salespersonNameAttr ||
            userData?.salespersonName ||
            userData?.default_saleperson ||
            "",
          uploadedDate: userData.uploadedDate,
          source: userData.source,
          isPreUnderwritten: userData?.preUwData?.preUwStatus ? true : false,
          dealFlag:
            userData?.dealData?.status === "true"
              ? "closed"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealFlagFilter:
            userData?.dealData?.status === "true"
              ? "won"
              : userData?.dealData?.status === "false"
              ? "lost"
              : "",
          dealConfirmed: userData?.dealConfirmed,
          recordDetails: {
            domain: userData.domain,
          },
          ispeowith: userData?.ispeowith || "",
          campaignDate: userData?.campaignDate || "",
          special_catergory: userData?.special_catergory,
          default_saleperson: userData?.default_saleperson,
          contactInfoAvailable,
          Emod: userData?.Emod ? userData?.Emod : "-",
          sff: userData?.sff ? userData?.sff : "-",
        };

        createCommentForDownload(rowData, "comment");
        createCommentForDownload(rowData, "admincomment");
        createCommentForDownload(rowData, "salescomment");
        createCommentForDownload(rowData, "smcomment");

        if (rowData.email_specific) {
          Object.values(rowData.email_specific).map((email) => {
            if (
              email.companyProfile &&
              email.companyProfile.contact_number &&
              email.companyProfile.contact_number.value
            ) {
              email.companyProfile.contact_number.value = this.appendRemaining(
                email.companyProfile.contact_number.value.toString()
              );
            }
          });
        } else {
          if (rowData.phoneNumber)
            rowData.phoneNumber = this.appendRemaining(
              rowData.phoneNumber.toString()
            );
        }

        if (rowData.dealConfirmed) {
          if (rowData.dealFlag === "closed") {
            wonList.push(rowData);
          } else {
            lostList.push(rowData);
          }
        } else {
        }
        rowDataList.push(rowData);

        domain_messageid_files[rowData.domain] = {};
        if (rowData.messageID)
          domain_messageid_files[rowData.domain]["messageID"] =
            rowData.messageID;
        if (rowData.dashboardFiles)
          domain_messageid_files[rowData.domain]["dashboardFiles"] =
            rowData.dashboardFiles;

        if (userData.preUwFiles) {
          let _preUwFiles = [];
          userData.preUwFiles.forEach((ele) => {
            _preUwFiles.push(ele.key);
          });
          domain_messageid_files[userData.domain]["preUwFiles"] = _preUwFiles;
        }

        if (userData.fileListToStore) {
          let _fileListToStore = [];
          userData.fileListToStore.forEach((ele) => {
            _fileListToStore.push(ele.key);
          });
          domain_messageid_files[userData.domain][
            "fileListToStore"
          ] = _fileListToStore;
        }

        if (rowData.domain) {
          salescommentsHistory[rowData.domain] = rowData.salescomment;
        }

        if (rowData.domain) {
          salesManagerCommentHistory[rowData.domain] = rowData.smcomment;
        }
      } catch (error) {
        console.log("error in processEngagedData: ", error);
      }
    }

    console.log("returning list: ", rowDataList);
    let withPeo = [];
    let withoutPeo = [];
    for (let i = 0; i < rowDataList.length; i++) {
      if (
        rowDataList[i]?.ispeowith &&
        rowDataList[i]?.ispeowith.toLowerCase() !== "no"
      ) {
        withPeo.push(rowDataList[i]);
      } else {
        withoutPeo.push(rowDataList[i]);
      }
    }

    return {
      value: rowDataList,
      won: wonList,
      lost: lostList,
      domain_messageid_files,
      salescommentsHistory,
      salesManagerCommentHistory,
      peo: withPeo,
      peoW: withoutPeo,
    };
  };

  fetchDataApi = (
    requestBody,
    sessionData,
    dataToDisplay,
    isLoading,
    st_key,
    tab_name
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
        .then((res) => {
          let apiData = res.data.data;

          // console.log(apiData , "APIDATA");

          let {
            value: EngageValue,
            won: EngageWon,
            lost: EngageLost,
            domain_messageid_files: Engage_domain_messageid_files,
            salescommentsHistory: EngageCommentsHistory,
            salesManagerCommentHistory: EngageSalesManagerCommentHistory,
            peo: peo,
            peoW: peoW,
          } = this.processEngagedData(Object.values(apiData), sessionData);

          dataToDisplay[`tab${st_key}`] = {
            value: EngageValue,
            domain_messageid_files: Engage_domain_messageid_files,
            salescommentsHistory: EngageCommentsHistory,
            salesManagerCommentHistory: EngageSalesManagerCommentHistory,
            name: tab_name,
            peo: peo,
            peoW: peoW,
          };

          isLoading[`tab${st_key}`] = false;
          resolve();
        })
        .catch((error) => {
          console.log("error", error);
          resolve();
        });
    });
  };

  fetchData = async () => {
    let sessionData = await Auth.currentAuthenticatedUser();
    let salesperson_type = [],
      _dataToDisplay = {},
      fetchApiPromiseList = [],
      _isLoading = {},
      selected = "";

    const TabNameMap = {
      avoidIsMoreThanThousandEmp: "<500",
      isMoreThanThousandEmp: ">500",
    };

    try {
      if (sessionData) {
        let groups =
          sessionData?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups) {
          if (groups.includes(process.env.REACT_APP_SALES_INTRSTD)) {
            salesperson_type.push("isMoreThanThousandEmp");
          }
          if (groups.includes(process.env.REACT_APP_SALES_PLUS)) {
            salesperson_type.push("avoidIsMoreThanThousandEmp");
          }
        }
      }
    } catch (error) {
      console.log("ERRO:", error);
    }

    if (sessionData && salesperson_type?.length) {
      for (let st_key in salesperson_type) {
        let _st_key = Number(st_key) + 1;

        if (!selected) selected = `tab${_st_key}`;

        _isLoading[`tab${_st_key}`] = true;
        _dataToDisplay[`tab${_st_key}`] = [];
      }

      this.setState(
        {
          isLoading: JSON.parse(JSON.stringify(_isLoading)),
          dataToDisplay: JSON.parse(JSON.stringify(_dataToDisplay)),
          selected,
        },
        () => {
          for (let st_key in salesperson_type) {
            let _st_key = Number(st_key) + 1;

            let requestBody = {
              sendtosalespersonExsist: "true",
              preUwSaveExists: "true",
              [salesperson_type[st_key]]: "true",
              tab: "tab1",
            };
            requestBody.salesPersonEmail = sessionData.attributes.email;

            fetchApiPromiseList.push(
              this.fetchDataApi(
                requestBody,
                sessionData,
                _dataToDisplay,
                _isLoading,
                _st_key,
                TabNameMap[salesperson_type[st_key]]
              )
            );
          }

          Promise.all(fetchApiPromiseList).then((res) => {
            this.setState({
              dataToDisplay: _dataToDisplay,
              isLoading: _isLoading,
            });
          });
        }
      );
    }
  };

  render() {
    let {
      selected,
      dataToDisplay,
      dealMap,
      globalAddContact,
      isLoading,
      selectedPeo,
    } = this.state;

    let showMultipleTabs = Object.keys(dataToDisplay).length > 1 ? true : false;
    return (
      <div className="dashboard-container">
        <div className="d-flex bg-light">
          <Header />
        </div>

        <div className="border-bottom-color">
          <HeaderMainSubPart />
        </div>

        {showMultipleTabs && (
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{
              fontSize: "16px",
              margin: "5px",
              fontFamily: "Montserrat",
              marginTop: "30px",
            }}
          >
            {Object.keys(dataToDisplay).map((tab) => (
              <Nav.Item>
                <Nav.Link
                  eventKey={tab}
                  onClick={() => this.setState({ selected: tab })}
                >
                  {dataToDisplay[tab].name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}

        <Nav
          variant="pills"
          defaultActiveKey={selectedPeo}
          style={{
            fontSize: "16px",
            margin: "5px",
            fontFamily: "Montserrat",
            marginTop: "30px",
          }}
        >
          {/* peoTabName */}
          {Object.keys(peoTabName).map((tab) => (
            <Nav.Item>
              <Nav.Link
                eventKey={tab}
                onClick={() => this.setState({ selectedPeo: tab })}
              >
                {peoTabName[tab]}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        {Object.keys(dataToDisplay).map(
          (tab) => (
            <>
              <SalesDashboard
                tabName={tab}
                selected={selected}
                selectedPeo={selectedPeo}
                list={dataToDisplay?.[tab] || []}
                domain_messageid_files={
                  dataToDisplay?.[tab]?.domain_messageid_files || {}
                }
                salescommentsHistory={
                  dataToDisplay?.[tab]?.salescommentsHistory || {}
                }
                salesManagerCommentHistory={
                  dataToDisplay?.[tab]?.salesManagerCommentHistory || {}
                }
                dealMap={dealMap}
                updateDealMap={this.updateDealMap}
                isLoading={isLoading?.[tab] ? true : false}
                globalAddContact={globalAddContact}
                saveContact={this.saveContact}
              />
            </>
          )
          // );
        )}

        <Loader />
      </div>
    );
  }
}

let initialcomment = {
  comment: "",
  commentTimestamp: "",
  email: "",
};

class SalesDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      rowDataList: [],
      domain_messageid_files: {},
      salescommentsHistory: {},
      style: {},
      salesPersonLogin: false,
      salesManagerLogin: false,
      uploadDateFlag: false,
      scrollLeft: false,
      loggedUserName: "",
      scrollRight: true,
      openModal: false,
      modalBody: [],
      stateSpecificCheck: false,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
    };

    this.handleDisplayHide = this.handleDisplayHide.bind();
  }

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;
    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const loggedUserName = currentAuthUser.attributes.name;
      this.setState({ loggedUserName: loggedUserName });
      if (currentAuthUser) {
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups) {
          if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
            this.setState({ salesManagerLogin: true });
          }
          if (groups.includes(process.env.REACT_APP_DATA_TEAM)) {
            this.setState({ uploadDateFlag: true });
          } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
            this.setState({ salesPersonLogin: true });
          } else {
            this.setState({ uploadDateFlag: false });
          }

          if (groups.includes(process.env.REACT_APP_STATE_GROUP)) {
            this.setState({ stateSpecificCheck: true });
          }
        }
      }
    } catch (error) {}
    window.addEventListener("load", this.handleDisplayHide);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
    if (
      this.props.selected !== prevProps.selected ||
      this.props?.list?.length !== prevProps?.list?.length
    ) {
      this.setState({ rowDataList: this.props.list });
    }
    let len1 = Object.keys(this.props.domain_messageid_files || {}).length,
      len2 = Object.keys(this.state.domain_messageid_files || {}).length;
    if (
      len1 &&
      len2 &&
      (len1 !== len2 ||
        JSON.stringify(this.props.domain_messageid_files) !==
          JSON.stringify(this.state.domain_messageid_files))
    ) {
      this.setState({
        domain_messageid_files: this.props.domain_messageid_files,
      });
    }
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };
  updateEngagedComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.salescomment) {
      requestBody.salescomment = data.salescomment;
    }

    requestBody.tableName = "tab1";
    requestBody.loggedUserName = this.state.loggedUserName;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateAdminComments = (e, rowData) => {
    let { rowDataList, program, source } = JSON.parse(
      JSON.stringify(this.state)
    );
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = rowDataList[index].domain;
    requestBody.admincomment = e.target.value;
    requestBody.tableName = "tab1";

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateSalesComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.smcomment) {
      requestBody.smcomment = data.smcomment;
    }
    if (data.comment) {
      requestBody.comment = data.comment;
    }
    requestBody.tableName = "tab3";
    requestBody.loggedUserName = this.state.loggedUserName;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateSalesManagerComment = (data) => {
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;

    if (data.smcomment) {
      requestBody.smcomment = data.smcomment;
    }
    if (data.comment) {
      requestBody.comment = data.comment;
    }

    requestBody.tableName = "tab1";
    requestBody.loggedUserName = this.state.loggedUserName;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  onClickCompanyName = (rowData) => {
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    if (rowData.dealConfirmed === "true") {
      sessionStorage.setItem("hideButtons", "true");
    }
    if (rowData.salesID) {
      sessionStorage.setItem("salesID", rowData.salesID);
    }
    if (rowData.formStage) {
      sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.amtrustAccountDetailsCopy) {
      sessionStorage.setItem(
        "amtrustAccountDetailsCopy",
        JSON.stringify(rowData.amtrustAccountDetailsCopy)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    if (rowData.isSalesforce) {
      sessionStorage.setItem("isSalesforce", true);
      sessionStorage.setItem("sfQuoteId", rowData.sfQuoteId);
    }

    if (rowData.latestQuoteKey) {
      sessionStorage.setItem("latestQuoteKey", rowData.latestQuoteKey);
    }
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY - hh:mm A");
    let momentB = moment(b[field], "M/D/YY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  copySuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  source = (Source) => {
    if (Source == "hotschedules") {
      return "HotSchedules";
    } else if (Source == "affinity") {
      return "Affinity";
    } else {
      return Source;
    }
  };
  copyError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  copyId = (id) => {
    try {
      navigator.clipboard.writeText(id);
      this.copySuccess("Copied");
    } catch (error) {
      console.log(error);
      this.copyError("Error while copying");
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }

    // if (term[0] == ">" && term[1] == "=") {
    //   return firstTwoCharacter <= rowData[customFieldName];
    // } else if (term[0] == "<" && term[1] == "=") {
    //   return firstTwoCharacter >= rowData[customFieldName];
    // } else if (term[0] == ">") {
    //   return firstCharacter < rowData[customFieldName];
    // } else if (term[0] == "<") {
    //   return firstCharacter > rowData[customFieldName];
    // } else if (term[0] == "=") {
    //   return firstCharacter == rowData[customFieldName];
    // } else {
    //   return rowData[customFieldName]?.toString()?.startsWith(term);
    // }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  tab1DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab1">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(this.formatInCamel(
                      rowData?.companyProfile?.firstName?.value
                    ) || "") +
                      " " +
                      (this.formatInCamel(
                        rowData?.companyProfile?.lastName?.value
                      ) || "") || "-"}
                  </TableCell>
                  <TableCell scope="row">
                    {rowData?.companyProfile?.phoneNumber?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) || "-"}
                  </TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"0"}</TableCell>
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  handleCommentHistory = (rowdata, rowkey, name) => {
    if (name === "admincomment") {
      rowdata.forEach((item) => {
        if (item.salesID || item.domain === rowkey) {
          if (typeof item.admincomment?.[0] === "object") {
            this.setState({ modalBody: item.admincomment });
          } else if (typeof item.admincomment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.admincomment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    }
    if (name === "salescomment") {
      rowdata.forEach((item) => {
        if (item.salesID || item.domain === rowkey) {
          if (typeof item.salescomment?.[0] === "object") {
            this.setState({ modalBody: item.salescomment });
          } else if (typeof item.salescomment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.salescomment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    } else if (name === "smcomment") {
      rowdata.forEach((item) => {
        if (item.salesID || item.domain === rowkey) {
          if (typeof item.smcomment?.[0] === "object") {
            this.setState({ modalBody: item.smcomment });
          } else if (typeof item.smcomment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.smcomment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    } else if (name === "comment") {
      rowdata.forEach((item) => {
        if (item.salesID || item.domain === rowkey) {
          if (typeof item.comment?.[0] === "object") {
            this.setState({ modalBody: item.comment });
          } else if (typeof item.comment?.[0] === "string") {
            let updatedComment = [
              {
                ...initialcomment,
                comment: item.comment?.[0],
                commentTimestamp: item.uploadTimestamp,
              },
            ];
            this.setState({ modalBody: updatedComment });
          } else {
            this.setState({ modalBody: [initialcomment] });
          }
        }
      });
    }
    this.setState({ openModal: true });
  };

  setCommentBoxColor = (comments) => {
    if (comments?.[0] !== "") {
      if (typeof comments?.[0] === "string") {
        return "#FFE7C1";
      }
    }
    if (comments?.length > 1) {
      return "#FFE7C1";
    }
  };

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  styles = (step, rowData) => {
    let style = {
      width: "80%",
      borderRadius: "20px",
      padding: "5px",
      textAlign: "center",
    };
    if (step === "Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "User Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "Proposal Generated") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "UW Portal") {
      style.backgroundColor = "#ffd737ac";
    } else if (step === "Approved") {
      style.backgroundColor = "#87FA4D";
    } else if (step === "Rejected") {
      style.backgroundColor = "#fa6d3e";
    } else if (step === "Company Information") {
      style.backgroundColor = "red";
    } else if (step === "MQL") {
      style.backgroundColor = "gray";
    } else if (step === "Initial Pricing") {
      style.backgroundColor = "#2ea44f";
    } else if (step === "Locations and Payroll") {
      style.backgroundColor = "#FFD737";
    } else if (Number(rowData.visitCount) > 0) {
      style.backgroundColor = "#FFD737";
    } else {
      style.backgroundColor = "#FFF";
    }
    return style;
  };

  setTableData = () => {
    let {
      rowDataList,
      isLoading,
      salesPersonLogin,
      salesManagerLogin,
      uploadDateFlag,
      scrollLeft,
      scrollRight,
      popupData,
    } = JSON.parse(JSON.stringify(this.state));
    const updateRowDataList = (domain, editedRows) => {
      const _rowDataList = JSON.parse(JSON.stringify(rowDataList));

      // const editedRowData =
      //   _rowDataList.find((item) => item.domain === domain)?.email_specific ||
      //   {};
      const editedRowData = _rowDataList?.value.find(
        (item) => item?.domain === domain
      );

      editedRows.forEach((item) => {
        const { emailid, ...properties } = item;
        if (editedRowData[emailid]) {
          // Merge properties from subset into superset
          editedRowData[emailid] = { ...editedRowData[emailid], ...properties };
        }
      });
      this.setState({ rowDataList: _rowDataList });
    };

    let {
      salesManagerCommentHistory,
      salescommentsHistory,
      domain_messageid_files,
      tabName,
      selected,
      selectedPeo,
      list,
      updateDealMap,
      dealMap,
      globalAddContact,
      saveContact,
    } = this.props;

    let interested_columns = [
      {
        title: "Uploaded Date",
        field: "campaignDate",
        filterPlaceholder: "Filter",

        render: (rowData) => {
          if (rowData?.campaignDate) {
            return <div>{rowData?.campaignDate}</div>;
          } else {
            return "-";
          }
        },
        cellStyle: {
          padding: "2px 0px 2px 2px",
          minWidth: "8rem",
        },
        headerStyle: {
          padding: "2px 0px 2px 2px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 2px",
        },
      },
      {
        title: "Visit Date (ET)",
        field: "visitDate",
        defaultSort: "desc",
        filtering: true,
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),
        customSort: (a, b) => this.dateSort(a, b, "visitDate"),

        render: (rowData) => {
          if (rowData.visitDate) {
            let date = rowData?.visitDate.split("-")[0] || "-";
            let time = rowData?.visitDate.split("-")[1] || "-";
            return (
              <span>
                {date} {"&"}
                {time}
              </span>
            );
          } else {
            return "-";
          }
        },
        cellStyle: {
          wordBreak: "break-all",
          padding: "3px 0px 3px 3px",
          minWidth: "10rem",
        },
        headerStyle: {
          padding: "5px 0px 5px 4px",
          minWidth: "10rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
      },
      {
        title: "Company Name",
        field: "companyName",

        cellStyle: {
          padding: "6px 5px 6px 10px",
          minWidth: "18rem",
          wordBreak: "break-word",
        },
        headerStyle: {
          padding: "3px 0px 3px 10px",
          minWidth: "12rem",
        },
        filterCellStyle: {
          padding: "2px 0px 2px 4px",
        },
        filtering: true,
        filterPlaceholder: "Filter",
        render: (rowData) => {
          if (rowData.sales === "true") {
            //copy here
            return (
              <span className="d-flex">
                <img
                  style={{ width: "12px", cursor: "pointer" }}
                  className="mr-2"
                  src={clipboard}
                  title={rowData.salesID}
                  onClick={() => {
                    this.copyId(rowData.salesID);
                  }}
                />
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"} style={{ color: "black" }}>
                      {this.camelCase(rowData?.companyName)}
                    </Link>
                  </span>
                </Clamp>
              </span>
            );
          } else {
            let random_user = rowData?.email_specific
              ? Object.keys(rowData?.email_specific)?.[0]
              : "";

            if (random_user) {
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("landingEmail", random_user);
                      // sessionStorage.setItem("domain", rowData.domain);
                      sessionStorage.setItem("salespersonMailCampaign", "true");
                    }}
                  >
                    {rowData?.ispeowith &&
                    rowData?.ispeowith.toLowerCase() !== "no" ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          to={`/Quote/?user=${random_user}&viewButton=true`}
                          style={{ color: "black" }}
                        >
                          {this.camelCase(rowData.companyName)}
                        </Link>
                        <Tooltip
                          title={
                            rowData?.ispeowith
                              ? rowData?.ispeowith
                              : "The Company is with PEO Relationship"
                          }
                          arrow
                        >
                          <p>
                            <FaRegHandshake size={15} color="#427cbc" />
                          </p>
                        </Tooltip>
                      </span>
                    ) : (
                      <Link
                        to={`/Quote/?user=${random_user}&viewButton=true`}
                        style={{ color: "black" }}
                      >
                        {this.camelCase(rowData.companyName)}
                      </Link>
                    )}
                  </span>
                </Clamp>
              );
            } else {
              return <>{this.camelCase(rowData.companyName)}</>;
            }
          }
        },
      },

      {
        title: "Payroll",
        field: "payroll",
        filterPlaceholder: "Filter",
        filtering: true,

        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterfunction(term, rowData, field),

        render: (rowData) => numeral(rowData?.payroll).format("$0,0") || "$0",
        cellStyle: {
          padding: "6px 20px 6px 10px",
          minWidth: "8rem",
          wordBreak: "break-all",
          textAlign: "right",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 0px",
        },
      },
      {
        title: "State",
        field: "state",
        filterPlaceholder: "Filter",
        filtering: true,
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),

        render: (rowData) => {
          return rowData?.state?.toUpperCase() || "";
        },
        cellStyle: {
          textTransform: "uppercase",
          padding: "6px 0 6px 10px",
          with: "20px",
          minWidth: "50px",
          maxWidth: "20px",
          textAlign: "left",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          with: "20px",
          minWidth: "50px",
          maxWidth: "20px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
      },
      {
        title: "City",
        field: "city",
        filterPlaceholder: "Filter",
        filtering: true,
        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterAll(term, rowData, field),
        render: (rowData) => this.camelCase(rowData?.city),
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "8rem",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 7px 2px 1px",
        },
      },
      {
        title: "# Emp",
        field: "total_emp",
        filterPlaceholder: "Filter",

        filtering: true,
        cellStyle: {
          padding: "6px 6px",
          paddingRight: "20px",
          minWidth: "5rem",
          textAlign: "right",
        },
        headerStyle: {
          padding: "6px 0 6px 18px",
          whiteSpace: "nowrap",
          minWidth: "5rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 15px",
        },
      },
      {
        title: "Emod",
        field: "Emod",
        filterPlaceholder: "Filter",
        render: (rowData) => rowData.Emod,

        cellStyle: {
          padding: "6px 20px 6px 10px",
          minWidth: "7rem",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "7rem",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 0px",
        },
      },
      {
        title: "SFF",
        field: "SFF",
        filterPlaceholder: "Filter",

        render: (rowData) => rowData.sff,

        cellStyle: {
          textTransform: "uppercase",
          padding: "6px 0 6px 10px",
          minWidth: "5rem",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "5rem",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 0px",
        },
      },
      {
        title: "Status",
        field: "step",
        filtering: true,
        filterPlaceholder: "Filter",
        cellStyle: (e, rowData) => {
          let style = { minWidth: "8rem", padding: "6px 0 6px 13px" };
          // if (rowData.step === "Submitted") {
          //   style.backgroundColor = "#5ADA38";
          // } else if (rowData.step === "User Submitted") {
          //   style.backgroundColor = "#5ADA38";
          // } else if (rowData.step === "Proposal Generated") {
          //   style.backgroundColor = "#5ADA38";
          // } else if (rowData.step === "UW Portal") {
          //   style.backgroundColor = "#ffd737ac";
          // } else if (rowData.step === "Approved") {
          //   style.backgroundColor = "#87FA4D";
          // } else if (rowData.step === "Rejected") {
          //   style.backgroundColor = "#fa6d3e";
          // } else if (rowData.step === "Initial Pricing") {
          //   style.backgroundColor = "#2ea44f";
          // } else if (rowData.step === "Locations & Payroll") {
          //   style.backgroundColor = "#FFD737";
          // } else if (Number(rowData.visitCount) > 0) {
          //   style.backgroundColor = "#FFD737";
          // } else {
          //   style.backgroundColor = "#FFF";
          // }
          return style;
        },
        headerStyle: {
          padding: "6px 0 6px 13px",
          minWidth: "8rem",
        },
        filterCellStyle: {
          padding: "2px 5px 2px 5px",
          minWidth: "170px",
        },
        render: (rowData) => (
          <div style={this.styles(rowData?.step, rowData)}>
            {rowData?.step || "-"}
          </div>
        ),
      },
    ];

    if (selected === "tab2" || selected === "tab3") {
      if (uploadDateFlag) {
        interested_columns.unshift({
          title: "Upload Date",
          field: "uploadedTimeStamp",
          filtering: true,
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData?.uploadedTimeStamp || ""}</Clamp>
          ),
        });
      }
    }

    interested_columns.push({
      title: "Sales Comments",
      field: "salescomment_download",
      filterPlaceholder: "Filter",
      filtering: true,

      render: (rowData) => {
        const callbackFunction = (data, rowData) => {
          if (rowData.sales === "true") {
            this.updateSalesComment(data);
          } else {
            this.updateEngagedComment(data);
          }
        };
        return (
          <div className="d-flex">
            <TextField
              key={`${rowData?.salesID || rowData?.domain}_comment`}
              value={
                typeof salescommentsHistory?.[rowData?.salesID]?.[0] ===
                  "object" ||
                typeof salescommentsHistory?.[rowData?.domain]?.[0] === "object"
                  ? salescommentsHistory?.[rowData?.salesID]?.[0]?.comment ||
                    salescommentsHistory?.[rowData?.domain]?.[0]?.comment
                  : salescommentsHistory?.[rowData?.salesID]?.[0] ||
                    salescommentsHistory?.[rowData?.domain]?.[0]
              }
              callback={callbackFunction}
              rowData={rowData}
              rowDataID={rowData.salesID || rowData.domain}
              rowDataName={rowData.salesID ? "id" : "domain"}
              rowDataSortKeyList={rowData.sortKeyList}
              rowDataComments={rowData.salesID ? "comment" : "salescomment"}
              comment={
                salescommentsHistory?.[rowData?.salesID] ||
                salescommentsHistory?.[rowData?.domain]
              }
              // handleAgentCommentHistory={this.handleAgentCommentHistory}
            />
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                style={{ height: "32px" }}
                onClick={() =>
                  this.handleAgentCommentsButtonClick(
                    salescommentsHistory?.[rowData?.salesID] ||
                      salescommentsHistory?.[rowData?.domain],
                    rowData.uploadTimestamp
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        minWidth: "230px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    });

    interested_columns.push(
      {
        title: "#Visit",
        field: "visitCount",
        filterPlaceholder: "Filter",

        customFilterAndSearch: (term, rowData, field) =>
          this.customFilterfunction(term, rowData, field),
        filtering: true,
        cellStyle: {
          padding: "6px 15px 6px 23px",
          minWidth: "50px",
          maxWidth: "50px",
          with: "50px",
          textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0px 6px 15px",
          minWidth: "50px",
          maxWidth: "50px",
          with: "50px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 10px",
        },
      },
      {
        title: (
          <img
            src={require("../../images/download_black.png")}
            alt=""
            style={{
              width: "20px",
              height: "20px",
              // filter: "grayscale(100%)  opacity(0.4)",
            }}
          />
        ),
        disableSortBy: true,
        filtering: true,
        sorting: false,
        cellStyle: {
          minWidth: "30px",
          width: "30px",
          maxWidth: "30px",
          padding: "0 0",
        },
        headerStyle: {
          paddingLeft: "15px",
        },
        render: (rowData) => {
          if (rowData.sales === "true") {
            return (
              <button className="btn pt-0" type="button">
                <img
                  src={require("../../images/download_black.png")}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    filter: "grayscale(100%)  opacity(0.4)",
                  }}
                />
              </button>
            );
          }

          let title = "";

          if (
            domain_messageid_files &&
            domain_messageid_files[rowData.domain]?.dashboardFiles?.length
          ) {
            for (let file of domain_messageid_files[rowData.domain]
              .dashboardFiles) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }
          if (domain_messageid_files[rowData.domain]?.fileListToStore?.length) {
            for (let file of domain_messageid_files[rowData.domain]
              .fileListToStore) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }
          if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
            for (let file of domain_messageid_files[rowData.domain]
              .preUwFiles) {
              let fileToDisplay = file.split("/");
              title =
                title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
            }
          }

          let totalFiles =
            (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
              0) +
            (domain_messageid_files[rowData.domain]?.preUwFiles?.length || 0) +
            (domain_messageid_files[rowData.domain]?.fileListToStore?.length ||
              0);

          return (
            <button
              className="btn pt-0"
              type="button"
              title={parse(title)}
              data-html="true"
              onClick={() => {
                this.setState({
                  showModel: true,
                  modalTitle: <h1>{"Download"}</h1>,
                  modalContent: (
                    <>
                      <DownloadDashboardFiles
                        dashboardFiles={
                          domain_messageid_files[rowData.domain]
                            .dashboardFiles || []
                        }
                        preUwFiles={
                          domain_messageid_files[rowData.domain].preUwFiles ||
                          []
                        }
                        fileListToStore={
                          domain_messageid_files[rowData.domain]
                            .fileListToStore || []
                        }
                        domainName={rowData.domain}
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                      />
                    </>
                  ),
                });
              }}
            >
              <img
                src={require("../../images/download_black.png")}
                alt=""
                style={
                  totalFiles > 0
                    ? {
                        width: "20px",
                        height: "20px  ",
                        filter: "grayscale(0)",
                      }
                    : {
                        width: "20px",
                        height: "20px",
                        filter: "grayscale(100%)  opacity(0.4)",
                      }
                }
              />
            </button>
          );
        },
      },
      {
        title: "Underwriter Comments",
        field: "comment_download",
        filterPlaceholder: "Filter",
        render: (rowData) => {
          return (
            <div className="d-flex">
              <textarea
                defaultValue={
                  typeof rowData?.comment?.[0] === "object"
                    ? rowData?.comment?.[0]?.comment
                    : rowData?.comment?.[0] || rowData?.comment || ""
                }
                disabled
                rows="1"
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  //  backgroundColor: this.setCommentBoxColor(rowData?.comment),
                }}
              ></textarea>
              <Tooltip title="Click to view comment history" arrow>
                <button
                  className="comment-btn"
                  onClick={
                    () =>
                      this.handleCommentHistory(
                        [rowData],
                        `${rowData?.domain}`,
                        "comment"
                      )
                    // console.log([rowData], `${rowData.salesID}`)
                  }
                >
                  ...
                </button>
              </Tooltip>
            </div>
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "230px",
        },
        headerStyle: {
          minWidth: "230px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 15px 2px 5px",
        },
      },
      {
        title: "Campaign Name",
        field: "latestCampaign",
        filterPlaceholder: "Filter",
        filtering: true,
        cellStyle: {
          padding: "6px 15px 6px 10px",
          wordBreak: "break-word",
          minWidth: "180px",
        },
        headerStyle: {
          minWidth: "180px",
          paddingLeft: "10px",
        },
        filterCellStyle: {
          padding: "2px 18px 2px 3px",
        },
        render: (rowData) => (
          <Clamp lines={2}>
            {this.camelCase(rowData?.latestCampaign) || ""}
          </Clamp>
        ),
      }
    );
    interested_columns.push({
      title: "Sales Person",
      field: "salespersonName",
      filterPlaceholder: "Filter",
      filtering: true,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "180px",
        textAlign: "left",
      },
      headerStyle: {
        minWidth: "180px",
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 3px",
      },
      render: (rowData) => {
        let name = "";
        try {
          if (rowData?.salespersonName?.length > 20) {
            name = `${rowData?.salespersonName.slice(0, 20)}...`;
          } else {
            name = rowData.salespersonName;
          }
        } catch (error) {}
        if (rowData?.special_catergory) {
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{name} </div>
              <CiLinkedin size={20} color="#427cbc" />
            </span>
          );
        } else {
          return name;
        }
      },
    });

    interested_columns.push({
      title: "Sold / Not Sold",
      field: "dealFlagFilter",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        let key = rowData.domain || rowData.salesID;
        let isClosed = false,
          isLost = false;
        if (key in dealMap) {
          isClosed = dealMap[key] === "true" ? true : false;
          isLost = dealMap[key] === "false" ? true : false;
        } else {
          isClosed = rowData.dealFlag === "closed" ? true : false;
          isLost = rowData.dealFlag === "lost" ? true : false;
        }
        return (
          <DealClosedLostButtons
            recordDetails={rowData.recordDetails}
            isClosed={isClosed}
            isLost={isLost}
            updateDealMap={updateDealMap}
            disabled={rowData.dealConfirmed === "true"}
            updatePopUpData={this.updatePopUpData}
          />
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "160px",
      },
      headerStyle: {
        padding: "6px 5px 6px 20px",
        color: "black",
      },
    });

    return (
      <>
        <div style={{ position: "relative" }} className="salesDashboard">
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}
          <MaterialTable
            isLoading={this.props.isLoading}
            icons={tableIcons}
            data={rowDataList?.[selectedPeo] || []}
            columns={interested_columns}
            options={{
              stickyHeader: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#ffc423",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
                height: "50px",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "2px 10px 2px 0px",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
            detailPanel={
              tabName === "tab2" || tabName === "tab3"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <>
                            {!!rowData?.email_specific ? (
                              <Tab2DetailPanel
                                showAddContact={true}
                                rowData={rowData}
                                parentAdditionalRows={
                                  globalAddContact?.[rowData.domain] || []
                                }
                                prim_key={rowData.domain}
                                saveContact={saveContact}
                                updateRowDataList={updateRowDataList}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    // {
                    //   tooltip: "Client info",
                    //   icon: () => <KeyboardArrowRightIcon />,
                    //   render: (rowData) => {
                    //     return this.tab1DetailPanel(rowData);
                    //   },
                    // },
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <>
                            {!!rowData?.email_specific ? (
                              <Tab2DetailPanel
                                showAddContact={true}
                                rowData={rowData}
                                parentAdditionalRows={
                                  globalAddContact?.[rowData.domain] || []
                                }
                                prim_key={rowData.domain}
                                saveContact={saveContact}
                                updateRowDataList={updateRowDataList}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      },
                    },
                  ]
                : tabName === "tab4"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if ("true" === rowData?.sales) {
                          return this.tab1DetailPanel(rowData);
                        } else {
                          return (
                            <>
                              {!!rowData?.email_specific ? (
                                <Tab2DetailPanel
                                  showAddContact={false}
                                  rowData={rowData}
                                  parentAdditionalRows={
                                    globalAddContact?.[rowData.domain] || []
                                  }
                                  saveContact={saveContact}
                                  prim_key={rowData.domain}
                                  updateRowDataList={updateRowDataList}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        }
                      },
                    },
                  ]
                : tabName === "tab5" || tabName === "tab6"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if ("true" === rowData?.sales) {
                          return this.tab1DetailPanel(rowData);
                        } else {
                          return (
                            <>
                              {!!rowData?.email_specific ? (
                                <Tab2DetailPanel
                                  showAddContact={false}
                                  rowData={rowData}
                                  parentAdditionalRows={
                                    globalAddContact?.[rowData.domain] || []
                                  }
                                  saveContact={saveContact}
                                  prim_key={rowData.domain}
                                  updateRowDataList={updateRowDataList}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        }
                      },
                    },
                  ]
                : false
            }
          />
        </div>
      </>
    );
  };

  handleAgentCommentsButtonClick = (comments, submittedDate) => {
    if (typeof comments[0] === "string" && comments[0] !== "") {
      let updatedComment = [
        {
          ...initialcomment,
          comment: comments[0],
          commentTimestamp: submittedDate,
        },
      ];
      this.setState({ modalBody: updatedComment });
    } else if (typeof comments[0] === "object") {
      this.setState({ modalBody: comments });
    } else {
      this.setState({ modalBody: [initialcomment] });
    }

    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render() {
    let {
      showModel,
      modalTitle,
      modalContent,
      openModal,
      modalBody,
    } = this.state;
    let { tabName, selected } = this.props;
    return (
      <>
        {selected === tabName ? (
          <div
            className={"sales-dashboard " + `${tabName}`}
            style={this.state.style}
          >
            <div ref={this.myRef}>{this.setTableData()}</div>

            <Dialog open={openModal} onClose={this.handleCloseModal}>
              <DialogTitle>
                <p style={{ textAlign: "center" }}>
                  {(modalBody.length > 0 &&
                    modalBody?.[0] !== "" &&
                    modalBody?.[0]?.comment !== "") ||
                  modalBody?.[0]?.previousComment
                    ? "Comment History"
                    : "No Comment History"}
                </p>
                <IconButton
                  aria-label="close"
                  onClick={this.handleCloseModal}
                  style={{ position: "absolute", right: "5px", top: "8px" }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {modalBody.length > 0 &&
                  modalBody[0] !== "" &&
                  modalBody.map((item, index) => {
                    let date = moment(item.commentTimestamp)
                      .format("M/D/YY h:mm A")
                      .split(" ")[0];
                    let time = moment(item.commentTimestamp)
                      .format("M/D/YY h:mm A")
                      .split(" ")[1];
                    if (item.comment !== "") {
                      if (item.commentTimestamp || item.email || item) {
                        return (
                          <div>
                            <div>
                              <span>
                                <b>{date}</b>
                              </span>
                              <span
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <b>{time}</b>
                              </span>
                              <span>{item.email ? "- " : ""}</span>
                              <span>
                                <b>{item.email?.split("@")[0]}</b>
                              </span>
                            </div>
                            <div>
                              <p>
                                {item?.comment
                                  ? item.comment
                                  : modalBody[index + 1]?.comment
                                  ? modalBody[index + 1].comment
                                  : item}
                              </p>
                            </div>
                            <br />
                          </div>
                        );
                      }
                    } else {
                      return;
                    }
                  })}
              </DialogContent>
            </Dialog>

            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton className="custom-modal-body">
                <Modal.Title className="modal-title-centered">
                  {modalTitle}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom-modal-body">
                {modalContent}
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {this.state.popupData.show && (
          <Popup
            dashboardstyle={true}
            popupData={this.state.popupData}
            updatePopUpData={this.updatePopUpData}
          />
        )}
        <Toaster />
      </>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      this.setState({
        loggedInEmail:
          sessionData["attributes"]["name"] ||
          sessionData["attributes"]["email"],
      });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    const checkcommentAvailble = (comment) => {
      if (comment?.[0] !== "") {
        if (typeof comment?.[0] === "string") {
          return "#FFE7C1";
        }
      }
      if (comment?.length > 1) {
        return "#FFE7C1";
      }
    };
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          borderRadius: "4px",
          // backgroundColor: checkcommentAvailble(this.props.comment),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let agentCommentsCopy = this.props.comment;

          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (agentCommentsCopy === undefined && e.target.value.trim() !== "") {
            agentCommentsCopy = [
              {
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              },
            ];
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (
              agentCommentsCopy[0]?.comment &&
              e.target.value.trim() !== agentCommentsCopy[0]?.comment
            ) {
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
            } else if (
              agentCommentsCopy[0] &&
              typeof agentCommentsCopy[0] === "string"
            ) {
              e.target.value.trim() !== agentCommentsCopy[0] &&
                agentCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  previousComment: "true",
                  email: loggedInEmail,
                });
            } else {
              console.log("I m returning as value is 0");
              return;
            }
          } else if (typeof agentCommentsCopy[0] === "string") {
            e.target.value.trim() !== agentCommentsCopy[0] &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          } else {
            e.target.value.trim() !== agentCommentsCopy[0].comment &&
              agentCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                email: loggedInEmail,
              });
          }

          // this.props.handleAgentCommentHistory(
          //   this.props.rowData,
          //   agentCommentsCopy
          // );
          if (this.props.rowDataSortKeyList) {
            dataToSave.sortKeyList = this.props.rowDataSortKeyList;
          }

          dataToSave[this.props.rowDataName] = this.props.rowDataID;
          dataToSave[this.props.rowDataComments] = agentCommentsCopy;
          dataToSave[this.props.rowDataComments] &&
            this.props.callback(dataToSave, this.props.rowData);
        }}
      ></textarea>
    );
  }
}

export default DashboardSales;

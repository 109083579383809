import React, { Component } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Form, Button } from "react-bootstrap";
import Newcode from "./Newcode";
import CompLoc from "./CompLoc";
import EmodQuestions from "./EmodQuestions";
import InputGroup from "react-bootstrap/InputGroup";
import {
  salesManagerLoginCheck,
  preUwPersonLoginCheck,
  adminLoginCheck,
  brdLogincheck,
} from "../../../utils/form_ques";
import {
  submitCompPro,
  stepOneGet,
  sendEmail,
  resendOTPMethod,
  verifyOTP,
  reset,
  setStateAndCity,
  getEmodStatesData,
  setStateAndCity1,
  setStateAndCity2,
} from "../../../utils/form_compPro";
import {
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
} from "../../../utils/common";
import {
  cookie,
  cookie2,
  setCookie,
  setProspectDetails,
  deleteAddress,
  setAddressDetails,
} from "../../../utils/session_data";
import NewProgress from "../../../newcomponent/common/NewProgress";
import $ from "jquery";
import SmallLoader from "../../common/SmallLoader";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL, awsUrl, awsUrl2 } from "../../../config";
import store from "../../../redux/store/index";
import StateList from "../../common/StateList";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import { companyProfileAddQuestions } from "../../../utils/ques_list";
import Allques from "../../subcompo/sections-quote/Allques";

import Context from "../../../contexts/Context";
import BrokerQues from "./BrokerQues";
// const companyProfileAddQuestions = []
var stateCity = require("../../../utils/state_city.json");
const numeral = require("numeral");
const uuidv4 = require("uuid/v4");
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
var prospect, addressCount, loggedin;

class FormCompPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      role: "",
      selDateBus: null,
      selDateEff: moment(),
      selDateExp: moment().add(1, "year"),
      header: {},
      childrenLoc: {},
      uploadingFile: false,
      awaitingFile: false,
      error: false,
      errorMsg: "",
      urlSuccess: false,
      urlFail: false,
      basicInfoFail: false,
      stateInfoFail: false,
      signedUrl: "",
      showCompanyProfile: true,
      showLocationAndPayroll: true,
      showAdditionalQuestions: true,
      companyProfileAddQuestions: companyProfileAddQuestions,
      state_class_code: {},
      salespersonCheck: false,
      brdCheck: false,
      salespersonName: "",
      companyProfile: {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        emailId: {},
        expectedStartDate: { value: "" },
        effectiveDate: { value: "" },
        fein: {},
        street1: {},
        zipCode1: {},
        cityName1: {},
        state1: {},
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
        website: {},
      },
      sameAsPrimary: false,
      requestedPricing: {
        checked: false,
        adminPercent: "",
        perCKdollar: "",
        WeeklySelect: "b",
      },
      sameAsPrimary: false,
      emodQuestions: {},
      emodStatesData: {},
      otpVerified:
        sessionStorage.getItem("otpVerified") === "true" ? true : false,
      quoteSubmitted: false,
      PreUwCheck: false,
      PreUwPersonName: "",
      adminCheck: false,
      adminPersonName: "",
      salesManagerCheck: false,
      salesManagerPersonName: "",
      brokerDetails: {
        brokerQuestionValue: false,
        brokerdropdownSelected: null,
        brokerPercentage: null,
      },
      stateSpecificCheck: false,
      specificStates: [],
      zipError: "",
      primaryStateCheck: false,
    };
    // this.setStateAndCity = setStateAndCity.bind(this);
    this.setStateAndCity1 = setStateAndCity1.bind(this);
    this.setStateAndCity2 = setStateAndCity2.bind(this);
  }

  setStateAndCity = (zipCode, index) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;
    if (!zipCode) return;
    axios
      .post(awsUrl2 + "/api/getStateAndCity/" + zipCode)
      .then((res) => {
        const data = res.data;

        if (Object.keys(data).length !== 0) {
          let locationIndex = index;
          let fieldName = "state";
          let prevState;
          if (
            fieldName === "state" &&
            childrenLoc[locationIndex][fieldName].value
          ) {
            prevState = childrenLoc[locationIndex][fieldName].value;

            //cc_delete
            for (let classCodeIndex in childrenLoc[locationIndex]
              .classCodesInfo) {
              let currState = childrenLoc[locationIndex].state.value || "";
              try {
                if (
                  currState.toLowerCase() in state_class_code &&
                  state_class_code[currState.toLowerCase()].val &&
                  childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ]
                ) {
                  let class_code_desc = childrenLoc[locationIndex]
                    .classCodesInfo[classCodeIndex]["classCodeDescription"]
                    ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                        "classCodeDescription"
                      ].value
                    : undefined;
                  if (class_code_desc) {
                    let class_code = class_code_desc.split(":")[0].trim();
                    if (
                      state_class_code[currState.toLowerCase()].cc_dsc &&
                      class_code in
                        state_class_code[currState.toLowerCase()].cc_dsc
                    ) {
                      let locToUpdate, ccToUpdate;
                      for (let locIndx in childrenLoc) {
                        if (childrenLoc[locIndx].state.value === currState) {
                          let ccInfo = childrenLoc[locIndx].classCodesInfo;
                          for (let ccIndx in ccInfo) {
                            let cc_desc;
                            try {
                              cc_desc =
                                ccInfo[ccIndx]["classCodeDescription"].value;
                            } catch (e) {}
                            if (cc_desc) {
                              let cc = cc_desc.split(":")[0].trim();
                              if (Number(cc) === Number(class_code)) {
                                childrenLoc[locIndx].classCodesInfo[ccIndx][
                                  "pricing"
                                ] = {
                                  value:
                                    state_class_code[currState.toLowerCase()]
                                      .cc_dsc[class_code],
                                  disabled: true,
                                };
                                if (
                                  !locToUpdate &&
                                  !ccToUpdate &&
                                  Number(locIndx) !== Number(locationIndex)
                                ) {
                                  locToUpdate = locIndx;
                                  ccToUpdate = ccIndx;
                                }
                              }
                            }
                          }
                        }
                      }
                      if (locToUpdate && ccToUpdate) {
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].disabled = false;
                        if (
                          !(
                            "cc_dsc" in
                            state_class_code[currState.toLowerCase()]
                          )
                        ) {
                          state_class_code[currState.toLowerCase()].cc_dsc = {};
                        }
                        try {
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ] =
                            childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                              "pricing"
                            ].value;
                        } catch (error) {}
                      } else {
                        delete state_class_code[currState.toLowerCase()].cc_dsc[
                          class_code
                        ];
                      }
                    }
                  }
                }
              } catch (error) {}
            }
            //cc_delete
          }
          childrenLoc[index].state = { value: data.state };
          childrenLoc[index].cityName = { value: data.city };

          let stateList = [];
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }
          if (fieldName === "state") {
            let value = data.state;
            try {
              for (let addressBlockNo in childrenLoc) {
                if (childrenLoc[addressBlockNo].state.value) {
                  if (
                    prevState &&
                    prevState === childrenLoc[addressBlockNo].state.value
                  ) {
                    childrenLoc[addressBlockNo].suta.disabled = false;
                    childrenLoc[addressBlockNo].clientsuta.disabled = false;
                    prevState = "#####";
                  }
                }
              }

              if (prevState && prevState !== "#####") {
                delete state_class_code[prevState.toLowerCase()];
              }

              let stateSet = new Set(stateList);

              for (let stateIndx in state_class_code) {
                if (!stateSet.has(stateIndx.toLowerCase())) {
                  delete state_class_code[stateIndx];
                }
              }
            } catch (error) {}

            try {
              if (!(value.toLowerCase() in state_class_code)) {
                state_class_code[value.toLowerCase()] = {
                  ...state_class_code[value.toLowerCase()],
                  val: childrenLoc[locationIndex].suta.value
                    ? childrenLoc[locationIndex].suta.value
                    : "",
                  val2: childrenLoc[locationIndex].clientsuta.value
                    ? childrenLoc[locationIndex].clientsuta.value
                    : "",
                };
                childrenLoc[locationIndex].suta.disabled = false;
                childrenLoc[locationIndex].clientsuta.disabled = false;
              } else {
                if (state_class_code[value.toLowerCase()].val) {
                  childrenLoc[locationIndex]["suta"] = {
                    value: state_class_code[value.toLowerCase()].val,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["suta"] = {
                    value: "",
                    disabled: false,
                  };
                }
                if (state_class_code[value.toLowerCase()].val2) {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: state_class_code[value.toLowerCase()].val2,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: "",
                    disabled: false,
                  };
                }
              }
            } catch (error) {
              console.log(
                "error in setStateAndZip inside state_class_code calculations"
              );
            }
          }

          if (stateList.length > 0) {
            let statesData = {
              ncciStates: [],
              actingNcciStates: [],
              indBureauStates: [],
            };
            axios
              .post(
                awsUrl2 + "/api/getExpModStateCategory",
                JSON.stringify({ states: stateList })
              )
              .then((response) => {
                statesData = response.data;
              })
              .catch((error) => {
                console.log("error", error);
              })
              .finally(() => {
                let newEmodData = getEmodStatesData(
                  statesData,
                  emodStatesData || {}
                );
                this.setState({ childrenLoc, emodStatesData: newEmodData });
              });
          } else {
            emodStatesData = {};
            this.setState({ childrenLoc, emodStatesData });
          }
        } else {
          childrenLoc[index].zipCode = { errorMsg: true, error: true };
          childrenLoc[index].state = { value: "" };
          childrenLoc[index].cityName = { value: "" };
          this.setState({ childrenLoc });
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
        childrenLoc[index].zipCode = { errorMsg: true, error: true };
        childrenLoc[index].state = { value: "" };
        childrenLoc[index].cityName = { value: "" };
        this.setState({ childrenLoc });
      });
  };

  setUWQues = (quesData) => {
    let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));

    for (let quesIndex in companyProfileAddQuestions) {
      if (quesData[quesIndex].resp === "Y") {
        companyProfileAddQuestions[quesIndex].response = true;
      } else {
        companyProfileAddQuestions[quesIndex].response = false;
      }
      companyProfileAddQuestions[quesIndex].remarks =
        quesData[quesIndex].remarks;
    }
    this.setState({ companyProfileAddQuestions });
  };

  updateQuesList = (e, key, value) => {
    let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
    let index = e.target.id.split("-")[1];
    companyProfileAddQuestions[index][key] = value;
    this.setState({ companyProfileAddQuestions });
  };
  updateLastThreeQuesList = (e, key, value) => {
    let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
    let index = e.target.name[2];
    // console.log("+", index, "+", key, "+", value);
    companyProfileAddQuestions[index][key] = value;
    this.setState({ companyProfileAddQuestions });
  };

  updateQuesList_sales_flow = (e, key, value) => {
    let { companyProfileAddQuestions } = JSON.parse(JSON.stringify(this.state));
    let name = e.target.name.slice(2);
    let index = isNaN(name) ? 3 : 2;
    companyProfileAddQuestions[index][key] = value;
    this.setState({ companyProfileAddQuestions });
  };

  addYearToDate = (dt) => {
    dt.exp = Object.assign({}, dt);
    dt.exp.eff.add(1, "year");
    return dt;
  };
  handleDateChangeBus = (date) => {
    this.setState({
      selDateBus: date,
    });
  };
  handleDateChangeEff = (date) => {
    let { companyProfile } = this.state;
    if (date) {
      companyProfile.expectedStartDate.value = date;
      companyProfile.effectiveDate.value = date;
      this.setState({ companyProfile });
    }
  };
  handleDateChangeExp = (date) => {
    this.setState({
      selDateExp: date,
    });
  };

  handleOptionChange = (e, key, value) => {
    let { requestedPricing } = this.state;
    requestedPricing["WeeklySelect"] = value;
    this.setState({
      ...requestedPricing,
      // companyProfileAddQuestions: companyProfileAddQuestions,
    });
  };

  brokerQuestionResponse = (e, response) => {
    let brokerDetails = { ...this.state.brokerDetails };
    brokerDetails.brokerQuestionValue = response;
    // if (response === false) {
    //   brokerDetails.brokerdropdownSelected = null;
    //   brokerDetails.brokerPercentage = null;
    // }
    // if (
    //   source === "affinity" &&
    //   (formStage === "one" || formStage === undefined) &&
    //   response === true
    // ) {
    //   brokerDetails.brokerdropdownSelected = {
    //     label: "Affinity Group PEO Solutions",
    //     value: "Affinity Group PEO Solutions",
    //     rate: "25",
    //   };
    //   brokerDetails.brokerPercentage = "25";
    // }
    this.setState({ brokerDetails });
  };

  brokerDropdownResponse = (selectedOption) => {
    let brokerDetails = { ...this.state.brokerDetails };
    // console.log("value got selecet", selectedOption);
    brokerDetails.brokerdropdownSelected = selectedOption;
    this.setState({
      brokerDetails,
    });
  };

  brokerPercentageResponse = (e) => {
    let brokerDetails = { ...this.state.brokerDetails };
    // console.log("value got selecet", e.target.value);
    brokerDetails.brokerPercentage = e.target.value;
    this.setState({
      brokerDetails,
    });
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    stepOneGet();
    // prospect = cookie();
    // addressCount = cookie2();
    // loggedin = sessionStorage.getItem("isLoggedIn");
    // let rand = Math.random();
    let dupChildrenLoc = {};
    // let initialCountOfAddress = 1;
    // if (prospect && addressCount && loggedin) {
    //   initialCountOfAddress = addressCount;
    // }
    for (let i = 0; i < 1; i++) {
      let rand = Math.random();
      dupChildrenLoc[i] = {
        number: i,
        key: rand,
        classCodesInfo: { 0: {} },
        state: {},
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
        clientsuta: {},
      };
    }
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
    $("#loader").css("display", "block");
  }

  // componentDidUpdate() {
  //   const { childrenLoc } = this.state;
  //   clearDelete(childrenLoc, "addressBlock");
  //   if (prospect && addressCount && loggedin) {
  //     setProspectDetails();
  //     setAddressDetails();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted)
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
  }

  setInputData = (currProspect) => {
    try {
      let { state_class_code } = this.state;
      let {
        childrenLoc,
        companyProfile,
        emodStatesData,
        requestedPricing,
        companyProfileAddQuestions,
        brokerDetails,
      } = currProspect;

      if (!childrenLoc || Object.keys(childrenLoc).length === 0) {
        let rand = Math.random();
        childrenLoc = {
          0: {
            number: 0,
            key: rand,
            classCodesInfo: { 0: {} },
            state: {},
            zipCode: {},
            cityName: {},
            street: {},
            suta: {},
            clientsuta: {},
          },
        };
      }

      let primaryKeys = ["cityName", "street", "state", "zipCode"];
      let formStage = sessionStorage.getItem("formStage");
      let sameAsPrimary = true;
      for (let key of primaryKeys) {
        if (
          companyProfile?.[`${key}1`]?.value !==
          companyProfile?.[`${key}2`]?.value
        ) {
          sameAsPrimary = false;
          break;
        }
      }

      //create state and cc_desc key in state_class_code map
      for (let stateIndx in childrenLoc) {
        if (
          childrenLoc[stateIndx] &&
          childrenLoc[stateIndx].state &&
          childrenLoc[stateIndx].state.value &&
          !(
            childrenLoc[stateIndx].state.value.toLowerCase() in state_class_code
          )
        ) {
          let currstate = childrenLoc[stateIndx].state.value.toLowerCase();
          let ccinfo = childrenLoc[stateIndx].classCodesInfo;
          if (currstate in state_class_code) continue;

          state_class_code[currstate] = {
            val:
              childrenLoc[stateIndx].suta && childrenLoc[stateIndx].suta.value
                ? childrenLoc[stateIndx].suta.value
                : "",
            val2:
              childrenLoc[stateIndx].clientsuta &&
              childrenLoc[stateIndx].clientsuta.value
                ? childrenLoc[stateIndx].clientsuta.value
                : "",
            cc_dsc: {},
          };

          for (let ccinfoIndx in ccinfo) {
            let ccObject = ccinfo[ccinfoIndx];
            if (ccObject) {
              let cc =
                ccObject.classCodeDescription &&
                ccObject.classCodeDescription.value
                  ? ccObject.classCodeDescription.value.split(":")[0]
                  : "";
              if (!(cc in state_class_code[currstate].cc_dsc)) {
                state_class_code[currstate].cc_dsc[cc] =
                  ccObject.pricing && ccObject.pricing.value
                    ? ccObject.pricing.value
                    : "";
              }
            }
          }
        }
      }

      if (!companyProfile.expectedStartDate)
        companyProfile.expectedStartDate = {};

      companyProfile.expectedStartDate.value =
        companyProfile.expectedStartDate &&
        companyProfile.expectedStartDate.value
          ? moment(companyProfile.expectedStartDate.value)
          : moment().date() === 1
          ? moment()
          : moment(moment().subtract(moment().date() - 1, "days")).add(
              1,
              "month"
            );

      if (!companyProfile.effectiveDate) companyProfile.effectiveDate = {};

      companyProfile.effectiveDate.value =
        companyProfile.effectiveDate && companyProfile.effectiveDate.value
          ? moment(companyProfile.effectiveDate.value)
          : moment().date() === 1
          ? moment()
          : moment(moment().subtract(moment().date() - 1, "days")).add(
              1,
              "month"
            );
      if (requestedPricing) {
        if (!requestedPricing?.["WeeklySelect"]) {
          requestedPricing["WeeklySelect"] = "b";
        }
        this.setState({ requestedPricing });
      }
      if (brokerDetails) {
        this.setState({ brokerDetails });
      }
      if (companyProfileAddQuestions) {
        for (let question of companyProfileAddQuestions) {
          console.log(question);
          if (question.name !== "qc3") {
            if (question.response === "Y") {
              question.response = true;
            } else {
              question.response = false;
            }
          }
        }

        for (let i = 0; i < this.state.companyProfileAddQuestions.length; i++) {
          if (
            companyProfileAddQuestions?.[i]?.["name"] !==
            this.state.companyProfileAddQuestions[i]["name"]
          ) {
            companyProfileAddQuestions[i] = {
              ...this.state.companyProfileAddQuestions[i],
            };
          } else if (
            companyProfileAddQuestions?.[i]?.["name"] ===
            this.state.companyProfileAddQuestions[i]["name"]
          ) {
            companyProfileAddQuestions[i][
              "type"
            ] = this.state.companyProfileAddQuestions[i]["type"];
            companyProfileAddQuestions[i][
              "hiddenText"
            ] = this.state.companyProfileAddQuestions[i]["hiddenText"];
          }
        }

        this.setState({ companyProfileAddQuestions });
      }

      this.setState(
        {
          childrenLoc,
          companyProfile,
          emodStatesData,
          state_class_code,
          sameAsPrimary,
        },
        () => {
          if (formStage === "one") {
            $("#loader").css("display", "none");
          }
        }
      );
    } catch (error) {
      console.log("error: ", error);
    }
  };

  checkSameAsPrimary = () => {
    let { companyProfile } = this.state;
    let primaryKeys = ["cityName", "street", "state", "zipCode"];
    let sameAsPrimary = true;
    for (let key of primaryKeys) {
      if (
        companyProfile?.[`${key}1`]?.value !==
        companyProfile?.[`${key}2`]?.value
      ) {
        sameAsPrimary = false;
        break;
      }
    }

    return sameAsPrimary;
  };

  validateStateCityAndZip = async (childrenLoc) => {
    try {
      function fetchStateAndCity(currObj) {
        new Promise((resolve, reject) => {
          let _zipCode = currObj.zipCode.value;
          let _cityName = currObj.cityName.value;
          let _state = currObj.state.value;

          if (!_zipCode) {
            resolve();
          }

          axios
            .post(awsUrl2 + "/api/getStateAndCity/" + _zipCode)
            .then((res) => {
              let { state, city } = res.data;

              if (_state !== state) {
                currObj.zipCode.error = true;
                currObj.cityName.error = true;
              } else if (_cityName !== city) {
                currObj.cityName.error = true;
              }

              resolve();
            })
            .catch((err) => {
              resolve();
            });
        });
      }

      let stateAndCityPromiseList = [];

      for (let key in childrenLoc) {
        let currObj = childrenLoc[key];

        stateAndCityPromiseList.push(fetchStateAndCity(currObj));
      }

      await Promise.allSettled(stateAndCityPromiseList)
        .then((res) => {
          console.log("details checked ", res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  componentDidMount = async () => {
    // let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    // if (currProspect) this.setInputData(currProspect);
    let mailCampaignUserCheck = checkMailCampaignUser();
    console.log(mailCampaignUserCheck, "mailCampaignUserCheck");
    let isLoggedIn = sessionStorage.getItem("isLoggedIn");
    console.log(isLoggedIn, "isLoggedIn");
    let isHomeCampaign = sessionStorage.getItem("homeCampaign") ? true : false;
    let source = sessionStorage.getItem("source");
    let formStage = sessionStorage.getItem("formStage");

    if (
      source === "affinity" &&
      (formStage === "one" || formStage === undefined)
    ) {
      let brokerDetails = this.state.brokerDetails;
      brokerDetails.brokerdropdownSelected = {
        label: "Affinity Group PEO Solutions",
        value: "Affinity Group PEO Solutions",
        rate: "25",
      };
      brokerDetails.brokerQuestionValue = true;
      brokerDetails.brokerPercentage = "25";
      this.setState({ brokerDetails });
    }

    let {
      salespersonName,
      salespersonCheck,
      salespersonNameAttr,
    } = await this.salesPersonLoginCheck();

    if (salespersonCheck) {
      this.setState({
        salespersonCheck,
        salespersonName,
      });
    }

    let { brdPersonName, brdPersonNameAttr, brdCheck } = await brdLogincheck();

    if (brdCheck) {
      this.setState({
        brdCheck,
        brdPersonName,
      });
    }

    let {
      PreUwPersonName,
      PreUwCheck,
      PreUwPersonNameAttr,
    } = await preUwPersonLoginCheck();

    if (PreUwCheck) {
      this.setState({
        PreUwCheck,
        PreUwPersonName,
      });
    }
    let {
      salesManagerPersonName,
      salesManagerCheck,
      salesManagerPersonNameAttr,
    } = await salesManagerLoginCheck();

    if (salesManagerCheck) {
      this.setState({
        salesManagerCheck,
        salesManagerPersonName,
      });
    }

    let {
      adminPersonName,
      adminPersonNameAttr,
      adminCheck,
    } = await adminLoginCheck();

    if (adminCheck) {
      this.setState({
        adminCheck,
        adminPersonName,
      });
    }

    Auth.currentAuthenticatedUser()
      .then((userTemp) => {
        console.log(userTemp, "userTemp");
        if (userTemp) {
          sessionStorage.setItem("isLoggedIn", true);
          if (
            userTemp.signInUserSession !== null &&
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_SALES_GROUP)
          ) {
            sessionStorage.setItem("otpVerified", true);
            this.setState({ otpVerified: true, salesLogin: true });
          }

          if (
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_PRIMARY_GROUP)
          ) {
            let specifiedState = userTemp?.attributes?.["custom:State"].split(
              ","
            );
            this.setState({ stateSpecificCheck: true });
            this.setState({ primaryStateCheck: true });
            if (specifiedState && specifiedState.length > 0) {
              this.setState({ specificStates: specifiedState });
              sessionStorage.setItem(
                "specificStates",
                JSON.stringify(specifiedState)
              );
            }
          }
        }
      })
      .catch((error) => console.log("Can not authenticate the user", error));

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let campaignName = urlParams.get("campaignName");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    let viewButton = urlParams.get("viewButton");
    let preUnderwriter = sessionStorage.getItem("preUnderwriter");
    if (user && !mailCampaignUserCheck) {
      $("#loader").css("display", "block");
      user = user.toLowerCase();
      let tempBusinessName = user.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = user.split("@")[0];
      } else {
        domain = business_name;
      }
      let currDomain = sessionStorage.getItem("domain");
      let timestamp = Math.floor(Date.now()).toString();
      sessionStorage.setItem("visitTimestamp", timestamp);
      sessionStorage.setItem("landingEmail", user);

      let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
      if (domainResp.data && domainResp.data.domain) {
        domain = domainResp.data.domain;
      }
      sessionStorage.setItem("domain", domain);

      let api_promise_list = [];
      let response_data = {};

      api_promise_list.push(
        axios
          .get(awsUrl + "/api/getUserDataStatus/" + domain + "/quote")
          .then(async (response) => {
            if (response.status === 200)
              response_data["user_status_data"] = response.data || {};
          })
          .catch((err) => console.error(err))
      );

      api_promise_list.push(
        axios
          .get(awsUrl2 + "/api/getDomainInputData/" + domain)
          .then(async (response) => {
            response_data["domain_data"] = response.data || {};
          })
          .catch((err) => console.error(err))
      );

      Promise.all(api_promise_list)
        .then(async (_response) => {
          let requestBody = {};

          console.log(response_data);

          if (response_data?.["user_status_data"]) {
            sessionStorage.setItem("emailCampaignUser", true);
            let carrierBasedData =
              response_data["user_status_data"].carrierBasedData;
            console.log("**inside here");
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];

                let currProspectDetails = lineObj.currProspectDetails;
                let preUwData = lineObj.preUwData;
                let landingUserCompanyProfile = JSON.parse(
                  JSON.stringify(
                    response_data?.["domain_data"]?.email_specific?.[user]
                      ?.companyProfile
                  )
                );
                if (landingUserCompanyProfile) {
                  let nameList =
                    landingUserCompanyProfile?.contact_name?.value?.split(
                      " "
                    ) || [];
                  let lastName = { value: nameList.pop() };
                  let firstName = { value: nameList.join(" ") };
                  if (!firstName.value) {
                    firstName.value = lastName.value;
                    lastName.value = "";
                  }
                  currProspectDetails.companyProfile = {
                    ...currProspectDetails.companyProfile,
                    ...landingUserCompanyProfile,
                    firstName,
                    lastName,
                  };
                }
                // currProspectDetails.companyProfile = response_data?.["domain_data"]?.email_specific[user]?.companyProfile;

                if (lineObj.preUwFiles)
                  sessionStorage.setItem(
                    "preUwFiles",
                    JSON.stringify(lineObj.preUwFiles)
                  );

                if (lineObj.dashboardFiles)
                  sessionStorage.setItem(
                    "dashboardFiles",
                    JSON.stringify(lineObj.dashboardFiles)
                  );

                // let companyProfile = {
                //   companyName: { value: lineObj.company_name },
                //   primaryContact: {
                //     value: lineObj.contact_person,
                //   },
                //   emailId: { value: lineObj.user_email_id },
                //   fein: { value: lineObj.fein },
                //   phoneNumber: { value: lineObj.phone_number },
                //   primaryContact: {
                //     value: lineObj.contact_person,
                //   },
                //   effectiveDate: {
                //     value: lineObj.effective_date,
                //   },
                //   healthQues: {
                //     value:
                //       lineObj.healthQues === true ||
                //         lineObj.healthQues === false
                //         ? lineObj.healthQues
                //         : true,
                //   },
                // };
                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let indicationCost = lineObj.carrier_location_data.total;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                let amtrustAccountDetails = lineObj.amtrustAccountDetails;
                let amtrustAccountDetailsCopy =
                  lineObj.amtrustAccountDetailsCopy;

                if (amtrustAccountDetails) {
                  sessionStorage.setItem(
                    "amtrustAccountDetails",
                    JSON.stringify(amtrustAccountDetails)
                  );
                }

                if (amtrustAccountDetailsCopy) {
                  sessionStorage.setItem(
                    "amtrustAccountDetailsCopy",
                    JSON.stringify(amtrustAccountDetailsCopy)
                  );
                }

                sessionStorage.setItem("preUwData", JSON.stringify(preUwData));
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspectDetails)
                );
                if (currProspectDetails.childrenLoc)
                  sessionStorage.setItem(
                    "childrenLoc",
                    JSON.stringify(currProspectDetails.childrenLoc)
                  );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem("domain", domain);
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
                requestBody = {
                  email: user.toLowerCase(),
                  domain: domain.toLowerCase(),
                  timestamp: timestamp,
                  payrollData: currProspectDetails.childrenLoc,
                  companyProfile: currProspectDetails.companyProfile,
                  indicationCost,
                  emodStatesData: currProspectDetails.emodStatesData,
                  campaignName,
                  preventCompanyProfileUpdate: true,
                };

                if (user && !viewButton && !isHomeCampaign) {
                  let _user_tracking_promise_list = [];

                  _user_tracking_promise_list.push(
                    axios
                      .post(
                        awsUrl2 +
                          "/api/updateEngagedWithEmailCampaign/" +
                          domain,
                        JSON.stringify(domain)
                      )
                      .catch((err) => console.log(err))
                  );

                  _user_tracking_promise_list.push(
                    axios
                      .post(
                        awsUrl2 + "/api/updateSolveTrackingData",
                        JSON.stringify(requestBody)
                      )
                      .catch((err) => console.log(err))
                  );

                  Promise.all(_user_tracking_promise_list)
                    .then((res) => {
                      console.log("API tracking");
                    })
                    .catch((err) => {
                      console.log("error in API: ", err);
                    })
                    .finally(() => {
                      // $("#loader").css("display", "none");
                      window.location.reload();
                    });
                } else {
                  window.location.reload();
                  // $("#loader").css("display", "none");
                }
              }
            }
          } else {
            if (currDomain !== domain) {
              if (response_data?.["domain_data"]?.email_specific) {
                sessionStorage.setItem("emailCampaignUser", true);
                this.props.updateMailCampaignUser(true);

                let stateCompanyProfile = JSON.parse(
                  JSON.stringify(this.state.companyProfile)
                );

                let {
                  companyProfile,
                  payrollData,
                  emodStatesData = {},
                  indicationCost,
                  email_specific,
                  client_source,
                  onlyPaygo,
                  // dba,
                  fein,
                  campaignMap,
                  zywaveCompanyProfile,
                  source,
                  formStage,
                  amtrustAccountDetails,
                  amtrustAccountDetailsCopy,
                  uwAmtrustQuesList,
                  uwQuesList,
                  uwExpectedResponses,
                  preUwData,
                  preUwFiles,
                  dashboardFiles,
                  requestedPricing,
                  dealConfirmed,
                } = response_data["domain_data"];

                if (dealConfirmed === "true") {
                  sessionStorage.setItem("hideButtons", "true");
                }

                if (preUwFiles)
                  sessionStorage.setItem(
                    "preUwFiles",
                    JSON.stringify(preUwFiles)
                  );

                if (dashboardFiles)
                  sessionStorage.setItem(
                    "dashboardFiles",
                    JSON.stringify(dashboardFiles)
                  );

                if (amtrustAccountDetails) {
                  sessionStorage.setItem(
                    "amtrustAccountDetails",
                    JSON.stringify(amtrustAccountDetails)
                  );
                }

                if (amtrustAccountDetailsCopy) {
                  sessionStorage.setItem(
                    "amtrustAccountDetailsCopy",
                    JSON.stringify(amtrustAccountDetailsCopy)
                  );
                }

                let childrenLoc = payrollData;

                for (let loc in childrenLoc) {
                  if (childrenLoc[loc].classCodesInfo) {
                    for (let cc in childrenLoc[loc].classCodesInfo) {
                      if (
                        !childrenLoc[loc].classCodesInfo[cc].ft ||
                        (childrenLoc[loc].classCodesInfo[cc].ft &&
                          !Number(childrenLoc[loc].classCodesInfo[cc].ft.value))
                      ) {
                        childrenLoc[loc].classCodesInfo[cc] = {
                          ...childrenLoc[loc].classCodesInfo[cc],
                          ft: {
                            value:
                              zywaveCompanyProfile.employees &&
                              zywaveCompanyProfile.employees.value
                                ? zywaveCompanyProfile.employees.value
                                : 0,
                          },
                        };
                      }
                    }
                  }
                }

                await this.validateStateCityAndZip(childrenLoc);

                let compName;

                if (
                  companyProfile &&
                  companyProfile.company_name &&
                  companyProfile.company_name.value
                ) {
                  compName = companyProfile.company_name.value;
                }

                if (companyProfile.state.value) {
                  companyProfile.state1 = {
                    value:
                      companyProfile.state1?.value ||
                      companyProfile.state.value,
                  };
                  companyProfile.state2 = {
                    value:
                      companyProfile.state2?.value ||
                      companyProfile.state.value,
                  };
                }
                if (companyProfile.zip.value) {
                  companyProfile.zipCode1 = {
                    value:
                      companyProfile.zipCode1?.value ||
                      companyProfile.zip.value,
                  };
                  companyProfile.zipCode2 = {
                    value:
                      companyProfile.zipCode2?.value ||
                      companyProfile.zip.value,
                  };
                }
                if (companyProfile.city.value) {
                  companyProfile.cityName1 = {
                    value:
                      companyProfile.cityName1?.value ||
                      companyProfile.city.value,
                  };
                  companyProfile.cityName2 = {
                    value:
                      companyProfile.cityName2?.value ||
                      companyProfile.city.value,
                  };
                }
                if (companyProfile.address.value) {
                  companyProfile.street1 = {
                    value:
                      companyProfile.street1?.value ||
                      companyProfile.address.value,
                  };
                  companyProfile.street2 = {
                    value:
                      companyProfile.street2?.value ||
                      companyProfile.address.value,
                  };
                }

                if (email_specific && !campaignMap) {
                  if (user in email_specific) {
                    companyProfile = {
                      ...companyProfile,
                      ...email_specific[user].companyProfile,
                    };
                  } else {
                    companyProfile.emailId.value = user;
                  }
                }

                if (user in email_specific) {
                  companyProfile = {
                    ...companyProfile,
                    contact_number:
                      email_specific[user].companyProfile.contact_number,
                    contact_name:
                      email_specific[user].companyProfile.contact_name,
                  };
                }

                if (compName) {
                  companyProfile = {
                    ...companyProfile,
                    companyName: {
                      value: compName,
                    },
                    company_name: {
                      value: compName,
                    },
                  };
                }

                companyProfile = {
                  ...stateCompanyProfile,
                  ...companyProfile,
                };

                if (
                  companyProfile &&
                  (!companyProfile.companyName ||
                    !companyProfile.companyName.value) &&
                  companyProfile.company_name &&
                  companyProfile.company_name.value
                ) {
                  companyProfile.companyName = companyProfile.company_name;
                }

                if (
                  companyProfile &&
                  (!companyProfile.phoneNumber ||
                    !companyProfile.phoneNumber.value) &&
                  companyProfile.contact_number &&
                  companyProfile.contact_number.value
                ) {
                  companyProfile.phoneNumber = companyProfile.contact_number;
                }

                if (
                  companyProfile &&
                  companyProfile.contact_name &&
                  companyProfile.contact_name.value
                ) {
                  let nameList = companyProfile.contact_name.value.split(" ");
                  let lastName = nameList.pop();
                  let firstName = nameList.join(" ");
                  if (!firstName) {
                    firstName = lastName;
                    lastName = "";
                  }
                  if (lastName) {
                    companyProfile.lastName = {
                      value: lastName,
                    };
                  }
                  if (firstName) {
                    companyProfile.firstName = {
                      value: firstName,
                    };
                  }
                }

                if (
                  companyProfile &&
                  (!companyProfile.descriptionOfOperations ||
                    !companyProfile.descriptionOfOperations.value) &&
                  companyProfile.description_of_operations &&
                  companyProfile.description_of_operations.value
                ) {
                  companyProfile.descriptionOfOperations =
                    companyProfile.description_of_operations;
                }

                // if (
                //   companyProfile &&
                //   (!companyProfile.dba || !companyProfile.dba.value) &&
                //   dba
                // ) {
                //   companyProfile.dba = {
                //     value: dba,
                //   };
                // }

                if (
                  companyProfile &&
                  (!companyProfile.fein || !companyProfile.fein.value) &&
                  fein
                ) {
                  companyProfile.fein = {
                    value: fein,
                  };
                }

                if (
                  companyProfile &&
                  companyProfile.fein &&
                  companyProfile.fein.value
                ) {
                  let value = companyProfile.fein.value + "";
                  value = value.split("-").join("");
                  var len = value.length,
                    dummystr = "000000000";

                  if (len > 0 && len < 9) {
                    value = dummystr.slice(0, 9 - len) + "" + value;
                    companyProfile.fein.value =
                      value[0] + value[1] + "-" + value.slice(2);
                    companyProfile.fein.errMsg = "";
                  } else if (len > 9) {
                    companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
                    companyProfile.fein.error = true;
                  } else if (len === 0) {
                    companyProfile.fein.value = undefined;
                    companyProfile.fein.errMsg = "";
                  } else {
                    companyProfile.fein.value =
                      value[0] + value[1] + "-" + value.slice(2);
                    companyProfile.fein.errMsg = "";
                  }
                }

                if (
                  companyProfile &&
                  (!companyProfile.effectiveDate ||
                    (companyProfile.effectiveDate &&
                      !companyProfile.effectiveDate.value))
                ) {
                  companyProfile = {
                    ...companyProfile,
                    effectiveDate: {
                      value:
                        moment().date() === 1
                          ? moment()
                          : moment(
                              moment().subtract(moment().date() - 1, "days")
                            ).add(1, "month"),
                    },
                  };
                }

                if (
                  companyProfile &&
                  (!companyProfile.expectedStartDate ||
                    (companyProfile.expectedStartDate &&
                      !companyProfile.expectedStartDate.value))
                ) {
                  companyProfile = {
                    ...companyProfile,
                    expectedStartDate: {
                      value:
                        moment().date() === 1
                          ? moment()
                          : moment(
                              moment().subtract(moment().date() - 1, "days")
                            ).add(1, "month"),
                    },
                  };
                }

                let currProspect = {
                  childrenLoc,
                  companyProfile,
                  emodStatesData,
                  requestedPricing,
                };

                if (source) {
                  sessionStorage.setItem("source", source);
                }

                if (preUwData) {
                  sessionStorage.setItem(
                    "preUwData",
                    JSON.stringify(preUwData)
                  );
                }

                if (uwAmtrustQuesList) {
                  sessionStorage.setItem(
                    "uwAmtrustQuesList",
                    JSON.stringify(uwAmtrustQuesList)
                  );
                }

                if (uwQuesList) {
                  sessionStorage.setItem(
                    "uwQuesList",
                    JSON.stringify(uwQuesList)
                  );
                }

                if (uwExpectedResponses) {
                  currProspect.uwExpectedResponses = uwExpectedResponses;
                }

                if (!("healthQues" in companyProfile)) {
                  companyProfile.healthQues = { value: true };
                }

                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem("formStage", formStage);
                let sameAsPrimary = this.checkSameAsPrimary();
                this.setState({ sameAsPrimary });

                requestBody = {
                  email: user.toLowerCase(),
                  domain: domain.toLowerCase(),
                  timestamp: timestamp,
                  payrollData: childrenLoc,
                  companyProfile,
                  indicationCost,
                  emodStatesData,
                  campaignName,
                  emailSpecific: email_specific,
                  client_source,
                  onlyPaygo,
                  source,
                };

                if (user && !viewButton && !preUnderwriter) {
                  //API tracking
                  try {
                    let _domain_tracking = [];

                    if (!isHomeCampaign) {
                      _domain_tracking.push(
                        axios
                          .post(
                            awsUrl2 + "/api/updateSolveTrackingData",
                            JSON.stringify(requestBody)
                          )
                          .catch((err) => console.error(err))
                      );

                      _domain_tracking.push(
                        axios
                          .post(
                            awsUrl2 +
                              "/api/updateEngagedWithEmailCampaign/" +
                              domain,
                            JSON.stringify(domain)
                          )
                          .catch((err) => console.error(err))
                      );
                    }

                    try {
                      requestBody = {
                        domain,
                        formStage: !formStage ? "one" : formStage,
                        email: user,
                      };

                      _domain_tracking.push(
                        axios
                          .post(
                            awsUrl2 +
                              "/api/updateSolveTrackingDataGeneric/updateFormStage",
                            JSON.stringify(requestBody)
                          )
                          .catch((err) => console.error(err))
                      );
                    } catch (error) {}

                    Promise.all(_domain_tracking).then((res) => {
                      this.setInputData(currProspect);

                      if (formStage === "two") {
                        window.location.reload();
                      }
                    });
                  } catch (error) {}

                  $("#loader").css("display", "none");
                } else {
                  window.location.reload();
                  $("#loader").css("display", "none");
                }
              } else {
                $("#loader").css("display", "none");
              }
            } else {
              $("#loader").css("display", "none");
            }
          }
        })
        .catch((err) => {
          $("#loader").css("display", "none");
        });
    } else if (sessionStorage.getItem("currProspect")) {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));

      await this.validateStateCityAndZip(currProspect.childrenLoc);

      this.setInputData(currProspect);
      let sameAsPrimary = this.checkSameAsPrimary();
      this.setState({ sameAsPrimary });
    } else {
      let { companyProfile } = this.state;

      companyProfile = {
        ...companyProfile,
        expectedStartDate: { value: moment() },
        effectiveDate: { value: moment() },
      };

      this.setState({ companyProfile });

      $("#loader").css("display", "none");
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  autoFillAcordData = (data, that) => {
    console.log("data", data);
    sessionStorage.setItem("acordData", JSON.stringify(data));
    const { basic_information, state_details, location_details } = data;
    if (
      basic_information.eff_date &&
      moment(basic_information.eff_date).isValid()
    ) {
      this.setState({ selDateEff: moment(basic_information.eff_date) });
    }
    if (
      basic_information.exp_date &&
      moment(basic_information.exp_date).isValid()
    ) {
      this.setState({ selDateExp: moment(basic_information.exp_date) });
    }
    if (basic_information.fein) {
      this.setState({ fein: basic_information.fein });
    }
    if (basic_information.company_name) {
      this.setState({ company_name: basic_information.company_name });
    }
    if (basic_information.phone_number) {
      this.setState({ phone_number: basic_information.phone_number });
    }

    if (basic_information.desc) {
      $("#description").val(basic_information.desc);
    }

    if (Object.entries(location_details).length > 0) {
      for (let locNumber in location_details) {
        that.onAddChildLoc(location_details[locNumber]);
      }
      that.onrmvChild(0);
    }
  };

  uploadFile = (event) => {
    if (this.state.file) {
      var s3 = new AWS.S3(myConfig);
      // $("#loader").css("display", "block");
      this.setState({ uploadingFile: true });
      this.setState({
        error: false,
        urlSuccess: false,
        urlFail: false,
        basicInfoFail: false,
        stateInfoFail: false,
        signedUrl: "",
        awaitingFile: false,
      });

      console.log("Clicked", this.state.file[0]);
      const that = this;
      event.preventDefault();

      const formData = new FormData();
      formData.append("file", this.state.file[0]);

      axios
        .post(
          "https://5swuplkv50.execute-api.us-east-1.amazonaws.com/dev/api/fileUploadAcord",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response);

          that.setState({ uploadingFile: false });
          that.setState({ awaitingFile: true });
          const fileName = response.data.fileName;
          const folder = fileName.split(".")[0];
          const resultFilePath = `${folder}/finalResponse.json`;
          console.log("++succ", resultFilePath);
          var params = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
            $waiter: {
              maxAttempts: 180,
              delay: 5,
            },
          };
          var getParams = {
            Bucket: "ocr-doc-demo-acord",
            Key: resultFilePath,
          };
          s3.waitFor("objectExists", params, function(err, data) {
            if (err) {
              console.log("file not yet ready", err);
              that.setState({ awaitingFile: false });
              that.setState({ error: true, errorMsg: "File not yet ready!" });
            } else {
              console.log("file created successfully", data); // successful response
              that.setState({ awaitingFile: false });
              s3.getSignedUrl("getObject", getParams, function(err, url) {
                if (err) {
                  $("#loader").css("display", "none");
                  console.log("Error", err);
                  that.setState({
                    error: true,
                    errorMsg: "Error in retreiving the file",
                  });
                } else {
                  console.log("The URL is", url);
                  fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                      console.log("++++data", data);
                      if (Object.entries(data).length === 0) {
                        that.setState({ urlFail: true });
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length !== 0
                      ) {
                        that.setState({ basicInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length !== 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ stateInfoFail: true });
                        that.autoFillAcordData(data, that);
                      } else if (
                        Object.entries(data.basic_information).length === 0 &&
                        Object.entries(data.location_details).length === 0
                      ) {
                        that.setState({ urlFail: true });
                      } else {
                        that.setState({ urlSuccess: true, signedUrl: url });
                        that.autoFillAcordData(data, that);
                      }
                      $("#loader").css("display", "none");
                    })
                    .catch((error) => {
                      console.log(`Failed because: ${error}`);
                      $("#loader").css("display", "none");
                    });
                }
              });
            }
          });
        })
        .catch((error) => {
          $("#loader").css("display", "none");
          this.setState({ uploadingFile: false });
          console.log("++error", error);
          // handle your error
        });
    }
  };

  updateCompanyProfile = (event, fieldName) => {
    let { companyProfile } = this.state;
    if (fieldName === "state1" || fieldName === "state2") {
      companyProfile[fieldName] = { value: event.target.value.toLowerCase() };
    } else {
      companyProfile[fieldName] = { value: event.target.value };
    }
    this.setState({ companyProfile });
  };

  updateLocationInfo = (value, locationIndex, fieldName, classCodeIndex) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;
    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      this.setState({ childrenLoc });
    } else {
      let prevState;
      if (
        fieldName === "state" &&
        childrenLoc[locationIndex][fieldName].value
      ) {
        prevState = childrenLoc[locationIndex][fieldName].value;

        //cc_delete
        for (let classCodeIndex in childrenLoc[locationIndex].classCodesInfo) {
          let currState = childrenLoc[locationIndex].state.value || "";
          try {
            if (
              currState.toLowerCase() in state_class_code &&
              state_class_code[currState.toLowerCase()].val &&
              childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ]
            ) {
              let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
                classCodeIndex
              ]["classCodeDescription"]
                ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ].value
                : undefined;
              if (class_code_desc) {
                let class_code = class_code_desc.split(":")[0].trim();
                if (
                  state_class_code[currState.toLowerCase()].cc_dsc &&
                  class_code in state_class_code[currState.toLowerCase()].cc_dsc
                ) {
                  let locToUpdate, ccToUpdate;
                  for (let locIndx in childrenLoc) {
                    if (childrenLoc[locIndx].state.value === currState) {
                      let ccInfo = childrenLoc[locIndx].classCodesInfo;
                      for (let ccIndx in ccInfo) {
                        let cc_desc;
                        try {
                          cc_desc =
                            ccInfo[ccIndx]["classCodeDescription"].value;
                        } catch (e) {}
                        if (cc_desc) {
                          let cc = cc_desc.split(":")[0].trim();
                          if (Number(cc) === Number(class_code)) {
                            childrenLoc[locIndx].classCodesInfo[ccIndx][
                              "pricing"
                            ] = {
                              value:
                                state_class_code[currState.toLowerCase()]
                                  .cc_dsc[class_code],
                              disabled: true,
                            };
                            if (
                              !locToUpdate &&
                              !ccToUpdate &&
                              Number(locIndx) !== Number(locationIndex)
                            ) {
                              locToUpdate = locIndx;
                              ccToUpdate = ccIndx;
                            }
                          }
                        }
                      }
                    }
                  }
                  if (locToUpdate && ccToUpdate) {
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].disabled = false;
                    if (
                      !("cc_dsc" in state_class_code[currState.toLowerCase()])
                    ) {
                      state_class_code[currState.toLowerCase()].cc_dsc = {};
                    }
                    try {
                      state_class_code[currState.toLowerCase()].cc_dsc[
                        class_code
                      ] =
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].value;
                    } catch (error) {}
                  } else {
                    delete state_class_code[currState.toLowerCase()].cc_dsc[
                      class_code
                    ];
                  }
                }
              }
            }
          } catch (error) {}
        }
        //cc_delete
      }
      childrenLoc[locationIndex][fieldName] = { value: value };
      if (fieldName === "suta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].suta.value !== value
              ) {
                childrenLoc[addressBlockNo].suta.value = value;
                childrenLoc[addressBlockNo].suta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "clientsuta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val2: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].clientsuta.value !== value
              ) {
                childrenLoc[addressBlockNo].clientsuta.value = value;
                childrenLoc[addressBlockNo].clientsuta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "state") {
        let stateList = [];

        try {
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              if (
                prevState &&
                prevState === childrenLoc[addressBlockNo].state.value
              ) {
                childrenLoc[addressBlockNo].suta.disabled = false;
                childrenLoc[addressBlockNo].clientsuta.disabled = false;
                prevState = "#####";
              }
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }

          if (prevState && prevState !== "#####") {
            delete state_class_code[prevState.toLowerCase()];
          }
        } catch (error) {}

        let stateSet = new Set(stateList);

        try {
          for (let stateIndx in state_class_code) {
            if (!stateSet.has(stateIndx.toLowerCase())) {
              delete state_class_code[stateIndx];
            }
          }

          if (!(value.toLowerCase() in state_class_code)) {
            state_class_code[value.toLowerCase()] = {
              ...state_class_code[value.toLowerCase()],
              val: childrenLoc[locationIndex].suta.value
                ? childrenLoc[locationIndex].suta.value
                : "",
              val2: childrenLoc[locationIndex].clientsuta.value
                ? childrenLoc[locationIndex].clientsuta.value
                : "",
            };
            childrenLoc[locationIndex].suta.disabled = false;
            childrenLoc[locationIndex].clientsuta.disabled = false;
          } else {
            if (state_class_code[value.toLowerCase()].val) {
              childrenLoc[locationIndex]["suta"] = {
                value: state_class_code[value.toLowerCase()].val,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["suta"] = {
                value: "",
                disabled: false,
              };
            }
            if (state_class_code[value.toLowerCase()].val2) {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: state_class_code[value.toLowerCase()].val2,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: "",
                disabled: false,
              };
            }
          }
        } catch (error) {}

        if (stateList.length > 0) {
          let statesData = {
            ncciStates: [],
            actingNcciStates: [],
            indBureauStates: [],
          };
          axios
            .post(
              awsUrl2 + "/api/getExpModStateCategory",
              JSON.stringify({ states: stateList })
            )
            .then((response) => {
              statesData = response.data;
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              let newEmodData = getEmodStatesData(
                statesData,
                emodStatesData || {}
              );
              this.setState({
                childrenLoc,
                emodStatesData: newEmodData,
                state_class_code,
              });
            });
        } else {
          emodStatesData = {};
          this.setState({ childrenLoc, emodStatesData, state_class_code });
        }
      } else {
        this.setState({ childrenLoc, state_class_code });
      }
    }
  };

  checkCompanyProfileError = () => {
    let { companyProfile } = this.state;

    let fieldList = ["companyName"];
    if (!this.state.PreUwCheck) {
      fieldList.push("firstName");
      fieldList.push("lastName");
      fieldList.push("phoneNumber");
    }
    fieldList.push("descriptionOfOperations");
    fieldList.push("entityType");
    fieldList.push("expectedStartDate");
    fieldList.push("fein");
    fieldList.push("yearsInBusiness");
    fieldList.push("street1");
    fieldList.push("zipCode1");
    fieldList.push("cityName1");
    fieldList.push("state1");
    fieldList.push("street2");
    fieldList.push("street2");
    fieldList.push("cityName2");
    fieldList.push("state2");
    fieldList.push("zipCode2");
    let error = false;
    let field;
    for (let fieldName of fieldList) {
      try {
        if (companyProfile[fieldName] === undefined) {
          companyProfile[fieldName] = {};
        }
        console.log(
          "companyProfilecompanyProfile",
          fieldName,
          companyProfile[fieldName]
        );
        if (
          !companyProfile[fieldName] ||
          !companyProfile[fieldName]?.value ||
          companyProfile[fieldName]?.value === "" ||
          companyProfile[fieldName]?.error
        ) {
          companyProfile[fieldName].error = true;
          if (!field) field = fieldName;
          error = true;
        }
      } catch (error) {
        console.log("values are not getting ");
      }

      // if (fieldName === "primaryContact") {
      //   var reg = /^[a-z A-Z\b]+$/;
      //   var isValid = reg.test(companyProfile[fieldName].value);
      //   if (!isValid) {
      //     companyProfile[fieldName].error = true;
      //     if (!field) field = fieldName;
      //     error = true;
      //   }
      // }
    }

    if (companyProfile?.phoneNumber?.value?.trim()) {
      let num = companyProfile?.phoneNumber?.value
        ?.trim()
        .match(/\d+/g)
        .join("").length;
      if (num !== 10) {
        if (!field) field = "phoneNumber";
        error = true;
        companyProfile.phoneNumber.error = true;
      }
    }

    this.setState({ companyProfile });
    return [error, field];
  };

  checkEmodInfoError = () => {
    let { emodStatesData } = this.state;
    let error = false;
    for (let state in emodStatesData) {
      if (emodStatesData[state].response) {
        if (!emodStatesData[state].rate1) {
          error = true;
          emodStatesData[state].rate1 = { value: "", error: true };
        } else if (
          !emodStatesData[state].rate1.value ||
          isNaN(emodStatesData[state].rate1.value)
        ) {
          error = true;
          emodStatesData[state].rate1.error = true;
        }
        if (emodStatesData[state].displayMore) {
          if (!emodStatesData[state].rate2) {
            error = true;
            emodStatesData[state].rate2 = { value: "", error: true };
          } else if (
            !emodStatesData[state].rate2.value ||
            isNaN(emodStatesData[state].rate2.value)
          ) {
            error = true;
            emodStatesData[state].rate2.error = true;
          }
        }
      }
    }

    return error;
  };

  validEmail = (e) => {
    let { companyProfile, otpVerified } = this.state;
    var email = e.target.value;
    var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(email);
    if (!isValid && email !== "") {
      companyProfile.emailId.error = true;
    } else {
      if (!otpVerified) {
        axios
          .post(awsUrl2 + "/api/sendOtpMail", { email })
          .then((res) => {
            this.setState({
              otpMessage:
                "A 4-digit verification code has been sent to your email.",
            });
            console.log("sent OTP successfully");
          })
          .catch((error) => {
            console.log("error while sending OTP");
          });
      }
    }
    this.setState({ companyProfile });
  };

  verifyOtp(email, otp) {
    axios
      .post(awsUrl2 + "/api/verifyOtp", { email, otp })
      .then((res) => {
        if (res.data === "otp_verified") {
          this.setState({
            otpMessage: "Email verified successfully.",
            otpVerified: true,
          });
          this.fetchAndFillDetails();
        } else if (res.data === "otp_expired") {
          this.setState({
            otpMessage: "OTP expired.",
          });
        } else if (res.data === "invalid_otp") {
          this.setState({
            otpMessage: "Invalid OTP.",
          });
        } else if (res.data === "otp_not_generated") {
          this.setState({
            otpMessage: "OTP not generated.",
          });
        }
      })
      .catch((error) => {
        console.log("error while sending OTP");
        this.setState({
          otpMessage: "Unable to verify OTP.",
        });
      });
  }

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  amtrustResponse = (uuid, apiType) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await axios.get(
          awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  formatPhoneNumber = (data) => {
    try {
      let phoneNumberString = data;
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      if (cleaned) {
        let len = cleaned.length;
        for (let i = 1; i <= 10 - len; i++) {
          cleaned = "0" + cleaned;
        }
        return cleaned;
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error while formatting phone number", error);
      return data;
    }
  };

  submitCompPro = async () => {
    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      requestedPricing,
      companyProfileAddQuestions,
      sameAsPrimary,
      brokerDetails,
    } = this.state;

    let domain = sessionStorage.getItem("domain");
    let salesID = sessionStorage.getItem("salesID");
    let landingEmail = sessionStorage.getItem("landingEmail");
    let salespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    );

    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;

    let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag")
      ? true
      : false;

    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) {}

    let amtrustAccountDetailsCopy = sessionStorage.getItem(
      "amtrustAccountDetailsCopy"
    );
    try {
      amtrustAccountDetailsCopy = JSON.parse(amtrustAccountDetailsCopy);
    } catch (error) {}

    let amtrustDetails;
    let addQuesData = [];
    let generateAmtrustQuote = true;

    if (companyProfileAddQuestions) {
      for (let question of companyProfileAddQuestions) {
        let dataQues = {
          response: question.response ? "Y" : "N",
          name: question.name,
          ques: question.ques,
        };

        if (question.optionLabels) {
          dataQues.optionLabels = question.optionLabels;
        }

        if (question.name === "qc3") {
          dataQues.response = question.response;
          generateAmtrustQuote =
            dataQues.response === "b" || dataQues.response === "c"
              ? true
              : false;
        }
        addQuesData.push(dataQues);
      }
    }

    let {
      salespersonName,
      salespersonCheck,
      salespersonNameAttr,
    } = await this.salesPersonLoginCheck();

    let dateNow = Math.floor(Date.now()).toString();
    let uuid_sp = uuidv4();
    let [companyProfileError, companyfield] = this.checkCompanyProfileError();

    if (companyProfile?.companyName?.value) {
      if ((flagPreUnderWriter || preUwEngagedFlag) && !companyProfileError) {
        try {
          let updateUnderWriterDetails = {};
          updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
          updateUnderWriterDetails.fein = companyProfile?.fein?.value;
          updateUnderWriterDetails.companyProfile = companyProfile;
          updateUnderWriterDetails.payrollData = childrenLoc;

          await axios
            .post(
              awsUrl2 +
                `/api/preUwData/${
                  preUwEngagedFlag ? "updateTrackingData" : "updateData"
                }`,
              updateUnderWriterDetails
            )
            .then((res) => {
              console.log("posted data", res);
            })
            .catch((error) => {
              console.log("error in posting data to user tracking", error);
            });
        } catch (error) {
          console.log("error while updating before Pre underwriter ", error);
        }
      }

      try {
        if (!domain || flagPreUnderWriter) throw new Error("!no domain found");

        let userTrackingData = {};
        userTrackingData.domain = domain;
        userTrackingData.companyProfile = companyProfile;
        userTrackingData.payrollData = childrenLoc;
        userTrackingData.emodStatesData = emodStatesData;
        userTrackingData.requestedPricing = requestedPricing;
        userTrackingData.companyProfileAddQuestions = addQuesData;

        if (salespersonMailCampaign) {
          userTrackingData.salespersonName = salespersonName;
          userTrackingData.salespersonNameAttr = salespersonNameAttr;
        }

        await axios
          .post(
            awsUrl2 + "/api/updateSolveTrackingDataGeneric/generic",
            JSON.stringify(userTrackingData)
          )
          .then(async (res) => {
            console.log("saved!");
            if (!salespersonCheck) {
              let updateEmailSpecific = {
                companyProfile,
                domain,
                email: landingEmail,
              };
              await axios.post(
                awsUrl2 +
                  "/api/updateSolveTrackingDataGeneric/updateEmailSpecific",
                JSON.stringify(updateEmailSpecific)
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error while updating user tracking flow ", error);
      }

      try {
        if (false && salespersonCheck && !salespersonMailCampaign) {
          // console.log("salespersonNameAttr: ", salespersonNameAttr);
          let salesPersonData = {};
          let type = "insert";

          if (salesID) {
            type = "update";
          } else {
            type = "insert";
            salesID = `insurecomp_${uuid_sp}_${dateNow}`;
            sessionStorage.setItem("salesID", salesID.toLowerCase());
          }
          let currProspectDetails = {
            companyProfile: companyProfile,
            childrenLoc: childrenLoc,
            emodStatesData: emodStatesData,
            requestedPricing: requestedPricing,
            brokerDetails: brokerDetails,
            companyProfileAddQuestions: addQuesData,
          };

          let compName = companyProfile.companyName.value;
          let fein = companyProfile.fein.value;

          let isSalesforce = sessionStorage.getItem("isSalesforce");

          salesPersonData.id = salesID.toLowerCase();
          salesPersonData.companyProfile = companyProfile;
          salesPersonData.payrollData = childrenLoc;
          salesPersonData.emodStatesData = emodStatesData;
          salesPersonData.companyProfileAddQuestions = addQuesData;
          if (!isSalesforce) {
            salesPersonData.created_timestamp = salesID
              .toLowerCase()
              .split("_")
              .pop();
          }
          salesPersonData.updated_timestamp = dateNow;
          salesPersonData.origin = "salesperson";
          salesPersonData.companyName = compName;
          salesPersonData.fein = fein;
          salesPersonData.salespersonName = salespersonName;
          salesPersonData.requestedPricing = requestedPricing;
          salesPersonData.salespersonNameAttr = salespersonNameAttr;
          salesPersonData.brokerDetails = brokerDetails;
          salesPersonData.currProspect = currProspectDetails;

          await axios
            .post(
              awsUrl2 + `/api/salesPersonData/${type}`,
              JSON.stringify(salesPersonData)
            )
            .then(() => {})
            .catch((error) => {
              console.log("error", error);
            });
        }
      } catch (error) {
        console.log("sales person flow error: ", error);
      }
    }

    // let mailCampaignUserCheck = checkMailCampaignUser();

    // let [locationInfoError, locationInfoField] = this.checkLocationInfoError();
    // let emodInfoError = this.checkEmodInfoError();
    let currProspect;

    try {
      if (companyProfileError) {
        document.getElementById(companyfield).focus();
      }
      // else if (locationInfoError) {
      //   document
      //     .getElementById(locationInfoField)
      //     .scrollIntoView({ behavior: "smooth" });
      // }
    } catch (error) {
      console.log(error);
    }

    if (!companyProfileError) {
      $("#loader").css("display", "block");

      currProspect = {
        childrenLoc: childrenLoc,
        companyProfile: companyProfile,
        emodStatesData: emodStatesData,
        requestedPricing,
        companyProfileAddQuestions: addQuesData,
        brokerDetails,
      };

      //Pre underwriter update

      if (flagPreUnderWriter && !preUwEngagedFlag) {
        try {
          let updateUnderWriterDetails = {};
          updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
          updateUnderWriterDetails.fein =
            currProspect?.companyProfile?.fein?.value;
          updateUnderWriterDetails.companyProfile = companyProfile;
          updateUnderWriterDetails.payrollData = childrenLoc;
          updateUnderWriterDetails.remove = true;
          updateUnderWriterDetails.clientEligibility = "";
          updateUnderWriterDetails.uwFormStage = "two";
          // updateUnderWriterDetails.formStage = "two";
          // updateUnderWriterDetails.pre_underwriter_status = "uw";

          await axios
            .post(
              awsUrl2 + "/api/preUwData/updateData",
              updateUnderWriterDetails
            )
            .then((res) => {
              console.log("posted data", res);
            })
            .catch((error) => {
              console.log("error in posting data to user tracking", error);
            });
        } catch (error) {
          console.log("error while updating before Pre underwriter ", error);
        }
        sessionStorage.removeItem("clientEligibility");
      } else if (!preUwEngagedFlag && generateAmtrustQuote) {
        //prepare the request body for the amtrust create quote API
        // try {
        //   let amtrustQuoteRequestBody = { Quote: {} };
        //   amtrustQuoteRequestBody.Quote = {
        //     EffectiveDate: moment(
        //       companyProfile.effectiveDate.value,
        //       "YYYY-MM-DD"
        //     ).format("MM/DD/YYYY"),
        //     Fein: companyProfile.fein.value.split("-").join(""),
        //     IsIncumbentAgent: false,
        //     NatureOfBusiness: "Hospitality",
        //     IsNonProfit: false,
        //     LegalEntity: 0,
        //     EntityType: companyProfile.entityType.value,
        //     BusinessName: companyProfile.companyName.value,
        //     YearsInBusiness: companyProfile.yearsInBusiness.value,
        //     PrimaryAddress: {
        //       Line1: companyProfile.street1.value,
        //       Line2: "",
        //       City: companyProfile.cityName1.value,
        //       State: companyProfile.state1.value.toUpperCase(),
        //       Zip: companyProfile.zipCode1.value,
        //     },
        //     MailingAddress: {
        //       Line1: companyProfile.street2.value,
        //       Line2: "",
        //       City: companyProfile.cityName2.value,
        //       State: companyProfile.state2.value.toUpperCase(),
        //       Zip: companyProfile.zipCode2.value,
        //     },
        //     ContactInformation: {
        //       FirstName: companyProfile.firstName.value,
        //       LastName: companyProfile.lastName.value,
        //       Phone: this.formatPhoneNumber(companyProfile.phoneNumber.value),
        //     },
        //     ClassCodes: [],
        //   };
        //   let amtrustClassCodesByState = {};
        //   for (let stateIndex in childrenLoc) {
        //     let state = childrenLoc[stateIndex].state.value;
        //     for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        //       let cc, desc, ccdescArray, ccdesc;
        //       let classCodeObj = {};
        //       ccdesc =
        //         childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
        //           .classCodeDescription.value;
        //       ccdescArray = ccdesc.split(":");
        //       cc = ccdescArray[0];
        //       desc = ccdescArray
        //         .slice(1)
        //         .join(":")
        //         .trim();
        //       classCodeObj = {
        //         ClassCode: String(cc).padStart(4, "0"),
        //         ClassCodeDescription: desc,
        //         State: state.toUpperCase(),
        //         Payroll: numeral(
        //           childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
        //             .value
        //         ).value(),
        //         FullTimeEmployees: numeral(
        //           childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
        //             .value
        //         ).value(),
        //         PartTimeEmployees: numeral(
        //           childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt
        //             .value
        //         ).value(),
        //       };
        //       if (!amtrustClassCodesByState[state]) {
        //         amtrustClassCodesByState[state] = {};
        //       }
        //       if (!amtrustClassCodesByState[state][cc]) {
        //         amtrustClassCodesByState[state][cc] = classCodeObj;
        //       } else {
        //         amtrustClassCodesByState[state][cc].Payroll += numeral(
        //           classCodeObj.Payroll
        //         ).value();
        //         amtrustClassCodesByState[state][
        //           cc
        //         ].FullTimeEmployees += numeral(
        //           classCodeObj.FullTimeEmployees
        //         ).value();
        //         amtrustClassCodesByState[state][
        //           cc
        //         ].PartTimeEmployees += numeral(
        //           classCodeObj.PartTimeEmployees
        //         ).value();
        //       }
        //     }
        //   }
        //   for (let state in amtrustClassCodesByState) {
        //     for (let classCode in amtrustClassCodesByState[state]) {
        //       amtrustQuoteRequestBody.Quote.ClassCodes.push(
        //         amtrustClassCodesByState[state][classCode]
        //       );
        //     }
        //   }
        //   let amtrustQuoteId =
        //     amtrustAccountDetails?.QuoteId ||
        //     amtrustAccountDetailsCopy?.QuoteId;
        //   if (amtrustQuoteId) {
        //     let amtrustTriggerResponse = await axios.post(
        //       awsUrl2 + "/api/triggerAmtrustQuoteUpdate/" + amtrustQuoteId,
        //       amtrustQuoteRequestBody.Quote
        //     );
        //     let uuid;
        //     if (amtrustTriggerResponse.data.uuid) {
        //       uuid = amtrustTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust update quote trigger";
        //     }
        //     let amtrustQuoteResponse;
        //     do {
        //       amtrustQuoteResponse = await this.amtrustResponse(
        //         uuid,
        //         "updateQuote"
        //       );
        //       // amtrustQuoteResponse = "error";
        //       if (amtrustQuoteResponse === "error") {
        //         throw "error in amtrust quote updation";
        //       }
        //     } while (amtrustQuoteResponse === "processing");
        //     if (amtrustQuoteResponse.StatusCode === "200") {
        //       if (!amtrustAccountDetails?.QuoteId) {
        //         amtrustDetails = amtrustAccountDetailsCopy;
        //         sessionStorage.setItem(
        //           "amtrustAccountDetails",
        //           JSON.stringify(amtrustAccountDetailsCopy)
        //         );
        //       }
        //     } else {
        //       console.log(
        //         "unable to create amtrust quote",
        //         amtrustQuoteResponse
        //       );
        //       sessionStorage.removeItem("amtrustAccountDetails");
        //     }
        //   } else {
        //     let amtrustTriggerResponse = await axios.post(
        //       awsUrl2 + "/api/triggerAmtrustQuoteGeneration",
        //       amtrustQuoteRequestBody
        //     );
        //     let uuid;
        //     if (amtrustTriggerResponse.data.uuid) {
        //       uuid = amtrustTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust create quote trigger";
        //     }
        //     let amtrustQuoteResponse;
        //     do {
        //       amtrustQuoteResponse = await this.amtrustResponse(
        //         uuid,
        //         "createQuote"
        //       );
        //       // amtrustQuoteResponse = "error";
        //       if (amtrustQuoteResponse === "error") {
        //         throw "error in amtrust quote generation";
        //       }
        //     } while (amtrustQuoteResponse === "processing");
        //     if (amtrustQuoteResponse.Message === "Quote created") {
        //       amtrustDetails = amtrustQuoteResponse.Data.AccountInformation;
        //       sessionStorage.setItem(
        //         "amtrustAccountDetails",
        //         JSON.stringify(amtrustQuoteResponse.Data.AccountInformation)
        //       );
        //       sessionStorage.setItem(
        //         "amtrustAccountDetailsCopy",
        //         JSON.stringify(amtrustQuoteResponse.Data.AccountInformation)
        //       );
        //     } else {
        //       console.log(
        //         "unable to create amtrust quote",
        //         amtrustQuoteResponse
        //       );
        //       sessionStorage.removeItem("amtrustAccountDetails");
        //     }
        //   }
        // } catch (error) {
        //   console.log("error while generating amtrust quote", error);
        //   sessionStorage.removeItem("amtrustAccountDetails");
        // }
      }

      try {
        if (preUwEngagedFlag) {
          try {
            let updateUnderWriterDetails = {};
            updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
            updateUnderWriterDetails.fein =
              currProspect?.companyProfile?.fein?.value;
            updateUnderWriterDetails.companyProfile = companyProfile;
            updateUnderWriterDetails.payrollData = childrenLoc;
            updateUnderWriterDetails.remove = true;
            updateUnderWriterDetails.clientEligibility = "";
            updateUnderWriterDetails.uwFormStage = "two";
            // updateUnderWriterDetails.formStage = "two";
            // updateUnderWriterDetails.pre_underwriter_status = "uw";

            await axios
              .post(
                awsUrl2 + "/api/preUwData/updateTrackingData",
                updateUnderWriterDetails
              )
              .then((res) => {
                console.log("posted data", res);
              })
              .catch((error) => {
                console.log("error in posting data to user tracking", error);
              });
          } catch (error) {
            console.log("error while updating before Pre underwriter ", error);
          }
          sessionStorage.removeItem("clientEligibility");
        }
      } catch (error) {
        console.log("error: ", error);
      }

      try {
        if (!domain || flagPreUnderWriter) throw new Error("!no domain found");

        let userTrackingData = {};
        userTrackingData.domain = domain;
        userTrackingData.companyProfile = companyProfile;
        userTrackingData.payrollData = childrenLoc;
        userTrackingData.emodStatesData = emodStatesData;
        userTrackingData.requestedPricing = requestedPricing;
        userTrackingData.companyProfileAddQuestions = addQuesData;

        if (salespersonMailCampaign) {
          userTrackingData.salespersonName = salespersonName;
          userTrackingData.salespersonNameAttr = salespersonNameAttr;
        }

        if (amtrustDetails) {
          userTrackingData.amtrustAccountDetails = amtrustDetails;
          userTrackingData.amtrustAccountDetailsCopy = amtrustDetails;
        } else {
          userTrackingData.amtrustAccountDetails = {};
        }

        await axios
          .post(
            awsUrl2 + "/api/updateSolveTrackingDataGeneric/generic",
            JSON.stringify(userTrackingData)
          )
          .then(async (res) => {
            if (!salespersonCheck) {
              let updateEmailSpecific = {
                companyProfile,
                domain,
                email: landingEmail,
              };
              await axios
                .post(
                  awsUrl2 +
                    "/api/updateSolveTrackingDataGeneric/updateEmailSpecific",
                  JSON.stringify(updateEmailSpecific)
                )
                .then((res) => {
                  console.log("updated emailSpecific");
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
            let requestBody = {
              domain,
              formStage: "two",
            };

            if (!salespersonCheck) requestBody["email"] = landingEmail;

            await axios
              .post(
                awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateFormStage",
                JSON.stringify(requestBody)
              )
              .then((res) => {
                console.log("updated formstage two");
              })
              .catch((error) => {
                console.log("error", error);
              });
            console.log("updated tracking data");
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error while updating user tracking flow ", error);
      }

      try {
        if (salespersonCheck && !salespersonMailCampaign) {
          // console.log("salespersonNameAttr: ", salespersonNameAttr);
          let salesPersonData = {};
          let type = "insert";

          if (salesID) {
            type = "update";
          } else {
            type = "insert";
            salesID = `insurecomp_${uuid_sp}_${dateNow}`;
            sessionStorage.setItem("salesID", salesID.toLowerCase());
          }

          let compName = companyProfile.companyName.value;
          let fein = companyProfile.fein.value;

          let isSalesforce = sessionStorage.getItem("isSalesforce");

          salesPersonData.id = salesID.toLowerCase();
          salesPersonData.companyProfile = companyProfile;
          salesPersonData.payrollData = childrenLoc;
          salesPersonData.emodStatesData = emodStatesData;
          salesPersonData.companyProfileAddQuestions = addQuesData;
          if (!isSalesforce) {
            salesPersonData.created_timestamp = salesID
              .toLowerCase()
              .split("_")
              .pop();
          }
          salesPersonData.updated_timestamp = dateNow;
          salesPersonData.origin = "salesperson";
          salesPersonData.formStage = "two";
          salesPersonData.companyName = compName;
          salesPersonData.fein = fein;
          salesPersonData.salespersonName = salespersonName;
          salesPersonData.requestedPricing = requestedPricing;
          salesPersonData.salespersonNameAttr = salespersonNameAttr;
          salesPersonData.brokerDetails = brokerDetails;
          salesPersonData.uwQues = "";

          if (amtrustDetails) {
            salesPersonData.amtrustAccountDetails = amtrustDetails;
            salesPersonData.amtrustAccountDetailsCopy = amtrustDetails;
          } else {
            salesPersonData.amtrustAccountDetails = {};
          }

          console.log("before calling API");

          await axios
            .post(
              awsUrl2 + `/api/salesPersonData/${type}`,
              JSON.stringify(salesPersonData)
            )
            .then(() => {
              console.log("this is done");
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      } catch (error) {
        console.log("sales person flow error: ", error);
      }

      console.log("reached here");

      sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
      sessionStorage.setItem("formStage", "two");
      window.location.reload();
    }
  };

  salesPersonLoginCheck = () => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let sessionData = await Auth.currentAuthenticatedUser();
        let groups;
        if (sessionData) {
          // console.log("sessionData: ", sessionData);
          groups =
            sessionData.signInUserSession.accessToken.payload["cognito:groups"];
          if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
            resolve({
              salespersonName: sessionData.attributes.email,
              salespersonNameAttr: sessionData.attributes.name,
              salespersonCheck: true,
            });
          } else {
            resolve({
              salespersonName: "",
              salespersonCheck: false,
            });
          }
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } catch (error) {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    });

    return promise;
  };

  // salesManagerLoginCheck = async () => {
  //   let promise = new Promise(async (resolve, reject) => {
  //     try {
  //       let sessionData = await Auth.currentAuthenticatedUser();
  //       let groups;
  //       if (sessionData) {
  //         // console.log("sessionData: ", sessionData);
  //         groups =
  //           sessionData.signInUserSession.accessToken.payload["cognito:groups"];
  //         if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
  //           resolve({
  //             salesManagerPersonName: sessionData.attributes.email,
  //             salesManagerPersonNameAttr: sessionData.attributes.name,
  //             salesManagerCheck: true,
  //           });
  //         } else {
  //           resolve({
  //             salesManagerPersonName: "",
  //             salesManagerCheck: false,
  //           });
  //         }
  //       } else {
  //         resolve({
  //           salesManagerPersonName: "",
  //           salesManagerCheck: false,
  //         });
  //       }
  //     } catch (error) {
  //       resolve({
  //         salesManagerPersonName: "",
  //         salesManagerCheck: false,
  //       });
  //     }
  //   });
  //   return promise;
  // };

  getDomainFromEmail = (user) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let response;
        let generalDomainNames = [
          "gmail",
          "yahoo",
          "aol",
          "mac",
          "cox",
          "frontier",
          "msn",
          "gammana",
          "hotmail",
          "pacbell",
          "att",
          "sbcglobal",
          "windstream",
          "verizon",
          "comcast",
          "ampcocontracting",
        ];
        let tempBusinessName = user.split("@")[1].split(".");
        tempBusinessName.pop();
        let business_name = tempBusinessName.join(".");
        if (generalDomainNames.includes(business_name)) {
          response = user.split("@")[0];
        } else {
          response = business_name;
        }

        let domainResp = await axios.get(awsUrl2 + "/api/getDomain/" + user);
        if (domainResp.data && domainResp.data.domain) {
          response = domainResp.data.domain;
        }

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };

  fetchAndFillDetails = async () => {
    try {
      $("#loader").css("display", "block");
      let user = this.state.companyProfile.emailId.value;

      await axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: {
                    value:
                      lineObj.healthQues === true ||
                      lineObj.healthQues === false
                        ? lineObj.healthQues
                        : true,
                  },
                };

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
              }
            }
            $("#loader").css("display", "none");
            window.location.reload();
          } else {
            let domain;
            try {
              domain = await this.getDomainFromEmail(user);
            } catch (error) {
              console.log("error in getting domain", error);
            }

            sessionStorage.setItem("domain", domain);
            axios
              .get(awsUrl2 + "/api/getDomainInputData/" + domain)
              .then((response) => {
                if (response.status === 200 && response.data.email_specific) {
                  let {
                    companyProfile,
                    childrenLoc,
                    emodStatesData,
                    indicationCost,
                    email_specific,
                  } = response.data;

                  if (email_specific) {
                    if (user in email_specific) {
                      companyProfile = email_specific[user].companyProfile;
                    } else {
                      companyProfile.emailId.value = user;
                    }
                  }

                  companyProfile.effectiveDate.value = moment(
                    companyProfile.effectiveDate.value
                  );

                  console.log("companyProfile: ", companyProfile);
                  console.log("emodStatesData: ", emodStatesData);

                  for (let emodKey in emodStatesData) {
                    if (emodStatesData[emodKey].date1.value)
                      emodStatesData[emodKey].date1.value = moment(
                        emodStatesData[emodKey].date1.value
                      );
                    if (emodStatesData[emodKey].date2.value)
                      emodStatesData[emodKey].date2.value = moment(
                        emodStatesData[emodKey].date2.value
                      );
                  }

                  if (!("healthQues" in companyProfile)) {
                    companyProfile.healthQues = { value: true };
                  }

                  let currProspect = {
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  };

                  sessionStorage.setItem(
                    "currProspect",
                    JSON.stringify(currProspect)
                  );
                  this.setState({
                    childrenLoc,
                    companyProfile,
                    emodStatesData,
                  });
                }
                $("#loader").css("display", "none");
              });
          }
          sessionStorage.setItem("otpVerified", "true");
        })
        .catch((error) => {
          console.log("error in data retrieval");
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in GET button click");
    }
  };

  updateHealthDropDown(event) {
    let { companyProfile } = this.state;
    companyProfile.healthQues.value =
      event.target.value === "true" ? true : false;
    this.setState({ companyProfile });
  }

  handleSameAsPrimaryCheck = () => {
    let { sameAsPrimary, companyProfile } = this.state;
    if (sameAsPrimary) {
      companyProfile.cityName2 = "";
      companyProfile.state2 = "";
      companyProfile.zipCode2 = "";
      companyProfile.street2 = "";
      this.setState({ sameAsPrimary: false, companyProfile });
    } else {
      companyProfile.cityName2 = companyProfile.cityName1;
      companyProfile.state2 = companyProfile.state1;
      companyProfile.zipCode2 = companyProfile.zipCode1;
      companyProfile.street2 = companyProfile.street1;
      this.setState({ sameAsPrimary: true, companyProfile });
    }
  };

  handleLocSameAsPrimaryCheck = () => {
    let { locSameAsPrimary, childrenLoc, companyProfile } = this.state;
    if (locSameAsPrimary) {
      childrenLoc[Object.keys(childrenLoc)[0]].cityName = "";
      childrenLoc[Object.keys(childrenLoc)[0]].state = "";
      childrenLoc[Object.keys(childrenLoc)[0]].zipCode = "";
      childrenLoc[Object.keys(childrenLoc)[0]].street = "";

      this.setState({ locSameAsPrimary: false, childrenLoc });
    } else {
      childrenLoc[Object.keys(childrenLoc)[0]].cityName =
        companyProfile.cityName1;
      childrenLoc[Object.keys(childrenLoc)[0]].state = companyProfile.state1;
      childrenLoc[Object.keys(childrenLoc)[0]].zipCode =
        companyProfile.zipCode1;
      childrenLoc[Object.keys(childrenLoc)[0]].street = companyProfile.street1;

      this.setState({ locSameAsPrimary: true, childrenLoc });
    }
  };

  condition_render_font = () => {
    let style = {};
    if (window.screen.width < 1350 && window.screen.width > 992) {
      style = {
        fontSize: "13px",
      };
      return style;
    }
  };

  render() {
    const {
      companyProfile,
      emodStatesData,
      childrenLoc,
      otpVerified,
      otpMessage,
      otpValue,
      salesLogin,
      showCompanyProfile,
      showAdditionalQuestions,
      showLocationAndPayroll,
      sameAsPrimary,
      locSameAsPrimary,
      requestedPricing,
      salespersonCheck,
      brdCheck,
      quoteSubmitted,
      salesManagerCheck,
      salesManagerPersonName,
      PreUwCheck,
      adminCheck,
      stateSpecificCheck,
      specificStates,
      primaryStateCheck,
      zipError,
    } = this.state;
    let index = 0;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
    let showAllFields = sessionStorage.getItem("showAllFields") ? true : false;

    let disableGetQuote = sessionStorage.getItem("disableGetQuote");
    let disableContinue = sessionStorage.getItem("disableContinue");

    //flagPreUnderWriter
    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;

    let isUnderWriterFlow = sessionStorage.getItem("preUnderwriter") === "true";

    let mailCampaignUserCheck = checkMailCampaignUser();
    const titleText = "Please verify/update below details";
    var isLoggedIn =
      sessionStorage.getItem("isLoggedIn") === undefined ||
      sessionStorage.getItem("isLoggedIn") === null
        ? false
        : sessionStorage.getItem("isLoggedIn");
    let locationCount = 1;

    let viewOnly = sessionStorage.getItem("viewOnly")
      ? JSON.parse(sessionStorage.getItem("viewOnly"))
      : false;

    let preUW = sessionStorage.getItem("preUnderwriter");

    let allStates1 = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (primaryStateCheck) {
        if (specificStates.includes(item.Code)) {
          if (
            companyProfile &&
            item ===
              (companyProfile.state1 && companyProfile.state1.value
                ? companyProfile.state1.value
                : "")
          ) {
            allStates1.push(
              <option key={item.Code} value={item.Code.toLowerCase()} selected>
                {item.Code}
              </option>
            );
          } else {
            allStates1.push(
              <option key={item.Code} value={item.Code.toLowerCase()}>
                {item.Code}
              </option>
            );
          }
        }
      } else {
        {
          if (
            companyProfile &&
            item ===
              (companyProfile.state1 && companyProfile.state1.value
                ? companyProfile.state1.value
                : "")
          ) {
            allStates1.push(
              <option key={item.Code} value={item.Code.toLowerCase()} selected>
                {item.Code}
              </option>
            );
          } else {
            allStates1.push(
              <option key={item.Code} value={item.Code.toLowerCase()}>
                {item.Code}
              </option>
            );
          }
        }
      }
    }
    let allStates2 = [
      <option key={0} value="">
        State
      </option>,
    ];
    for (var item of stateCity) {
      if (
        companyProfile &&
        item ===
          (companyProfile.state2 && companyProfile.state2.value
            ? companyProfile.state2.value
            : "")
      ) {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()} selected>
            {item.Code}
          </option>
        );
      } else {
        allStates2.push(
          <option key={item.Code} value={item.Code.toLowerCase()}>
            {item.Code}
          </option>
        );
      }
    }

    let locAndPrimaryAreSame = false;
    let firstChildrenOb = childrenLoc[Object.keys(childrenLoc)[0]];
    if (
      firstChildrenOb?.state?.value === companyProfile?.state1?.value &&
      firstChildrenOb?.cityName?.value === companyProfile?.cityName1?.value &&
      firstChildrenOb?.zipCode?.value === companyProfile?.zipCode1?.value &&
      firstChildrenOb?.street?.value === companyProfile?.street1?.value &&
      firstChildrenOb?.state?.value &&
      firstChildrenOb?.cityName?.value &&
      firstChildrenOb?.zipCode?.value &&
      firstChildrenOb?.street?.value
    ) {
      locAndPrimaryAreSame = true;
    }

    if (locAndPrimaryAreSame && !locSameAsPrimary) {
      this.setState({ locSameAsPrimary: true });
    } else if (!locAndPrimaryAreSame && locSameAsPrimary) {
      this.setState({ locSameAsPrimary: false });
    }

    try {
      locationCount = Object.keys(childrenLoc).length;
    } catch (e) {}

    return (
      <div id="compPro" className="container-fluid active per98">
        {/* <NewProgress /> */}
        <div id="compProfile" style={this.condition_render_font()}>
          <form autoComplete="none">
            {/* <div className="formTitle">
              <b className="font-family-montserrat-bold">{titleText}</b>
            </div> */}

            {!isLoggedIn && (
              <div className="external-box">
                <div className="no-login-user-heading-check">
                  <div className="check-company-info">
                    Please fill/verify the fields below
                  </div>
                </div>
              </div>
            )}

            <div className="pink-header">
              <div className="font-family-montserrat-bold">
                Company Information
              </div>
              <div className="companyProfileheadertwo"></div>

              {/* {showCompanyProfile ? (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (showCompanyProfile)
                      this.setState({ showCompanyProfile: false });
                    else return;
                  }}
                >
                  &#8722;
                </span>
              ) : (
                <span
                  className="pink-sign noselect"
                  onClick={() => {
                    if (!showCompanyProfile)
                      this.setState({ showCompanyProfile: true });
                    else return;
                  }}
                >
                  &#43;
                </span>
              )} */}
            </div>

            {showCompanyProfile && (
              <div className="company-profile-container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 grey">
                    <Form.Group>
                      <label htmlFor="companyName" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Company Name (Applicant Name)
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.companyName &&
                          companyProfile.companyName.value
                            ? companyProfile.companyName.value
                            : ""
                        }
                        id="companyName"
                        type="text"
                        style={
                          companyProfile.companyName &&
                          companyProfile.companyName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "companyName")
                        }
                        placeholder="Company Name"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="descriptionOfOperations"
                        title="Description of Operations"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Description of Operations
                        </b>
                      </label>

                      <input
                        value={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.value
                            ? companyProfile.descriptionOfOperations.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(
                            event,
                            "descriptionOfOperations"
                          )
                        }
                        style={
                          companyProfile.descriptionOfOperations &&
                          companyProfile.descriptionOfOperations.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="Description of Operations"
                        id="descriptionOfOperations"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                      <span></span>
                      {PreUwCheck && (
                        <span className="font-family-montserrat-semi-bold">
                          <a
                            style={{ color: "blue" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              companyProfile?.website?.value &&
                              (companyProfile.website.value.includes("://")
                                ? companyProfile.website.value
                                : "//" + companyProfile.website.value)
                            }
                          >
                            {companyProfile?.website?.value || ""}
                          </a>
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label htmlFor="dba" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">DBA</b>
                      </label>
                      <input
                        value={
                          companyProfile.dba && companyProfile.dba.value
                            ? companyProfile.dba.value
                            : ""
                        }
                        id="dba"
                        type="text"
                        // style={
                        //   companyProfile.dba &&
                        //   companyProfile.companyName.error
                        //     ? { borderColor: "red" }
                        //     : {}
                        // }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "dba")
                        }
                        placeholder="DBA"
                        className="rectangle1"
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div> */}
                  <div className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label htmlFor="fein" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">FEIN</b>
                      </label>
                      <input
                        placeholder="FEIN"
                        value={
                          companyProfile.fein ? companyProfile.fein.value : ""
                        }
                        onChange={(event) => {
                          this.updateCompanyProfile(event, "fein");
                        }}
                        style={
                          companyProfile.fein && companyProfile.fein.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="fein"
                        type="text"
                        autoComplete="off"
                        onInput={(event) => {
                          event.target.value = event.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                        }}
                        onBlur={(event) => {
                          var value = event.target.value.toString().trim();
                          value = value.split("-").join("");
                          var len = value.length,
                            dummystr = "000000000";

                          if (len > 0 && len < 9) {
                            value = dummystr.slice(0, 9 - len) + "" + value;
                            companyProfile.fein.value =
                              value[0] + value[1] + "-" + value.slice(2);
                            companyProfile.fein.errMsg = "";
                          } else if (len > 9) {
                            companyProfile.fein.errMsg =
                              "FEIN needs to be 9 digits";
                            companyProfile.fein.error = true;
                          } else if (len === 0) {
                            companyProfile.fein.value = undefined;
                            companyProfile.fein.errMsg = "";
                          } else {
                            companyProfile.fein.value =
                              value[0] + value[1] + "-" + value.slice(2);
                            companyProfile.fein.errMsg = "";
                          }
                          this.setState({ companyProfile });
                        }}
                      />
                      <p className="errMsg">
                        {companyProfile.fein &&
                        companyProfile.fein.errMsg &&
                        companyProfile.fein.errMsg !== ""
                          ? companyProfile.fein.errMsg
                          : ""}
                      </p>
                    </Form.Group>
                  </div>
                  <div id="entity-type" className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label htmlFor="entityType" className="mandatory">
                        <b className="font-family-montserrat-semi-bold ">
                          Entity Type
                        </b>
                      </label>
                      <select
                        value={
                          companyProfile.entityType &&
                          companyProfile.entityType.value
                            ? companyProfile.entityType.value
                            : ""
                        }
                        id="entityType"
                        type="text"
                        style={
                          companyProfile.entityType &&
                          companyProfile.entityType.error
                            ? { borderColor: "red" }
                            : { fontSize: "" }
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "entityType")
                        }
                        autoComplete="off"
                      >
                        <option key={1} value="">
                          Please Select
                        </option>
                        <option key={2} value={"Corporation"}>
                          {" "}
                          Corporation{" "}
                        </option>
                        <option key={3} value={"Joint Venture"}>
                          {" "}
                          Joint Venture{" "}
                        </option>
                        <option key={4} value={"LLC"}>
                          {" "}
                          LLC{" "}
                        </option>
                        <option key={5} value={"SubCharter 'S' corp"}>
                          {" "}
                          SubCharter 'S' corp{" "}
                        </option>
                        <option key={6} value={"Not for Profit 501[c][3]"}>
                          {" "}
                          Not for Profit 501[c][3]{" "}
                        </option>
                        <option key={7} value={"Other"}>
                          {" "}
                          Other{" "}
                        </option>
                        <option key={8} value={"Partnership"}>
                          {" "}
                          Partnership{" "}
                        </option>
                        <option key={9} value={"Sole Proprietor"}>
                          {" "}
                          Sole Proprietor{" "}
                        </option>
                        <option key={10} value={"Trust"}>
                          {" "}
                          Trust{" "}
                        </option>
                      </select>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="years-in-business" className="col-lg-2 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="yearsInBusiness"
                        title="Years in Business"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold text-nowrap ">
                          Years in Business
                        </b>
                      </label>
                      <span id="yearsInBusiness-span">
                        <NumberFormat
                          value={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.value
                              ? companyProfile.yearsInBusiness.value
                              : ""
                          }
                          onChange={(event) =>
                            this.updateCompanyProfile(event, "yearsInBusiness")
                          }
                          style={
                            companyProfile.yearsInBusiness &&
                            companyProfile.yearsInBusiness.error
                              ? { borderColor: "red", textAlign: "center" }
                              : { textAlign: "center" }
                          }
                          autoComplete="off"
                          id="yearsInBusiness"
                          format="####"
                          type="text"
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div id="expected-start-date" className="col-lg-4 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="expectedStartDate"
                        title="Expected Start Date"
                        className="mandatory"
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Anticipated Start Date for PEO Services
                        </b>
                      </label>
                      <span id="expectedStartDate">
                        <DatePicker
                          selected={
                            companyProfile.expectedStartDate
                              ? companyProfile.expectedStartDate.value
                              : moment()
                          }
                          onChange={this.handleDateChangeEff}
                          showYearDropdown
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </span>
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="row-col-2 mb-0 pl-3"
                    id="company-profile-row3"
                  >
                    <Form.Group style={{ marginBottom: "5.95px" }}>
                      <b className="font-family-montserrat-bold">
                        Primary Contact
                      </b>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="firstName"
                        title="First Name"
                        className={` ${PreUwCheck ? "" : "mandatory"}`}
                      >
                        <b className="font-family-montserrat-semi-bold">
                          First Name
                        </b>
                      </label>
                      <input
                        placeholder="First Name"
                        className={`${PreUwCheck ? "disableFirstName" : ""}`}
                        disabled={PreUwCheck}
                        value={
                          companyProfile.firstName &&
                          companyProfile.firstName.value
                            ? companyProfile.firstName.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "firstName")
                        }
                        style={
                          companyProfile.firstName &&
                          companyProfile.firstName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="firstName"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <Form.Group>
                      <label
                        htmlFor="lastName"
                        title="Last Name"
                        className={` ${PreUwCheck ? "" : "mandatory"}`}
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Last Name
                        </b>
                      </label>
                      <input
                        placeholder="Last Name"
                        disabled={PreUwCheck}
                        className={`${PreUwCheck ? "disableLastName" : ""}`}
                        value={
                          companyProfile.lastName &&
                          companyProfile.lastName.value
                            ? companyProfile.lastName.value
                            : ""
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "lastName")
                        }
                        style={
                          companyProfile.lastName &&
                          companyProfile.lastName.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        id="lastName"
                        type="text"
                        // onKeyUp={onlyAlpha}
                        autoComplete="off"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    id="phone-number"
                    className="col-lg-2 col-md-12"
                    // style={{ width: "10%", marginLeft: "11px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor="phoneNumber"
                        className={` ${PreUwCheck ? "" : "mandatory"}`}
                      >
                        <b className="font-family-montserrat-semi-bold">
                          Phone Number
                        </b>
                      </label>
                      <NumberFormat
                        value={
                          companyProfile.phoneNumber &&
                          companyProfile.phoneNumber.value
                            ? companyProfile.phoneNumber.value
                            : ""
                        }
                        className={`${PreUwCheck ? "disablePhoneNumber" : ""}`}
                        disabled={PreUwCheck}
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "phoneNumber")
                        }
                        style={
                          companyProfile.phoneNumber &&
                          companyProfile.phoneNumber.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="(000) 000-0000"
                        autoComplete="off"
                        id="phoneNumber"
                        format="(###) ###-####"
                        type="text"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                </div>

                <div className="row-col-2 mb-0" id="company-profile-row3">
                  <Form.Group style={{ marginBottom: "5.95px" }}>
                    <b className="font-family-montserrat-bold">
                      Mailing Address
                    </b>
                  </Form.Group>
                </div>
                <div className="row mailingaddress">
                  <div
                    id="street-1"
                    style={{ width: "51%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label htmlFor="street1" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Street Address
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.street1 && companyProfile.street1.value
                            ? companyProfile.street1.value
                            : ""
                        }
                        id="street1"
                        type="text"
                        style={
                          companyProfile.street1 && companyProfile.street1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "street1")
                        }
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    id="zip-1"
                    style={{ width: "11%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"zipCode1"}
                        className="mandatory"
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode1"
                        id={"zipCode1"}
                        format="#####"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "zipCode1")
                        }
                        value={
                          companyProfile.zipCode1
                            ? companyProfile.zipCode1.value
                            : ""
                        }
                        onBlur={(event) =>
                          this.setStateAndCity1(event.target.value)
                        }
                        style={
                          companyProfile.zipCode1 &&
                          companyProfile.zipCode1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      {zipError ? (
                        <p style={{ color: "red" }}>
                          Please enter only{" "}
                          {specificStates.join(",") || "valid"} zip code
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    id="city-1"
                    style={{ width: "27%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"cityName1"}
                        className="mandatory"
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"cityName1"}
                        name="cityName1"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "cityName1")
                        }
                        value={
                          companyProfile.cityName1
                            ? companyProfile.cityName1.value
                            : ""
                        }
                        style={
                          companyProfile.cityName1 &&
                          companyProfile.cityName1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="state-1"
                  >
                    <Form.Group>
                      <label className="mandatory" title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"state1"}
                        name="state1"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "state1")
                        }
                        value={
                          companyProfile.state1 && companyProfile.state1.value
                            ? companyProfile.state1.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state1 && companyProfile.state1.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates1}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                <div className="row-col-2 companyheadquarters">
                  <Form.Group style={{ marginBottom: "5.95px" }}>
                    <div className="row">
                      <b
                        className="font-family-montserrat-bold"
                        style={{ paddingInline: "15px" }}
                      >
                        Company Headquarters
                      </b>
                      <div className="col-9 d-flex align-items-center">
                        <input
                          className="mr-1"
                          type="checkbox"
                          id="sameAsPrimary-1"
                          name={"sameAsPrimary"}
                          checked={sameAsPrimary}
                          onClick={this.handleSameAsPrimaryCheck}
                        />
                        <label htmlFor="sameAsPrimary-1" className="text-wrap">
                          <small>Same as Mailing Address</small>
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="row">
                  <div
                    id="street-2"
                    style={{ width: "51%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label htmlFor="street2" className="mandatory">
                        <b className="font-family-montserrat-semi-bold">
                          Street Address
                        </b>
                      </label>
                      <input
                        value={
                          companyProfile.street2 && companyProfile.street2.value
                            ? companyProfile.street2.value
                            : ""
                        }
                        id="street2"
                        type="text"
                        style={
                          companyProfile.street2 && companyProfile.street2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "street2")
                        }
                        placeholder="Street Address"
                        autoComplete="off"
                        className="rectangle1"
                      />
                      <p className="errMsg" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="zip-2"
                  >
                    <Form.Group>
                      <label
                        htmlFor={"zipCode2"}
                        className="mandatory"
                        title="Zip Code"
                      >
                        <b className="font-family-montserrat-semi-bold">Zip</b>
                      </label>
                      <NumberFormat
                        name="zipCode2"
                        id={"zipCode2"}
                        format="#####"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "zipCode2")
                        }
                        value={
                          companyProfile.zipCode2
                            ? companyProfile.zipCode2.value
                            : ""
                        }
                        style={
                          companyProfile.zipCode2 &&
                          companyProfile.zipCode2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        onBlur={(event) =>
                          this.setStateAndCity2(event.target.value)
                        }
                        placeholder="Zip"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    id="city-2"
                    style={{ width: "27%", paddingInline: "15px" }}
                  >
                    <Form.Group>
                      <label
                        htmlFor={"cityName2"}
                        className="mandatory"
                        title="City"
                      >
                        <b className="font-family-montserrat-semi-bold">City</b>
                      </label>
                      <input
                        id={"cityName2"}
                        name="cityName2"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "cityName2")
                        }
                        value={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.value
                            ? companyProfile.cityName2.value
                            : ""
                        }
                        style={
                          companyProfile.cityName2 &&
                          companyProfile.cityName2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        placeholder="City Name"
                        autoComplete="off"
                      />
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "11%", paddingInline: "15px" }}
                    id="state-2"
                  >
                    <Form.Group>
                      <label className="mandatory" title="State">
                        <b className="font-family-montserrat-semi-bold">
                          State
                        </b>
                      </label>
                      <select
                        id={"state2"}
                        name="state2"
                        type="text"
                        onChange={(event) =>
                          this.updateCompanyProfile(event, "state2")
                        }
                        value={
                          companyProfile.state2 && companyProfile.state2.value
                            ? companyProfile.state2.value.toLowerCase()
                            : ""
                        }
                        style={
                          companyProfile.state2 && companyProfile.state2.error
                            ? { borderColor: "red" }
                            : {}
                        }
                        autoComplete="off"
                      >
                        {allStates2}
                      </select>
                      <p className="errMsg addressErrs" />
                    </Form.Group>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            )}
            {/* {isLoggedIn && (
              <div className="row notes-row">
                <Form.Group>
                  <div className="notes">
                    <label>
                      <b className="font-family-montserrat-semi-bold">
                        {" "}
                        Notes{" "}
                      </b>
                    </label>
                    <textarea
                      defaultValue={companyProfile?.notes?.value || ""}
                      // className={!salespersonCheck || salesManagerCheck ? "greyout" : ""}
                      disabled={
                        !salespersonCheck || brdCheck || salesManagerCheck
                          ? true
                          : false
                      }
                      onBlur={(e) => {
                        if (!salespersonCheck || brdCheck || salesManagerCheck)
                          return;
                        companyProfile.notes = {
                          value: e.target.value,
                        };
                        this.setState({ companyProfile: companyProfile });
                      }}
                      rows="4"
                      style={{ width: "100%" }}
                    ></textarea>
                  </div>
                </Form.Group>
              </div>
            )} */}
            {/* <div className="pink-header">
              <div className="font-family-montserrat-bold">
                Locations &amp; Payroll
              </div>
              <div className="companyProfileheadertwo"></div>
            </div>
            <div className=" d-flex align-items-center">
              <input
                className="mr-1"
                type="checkbox"
                id="locSameAsPrimary-1"
                name={"locSameAsPrimary"}
                checked={locSameAsPrimary}
                onClick={this.handleLocSameAsPrimaryCheck}
              />
              <label
                htmlFor="locSameAsPrimary-1"
                className="text-wrap locSameAsPrimary-1-label"
              >
                <small>Same as Physical Address</small>
              </label>
            </div>

            {showLocationAndPayroll && (
              <div className="calculator location-payroll-container">
                <div id="allLocations">
                  <span className="formTitle">
                    <h4 className="company-locations">
                      <b>Exposure Details</b>
                    </h4>
                  </span>
                  <div id="addressDiv">
                    {childrenLoc &&
                      Object.values(childrenLoc).map((e) => {
                        index++;
                        return (
                          <>
                            <CompLoc
                              index={index}
                              key={e.key}
                              locationInfo={e}
                              updateLocationInfo={this.updateLocationInfo}
                              setStateAndCity={this.setStateAndCity}
                              removeClassCode={this.removeClassCode}
                              removeLocation={this.onrmvChild}
                              updateClassCodesInfo={this.updateClassCodesInfo}
                              updateLocationError={this.updateLocationError}
                              resetClassCodeDescriptionData={
                                this.resetClassCodeDescriptionData
                              }
                              effectiveDate={companyProfile.effectiveDate}
                              locationCount={locationCount}
                              isLoggedIn={isLoggedIn}
                              specificStates={specificStates}
                            />
                            <hr style={{ marginBottom: "2rem" }}></hr>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btnInvisible addLoc transpButton p-0"
                    onClick={this.onAddChildLoc}
                  >
                    <img src={require("../../../images/plus4.png")} />{" "}
                    <b>Add location</b>
                  </button>
                </div>
                {(salespersonCheck || brdCheck || showAllFields) && (
                  
                )}
              </div>
            )} */}
            {/* {!mailCampaignUserCheck &&
              !isUnderWriterFlow &&
              (!salespersonCheck || !brdCheck) &&
              isLoggedIn && (
                <div className="pink-header">
                  <div className="font-family-montserrat-bold">
                    Additional Questions
                  </div>
                  {showAdditionalQuestions ? (
                    <span
                      className="pink-sign noselect"
                      onClick={() => {
                        if (showAdditionalQuestions)
                          this.setState({ showAdditionalQuestions: false });
                        else return;
                      }}
                    >
                      &#8722;
                    </span>
                  ) : (
                    <span
                      className="pink-sign noselect"
                      onClick={() => {
                        if (!showAdditionalQuestions)
                          this.setState({ showAdditionalQuestions: true });
                        else return;
                      }}
                    >
                      &#43;
                    </span>
                  )}
                </div>
              )} */}

            {/* {salespersonCheck || brdCheck || showAllFields ? (
              <BrokerQues
                brokerQuestionValue={
                  this.state.brokerDetails.brokerQuestionValue
                }
                brokerdropdownSelected={
                  this.state.brokerDetails.brokerdropdownSelected
                }
                brokerPercentage={this.state.brokerDetails.brokerPercentage}
                brokerQuestionResponse={this.brokerQuestionResponse}
                brokerDropdownResponse={this.brokerDropdownResponse}
                brokerPercentageResponse={this.brokerPercentageResponse}
              />
            ) : (
              ""
            )} */}
            {/* {salespersonCheck || brdCheck || showAllFields ? (
              <>
                <div
                  className="table-responsive question-list-container"
                  id="companyproQuestPEOPaygo"
                >
                  <Allques
                    quesList={[this.state.companyProfileAddQuestions[2]]}
                    updateQuesList={this.updateQuesList_sales_flow}
                    header={false}
                  />
                </div>
                {["b", "c"].includes(
                  this.state.companyProfileAddQuestions?.[2]?.response
                ) && (
                  <div
                    className="table-responsive question-list-container"
                    id="companyprofilequestion"
                  >
                    <Allques
                      quesList={[this.state.companyProfileAddQuestions[3]]}
                      updateQuesList={this.updateQuesList_sales_flow}
                      header={false}
                    />
                  </div>
                )}
              </>
            ) : (
              !mailCampaignUserCheck &&
              !isUnderWriterFlow &&
              isLoggedIn &&
              showAdditionalQuestions && (
                <div className="table-responsive question-list-container">
                  <Allques
                    quesList={this.state.companyProfileAddQuestions}
                    updateQuesList={this.updateQuesList}
                    header={false}
                  />
                </div>
              )
            )}
            {salespersonCheck ||
            brdCheck ||
            mailCampaignUserCheck ||
            !isLoggedIn ? (
              <div
                className="table-responsive question-list-container"
                id="companyprofilequestion"
              >
                {this.state.companyProfileAddQuestions.map((tabName, index) =>
                  index > 3 ? (
                    <Allques
                      quesList={[this.state.companyProfileAddQuestions[index]]}
                      updateQuesList={this.updateLastThreeQuesList}
                      header={false}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>
            ) : (
              ""
            )} */}
            {!preUW && (
              <div className="table-responsive question-list-container">
                <Allques
                  className="mb-0"
                  quesList={this.state.companyProfileAddQuestions}
                  updateQuesList={this.updateQuesList}
                  header={false}
                />
                <div className="paycycle-container d-flex justify-content-between ">
                  <div className="d-flex requstedAdminRow">
                    <div className="d-flex align-items-center requestedAdminContent">
                      <b>What is your current Pay Cycle</b>
                    </div>
                  </div>
                  <div className="paycycle-style">
                    <Form.Check
                      inline
                      id="Weekly_Rate"
                      value="a"
                      name=""
                      checked={requestedPricing["WeeklySelect"] === "a"}
                      label="Weekly"
                      type="radio"
                      onChange={(e) =>
                        this.handleOptionChange(e, "response", "a")
                      }
                    />
                    <Form.Check
                      inline
                      id="Bi-Weekly"
                      value="b"
                      name=""
                      checked={requestedPricing["WeeklySelect"] === "b"}
                      label="Bi-Weekly"
                      type="radio"
                      onChange={(e) =>
                        this.handleOptionChange(e, "response", "b")
                      }
                    />
                    <Form.Check
                      inline
                      id="Semi-Monthly"
                      value="c"
                      name=""
                      checked={requestedPricing["WeeklySelect"] === "c"}
                      label="Semi-Monthly"
                      type="radio"
                      onChange={(e) =>
                        this.handleOptionChange(e, "response", "c")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="emod-ques">
              {Object.values(emodStatesData).length > 0 && (
                <h4 className="emod-ques-heading">
                  <b>Experience Modification</b>
                </h4>
              )}
              {Object.keys(emodStatesData).map((e) => {
                return (
                  <EmodQuestions
                    key={e}
                    state={e}
                    emodData={emodStatesData[e]}
                    updateEmodInfo={this.updateEmodInfo}
                  />
                );
              })}
            </div> */}
          </form>
          <div className="mt-5">
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                onClick={() => {
                  if (
                    hideButtons ||
                    quoteSubmitted ||
                    disableContinue ||
                    disableGetQuote ||
                    salesManagerCheck
                  )
                    return;
                  this.submitCompPro();
                }}
                disabled={
                  hideButtons ||
                  quoteSubmitted ||
                  disableContinue ||
                  disableGetQuote ||
                  salesManagerCheck ||
                  brdCheck
                }
                id="profileSaveButton"
                className="btn mt-0 button_color btn-lg btn-warning-shadow btnSubmits compsbtbtn"
              >
                Continue
              </button>
              <p className="errMsg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onAddChildLoc = (data) => {
    let { childrenLoc } = this.state;
    let dupChildrenLoc = childrenLoc;
    console.log(dupChildrenLoc, " chkeic on clidk");
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
      clientsuta: {},
    };
    this.setState({
      childrenLoc: dupChildrenLoc,
    });
  };

  onrmvChild = (addressId) => {
    let { childrenLoc, emodStatesData, state_class_code } = this.state;

    if (!childrenLoc[addressId].state || !childrenLoc[addressId].state.value) {
      delete childrenLoc[addressId];
      this.setState({ childrenLoc });
      return;
    }

    let stateDeleted = childrenLoc[addressId].state.value;
    let stateDoesNotExistAgain = true;
    let firstToBeDisabledIndex;

    //cc_delete
    for (let classCodeIndex in childrenLoc[addressId].classCodesInfo) {
      let locationIndex = addressId;
      let currState = childrenLoc[locationIndex].state.value || "";
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        try {
          let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
            classCodeIndex
          ]["classCodeDescription"]
            ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ].value
            : undefined;
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              state_class_code[currState.toLowerCase()].cc_dsc &&
              class_code in state_class_code[currState.toLowerCase()].cc_dsc
            ) {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (e) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (Number(cc) === Number(class_code)) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value:
                            state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                        if (
                          !locToUpdate &&
                          !ccToUpdate &&
                          Number(locIndx) !== Number(locationIndex)
                        ) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;
                if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
                  state_class_code[currState.toLowerCase()].cc_dsc = {};
                }
                try {
                  state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].value;
                } catch (error) {}
              } else {
                delete state_class_code[currState.toLowerCase()].cc_dsc[
                  class_code
                ];
              }
            }
          }
        } catch (error) {}
      }
    }
    //cc_delete

    delete childrenLoc[addressId];

    let stateList = [];
    try {
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          if (childrenLoc[addressBlockNo].state.value === stateDeleted) {
            childrenLoc[addressBlockNo].suta.disabled = true;
            childrenLoc[addressBlockNo].clientsuta.disabled = true;
            if (!firstToBeDisabledIndex)
              firstToBeDisabledIndex = addressBlockNo;
            stateDoesNotExistAgain = false;
          }
          stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
        }
      }

      if (stateDoesNotExistAgain) {
        state_class_code[stateDeleted.toLowerCase()].val = "";
      }
      if (firstToBeDisabledIndex) {
        childrenLoc[firstToBeDisabledIndex].suta.disabled = false;
        childrenLoc[firstToBeDisabledIndex].clientsuta.disabled = false;
      }
    } catch (error) {}

    if (stateList.length > 0) {
      let statesData = {
        ncciStates: [],
        actingNcciStates: [],
        indBureauStates: [],
      };
      axios
        .post(
          awsUrl2 + "/api/getExpModStateCategory",
          JSON.stringify({ states: stateList })
        )
        .then((response) => {
          statesData = response.data;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          let newEmodData = getEmodStatesData(statesData, emodStatesData || {});
          this.setState({
            childrenLoc,
            emodStatesData: newEmodData,
            state_class_code,
          });
        });
    } else {
      emodStatesData = {};
      this.setState({ childrenLoc, emodStatesData, state_class_code });
    }
  };
}

export default function FormCompProWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormCompPro
          updateMailCampaignUser={props.updateMailCampaignUser}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
        />
      )}
    </Context.Consumer>
  );
}

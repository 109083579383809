import React, { useEffect, useState } from "react";
import CompLoc from "./CompLoc";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import * as XLSX from "xlsx";
import $ from "jquery";
import { IoMdCloudUpload } from "react-icons/io";
import { SlExclamation } from "react-icons/sl";
import Tooltip from "@material-ui/core/Tooltip";
import reference from "../../../utils/Locations & Payroll Reference.csv";
import { submitQuote } from "../../../utils/form_ques";
import DropZone from "../PreUwUpload/DropZone";
import { Auth } from "aws-amplify";
import Popup from "../../common/Popup";
import moment from "moment";

function FormWorkerComp(props) {
  const [locationPayroll, setLocationPayroll] = useState({
    0: {
      number: 0,
      key: Math.random(),
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
      clientsuta: {},
    },
  });
  const [stateClassCode, setStateClassCode] = useState({});
  const [fileList, setFileList] = useState([]);
  const [docUploaded, setDocUploaded] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [currentUserName, setCurrentUserName] = useState({});
  const [popupData, setPopupData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });
  const fetchUser = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      setCurrentUserName({
        name: user.attributes.name,
        email: user.attributes.email,
      });
    } catch (error) {
      console.error("Error fetching user: ", error);
    }
  };
  const updatePopUpData = (pData) => {
    console.log("updatePopUpData", pData);
    setPopupData(pData);
  };
  let salesclicked = sessionStorage.getItem("salesclicked");
  let currProspect = sessionStorage.getItem("currProspect")
    ? JSON.parse(sessionStorage.getItem("currProspect"))
    : {};

  let fein = currProspect?.companyProfile?.fein?.value;
  let companyName = currProspect?.companyProfile?.companyName?.value;

  let preUwFiles = sessionStorage.getItem("preUwFiles");

  let childrenLoc = currProspect?.childrenLoc
    ? currProspect?.childrenLoc
    : false;

  let domain = sessionStorage.getItem("domain");

  let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    ? true
    : false;

  useEffect(() => {
    fetchUser();
    if (currProspect?.childrenLoc) {
      setLocationPayroll(currProspect.childrenLoc);
    }

    if (preUwFiles) {
      let _fileList = {};
      preUwFiles = JSON.parse(preUwFiles);

      preUwFiles.map((ele) => (_fileList[ele.keyToFile] = "#empty"));

      setFileList(_fileList);
    }

    if (childrenLoc) {
      setLocationPayroll(childrenLoc);
    }

    console.log(currProspect?.childrenLoc, childrenLoc, "soujuuu");
  }, []);

  const setStateAndCity = (zipCode, index) => {
    let {
      locationPayroll: childrenLoc,
      stateClassCode: state_class_code,
    } = JSON.parse(JSON.stringify({ locationPayroll, stateClassCode }));

    if (!zipCode) return;
    axios
      .post(awsUrl2 + "/api/getStateAndCity/" + zipCode)
      .then((res) => {
        const data = res.data;

        if (Object.keys(data).length !== 0) {
          let locationIndex = index;
          let fieldName = "state";
          let prevState;
          if (
            fieldName === "state" &&
            childrenLoc[locationIndex][fieldName].value
          ) {
            prevState = childrenLoc[locationIndex][fieldName].value;

            //cc_delete
            for (let classCodeIndex in childrenLoc[locationIndex]
              .classCodesInfo) {
              let currState = childrenLoc[locationIndex].state.value || "";
              try {
                if (
                  currState.toLowerCase() in state_class_code &&
                  state_class_code[currState.toLowerCase()].val &&
                  childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ]
                ) {
                  let class_code_desc = childrenLoc[locationIndex]
                    .classCodesInfo[classCodeIndex]["classCodeDescription"]
                    ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                        "classCodeDescription"
                      ].value
                    : undefined;
                  if (class_code_desc) {
                    let class_code = class_code_desc.split(":")[0].trim();
                    if (
                      state_class_code[currState.toLowerCase()].cc_dsc &&
                      class_code in
                        state_class_code[currState.toLowerCase()].cc_dsc
                    ) {
                      let locToUpdate, ccToUpdate;
                      for (let locIndx in childrenLoc) {
                        if (childrenLoc[locIndx].state.value === currState) {
                          let ccInfo = childrenLoc[locIndx].classCodesInfo;
                          for (let ccIndx in ccInfo) {
                            let cc_desc;
                            try {
                              cc_desc =
                                ccInfo[ccIndx]["classCodeDescription"].value;
                            } catch (e) {}
                            if (cc_desc) {
                              let cc = cc_desc.split(":")[0].trim();
                              if (Number(cc) === Number(class_code)) {
                                childrenLoc[locIndx].classCodesInfo[ccIndx][
                                  "pricing"
                                ] = {
                                  value:
                                    state_class_code[currState.toLowerCase()]
                                      .cc_dsc[class_code],
                                  disabled: true,
                                };
                                if (
                                  !locToUpdate &&
                                  !ccToUpdate &&
                                  Number(locIndx) !== Number(locationIndex)
                                ) {
                                  locToUpdate = locIndx;
                                  ccToUpdate = ccIndx;
                                }
                              }
                            }
                          }
                        }
                      }
                      if (locToUpdate && ccToUpdate) {
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].disabled = false;
                        if (
                          !(
                            "cc_dsc" in
                            state_class_code[currState.toLowerCase()]
                          )
                        ) {
                          state_class_code[currState.toLowerCase()].cc_dsc = {};
                        }
                        try {
                          state_class_code[currState.toLowerCase()].cc_dsc[
                            class_code
                          ] =
                            childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                              "pricing"
                            ].value;
                        } catch (error) {}
                      } else {
                        delete state_class_code[currState.toLowerCase()].cc_dsc[
                          class_code
                        ];
                      }
                    }
                  }
                }
              } catch (error) {}
            }
            //cc_delete
          }
          childrenLoc[index].state = { value: data.state };
          childrenLoc[index].cityName = { value: data.city };

          let stateList = [];
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }
          if (fieldName === "state") {
            let value = data.state;
            try {
              for (let addressBlockNo in childrenLoc) {
                if (childrenLoc[addressBlockNo].state.value) {
                  if (
                    prevState &&
                    prevState === childrenLoc[addressBlockNo].state.value
                  ) {
                    childrenLoc[addressBlockNo].suta.disabled = false;
                    childrenLoc[addressBlockNo].clientsuta.disabled = false;
                    prevState = "#####";
                  }
                }
              }

              if (prevState && prevState !== "#####") {
                delete state_class_code[prevState.toLowerCase()];
              }

              let stateSet = new Set(stateList);

              for (let stateIndx in state_class_code) {
                if (!stateSet.has(stateIndx.toLowerCase())) {
                  delete state_class_code[stateIndx];
                }
              }
            } catch (error) {}

            try {
              if (!(value.toLowerCase() in state_class_code)) {
                state_class_code[value.toLowerCase()] = {
                  ...state_class_code[value.toLowerCase()],
                  val: childrenLoc[locationIndex].suta.value
                    ? childrenLoc[locationIndex].suta.value
                    : "",
                  val2: childrenLoc[locationIndex].clientsuta.value
                    ? childrenLoc[locationIndex].clientsuta.value
                    : "",
                };
                childrenLoc[locationIndex].suta.disabled = false;
                childrenLoc[locationIndex].clientsuta.disabled = false;
              } else {
                if (state_class_code[value.toLowerCase()].val) {
                  childrenLoc[locationIndex]["suta"] = {
                    value: state_class_code[value.toLowerCase()].val,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["suta"] = {
                    value: "",
                    disabled: false,
                  };
                }
                if (state_class_code[value.toLowerCase()].val2) {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: state_class_code[value.toLowerCase()].val2,
                    disabled: true,
                  };
                } else {
                  childrenLoc[locationIndex]["clientsuta"] = {
                    value: "",
                    disabled: false,
                  };
                }
              }
            } catch (error) {
              console.log(
                "error in setStateAndZip inside state_class_code calculations"
              );
            }
          }

          setLocationPayroll(childrenLoc);
        } else {
          childrenLoc[index].zipCode = { errorMsg: true, error: true };
          childrenLoc[index].state = { value: "" };
          childrenLoc[index].cityName = { value: "" };
          setLocationPayroll(childrenLoc);
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
        childrenLoc[index].zipCode = { errorMsg: true, error: true };
        childrenLoc[index].state = { value: "" };
        childrenLoc[index].cityName = { value: "" };
        setLocationPayroll(childrenLoc);
      });
  };

  const removeClassCode = (locationIndex, classCodeIndex) => {
    let {
      locationPayroll: childrenLoc,
      stateClassCode: state_class_code,
    } = JSON.parse(JSON.stringify({ locationPayroll, stateClassCode }));

    if (
      !childrenLoc[locationIndex].state ||
      !childrenLoc[locationIndex].state.value
    ) {
      delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
      setLocationPayroll(childrenLoc);
      return;
    }

    //code to handle state-classcode combination requested pricing starts
    let currState = childrenLoc[locationIndex].state.value || "";
    try {
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            state_class_code[currState.toLowerCase()].cc_dsc &&
            class_code in state_class_code[currState.toLowerCase()].cc_dsc
          ) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in childrenLoc) {
              if (childrenLoc[locIndx].state.value === currState) {
                let ccInfo = childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  if (
                    !(
                      ccInfo[ccIndx] &&
                      ccInfo[ccIndx].classCodeDescription &&
                      ccInfo[ccIndx].classCodeDescription.value
                    )
                  ) {
                    continue;
                  }
                  let local_cc_desc = ccInfo[ccIndx].classCodeDescription.value;

                  let local_cc = local_cc_desc.split(":")[0].trim();
                  if (
                    !locToUpdate &&
                    !ccToUpdate &&
                    (Number(locIndx) !== Number(locationIndex) ||
                      Number(ccIndx) !== Number(classCodeIndex)) &&
                    Number(class_code) === Number(local_cc)
                  ) {
                    childrenLoc[locIndx].classCodesInfo[
                      ccIndx
                    ].pricing.disabled = true;
                    locToUpdate = locIndx;
                    ccToUpdate = ccIndx;
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              childrenLoc[locToUpdate].classCodesInfo[
                ccToUpdate
              ].pricing.disabled = false;
            } else {
              delete state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ];
            }
          }
        }
      }
    } catch (error) {}
    //code to handle state-classcode ends here

    delete childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    setLocationPayroll(childrenLoc);
    setStateClassCode(state_class_code);
  };

  const updateClassCodesInfo = (
    locationIndex,
    classCodeIndex,
    fieldName,
    value
  ) => {
    let {
      locationPayroll: childrenLoc,
      stateClassCode: state_class_code,
    } = JSON.parse(JSON.stringify({ locationPayroll, stateClassCode }));

    childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };

    if (fieldName === "pricing") {
      let currState = childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in state_class_code) {
        let class_code_desc;
        try {
          class_code_desc =
            childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value;
        } catch (error) {}
        if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
          state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        try {
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              !(class_code in state_class_code[currState.toLowerCase()].cc_dsc)
            ) {
              state_class_code[currState.toLowerCase()].cc_dsc[
                class_code
              ] = value;
            } else {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (error) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (cc === class_code) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value: value,
                          disabled: true,
                        };
                        if (!locToUpdate && !ccToUpdate) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;

                state_class_code[currState.toLowerCase()].cc_dsc[
                  class_code
                ] = value;
              }
            }
          }
        } catch (error) {}
      }
    }
    try {
      if (fieldName === "classCodeDescription") {
        let currState = childrenLoc[locationIndex].state.value || "";
        if (currState.toLowerCase() in state_class_code) {
          let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
            classCodeIndex
          ]["classCodeDescription"]
            ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ].value
            : undefined;
          if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
            state_class_code[currState.toLowerCase()].cc_dsc = {};
          }
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              class_code in state_class_code[currState.toLowerCase()].cc_dsc
            ) {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (e) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (cc === class_code) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value:
                            state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                        if (!locToUpdate && !ccToUpdate) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;
              }
            } else {
              if (
                childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                  .pricing &&
                childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                  .pricing.value
              ) {
                state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                  childrenLoc[locationIndex].classCodesInfo[
                    classCodeIndex
                  ].pricing.value;
              }
            }
          }
        }
      }
    } catch (error) {}
    setLocationPayroll(childrenLoc);
    setStateClassCode(state_class_code);
  };

  const updateLocationError = (
    locationIndex,
    classCodeIndex,
    fieldName,
    value
  ) => {
    let { locationPayroll: childrenLoc } = JSON.parse(
      JSON.stringify({ locationPayroll })
    );

    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else {
      childrenLoc[locationIndex][fieldName].error = value;
    }
    setLocationPayroll(childrenLoc);
  };

  const resetClassCodeDescriptionData = (locationIndex, childrenLoc) => {
    // let { locationPayroll: childrenLoc } = JSON.parse(JSON.stringify({ locationPayroll }));
    for (let index in childrenLoc[locationIndex].classCodesInfo) {
      // if (
      //   childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      // ) {
      //   console.log(locationIndex, childrenLoc, "soujuuu")
      //   childrenLoc[locationIndex].classCodesInfo[index]
      //     .classCodeDescription.value = "";
      // }
      if (childrenLoc[locationIndex].classCodesInfo[index].pricing) {
        delete childrenLoc[locationIndex].classCodesInfo[index].pricing;
      }
    }
  };

  const updateLocationInfo = (
    value,
    locationIndex,
    fieldName,
    classCodeIndex
  ) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : {};

    let {
      locationPayroll: childrenLoc,
      stateClassCode: state_class_code,
    } = JSON.parse(JSON.stringify({ locationPayroll, stateClassCode }));

    if (classCodeIndex) {
      childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      setLocationPayroll(childrenLoc);
    } else {
      let prevState;
      if (
        fieldName === "state" &&
        childrenLoc[locationIndex][fieldName].value
      ) {
        prevState = childrenLoc[locationIndex][fieldName].value;

        //cc_delete
        for (let classCodeIndex in childrenLoc[locationIndex].classCodesInfo) {
          let currState = childrenLoc[locationIndex].state.value || "";
          try {
            if (
              currState.toLowerCase() in state_class_code &&
              state_class_code[currState.toLowerCase()].val &&
              childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ]
            ) {
              let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
                classCodeIndex
              ]["classCodeDescription"]
                ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                    "classCodeDescription"
                  ].value
                : undefined;
              if (class_code_desc) {
                let class_code = class_code_desc.split(":")[0].trim();
                if (
                  state_class_code[currState.toLowerCase()].cc_dsc &&
                  class_code in state_class_code[currState.toLowerCase()].cc_dsc
                ) {
                  let locToUpdate, ccToUpdate;
                  for (let locIndx in childrenLoc) {
                    if (childrenLoc[locIndx].state.value === currState) {
                      let ccInfo = childrenLoc[locIndx].classCodesInfo;
                      for (let ccIndx in ccInfo) {
                        let cc_desc;
                        try {
                          cc_desc =
                            ccInfo[ccIndx]["classCodeDescription"].value;
                        } catch (e) {}
                        if (cc_desc) {
                          let cc = cc_desc.split(":")[0].trim();
                          if (Number(cc) === Number(class_code)) {
                            childrenLoc[locIndx].classCodesInfo[ccIndx][
                              "pricing"
                            ] = {
                              value:
                                state_class_code[currState.toLowerCase()]
                                  .cc_dsc[class_code],
                              disabled: true,
                            };
                            if (
                              !locToUpdate &&
                              !ccToUpdate &&
                              Number(locIndx) !== Number(locationIndex)
                            ) {
                              locToUpdate = locIndx;
                              ccToUpdate = ccIndx;
                            }
                          }
                        }
                      }
                    }
                  }
                  if (locToUpdate && ccToUpdate) {
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].disabled = false;
                    if (
                      !("cc_dsc" in state_class_code[currState.toLowerCase()])
                    ) {
                      state_class_code[currState.toLowerCase()].cc_dsc = {};
                    }
                    try {
                      state_class_code[currState.toLowerCase()].cc_dsc[
                        class_code
                      ] =
                        childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                          "pricing"
                        ].value;
                    } catch (error) {}
                  } else {
                    delete state_class_code[currState.toLowerCase()].cc_dsc[
                      class_code
                    ];
                  }
                }
              }
            }
          } catch (error) {}
        }
        //cc_delete
      }

      childrenLoc[locationIndex][fieldName] = { value: value };

      if (fieldName === "suta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].suta.value !== value
              ) {
                childrenLoc[addressBlockNo].suta.value = value;
                childrenLoc[addressBlockNo].suta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "clientsuta") {
        try {
          if (
            childrenLoc[locationIndex].state &&
            childrenLoc[locationIndex].state.value &&
            childrenLoc[locationIndex].state.value.toLowerCase() in
              state_class_code
          ) {
            state_class_code[
              childrenLoc[locationIndex].state.value.toLowerCase()
            ] = {
              ...state_class_code[
                childrenLoc[locationIndex].state.value.toLowerCase()
              ],
              val2: value,
            };
            for (let addressBlockNo in childrenLoc) {
              if (
                childrenLoc[addressBlockNo].state.value &&
                childrenLoc[addressBlockNo].state.value ===
                  childrenLoc[locationIndex].state.value &&
                childrenLoc[addressBlockNo].clientsuta.value !== value
              ) {
                childrenLoc[addressBlockNo].clientsuta.value = value;
                childrenLoc[addressBlockNo].clientsuta.disabled = true;
              }
            }
          }
        } catch (error) {}
      }
      if (fieldName === "state") {
        let stateList = [];

        try {
          for (let addressBlockNo in childrenLoc) {
            if (childrenLoc[addressBlockNo].state.value) {
              if (
                prevState &&
                prevState === childrenLoc[addressBlockNo].state.value
              ) {
                childrenLoc[addressBlockNo].suta.disabled = false;
                childrenLoc[addressBlockNo].clientsuta.disabled = false;
                prevState = "#####";
              }
              stateList.push(
                childrenLoc[addressBlockNo].state.value.toLowerCase()
              );
            }
          }

          if (prevState && prevState !== "#####") {
            delete state_class_code[prevState.toLowerCase()];
          }
        } catch (error) {}

        let stateSet = new Set(stateList);

        try {
          for (let stateIndx in state_class_code) {
            if (!stateSet.has(stateIndx.toLowerCase())) {
              delete state_class_code[stateIndx];
            }
          }

          if (!(value.toLowerCase() in state_class_code)) {
            state_class_code[value.toLowerCase()] = {
              ...state_class_code[value.toLowerCase()],
              val: childrenLoc[locationIndex].suta.value
                ? childrenLoc[locationIndex].suta.value
                : "",
              val2: childrenLoc[locationIndex].clientsuta.value
                ? childrenLoc[locationIndex].clientsuta.value
                : "",
            };
            childrenLoc[locationIndex].suta.disabled = false;
            childrenLoc[locationIndex].clientsuta.disabled = false;
          } else {
            if (state_class_code[value.toLowerCase()].val) {
              childrenLoc[locationIndex]["suta"] = {
                value: state_class_code[value.toLowerCase()].val,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["suta"] = {
                value: "",
                disabled: false,
              };
            }
            if (state_class_code[value.toLowerCase()].val2) {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: state_class_code[value.toLowerCase()].val2,
                disabled: true,
              };
            } else {
              childrenLoc[locationIndex]["clientsuta"] = {
                value: "",
                disabled: false,
              };
            }
          }
        } catch (error) {}

        setLocationPayroll(childrenLoc);
        setStateClassCode(state_class_code);
      }

      // if (fieldName === "zipCode") {
      //   resetClassCodeDescriptionData(locationIndex, childrenLoc);
      // }
      setLocationPayroll(childrenLoc);
      setStateClassCode(state_class_code);
    }
  };

  const onrmvChild = (addressId) => {
    let {
      locationPayroll: childrenLoc,
      stateClassCode: state_class_code,
    } = JSON.parse(JSON.stringify({ locationPayroll, stateClassCode }));

    if (!childrenLoc[addressId].state || !childrenLoc[addressId].state.value) {
      delete childrenLoc[addressId];
      setLocationPayroll(childrenLoc);
      return;
    }

    let stateDeleted = childrenLoc[addressId].state.value;
    let stateDoesNotExistAgain = true;
    let firstToBeDisabledIndex;

    //cc_delete
    for (let classCodeIndex in childrenLoc[addressId].classCodesInfo) {
      let locationIndex = addressId;
      let currState = childrenLoc[locationIndex].state.value || "";
      if (
        currState.toLowerCase() in state_class_code &&
        state_class_code[currState.toLowerCase()].val
      ) {
        try {
          let class_code_desc = childrenLoc[locationIndex].classCodesInfo[
            classCodeIndex
          ]["classCodeDescription"]
            ? childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
                "classCodeDescription"
              ].value
            : undefined;
          if (class_code_desc) {
            let class_code = class_code_desc.split(":")[0].trim();
            if (
              state_class_code[currState.toLowerCase()].cc_dsc &&
              class_code in state_class_code[currState.toLowerCase()].cc_dsc
            ) {
              let locToUpdate, ccToUpdate;
              for (let locIndx in childrenLoc) {
                if (childrenLoc[locIndx].state.value === currState) {
                  let ccInfo = childrenLoc[locIndx].classCodesInfo;
                  for (let ccIndx in ccInfo) {
                    let cc_desc;
                    try {
                      cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                    } catch (e) {}
                    if (cc_desc) {
                      let cc = cc_desc.split(":")[0].trim();
                      if (Number(cc) === Number(class_code)) {
                        childrenLoc[locIndx].classCodesInfo[ccIndx][
                          "pricing"
                        ] = {
                          value:
                            state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                        if (
                          !locToUpdate &&
                          !ccToUpdate &&
                          Number(locIndx) !== Number(locationIndex)
                        ) {
                          locToUpdate = locIndx;
                          ccToUpdate = ccIndx;
                        }
                      }
                    }
                  }
                }
              }
              if (locToUpdate && ccToUpdate) {
                childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                  "pricing"
                ].disabled = false;
                if (!("cc_dsc" in state_class_code[currState.toLowerCase()])) {
                  state_class_code[currState.toLowerCase()].cc_dsc = {};
                }
                try {
                  state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                    childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                      "pricing"
                    ].value;
                } catch (error) {}
              } else {
                delete state_class_code[currState.toLowerCase()].cc_dsc[
                  class_code
                ];
              }
            }
          }
        } catch (error) {}
      }
    }
    //cc_delete

    delete childrenLoc[addressId];

    let stateList = [];
    try {
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          if (childrenLoc[addressBlockNo].state.value === stateDeleted) {
            childrenLoc[addressBlockNo].suta.disabled = true;
            childrenLoc[addressBlockNo].clientsuta.disabled = true;
            if (!firstToBeDisabledIndex)
              firstToBeDisabledIndex = addressBlockNo;
            stateDoesNotExistAgain = false;
          }
          stateList.push(childrenLoc[addressBlockNo].state.value.toLowerCase());
        }
      }

      if (stateDoesNotExistAgain) {
        state_class_code[stateDeleted.toLowerCase()].val = "";
      }
      if (firstToBeDisabledIndex) {
        childrenLoc[firstToBeDisabledIndex].suta.disabled = false;
        childrenLoc[firstToBeDisabledIndex].clientsuta.disabled = false;
      }
    } catch (error) {}

    setLocationPayroll(childrenLoc);
    setStateClassCode(state_class_code);
  };

  const onAddChildLoc = (data) => {
    let { locationPayroll: childrenLoc } = JSON.parse(
      JSON.stringify({ locationPayroll })
    );

    let dupChildrenLoc = childrenLoc;

    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;

    if (!data.location_details) {
      data.location_details = {};
    }

    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
      clientsuta: {},
    };

    setLocationPayroll(childrenLoc);
  };

  const onClickSave = (domain, payrollData) => {
    $("#loader").css("display", "block");

    axios
      .post(awsUrl2 + `/api/moveCampaignToTracking`, { domain })
      .then((res) => {
        if (res.data === "Updated successfully") {
          axios
            .post(
              awsUrl2 + "/api/updateEngagedWithEmailCampaign/" + domain,
              JSON.stringify(domain)
            )
            .catch((err) => console.error(err));
        }

        setDocUploaded(true);

        let paramsToSend = {
          domain,
          payrollData,
          preUwSaved: "true",
          completedDate: moment().format("x"),
          preUwNameAttr: currentUserName.name,
          preUWName: currentUserName.email,
        };
        // console.log(paramsToSend, "ouju");
        axios
          .post(awsUrl2 + `/api/preUwData/updateTrackingData`, paramsToSend)
          .then((res) => {
            console.log("posted data", res);
            $("#loader").css("display", "none");
          })
          .catch((error) => {
            console.log("error in posting data to user tracking", error);
            $("#loader").css("display", "none");
          });
      })
      .catch((error) => {
        console.log(error);
        $("#loader").css("display", "none");
      });
  };

  const checkLocationInfoError = () => {
    let { locationPayroll: childrenLoc } = JSON.parse(
      JSON.stringify({ locationPayroll })
    );
    let error = false;
    let locationKeys = ["zipCode", "state", "cityName", "street"];
    let classCodeKeys = ["classCodeDescription", "payroll"];

    let field;
    let index = 0;
    for (let addressBlockNo in childrenLoc) {
      locationKeys.forEach((fieldName) => {
        if (
          (childrenLoc[addressBlockNo] &&
            childrenLoc[addressBlockNo][fieldName] &&
            !childrenLoc[addressBlockNo][fieldName].value) ||
          (childrenLoc[addressBlockNo] &&
            childrenLoc[addressBlockNo][fieldName] &&
            childrenLoc[addressBlockNo][fieldName].error)
        ) {
          childrenLoc[addressBlockNo][fieldName].error = true;
          error = true;
          if (!field) field = "officeLoc" + index;
        }
      });
      for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
        if (!childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"] = {};
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + index;
        } else if (
          !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
        ) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"] = {};
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = true;
          error = true;
          if (!field) field = "officeLoc" + index;
        } else if (
          Number(
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
              .value == 0
          ) &&
          Number(
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
              .value == 0
          )
        ) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = true;
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = true;
          error = true;
        } else if (
          !(
            Number(
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
                .value
            ) ||
            Number(
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
                .value
            )
          )
        ) {
          if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
              .value
          )
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "ft"
            ].error = true;
          if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
              .value
          )
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              "pt"
            ].error = true;
          error = true;
          if (!field) field = "officeLoc" + index;
        } else {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = false;
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = false;
        }

        classCodeKeys.forEach((fieldName) => {
          if (
            !(
              fieldName in
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
            )
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ] = { error: true };
            error = true;
            if (!field) field = "officeLoc" + index;
          } else if (
            !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].value ||
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error
          ) {
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
              fieldName
            ].error = true;
            if (!field) field = "officeLoc" + index;
            error = true;
          }
        });
      }
      index++;
    }
    setLocationPayroll(childrenLoc);
    return [error, field];
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    };

    reader.readAsBinaryString(file);
  };

  const handleClick = async () => {
    if (excelData) {
      let result = {};

      excelData.forEach((item) => {
        let locNumber = item["Location#"];

        if (!result[locNumber]) {
          result[locNumber] = {
            number: locNumber,
            zipCode: {
              value: item["Location Zip"].toString(),
            },
            cityName: {
              value: item["Location City"],
            },
            state: {
              value: item["Location State"],
            },
            locationAddress: {
              value: item["Location Street Address"],
            },
            classCodesInfo: {},
            key: Math.random(),
          };
        }

        let classCodeIndex = Object.keys(result[locNumber]["classCodesInfo"])
          .length;

        result[locNumber]["classCodesInfo"][classCodeIndex] = {
          classCodeDescription: {
            value: `${item["Class Code"]}:${item["Class Code Description"]}`,
          },
          payroll: {
            value: `$${item["Payroll"].toLocaleString()}`,
          },
          pt: {
            value: item["PT"].toString(),
          },
          ft: {
            value: item["FT"].toString(),
          },
        };
      });

      let updateUnderWriterDetails = {};
      updateUnderWriterDetails.domain = sessionStorage.getItem("domain");
      updateUnderWriterDetails.fein = currProspect?.companyProfile?.fein?.value;
      updateUnderWriterDetails.companyProfile = currProspect?.companyProfile;
      updateUnderWriterDetails.payrollData = result;

      await axios
        .post(
          awsUrl2 + `/api/preUwData/updateTrackingData`,
          updateUnderWriterDetails
        )
        .then((res) => {
          console.log("posted data", res);
        })
        .catch((error) => {
          console.log("error in posting data to user tracking", error);
        });

      sessionStorage.setItem("childrenLoc", JSON.stringify(result));
      window.location.reload();
    }
  };

  let isLoggedIn =
    sessionStorage.getItem("isLoggedIn") === undefined ||
    sessionStorage.getItem("isLoggedIn") === null
      ? false
      : sessionStorage.getItem("isLoggedIn");

  let specificStates = sessionStorage.getItem("specificStates")
    ? JSON.parse(sessionStorage.getItem("specificStates"))
    : [];

  let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

  let tab_source = sessionStorage.getItem("tab_source") || "";

  let index = 0;

  return (
    <div className="Location-and-Payroll" id="locationPayrollContainer">
      <form autoComplete="none">
        <div className="justify-content-end">
          <div className="col-auto text-right mr-5">
            <h6
              style={{
                fontFamily: "MontserratSemiBold",
                fontSize: "16px",
              }}
            >
              {companyName}
            </h6>
            <br />
            <h6
              style={{
                fontFamily: "MontserratSemiBold",
                fontSize: "16px",
                marginTop: "-25px",
                marginBottom: "-25px",
              }}
            >
              {fein}
            </h6>
          </div>
        </div>
        <div className="pink-header1">
          <div className="font-family-montserrat-bold">
            Company Location(s) &amp; Payroll
          </div>
          <div className="companyProfileheadertwo"></div>
        </div>
        {flagPreUnderWriter && (
          <div class="custom-file-upload" style={{ marginTop: "-2%" }}>
            <Tooltip
              title="Click to download the reference file"
              arrow
              style={{ marginRight: "0.4%" }}
            >
              <a href={reference}>
                <SlExclamation size={11} />
              </a>
            </Tooltip>
            <input type="file" id="file-upload" onChange={handleFileUpload} />
            <label for="file-upload" class="file-upload-label">
              Choose file
            </label>
            <IoMdCloudUpload
              size={28}
              color="#427cbc"
              style={{ marginLeft: "0.5%" }}
              onClick={handleClick}
            />
          </div>
        )}
        <div className="calculator location-payroll-container">
          <div id="allLocations">
            <div id="addressDiv">
              {locationPayroll &&
                Object.values(locationPayroll).map((e) => {
                  index++;
                  return (
                    <>
                      <CompLoc
                        index={index}
                        key={e.key}
                        locationInfo={e}
                        updateLocationInfo={updateLocationInfo}
                        setStateAndCity={setStateAndCity}
                        removeClassCode={removeClassCode}
                        removeLocation={onrmvChild}
                        updateClassCodesInfo={updateClassCodesInfo}
                        updateLocationError={updateLocationError}
                        resetClassCodeDescriptionData={
                          resetClassCodeDescriptionData
                        }
                        effectiveDate={
                          currProspect?.companyProfile?.effectiveDate
                        }
                        locationCount={Object.keys(locationPayroll).length}
                        isLoggedIn={isLoggedIn}
                        specificStates={specificStates}
                      />
                      <hr style={{ marginBottom: "2rem" }}></hr>
                    </>
                  );
                })}
            </div>
            <div className="">
              <button
                type="button"
                className="btnInvisible addLoc transpButton p-0"
                onClick={onAddChildLoc}
              >
                <img src={require("../../../images/plus4.png")} />{" "}
                <b>Add location</b>
              </button>
            </div>
          </div>
        </div>
      </form>
      {flagPreUnderWriter && (
        <div className="m-3 d-flex align-items-center justify-content-center">
          <DropZone
            fileList={fileList}
            docUploaded={docUploaded}
            updatePopUpData={props.updatePopUpData}
            setDocUploaded={setDocUploaded}
          />
        </div>
      )}
      <div>
        <div className="col-12 d-flex justify-content-center">
          {flagPreUnderWriter ? (
            <button
              type="button"
              onClick={() => {
                if (hideButtons) return;

                let [error, field] = checkLocationInfoError();

                if (error) return;

                currProspect.childrenLoc = locationPayroll;
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                updatePopUpData({
                  show: true,
                  title: "Thank you. Pre-underwriting has been saved",
                  acceptBtn: "Ok",
                  rejectBtn: " ",
                  acceptBtnCallback: () => {},
                  rejectBtnCallback: () => {},
                });

                onClickSave(
                  domain,
                  JSON.parse(JSON.stringify(locationPayroll))
                );
              }}
              disabled={hideButtons}
              id="profileSaveButton"
              className="btn mt-0 button_color btn-lg btn-warning-shadow btnSubmits"
            >
              Save
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                if (
                  hideButtons
                  //   quoteSubmitted ||
                  //   disableContinue ||
                  //   disableGetQuote ||
                  //   salesManagerCheck
                )
                  return;

                let [error, field] = checkLocationInfoError();

                if (error) return;

                currProspect.childrenLoc = locationPayroll;
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );

                submitQuote(JSON.parse(JSON.stringify(currProspect)));
              }}
              disabled={
                hideButtons
                //   quoteSubmitted ||
                //   disableContinue ||
                //   disableGetQuote ||
                //   salesManagerCheck ||
                //   brdCheck
              }
              id="profileSaveButton"
              className="btn mt-0 button_color btn-lg btn-warning-shadow btnSubmits"
            >
              Get Initial Pricing
            </button>
          )}
          <p className="errMsg" />
          {popupData.show && (
            <Popup
              dashboardstyle={false}
              popupData={popupData}
              updatePopUpData={updatePopUpData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FormWorkerComp;

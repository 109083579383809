// import React, { useCallback } from "react";
// import { useDropzone } from "react-dropzone";

// class CustomDropZone extends React.Component {
//   render() {
//     let {
//       fileList,
//       removeFile,
//       updateFileList,
//       name,
//       headingMap,
//       uploadMsg,
//     } = this.props;
//     // console.log(fileList, "soujuuu");
//     return (
//       <div className="customDropZone">
//         <p
//           className="font-family-montserrat-semi-bold customdropcontent"
//           style={{ marginBottom: "0.5px" }}
//         >
//           {headingMap[name]}
//         </p>
//         <DropZone
//           listLen={Object.keys(fileList[name]).length}
//           updateFileList={updateFileList}
//           name={name}
//           fileList={fileList}
//         />
//         <ul className="list-group list-group-flush">
//           {Object.keys(fileList[name]).map((val) => (
//             <li
//               className="list-group-item d-flex justify-content-between"
//               key={val}
//             >
//               <span>{val}</span>
//               {fileList[name][val] !== "#empty" &&
//                 uploadMsg !== "files uploaded" && (
//                   <span
//                     className="fw-bold"
//                     onClick={() => removeFile(name, val)}
//                   >
//                     {"X"}
//                   </span>
//                 )}
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   }
// }

// const DropZone = (props) => {
//   let { updateFileList, listLen, name, fileList } = props;
//   const onDrop = useCallback((acceptedFiles) => {
//     acceptedFiles.forEach((file) => {
//       updateFileList(name, file);
//     });
//   }, listLen);
//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   return (
//     <div {...getRootProps({ className: `dropzone` })}>
//       <input {...getInputProps()} />
//       {/* <span>{"📂"}</span> */}
//       <p className="dropZoneText">Drag &amp; Drop or Browse file</p>
//     </div>
//   );
// };

// export default CustomDropZone;

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";

class CustomDropZone extends React.Component {
  render() {
    let {
      fileList,
      removeFile,
      updateFileList,
      name,
      headingMap,
      uploadMsg,
    } = this.props;
    // console.log(fileList, "soujuuu");
    return (
      <div style={{ textAlign: "center" }}>
        <div className="customDropZone">
          <p
            className="font-family-montserrat-semi-bold customdropcontent"
            style={{ marginBottom: "0.5px" }}
          >
            {headingMap[name]}
          </p>
          <DropZone
            listLen={Object.keys(fileList[name]).length}
            updateFileList={updateFileList}
            name={name}
            fileList={fileList}
          />
        </div>
        <div>
          {Object.keys(fileList[name]).map((val) => (
            <div key={val}>
              <span
                style={{
                  fontSize: "10px",
                  color: "#427cbc",
                  marginRight: "10px",
                }}
              >
                {val}
              </span>
              {fileList[name][val] !== "#empty" &&
                uploadMsg !== "files uploaded" && (
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    className="fw-bold"
                    onClick={() => removeFile(name, val)}
                  >
                    <MdDelete />

                    {/* {"X"} */}
                  </span>
                )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList, listLen, name, fileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(name, file);
    });
  }, listLen);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      {/* <span>{"📂"}</span> */}
      <p className="dropZoneText">Drag &amp; Drop or Browse file</p>
    </div>
  );
};

export default CustomDropZone;

import React, { useState, useEffect } from "react";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import FormCompPro from "../../component/subcompo/sections-quote/FormCompPro";
// import FormUnderQues from "../../component/subcompo/sections-quote/FormUnderQues";
import FormWorkerComp from "../../component/subcompo/sections-quote/FormWorkerComp";
import FormCmprQuote from "../../component/subcompo/sections-quote/FormCmprQuote";
import FormPayment from "../../component/subcompo/sections-quote/FormPayment";
import {
  IoCheckmarkDoneCircle,
  IoCloudDone,
  IoLocation,
} from "react-icons/io5";
import { BsBuildingAdd } from "react-icons/bs";
import { versionNumber } from "../../config";
import { Auth } from "aws-amplify";
import { logout } from "../../utils/common";
import HeaderSubPart from "../../component/common/HeaderSubPart";
import SalesManager from "../../component/pages/SalesManagerFlow/SalesManager";
import HeaderMainSubPart from "../../component/common/HeaderMainSubPart";
import {
  MdFileDownloadDone,
  MdOutlinePriceCheck,
  MdOutlineRequestPage,
} from "react-icons/md";

const stages = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
};

let isMobile = window.innerWidth < 992;
const windowPath = window.location.pathname.toLowerCase().includes("/quote");

function TabNavItem(props) {
  const Icon = props.icon;
  let currProspect = sessionStorage.getItem("currProspect");
  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }
  return (
    <Nav.Item>
      <Nav.Link
        eventKey={props.eventKey}
        className={props.disabled ? "disabled" : ""}
        onClick={props.onClick}
      >
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            {/* <img
              src={props.image}
              width="30px"
              height="30px"
              alt={props.label}
            /> */}
            {Icon && <Icon size={30} color="#ffc423" />}
            <p
              className={
                "sidebar-stagename mb-0 ml-2 " + (props.active ? "active" : "")
              }
            >
              {props.label}
            </p>
          </div>
          <div className="ml-auto">
            {props.dash ? (
              <MdFileDownloadDone className="tick" color="#ffc423" />
            ) : (
              <MdFileDownloadDone
                className={props.completed ? "tick" : "tick disabled"}
                color="#ffc423"
              />
              // <img
              //   src={require("../../images/checkmark.png")}
              //   width={"15px"}
              //   className={props.completed ? "tick" : "tick disabled"}
              //   alt="Completed"
              // />
            )}
          </div>
        </div>
      </Nav.Link>
    </Nav.Item>
  );
}

function SideTab(props) {
  let {
    updatePopUpData,
    updateCurrentFormStage,
    updateMailCampaignUser,
  } = props;
  let formStage = sessionStorage.getItem("formStage");
  let currProspect = sessionStorage.getItem("currProspect");
  let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    ? true
    : false;
  let [isLoggedIn, setLoggedInStatus] = useState(false);
  let [group, setGroup] = useState([]);

  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }

  if (!formStage || formStage === "undefined" || formStage === "null") {
    formStage = "one";
    sessionStorage.setItem("formStage", formStage);
  }

  let stageNumber = stages[formStage];

  let tabLinks;
  tabLinks = [
    {
      label: "Company Information",
      image: require("../../images/companyProfilenewchange.png"),
      icon: BsBuildingAdd,
    },
    {
      label: "Company Location(s)",
      image: require("../../images/locup.png"),
      icon: IoLocation,
    },
    // {
    //   label: "Initial Pricing",
    //   image: require("../../images/initialP.png"),
    // },
  ];

  if (!flagPreUnderWriter) {
    tabLinks.push(
      ...[
        {
          label: "Initial Pricing",
          image: require("../../images/Quotenewchange.png"),
          icon: MdOutlineRequestPage,
        },
        // { label: "View Proposal", image: require("../../images/bind2.png") },
      ]
    );
  }

  const SalesManagerTabLink = {
    label: "Sales Manager",
    image: require("../../images/bind2.png"),
    eventKey: "salesManager",
    disabled: stageNumber < 3,
    completed: stageNumber > 2,
  };

  if (!props.currentFormStage) {
    updateCurrentFormStage(stageNumber, stageNumber);
  }
  for (let i = 0; i < tabLinks.length; i++) {
    let tabLink = tabLinks[i];
    tabLink.eventKey = Object.keys(stages)[i];
    // Disable if this stage is later than the current stage.
    tabLink.disabled = i >= stageNumber;
    tabLink.onClick = () => {
      updateCurrentFormStage(i + 1, stageNumber);
    };
    // Completed if stateNuber is more than this stage.
    tabLink.completed = stageNumber > i + 1;
    if (isHistoryVariant) {
      if (i < 4) {
        tabLink.disabled = true;
        tabLink.completed = false;
        tabLink.dash = true;
      }
    }
    // Active tab if stageNumber equals this stage.
    tabLink.active = stageNumber === i + 1;
  }

  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn, groups;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
        groups =
          isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      } catch (error) {}
      if (isLoggedIn) {
        setLoggedInStatus(true);
        if (groups) {
          setGroup(groups);
        }
      }
    }
    checkLoggedInStatus();
  }, []);

  const NavigationSubcontent = (
    <Nav.Item>
      <Nav.Link>
        <HeaderSubPart />
      </Nav.Link>
    </Nav.Item>
  );
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={formStage}>
      <div
        className={`row ${windowPath ? "position-relative" : ""} no-gutters`}
      >
        {!isMobile ? (
          <Col
            md="auto"
            className="newquote-sidebar d-flex flex-column justify-content-between"
          >
            <Nav className="flex-column">
              <Nav.Item>
                <Nav.Link className="sidebar-heading" disabled>
                  <span>
                    To receive your personalized quote in a few easy steps,
                    please submit the information on the following screens.
                  </span>
                </Nav.Link>
              </Nav.Item>
              {tabLinks.map((tabLink) => (
                <TabNavItem {...tabLink} />
              ))}
              {false &&
                isLoggedIn &&
                group.includes(process.env.REACT_APP_SALES_GROUP) && (
                  <>
                    <hr style={{ width: "90%", backgroundColor: "grey" }} />
                    <Nav.Item>
                      <Nav.Link
                        className="sidebar"
                        rel="noopener noreferrer"
                        onClick={() => {
                          window.location.href = "/SalesDashboard";
                        }}
                      >
                        <div className="d-flex flex-row align-items-center">
                          <img
                            width="50"
                            height="50"
                            src={require("../../images/black-card-1.png")}
                            label="dashboard"
                          />
                          <p className="ml-4" style={{ alignSelf: "flex-end" }}>
                            Your Dashboard
                          </p>
                          <div className="ml-auto mr-2 mb-3" disabled></div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              {group.includes(process.env.REACT_APP_SALES_MANAGER_GROUP) && (
                <>
                  <hr style={{ width: "90%", backgroundColor: "grey" }} />
                  <TabNavItem {...SalesManagerTabLink} />
                </>
              )}
            </Nav>
            <div className="ml-4">{/*versionNumber*/}</div>
          </Col>
        ) : (
          <nav className="navwidth newquote-sidebar bg-white">
            <input
              type="checkbox"
              id="menu"
              name="menu"
              className="m-menu__checkbox bg-white"
            />
            <label className="m-menu__toggle" htmlFor="menu">
              <svg
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="butt"
                strokeLinejoin="arcs"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </label>
            <label className="m-menu__overlay" htmlFor="menu"></label>
            <div className="m-menu">
              <div className="m-menu__header">
                <label className="m-menu__toggle" htmlFor="menu">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="butt"
                    strokeLinejoin="arcs"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </label>
                <span></span>
              </div>
              <Nav.Item>
                <Nav.Link className="sidebar-heading" disabled>
                  <span>
                    To receive your personalized quote in a few easy steps,
                    please submit the information on the following screens.
                  </span>
                </Nav.Link>
              </Nav.Item>
              {tabLinks.map((tabLink) => (
                <TabNavItem {...tabLink} />
              ))}
              {/* {isLoggedIn && group.includes(process.env.REACT_APP_SALES_GROUP) && (
                <>
                  <hr style={{ width: "90%", backgroundColor: "grey" }} />
                  <Nav.Item>
                    <Nav.Link
                      className="sidebar"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      href={"/SalesDashboard"}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      <div className="d-flex flex-row align-items-center">
                        <img
                          width="50"
                          height="50"
                          src={require("../../images/black-card-1.png")}
                          label="dashboard"
                        />
                        <p className="ml-4" style={{ alignSelf: "flex-end" }}>
                          Your Dashboard
                        </p>
                        <div className="ml-auto mr-2 mb-3" disabled></div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              {<TabNavItem {...SalesManagerTabLink} />} */}
              {/* <hr
                style={{
                  width: "90%",
                  backgroundColor: "grey",
                }}
              /> */}
              {/* {NavigationSubcontent} */}
            </div>
          </nav>
        )}
        <Col className="newquote-content">
          <Tab.Content>
            <HeaderMainSubPart />
            <Tab.Pane eventKey="one">
              <FormCompPro updateMailCampaignUser={updateMailCampaignUser} />
            </Tab.Pane>
            <Tab.Pane eventKey="two">
              <FormWorkerComp updatePopUpData={updatePopUpData} />
            </Tab.Pane>
            <Tab.Pane eventKey="three">
              <FormCmprQuote updatePopUpData={updatePopUpData} />
            </Tab.Pane>
            <Tab.Pane eventKey="salesManager">
              <SalesManager updatePopUpData={updatePopUpData} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </div>
    </Tab.Container>
  );
}

export default SideTab;

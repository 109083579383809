import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

class Allques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: this.props.quesList,
    };
  }

  componentDidUpdate() {
    if (this.state.quesList !== this.props.quesList) {
      this.setState({ quesList: this.props.quesList });
    }
  }

  handleKeyDown = (event) => {
    // Prevent the input of numeric characters
    if (event.key === '-') {
      event.preventDefault();
    }
  };


  createTable = () => {
    const { quesList } = this.state;
    const { updateQuesList, defaultChecked } = this.props;

    let table = [];

    for (let i = 0; i < quesList.length; i++) {
      let checkedYes = false,
        checkedNo = false;
      if (defaultChecked) {
        if (quesList[i].response) {
          checkedYes = true;
        } else {
          checkedNo = true;
        }
      } else {
        if (quesList[i].response === true) {
          checkedYes = true;
        } else if (quesList[i].response === false) {
          checkedNo = true;
        }
      }

      //logic to skip additional questions on the basis of value selected in previous question
      if (quesList[i]?.displayQues === "false") continue;

      //logic to display specific TD tag
      let showFirstOrSecond = quesList[i].showRemarks ? "second" : "first";

      table.push(
        <tr key={i}>
          <td className={"tableQuesCol" + " " + `trow${i}`}>
            <b>{quesList[i].ques}</b>
            {quesList[i].hiddenText &&
              (quesList[i]?.response || quesList[i]?.remarks) && (
                <span className="color-blue">
                  <b> {quesList[i].hiddenText}</b>
                </span>
              )}
          </td>
          {showFirstOrSecond === "first" && (
            <td
              className={
                quesList[i].showRemarks ? "customtableAns" : "tableAns"
              }
            >
              <div className="d-flex align-right ml-10">
                {quesList[i]?.type === "radio" && (
                  <>
                    <Form.Check
                      inline
                      id={`inputY-${i}`}
                      value="Y"
                      name={quesList[i].name}
                      label={quesList[i]?.labels?.[0] || "Yes"}
                      checked={checkedYes}
                      type="radio"
                      onChange={(e) => updateQuesList(e, "response", true)}
                    />
                    <Form.Check
                      inline
                      id={`inputN-${i}`}
                      value="N"
                      name={quesList[i].name}
                      checked={checkedNo}
                      label={quesList[i]?.labels?.[1] || "No"}
                      type="radio"
                      onChange={(e) => updateQuesList(e, "response", false)}
                    />
                  </>
                )}

                {quesList[i].type === "dropdown" && (
                  <>
                    <select
                      className="selectstyle"
                      onChange={(e) => {
                        console.log(e.target.value, "soujuuu", quesList[i].response);
                        updateQuesList(e, "response", e.target.value);
                      }}
                      value={quesList[i].response}
                      id={`dropdown-${i}`}
                    >
                      {quesList[i].labels.map((v, i) => {
                        return (
                          <option value={v.value} key={i}>
                            {v.display}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </div>
            </td>
          )}
          {showFirstOrSecond === "second" && (
            <td className="tableRemarks align-right">
              {quesList[i].type === "percent" && (
                <NumberFormat
                  id={`inputNumber-${i}`}
                  className={"ques-input"}
                  maxLength="6"
                  suffix={"%"}
                  placeholder={"0.00%"}
                  value={
                    quesList[i].remarks && quesList[i].remarks !== "none"
                      ? quesList[i].remarks
                      : "" || ""
                  }
                  decimalScale={2}
                  onChange={(e) => {
                    updateQuesList(e, "remarks", e.target.value, i);
                  }}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />
              )}
              {quesList[i].type === "number" && (
                <div className="align-right">
                  <NumberFormat
                    id={`remarks-${i}`}
                    value={
                      quesList[i].remarks && quesList[i].remarks !== "none"
                        ? quesList[i].remarks
                        : ""
                    }
                    type="text"
                    className="ques-input"
                    onChange={(e) => {
                      updateQuesList(e, "remarks", e.target.value);
                    }}
                    placeholder="0"
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  />
                </div>
              )}
            </td>
          )}
        </tr>
      );
    }
    return table;
  };

  render() {
    let { clearAll, saveQuestionsResponse, className } = this.props;

    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;
    return (
      <table className={`table table-borderless table-undrques ${className}`}>
        {this.props.header && (
          <thead>
            <tr>
              <th colSpan={3} className="tableQuesCol">
                <div className="d-flex justify-content-between">
                  <div
                    className="pink-header font-family-montserrat-bold"
                    style={{ fontSize: "x-large" }}
                  >
                    Underwriting Questions
                  </div>
                  <div>
                    {flagPreUnderWriter && false && (
                      <button
                        className="btn clear-btn btn-dark mr-2"
                        style={{
                          width: "max-content",
                          boxShadow: "0px 0px 9px 2px grey",
                          border: "1px solid orange",
                          backgroundColor: "gray",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          saveQuestionsResponse();
                        }}
                      >
                        Save
                      </button>
                    )}
                    <button
                      className="btn clear-btn btn-dark"
                      style={{
                        width: "max-content",
                        boxShadow: "0px 0px 9px 2px grey",
                        border: "1px solid orange",
                        backgroundColor: "gray",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        clearAll();
                      }}
                    >
                      Clear All
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        )}
        <tbody>{this.createTable()}</tbody>
      </table>
    );
  }
}
export default Allques;

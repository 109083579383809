import React from "react";

const EMTY_OBJ = {
  title: "",
  children: <></>,
  acceptBtn: "",
  rejectBtn: "",
  acceptBtnCallback: () => {},
  rejectBtnCallback: () => {},
  show: false,
};

class Popup extends React.Component {
  render() {
    let { popupData, updatePopUpData, dashboardstyle } = this.props;

    let {
      show,
      title,
      children,
      acceptBtn,
      rejectBtn,
      acceptBtnCallback,
      rejectBtnCallback,
      disableNoButton,
    } = popupData;

    // Determine the class name based on the dashboardstyle prop
    const popupClassName = show ? "popup d-block" : "popup d-none";
    const popupContainerClassName = dashboardstyle
      ? "custom-popup-container"
      : "popup-container";

    return (
      <div className={popupClassName}>
        <div className={popupContainerClassName}>
          <h5 className="header-text">{title}</h5>
          {/* {children && <div className="content">{children}</div>} */}

          <div className="d-flex justify-content-center mt-3">
            <div className="d-flex w-50 justify-content-center">
              <button
                className="btn upload-docs-action"
                onClick={() => {
                  if (acceptBtnCallback) {
                    acceptBtnCallback();
                  } else {
                    console.log("popup closed");
                  }
                  updatePopUpData(EMTY_OBJ);
                }}
              >
                {dashboardstyle ? "Yes" : acceptBtn || "OK"}
              </button>

              {(dashboardstyle || disableNoButton) && (
                <button
                  className="btn upload-docs-action"
                  onClick={() => {
                    if (rejectBtnCallback) {
                      rejectBtnCallback();
                    } else {
                      console.log("popup closed");
                    }
                    updatePopUpData(EMTY_OBJ);
                  }}
                >
                  {dashboardstyle && "No"}
                </button>
              )}
              {/* {!disableNoButton && (
                <button
                  className="btn upload-docs-action"
                  onClick={() => {
                    if (rejectBtnCallback) {
                      rejectBtnCallback();
                    } else {
                      console.log("popup closed");
                    }
                    updatePopUpData(EMTY_OBJ);
                  }}
                >
                  No
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;

import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import moment from "moment";
function UpdateTextField(props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <textarea
      rows="1"
      style={{
        width: "100%",
        borderRadius: "4px",
      }}
      value={value || ""}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        let emod = e.target.value;
        let sff = e.target.value;
        let dataToSave = {};

        dataToSave.emod = emod;
        dataToSave.sff = sff;
        dataToSave.domain = props.rowDataID;

        dataToSave && props.callback(dataToSave);
      }}
    ></textarea>
  );
}
export default UpdateTextField;

import React, { Component } from "react";
import Navbar from "../component/common/Navbar";
import Loader from "../component/common/Loader";
import SideTab from "./common/SideTab";
import Header from "../component/common/Header";
import Popup from "../component/common/Popup";
import Stats2 from "../component/subcompo/sections-home/Stats2";
import QuotePageBanner from "../component/subcompo/sections-home/QuotePageBanner";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";
import axios from "axios";
import { awsUrl } from "../config";
const numeral = require("numeral");

class NewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
    };
  }

  async componentDidMount() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("sales");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
      "hotmail",
      "pacbell",
      "att",
      "sbcglobal",
      "windstream",
      "verizon",
      "comcast",
      "ampcocontracting",
    ];
    let domain;
    if (user) {
      let tempBusinessName = user.split("@")[1].split(".");
      tempBusinessName.pop();
      let business_name = tempBusinessName.join(".");
      if (generalDomainNames.includes(business_name)) {
        domain = user.split("@")[0];
      } else {
        domain = business_name;
      }
      let currDomain = sessionStorage.getItem("domain");
      let timestamp = Math.floor(Date.now()).toString();
      sessionStorage.setItem("visitTimestamp", timestamp);
      sessionStorage.setItem("landingEmail", user);
      this.setState({ mailCampaignUserCheck: true });
      await axios
        .get(awsUrl + "/api/getUserDataStatus/" + user + "/quote")
        .then(async (response) => {
          let requestBody = {};
          if (response.data) {
            let carrierBasedData = response.data.carrierBasedData;
            let currProspect = {},
              sortKeyList = [],
              quoteData = { uuid: "", date: "" },
              address = [];
            for (let carrier in carrierBasedData) {
              for (let line in carrierBasedData[carrier]) {
                let lineObj = carrierBasedData[carrier][line];
                let childrenLoc = {};

                let uwQues = lineObj.uw_ques;

                let companyProfile = {
                  companyName: { value: lineObj.company_name },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  fein: { value: lineObj.fein },
                  emailId: { value: lineObj.user_email_id },
                  phoneNumber: { value: lineObj.phone_number },
                  primaryContact: {
                    value: lineObj.contact_person,
                  },
                  effectiveDate: {
                    value: lineObj.effective_date,
                  },
                  healthQues: { value: lineObj.healthQues },
                };

                let adminFeeTemp = lineObj.carrier_location_data.admin_fee;
                let discountData = lineObj.discountData;

                if (discountData && discountData.value) {
                  if (discountData.type === "percent") {
                    let valueToSub = numeral(adminFeeTemp)
                      .multiply(discountData.value)
                      .divide(100)
                      .value();
                    adminFeeTemp = numeral(adminFeeTemp)
                      .subtract(valueToSub)
                      .value();
                  } else {
                    adminFeeTemp = discountData.value;
                  }
                }

                let quoteFactors = {
                  ficaCharge: lineObj.carrier_location_data.fica,
                  futaCharge: lineObj.carrier_location_data.futa,
                  sutaCharge: lineObj.carrier_location_data.suta,
                  teap:
                    lineObj.carrier_location_data
                      .total_estimated_annual_premium,
                  total: lineObj.carrier_location_data.total,
                  adminFee: adminFeeTemp,
                  payroll: 0,
                };

                let indicationCost = lineObj.carrier_location_data.total;

                let emodStatesData = lineObj.emodStatesData;

                sortKeyList.push(lineObj.uuid_carrier);
                quoteData.date = lineObj.date;
                quoteData.uuid = lineObj.uuid_carrier;
                address.push(lineObj.uuid_carrier.split("+")[1]);

                for (let prospectAddressesIndx in lineObj.prospect_addresses) {
                  let prospectAddressesObj =
                    lineObj.prospect_addresses[prospectAddressesIndx];

                  let rand = Math.random();
                  childrenLoc[prospectAddressesIndx] = {
                    cityName: {},
                    classCodesInfo: {},
                    key: rand,
                    number: prospectAddressesObj.addressId,
                    state: { value: prospectAddressesObj.stateCode },
                    zipcode: {},
                  };
                  for (let classCodesIndx in prospectAddressesObj.classCodes) {
                    var formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
                    let classCodesObj =
                      prospectAddressesObj.classCodes[classCodesIndx];
                    childrenLoc[prospectAddressesIndx]["classCodesInfo"][
                      classCodesIndx
                    ] = {
                      classCodeDescription: {
                        value: `${classCodesObj.code}: ${classCodesObj.description}`,
                      },
                      ft: { value: classCodesObj.ft },
                      pt: { value: classCodesObj.pt },
                      payroll: {
                        value: formatter.format(classCodesObj.payroll),
                      },
                    };
                    quoteFactors.payroll += numeral(
                      classCodesObj.payroll
                    ).value();
                  }
                }
                currProspect = {
                  childrenLoc,
                  companyProfile,
                  uwQues,
                  emodStatesData,
                };
                sessionStorage.setItem(
                  "currProspect",
                  JSON.stringify(currProspect)
                );
                sessionStorage.setItem(
                  "childrenLoc",
                  JSON.stringify(childrenLoc)
                );
                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
                sessionStorage.setItem("address", JSON.stringify(address));
                sessionStorage.setItem("formStage", "three");
                sessionStorage.setItem("domain", domain);
                sessionStorage.setItem(
                  "quoteFactors",
                  JSON.stringify(quoteFactors)
                );
                sessionStorage.setItem("otpVerified", true);
              }
            }
          }
          window.location.href = "./Quote";
        });
    }
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  updateCurrentFormStage = (currentFormStage, stageNumber) => {
    if (
      currentFormStage <= stageNumber &&
      this.state.currentFormStage !== currentFormStage
    ) {
      this.setState({ currentFormStage });
    }
  };

  updateMailCampaignUser = (mailCampaignUser) => {
    if (this.state.mailCampaignUser !== mailCampaignUser) {
      this.setState({ mailCampaignUser });
    }
  };

  render() {
    let isSalespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    )
      ? true
      : false;
    let { popupData, currentFormStage, mailCampaignUser } = this.state;

    return (
      <div className="newquote-mainbody container-fluid">
        {/* <Navbar /> */}
        <div
          className="d-flex justify-content-between negative-margin-bottom"
          style={{ backgroundColor: "white" }}
        >
          <Header />
          {/* <Stats2 text2={true} /> */}
        </div>
        {(checkMailCampaignUser() || mailCampaignUser) &&
          !isSalespersonMailCampaign &&
          currentFormStage === 1 && <QuotePageBanner />}
        <SideTab
          updatePopUpData={this.updatePopUpData}
          updateCurrentFormStage={this.updateCurrentFormStage}
          updateMailCampaignUser={this.updateMailCampaignUser}
          currentFormStage={currentFormStage}
        />
        <Loader />
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </div>
    );
  }
}

export default NewQuote;

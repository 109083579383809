import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";

class CustomDropZoneDashborad extends React.Component {
  render() {
    let { fileList, updateFileList, removeFile, uploadMsg } = this.props;
    let listToShow = [];

    for (let fileName in fileList) {
      if (fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded") {
        listToShow.push(
          <div className="d-flex">
            <li className="m-3 d-flex justify-content-between" key={fileName}>
              <span>{fileName}</span>
            </li>
            <div className="d-flex justify-content-center align-items-center">
              {fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded" && (
                <span
                  className="fw-bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFile(fileName)}
                >
                  <MdDelete size={30} color="red" />
                </span>
              )}
            </div>
          </div>
        );
      }
    }
    console.log("Suraj listtoshow", listToShow)

    return (
      <div className="customDropZone-dashborad" style={{ width: "420px" }}>
        <DropZone updateFileList={updateFileList} />
        {listToShow?.length > 0 ? (
          // <ul style={{ fontSize: "12px" }}>{listToShow}</ul>
          <p className="dropZoneText">{listToShow.length} Files Selected</p>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(file);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className="dropZoneText">Drag &amp; drop or browse to select file</p>
    </div>
  );
};

export default CustomDropZoneDashborad;

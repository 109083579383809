import React, { forwardRef, useState, useRef } from "react";

import MaterialTable from "material-table";

import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function MaterialTableContainer(props) {
  const myRefcampaignedTab = useRef();
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);
  let { isLoadingCampaigned, rowDataList, showDetailPanel, columns } = props;

  const fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };
  const scrollLeftHandler = () => {
    const tableContainerDiv =
      myRefcampaignedTab.current.children[0].children[2].children[1].children[0]
        .children[0];
    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        setScrollLeft(false);
        setScrollRight(true);
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        setScrollRight(true);
      }
    }
  };
  const scrollRightHandler = () => {
    const tableContainerDiv =
      myRefcampaignedTab.current.children[0].children[2].children[1].children[0]
        .children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      setScrollLeft(true);
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
      setScrollRight(false);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {scrollLeft && (
          <button className="ChevronLeft" onClick={scrollLeftHandler}>
            &lt;
          </button>
        )}
        {scrollRight && (
          <button className="ChevronRight" onClick={scrollRightHandler}>
            &gt;
          </button>
        )}
        <div ref={myRefcampaignedTab}>
          <MaterialTable
            isLoading={isLoadingCampaigned}
            icons={tableIcons}
            data={rowDataList}
            columns={columns}

            options={{
              stickyHeader: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              doubleHorizontalScroll: true,
              filtering: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [
                10,
                25,
                50,
                75,
                100,
                rowDataList.length ? rowDataList.length : 0,
              ],
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#ffc423",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "2px 10px 2px 0px",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
            detailPanel={
              showDetailPanel
                ? [
                  {
                    tooltip: "Client info",
                    icon: () => <KeyboardArrowRightIcon />,
                    render: (rowData) => {
                      let headers = [],
                        dataRows = [];
                      let header = rowData?.detailPanelData?.header;
                      let data = rowData?.detailPanelData?.data;

                      for (let head of header) {
                        headers.push(
                          <TableCell scope="row">{head}</TableCell>
                        );
                      }

                      let index = 0;
                      for (let row of data) {
                        index++;
                        let dataRow = [];
                        for (let key in row) {
                          dataRow.push(
                            <TableCell scope="row">{row[key]}</TableCell>
                          );
                        }
                        dataRows.push(
                          <TableRow key={index}>{dataRow}</TableRow>
                        );
                      }

                      return (
                        <div style={{ padding: "25px" }}>
                          <TableContainer component={Paper}>

                            <Table
                              size="small"
                              className="sub_tab1"
                              id="pre_campaigned_subtab"
                            >
                              <TableHead
                                style={{
                                  background: "#00000066",
                                  color: "whitesmoke",
                                }}
                              >
                                <TableRow>{headers}</TableRow>
                              </TableHead>
                              <TableBody>{dataRows}</TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      );
                    },
                  },
                ]
                : false
            }
          />
        </div>
      </div>
    </>
  );
}

export default MaterialTableContainer;

import React from "react";
import LearnMoreAboutUs from "./LearnMoreAboutUs";
import bannerStamps from "../../../images/Grouplogos.png";
import { div } from "react-dom-factories";

const TopImageTwo = (props) => {
  return (
    <div className="home-banner containerfluid">
      <div className="banner-container">
        <div className="white-box"></div>
        <div className="zonefirst justify-content-center">
          <div className="contact-form-client">
            <LearnMoreAboutUs
              domain={props.domain}
              user={props.user}
              campaignName={props.campaignName}
              handleHomeGetQuoteClicked={props.handleHomeGetQuoteClicked}
            />
          </div>
          <div className="headers-companies col-lg-4 col-12 col-md-2">
            <div className="header-sompany-logo-style">
              <h1> Specializing in Long Term Care</h1>
              <h5>
                Assisted Living Centers | Skilled Nursing Facilites |
                Independent Living
              </h5>
            </div>
            <div className="image-background-banner">
              <div className="image-and-logo">
                <img
                  src={bannerStamps}
                  style={{ height: "90%" }}
                  alt=""
                  className="bannerStamps"
                />
              </div>
              <div className="bannerStampText">
                <h5>
                  Engage is in the top 3% of all PEOs in the country with these
                  credentials
                </h5>
              </div>
            </div>
          </div>
          <div className="image-banner-style col-lg-4 col-12">
            <img
              style={{ height: "100%" }}
              src={props.twoPeopleImage}
              alt="banner-two-people-imag"
              className="banner-two-people-image"
            />
          </div>
        </div>
      </div>

      {/* <div className="banner-content-container">
        <div className="col-lg-4 banner-form-container">
          <div className=" d-flex">
            <LearnMoreAboutUs
              domain={props.domain}
              user={props.user}
              campaignName={props.campaignName}
              handleHomeGetQuoteClicked={props.handleHomeGetQuoteClicked}
            />
          </div>
        </div>
        <div className="banner-images-container">
          <div className="banner-text-box">
            <div>
              <h1>
                Specializing in <br /> Long Term Care
              </h1>
              <h5>
                Assisted Living Centers | Skilled Nursing Facilites |
                Independent Living
              </h5>
            </div>
            <div>
              <div className="banner-stamps-text-container">
                <div>
                  <img src={bannerStamps} alt="" className="bannerStamps" />
                </div>
                <div>
                  <div className="bannerStampText">
                    <h5>
                      Engage is in the top 3% of all PEOs in the country with
                      these credentials
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-people-image-box">
            <img
              src={props.twoPeopleImage}
              alt=""
              className="banner-two-people-image"
            />
          </div>
        </div>
      </div> */}
      {/* <div className="et_pb_bottom_inside_divider"></div> */}
    </div>
  );
};

export default TopImageTwo;

import React, { useEffect, useState, useRef } from "react";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTableContainer from "../../common/MaterialTableContainer/MaterialTableContainer";
import parse from "html-react-parser";
import { CiLinkedin } from "react-icons/ci";
import axios from "axios";
import { awsUrl2, awsUrl, awsUrl3 } from "../../../config";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Header from "../../common/Header";
import EngagedTabMUI from "../../common/MaterialTableContainer/EngagedTabMUI";
import TextField from "../../common/TextField";
import UpdateTextField from "../../common/UpdateTextField.jsx";
import { handleUWCommentHistoryButtonClick } from "../../../utils/dashboard";
import Tooltip from "@material-ui/core/Tooltip";
import DialogBox from "../../common/DialogBox.jsx";
import { Modal } from "react-bootstrap";
import { createCommentForDownload } from "../../common/dashboard_helper.js";
import DropZoneDashboard from "../../subcompo/PreUwUpload/DropZoneDashboard.jsx";
import { FaRegHandshake } from "react-icons/fa6";
import { span } from "react-dom-factories";

const {
  DownloadDashboardFiles,
} = require("../../subcompo/DownloadDashboardFiles.jsx");

const statusNameMapping = {
  "Opened Email": "Opened Email",
  "Quote Generated": "Initial Pricing",
  "UW Questions": "Locations and Payroll",
  "Company Profile": "Company Information",
};

const dateSort = (a, b, field) => {
  let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
  let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
  if ((!momentA.isValid() && momentB.isValid()) || momentA.isBefore(momentB)) {
    return -1;
  } else if (
    (momentA.isValid() && !momentB.isValid()) ||
    momentA.isAfter(momentB)
  ) {
    return 1;
  } else if (
    (!momentA.isValid() && !momentB.isValid()) ||
    momentA.isSame(momentB)
  ) {
    return 0;
  }
};

const camelCase = (str) => {
  return str
    .trim()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

const appendRemaining = (str) => {
  let len = str.length;
  for (let i = 1; i <= 10 - len; i++) {
    str = "0" + str;
  }
  return str;
};

const updateDiscountData = (rowData) => {
  let requestBody = {
    user_email_id: rowData.userEmail,
    uuid_carrier: rowData.uuid_carrier,
    discountData: rowData.discountData,
  };
  axios
    .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
    .then((res) => {})
    .then((res) => {})
    .catch((err) => console.log("error: ", err));
};

const formatFein = (value) => {
  let fein;
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

const onClickCompanyName = (row, tab_source) => {
  try {
    sessionStorage.clear();
    sessionStorage.setItem("preUnderwriter", "true");

    if (row.disableContinue) sessionStorage.setItem("disableContinue", "true");
    if (row.disableGetQuote) sessionStorage.setItem("disableGetQuote", "true");

    if (row.disableContinue || row.disableGetQuote) {
      sessionStorage.setItem("formStage", "two");
    } else {
      if (row.formStage) {
        sessionStorage.setItem("formStage", row.formStage);
      }
    }

    sessionStorage.setItem("tab_source", tab_source);

    if (row.dealLostOrClosed) {
      sessionStorage.setItem("disableGetQuote", "true");
    }

    if (row.isEngaged) {
      sessionStorage.setItem("preUwEngagedFlag", "true");
    }

    if (row.clientEligibility) {
      sessionStorage.setItem("clientEligibility", row.clientEligibility);
    }

    if (row.preUwFiles) {
      sessionStorage.setItem("preUwFiles", JSON.stringify(row.preUwFiles));
    }

    if (row.currProspectDetails) {
      let {
        companyProfile,
        childrenLoc,
        emodStatesData,
      } = row.currProspectDetails;

      let zywaveCompanyProfile = row.zywaveCompanyProfile;

      let campaignMap;

      for (let loc in childrenLoc) {
        if (childrenLoc[loc].classCodesInfo) {
          for (let cc in childrenLoc[loc].classCodesInfo) {
            if (
              !childrenLoc[loc].classCodesInfo[cc].ft ||
              (childrenLoc[loc].classCodesInfo[cc].ft &&
                !Number(childrenLoc[loc].classCodesInfo[cc].ft.value))
            ) {
              childrenLoc[loc].classCodesInfo[cc] = {
                ...childrenLoc[loc].classCodesInfo[cc],
                ft: {
                  value:
                    zywaveCompanyProfile.employees &&
                    zywaveCompanyProfile.employees.value
                      ? zywaveCompanyProfile.employees.value
                      : 0,
                },
              };
            }
          }
        }
      }

      let compName;

      if (
        companyProfile &&
        companyProfile.company_name &&
        companyProfile.company_name.value
      ) {
        compName = companyProfile.company_name.value;
      }

      if (companyProfile.state.value) {
        companyProfile.state1 = {
          value: companyProfile.state1?.value || companyProfile.state.value,
        };
        companyProfile.state2 = {
          value: companyProfile.state2?.value || companyProfile.state.value,
        };
      }
      if (companyProfile.zip.value) {
        companyProfile.zipCode1 = {
          value: companyProfile.zipCode1?.value || companyProfile.zip.value,
        };
        companyProfile.zipCode2 = {
          value: companyProfile.zipCode2?.value || companyProfile.zip.value,
        };
      }
      if (companyProfile.city.value) {
        companyProfile.cityName1 = {
          value: companyProfile.cityName1?.value || companyProfile.city.value,
        };
        companyProfile.cityName2 = {
          value: companyProfile.cityName2?.value || companyProfile.city.value,
        };
      }
      if (companyProfile.address.value) {
        companyProfile.street1 = {
          value: companyProfile.street1?.value || companyProfile.address.value,
        };
        companyProfile.street2 = {
          value: companyProfile.street2?.value || companyProfile.address.value,
        };
      }

      if (compName) {
        companyProfile = {
          ...companyProfile,
          companyName: {
            value: compName,
          },
          company_name: {
            value: compName,
          },
        };
      }

      companyProfile = {
        ...companyProfile,
      };

      if (
        companyProfile &&
        (!companyProfile.companyName || !companyProfile.companyName.value) &&
        companyProfile.company_name &&
        companyProfile.company_name.value
      ) {
        companyProfile.companyName = companyProfile.company_name;
      }

      if (
        companyProfile &&
        (!companyProfile.phoneNumber || !companyProfile.phoneNumber.value) &&
        companyProfile.contact_number &&
        companyProfile.contact_number.value
      ) {
        companyProfile.phoneNumber = companyProfile.contact_number;
      }

      if (
        companyProfile &&
        companyProfile.contact_name &&
        companyProfile.contact_name.value
      ) {
        let nameList = companyProfile.contact_name.value.split(" ");
        let lastName = nameList.pop();
        let firstName = nameList.join(" ");
        if (lastName) {
          companyProfile.lastName = {
            value: lastName,
          };
        }
        if (firstName) {
          companyProfile.firstName = {
            value: firstName,
          };
        }
      }

      if (
        companyProfile &&
        (!companyProfile.descriptionOfOperations ||
          !companyProfile.descriptionOfOperations.value) &&
        companyProfile.description_of_operations &&
        companyProfile.description_of_operations.value
      ) {
        companyProfile.descriptionOfOperations =
          companyProfile.description_of_operations;
      }

      if (companyProfile && companyProfile.fein && companyProfile.fein.value) {
        let value = companyProfile.fein.value + "";
        value = value.split("-").join("");
        var len = value.length,
          dummystr = "000000000";

        if (len > 0 && len < 9) {
          value = dummystr.slice(0, 9 - len) + "" + value;
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        } else if (len > 9) {
          companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
          companyProfile.fein.error = true;
        } else if (len === 0) {
          companyProfile.fein.value = undefined;
          companyProfile.fein.errMsg = "";
        } else {
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        }
      }

      if (
        companyProfile &&
        (!companyProfile.effectiveDate ||
          (companyProfile.effectiveDate && !companyProfile.effectiveDate.value))
      ) {
        companyProfile = {
          ...companyProfile,
          effectiveDate: {
            value:
              moment().date() === 1
                ? moment()
                : moment(moment().subtract(moment().date() - 1, "days")).add(
                    1,
                    "month"
                  ),
          },
        };
      }

      if (
        companyProfile &&
        (!companyProfile.expectedStartDate ||
          (companyProfile.expectedStartDate &&
            !companyProfile.expectedStartDate.value))
      ) {
        companyProfile = {
          ...companyProfile,
          expectedStartDate: {
            value:
              moment().date() === 1
                ? moment()
                : moment(moment().subtract(moment().date() - 1, "days")).add(
                    1,
                    "month"
                  ),
          },
        };
      }

      let currProspect = {
        childrenLoc,
        companyProfile,
        emodStatesData,
      };

      if (row.uwAmtrustQuesList) {
        currProspect.amtrustQuesList = row.uwAmtrustQuesList;
      }

      if (row.uwQuesList) {
        currProspect.uwQues = row.uwQuesList;
      }

      if (row.uwExpectedResponses) {
        currProspect.uwExpectedResponses = row.uwExpectedResponses;
      }

      if (!("healthQues" in companyProfile)) {
        companyProfile.healthQues = { value: true };
      }

      if (row.preUwStatus) {
        currProspect.preUwStatus = row.preUwStatus;
      }

      sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    }

    sessionStorage.setItem("domain", row.domain);

    if (row.salesID) {
      sessionStorage.setItem("salesID", row.salesID);
    }

    if (row.address) {
      sessionStorage.setItem("address", JSON.stringify(row.address));
    }
    if (row.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(row.quoteData));
    }
    if (
      row.pre_underwriter_status === "uw" ||
      row.pre_underwriter_status === "completed"
    ) {
      sessionStorage.setItem("formStage", "two");
    }
  } catch (error) {
    console.log("++error", error);
  }
};

const formatInCamel = (str) => {
  if (str) {
    return str[0].toUpperCase() + "" + str.slice(1);
  }
  return str;
};

const source = (Source) => {
  if (Source == "hotschedules") {
    return "HotSchedules";
  } else if (Source == "affinity") {
    return "Affinity";
  } else {
    return Source;
  }
};
const tabsMap = {
  tab2: ">500",
  tab1: "<500",
};

const peoTabName = {
  peo: "With PEO",
  peoW: "Without PEO",
};

const getUploadDateFlag = async () => {
  const currentAuthUser = await Auth.currentAuthenticatedUser();
  if (currentAuthUser) {
    let groups =
      currentAuthUser?.signInUserSession?.accessToken?.payload[
        "cognito:groups"
      ];
    if (groups.includes(process.env.REACT_APP_DATA_TEAM)) return true;
    else return false;
  }
};

function PreUnderWriterDashboard() {
  const [
    thousandPlusCampaignedRowDataListpeo,
    setThousandPlusCampaignedRowDataListpeo,
  ] = useState([]);
  const [
    thousandPlusCampaignedRowDataListwithoutpeo,
    setThousandPlusCampaignedRowDataListwithoutpeo,
  ] = useState([]);
  const [
    thousandPlusCampaignedRowDataList,
    setThousandPlusCampaignedRowDataList,
  ] = useState([]);

  const [
    historyThousandPlusCampaignedComment,
    setHistoryThousandPlusCampaignedComment,
  ] = useState({});
  const [
    isLoadingThousandPlusCampaigned,
    setIsLoadingThousandPlusCampaigned,
  ] = useState(true);

  const [
    thousandPlusEngagedRowDataList,
    setThousandPlusEngagedRowDataList,
  ] = useState([]);

  const [
    thousandPlusEngagedRowDataListpeo,
    setThousandPlusEngagedRowDataListpeo,
  ] = useState([]);

  const [
    thousandPlusEngagedRowDataListwithoutpeo,
    setThousandPlusEngagedRowDataListwithpeo,
  ] = useState([]);
  const [
    historyThousandPlusEngagedComment,
    setHistoryThousandPlusEngagedComment,
  ] = useState({});
  const [
    isLoadingThousandPlusEngaged,
    setIsLoadingThousandPlusEngaged,
  ] = useState(true);

  const [interestedRowDataList, setInterestedRowDataList] = useState([]);
  const [interestedRowDataListpeo, setInterestedRowDataListpeo] = useState([]);
  const [
    interestedRowDataListwithoupeo,
    setInterestedRowDataListwithoupeo,
  ] = useState([]);
  const [interestedHistoryComment, setInterestedHistoryComment] = useState({});
  const [
    fiveThousandDomain_messageid_file,
    setFiveThousandDomain_messageid_files,
  ] = useState({});
  const [
    interestedDomain_messageid_file,
    setinterestedDomain_messageid_files,
  ] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const initialTab = localStorage.getItem("selectedTab") || "tab2";
  const [selected, setSelected] = useState(initialTab);
  const [selectedPeo, setSelectedPeo] = useState("peo");
  const [uploadDateFlag, setUploadDateFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [docUploaded, setDocUploaded] = useState(false);
  const [loggedInInfo, setLoggedInInfo] = useState("");

  const getLoggedInInfo = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let loggedUserName =
        sessionData["attributes"]["name"] || sessionData["attributes"]["email"];
      setLoggedInInfo(loggedUserName);
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("selectedTab", selected);
    const PreUwCampaigned1000Plus = () => {
      let userDataList = [];
      try {
        axios
          .post(awsUrl2 + "/api/preUwData/getAllRows", {
            isMoreThanThousandEmp: "true",
            tab: "tab2",
          })
          .then((res) => {
            // console.log("Suraj 32432", res.data);
            let tempObj;
            let rowData;

            let rowDataList = [],
              thousandPlusRowDataList = [];
            let domain_messageid_files = {};
            let historyComment = {};

            let dataFromApi = res.data;

            for (let key in dataFromApi) {
              tempObj = {};
              for (let dataKey in dataFromApi[key]) {
                tempObj[dataKey] = dataFromApi[key][dataKey];
              }
              userDataList.push(tempObj);
            }

            for (let userData of userDataList) {
              let quote_status;
              let step;
              let excluded_status;

              let detailPanelData = {};

              detailPanelData.header = [
                "Contact Name",
                "Phone Number",
                "Email",
                "Job Description",
                "Visit Count",
                "Status",
                // "Campaign Name",
              ];
              detailPanelData.data = [];

              let email_specific_status;
              let landing_email_status, landing_email_get_quote_clicked;

              let emailCount = 0;
              let statusPriorityMap = {
                Submitted: 1,
                "User Submitted": 2,
                "Quote Generated": 3,
                "Uploaded Document": 4,
                "UW Questions": 5,
                // "Opened Email": 6,
                SQL: 7,
                "Company Profile(Get Quote)": 8,
                "Company Profile": 9,
                "Company Information": 9,
                "Initial Pricing": 3,
                "Locations and Payroll": 5,
                "Landing Page": 10,
                "Visited Website": 11,
                "Affinity Logo Clicked": 12,
                "Fourth Logo Clicked": 13,
                "Clicked on Affinity logo": 14,
                "Clicked on Fourth logo": 15,
                "Clicked on the image": 16,
                "Clicked on insure comp logo": 17,
                "Watched Video": 18,
                "Visited LinkedIn": 19,
                "Visited Facebook": 20,
                "Visited Instagram": 21,
                "Accessed Social Media": 22,
                "Opened Email": 90,
                "Not Campaigned": 24,
                "-": 999,
              };

              for (let email in userData.email_specific) {
                emailCount++;
                let element = userData.email_specific[email];
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value
                ) {
                  let emailStatus =
                    userData.email_specific[email].companyProfile.status.value;
                  if (!email_specific_status)
                    email_specific_status =
                      statusNameMapping?.[emailStatus || ""] || emailStatus;
                  else if (
                    statusPriorityMap[emailStatus] <
                    statusPriorityMap[email_specific_status]
                  ) {
                    email_specific_status =
                      statusNameMapping?.[emailStatus || ""] || emailStatus;
                  }
                }
                if (!landing_email_status) {
                  landing_email_status = userData.email_specific[email]
                    .landingPage
                    ? userData.email_specific[email].landingPage
                    : null;
                }

                if (!landing_email_get_quote_clicked) {
                  landing_email_get_quote_clicked = userData.email_specific[
                    email
                  ].homeGetQuote
                    ? userData.email_specific[email].homeGetQuote
                    : null;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }

                let step;
                if (element.companyProfile) {
                  if (element.isUserSubmitted) {
                    step = "User Submitted";
                  } else if (Number(element.proposalGeneratedCount) > 0) {
                    step = "Proposal Generated";
                  } else if (element.quote_status) {
                    if (element.quote_status === "uwportal") {
                      step = "UW Portal";
                    } else if (element.quote_status === "proceed_uw") {
                      step = "Approved";
                    } else if (element.quote_status === "reject") {
                      step = "Rejected";
                    }
                  } else if (Number(element.quoteGeneratedCount) > 0) {
                    step = "Initial Pricing";
                  } else if (element.formStage) {
                    if (element.formStage === "two") {
                      if (element.nonEligibilityList) {
                        step = "Not Eligible";
                      } else {
                        step = "Locations and Payroll";
                      }
                    } else if (element.formStage === "one") {
                      step = "Company Information";
                      if (landing_email_get_quote_clicked) {
                        step += " (Get Quote)";
                      }
                    }
                  } else if (
                    element.visitCount &&
                    Number(element.visitCount) > 0
                  ) {
                    step = "Landing Page";
                  } else if (landing_email_status) {
                    step =
                      landing_email_status === "affinityLogo"
                        ? "Affinity Logo Clicked"
                        : "Fourth Logo Clicked";
                  } else {
                    step = "-";
                  }
                  if (
                    element.companyProfile.status &&
                    element.companyProfile.status.value
                  ) {
                    step =
                      statusPriorityMap[element.companyProfile.status.value] <
                      statusPriorityMap[step]
                        ? statusNameMapping?.[
                            element?.companyProfile?.status?.value || ""
                          ] || element.companyProfile.status.value
                        : step;
                  }
                  detailPanelData.data.push({
                    contactName:
                      element.companyProfile &&
                      element.companyProfile.contact_name ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>{element.companyProfile.contact_name.value}</div>
                          {element?.companyProfile?.LinkedinURL?.value && (
                            <button
                              onClick={() =>
                                window.open(
                                  `https://www.${element?.companyProfile?.LinkedinURL?.value}`,
                                  "_blank"
                                )
                              }
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                padding: 0,
                              }}
                            >
                              <CiLinkedin size={15} color="#427cbc" />
                            </button>
                          )}
                        </span>
                      ) : (
                        ""
                      ),
                    contactNumber:
                      element?.companyProfile?.phoneNumber?.value?.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      ) ||
                      element?.companyProfile?.contact_number?.value?.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      ) ||
                      "",
                    email:
                      (
                        <a
                          href={`mailto:${element?.companyProfile?.emailId?.value}`}
                        >
                          {element?.companyProfile?.emailId?.value}
                        </a>
                      ) || "",
                    jobTitle: element?.companyProfile?.job_title?.value || "",
                    count: element.visitCount || 0,
                    step: step || "-",
                    campaignName: userData.latestCampaign || "", // Adding campaign name here
                    // ispeowith: userData?.ispeowith || ""
                  });
                }
              }

              if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (quote_status) {
                if (quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Initial Pricing";
              } else if (userData.formStage) {
                if (userData.formStage === "one") {
                  step = "Company Information";
                } else {
                  if (excluded_status) {
                    step = "Not Eligible";
                  } else {
                    step = "Locations and Payroll";
                  }
                }
              } else if (
                userData.visitCount &&
                Number(userData.visitCount) > 0
              ) {
                step = "Landing Page";
              } else {
                step = "-";
              }
              if (email_specific_status) {
                step =
                  statusPriorityMap[email_specific_status] <
                  statusPriorityMap[step]
                    ? email_specific_status
                    : step;
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
              };

              if (
                !currProspectDetails?.companyProfile?.fein &&
                !currProspectDetails?.companyProfile?.fein?.value
              ) {
                if (userData?.fein) {
                  currProspectDetails.companyProfile.fein = {
                    value: userData.fein,
                  };
                }
              }

              rowData = {
                visitDate:
                  userData?.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YYYY - hh:mm A"),
                userEmail: userData?.email,
                quoteGeneratedCount: userData?.quoteGeneratedCount || 0,
                proposalGeneratedCount: userData?.proposalGeneratedCount || 0,
                uuid: userData?.uuid,
                phoneNumber: userData?.phoneNumber,
                payroll: userData?.payroll,
                indicationCost: userData?.indicationCost,
                emod:
                  userData.emod && Number(userData.emod)
                    ? parseFloat(userData.emod).toFixed(2)
                    : "-",
                uwEmail: userData?.uwEmail,
                effectiveDate: userData?.effectiveDate
                  ? moment(userData.effectiveDate).format("x")
                  : "",
                contactName: userData?.contactName || "",
                companyName: userData?.companyName || "",
                disableCompanyName: emailCount ? false : true,
                comment: userData?.comment
                  ? typeof userData?.comment === "string"
                    ? [userData?.comment]
                    : userData?.comment
                  : [""],
                domain: userData?.domain,
                visitCount: userData?.visitCount || 0,
                step,
                latestCampaign: userData?.latestCampaign,
                email_specific: userData?.email_specific,
                messageID: userData?.messageID,
                dashboardFiles: userData?.dashboardFiles,
                uploadedDate: userData?.uploadedDate,
                uploadedTimeStamp: userData?.uploadedTimeStamp
                  ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                  : userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YYYY"
                    )
                  : "-",
                currProspectDetails,
                salesID: userData?.domain,
                formStage: userData?.uwFormStage,
                address: userData?.address,
                quoteData: userData?.quoteData,
                state: userData?.state || "-",
                city: userData?.city || "-",
                total_emp: userData?.total_emp || 0,
                salespersonName:
                  userData?.salespersonNameAttr || userData?.salespersonName,
                uwExpectedResponses: userData?.uwExpectedResponses,
                quesList: userData?.quesList,
                amtrustQuesList: userData?.amtrustQuesList,
                uwQuesList: userData?.uwQuesList,
                uwAmtrustQuesList: userData?.uwAmtrustQuesList,
                preUwStatus: userData?.preUwStatus || "approved",
                preUwName: userData?.preUwName,
                isPreUnderwritten: userData?.preUwStatus ? true : false,
                clientEligibility: userData?.clientEligibility,
                zywaveCompanyProfile: userData?.zywaveCompanyProfile,
                website: userData?.companyProfile?.website?.value,
                fein: userData?.fein,
                preUwFiles: userData?.preUwFiles,
                source: userData?.source,
                ispeowith: userData?.ispeowith || "",
                isEngaged: false,
                dealLostOrClosed:
                  userData?.dealData?.status === "true" ||
                  userData?.dealData?.status === "false"
                    ? true
                    : false,
                isMoreThanThousandEmp: userData?.isMoreThanThousandEmp
                  ? true
                  : false,
                preUwSaved: userData?.preUwSaved === "true" ? "Yes" : "No",
                campaignName: userData?.latestCampaign || "",
                _completedDate: userData?.completedDate
                  ? userData?.completedDate &&
                    moment(Number(userData.completedDate))
                      .utcOffset("-0500")
                      .format("M/D/YYYY")
                  : "-",
                Emod: userData?.Emod ? userData?.Emod : "-",
                sff: userData?.sff ? userData?.sff : "-",
              };
              console.log(userData, "userData");

              createCommentForDownload(rowData, "comment");
              createCommentForDownload(rowData, "admincomment");
              createCommentForDownload(rowData, "salescomment");
              createCommentForDownload(rowData, "smcomment");

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              if (!rowData.email_specific) {
                detailPanelData.data.push({
                  contactName: rowData.contactName,
                  contactNumber: rowData.phoneNumber
                    ? rowData.phoneNumber.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      )
                    : "-",
                  email: rowData.userEmail,
                  jobTitle: "",
                  count: rowData.visitCount || 0,
                  step: step || "-",
                  // campaignName: userData.latestCampaign || "", // Adding campaign name here
                });
              }

              rowData.detailPanelData = detailPanelData;

              if (rowData.isMoreThanThousandEmp) {
                thousandPlusRowDataList.push(rowData);
              } else {
                rowDataList.push(rowData);
              }

              domain_messageid_files[rowData.domain] = {};

              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;

              if (rowData.comment) {
                historyComment[rowData.domain] = rowData.comment;
              }
            }

            let withpeo = [],
              withoutpeo = [];
            for (let i = 0; i < thousandPlusRowDataList.length; i++) {
              if (
                thousandPlusRowDataList[i]?.ispeowith &&
                thousandPlusRowDataList[i]?.ispeowith.toLowerCase() !== "no"
              ) {
                withpeo.push(thousandPlusRowDataList[i]);
              } else {
                withoutpeo.push(thousandPlusRowDataList[i]);
              }
            }

            setThousandPlusCampaignedRowDataListpeo(withpeo);
            setThousandPlusCampaignedRowDataListwithoutpeo(withoutpeo);
            setThousandPlusCampaignedRowDataList(thousandPlusRowDataList);
            setHistoryThousandPlusCampaignedComment(historyComment);
            // console.log(thousandPlusRowDataList);
          })
          .catch((error) => {
            setIsLoadingThousandPlusCampaigned(false);
            console.log("error", error);
          })
          .finally(() => {
            setIsLoadingThousandPlusCampaigned(false);
          });
      } catch (error) {
        console.log("error in update data: ", error);
      }
    };

    const PreUwEngaged1000Plus = () => {
      let requestBody = {
        tab: "tab1",
        isMoreThanThousandEmp: "true",
      };
      let engagedHistoryComment = {};
      axios
        .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
        .then((res) => {
          let userDataList = [];
          let tempObj;
          let rowData;

          let rowDataList = [];
          let domain_messageid_files = {};

          let dataFromApi = res.data.data;
          // console.log(dataFromApi);
          for (let key in dataFromApi) {
            tempObj = {};
            for (let dataKey in dataFromApi[key]) {
              tempObj[dataKey] = dataFromApi[key][dataKey];
            }
            userDataList.push(tempObj);
          }

          for (let userData of userDataList) {
            let quote_status;
            let step;
            let excluded_status;

            let detailPanelData = {};

            detailPanelData.header = [
              "Contact Name",
              "Phone Number",
              "Email",
              "Job Description",
              "Visit Count",
              "Status",
              // "Campaign Name",
            ];
            detailPanelData.data = [];

            let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
            let secondFilter =
              userData.client_source === "Hotschedules"
                ? "Hotschedules"
                : "Affinity";

            let email_specific_status;
            let landing_email_status, landing_email_get_quote_clicked;
            let statusPriorityMap = {
              Submitted: 1,
              "User Submitted": 2,
              "Quote Generated": 3,
              "Uploaded Document": 4,
              "UW Questions": 5,
              // "Opened Email": 6,
              SQL: 7,
              "Company Profile(Get Quote)": 8,
              "Company Profile": 9,
              "Company Information": 9,
              "Initial Pricing": 3,
              "Locations and Payroll": 5,
              "Landing Page": 10,
              "Visited Website": 11,
              "Affinity Logo Clicked": 12,
              "Fourth Logo Clicked": 13,
              "Clicked on Affinity logo": 14,
              "Clicked on Fourth logo": 15,
              "Clicked on the image": 16,
              "Clicked on insure comp logo": 17,
              "Watched Video": 18,
              "Visited LinkedIn": 19,
              "Visited Facebook": 20,
              "Visited Instagram": 21,
              "Accessed Social Media": 22,
              "Opened Email": 90,
              "Not Campaigned": 24,
              "-": 999,
            };

            for (let email in userData.email_specific) {
              let db_quote_status = userData.email_specific[email].quote_status;
              let element = userData.email_specific[email];
              let db_excluded =
                userData.email_specific[email].nonEligibilityList;
              if (db_excluded && db_excluded.length > 0) {
                excluded_status = true;
              }

              if (
                userData.email_specific[email].companyProfile &&
                userData.email_specific[email].companyProfile.status &&
                userData.email_specific[email].companyProfile.status.value
              ) {
                let emailStatus =
                  userData.email_specific[email].companyProfile.status.value;
                if (statusNameMapping[emailStatus])
                  email_specific_status = statusNameMapping[emailStatus];
                else if (
                  statusPriorityMap[emailStatus] <
                  statusPriorityMap[email_specific_status]
                ) {
                  email_specific_status =
                    statusNameMapping?.[emailStatus || ""] || emailStatus;
                }
              }

              if (!landing_email_status) {
                landing_email_status = userData.email_specific[email]
                  .landingPage
                  ? userData.email_specific[email].landingPage
                  : null;
              }

              if (!landing_email_get_quote_clicked) {
                landing_email_get_quote_clicked = userData.email_specific[email]
                  .homeGetQuote
                  ? userData.email_specific[email].homeGetQuote
                  : null;
              }

              if (
                (!quote_status && db_quote_status) ||
                (quote_status === "reject" && db_quote_status) ||
                db_quote_status === "proceed_uw"
              ) {
                quote_status = db_quote_status;
              }

              let step;
              if (element.companyProfile) {
                if (element?.companyProfile?.status?.value === "User Submitted")
                  if (Number(element.proposalGeneratedCount) > 0) {
                    step = "Proposal Generated";
                  } else if (element.quote_status) {
                    if (element.quote_status === "uwportal") {
                      step = "UW Portal";
                    } else if (element.quote_status === "proceed_uw") {
                      step = "Approved";
                    } else if (element.quote_status === "reject") {
                      step = "Rejected";
                    }
                  } else if (Number(element.quoteGeneratedCount) > 0) {
                    step = "Initial Pricing";
                  } else if (element.formStage) {
                    if (element.formStage === "two") {
                      if (element.nonEligibilityList) {
                        step = "Not Eligible";
                      } else {
                        step = "Locations and Payroll";
                      }
                    } else if (element.formStage === "one") {
                      step = "Company Information";
                      if (landing_email_get_quote_clicked) {
                        step += " (Get Quote)";
                      }
                    }
                  } else if (
                    element.visitCount &&
                    Number(element.visitCount) > 0
                  ) {
                    step = "Landing Page";
                  } else if (landing_email_status) {
                    step =
                      landing_email_status === "affinityLogo"
                        ? "Affinity Logo Clicked"
                        : "Fourth Logo Clicked";
                  } else {
                    step = "-";
                  }

                if (
                  element.companyProfile.status &&
                  element.companyProfile.status.value
                ) {
                  step =
                    statusPriorityMap[element.companyProfile.status.value] <
                    statusPriorityMap[step]
                      ? element.companyProfile.status.value
                      : step;
                }

                const campaignMapEntries = Object.entries(
                  element.campaignMap || {}
                );
                const lastCampaignEntry =
                  campaignMapEntries.length > 0
                    ? campaignMapEntries[campaignMapEntries.length - 1]
                    : [null, ""];
                const lastCampaignName = lastCampaignEntry[1];

                detailPanelData.data.push({
                  contactName:
                    element.companyProfile &&
                    element.companyProfile.contact_name ? (
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>{element.companyProfile.contact_name.value}</div>
                        {element?.companyProfile?.LinkedinURL?.value && (
                          <button
                            onClick={() =>
                              window.open(
                                `https://www.${element?.companyProfile?.LinkedinURL?.value}`,
                                "_blank"
                              )
                            }
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              padding: 0,
                            }}
                          >
                            <CiLinkedin size={15} color="#427cbc" />
                          </button>
                        )}
                      </span>
                    ) : (
                      ""
                    ),
                  contactNumber:
                    element?.companyProfile?.phoneNumber?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) ||
                    element?.companyProfile?.contact_number?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) ||
                    "",
                  email:
                    (
                      <a
                        href={`mailto:${element?.companyProfile?.emailId?.value}`}
                      >
                        {element?.companyProfile?.emailId?.value}
                      </a>
                    ) || "",
                  jobTitle: element?.companyProfile?.job_title?.value || "",
                  count: element.visitCount || 0,
                  step: step || "-",
                  // campaignName: lastCampaignName || userData.latestCampaign,
                });
              }
            }

            if (userData.isSalespersonSubmitted) {
              step = "Submitted";
            } else if (userData.isUserSubmitted) {
              step = "User Submitted";
            } else if (userData.isSubmitted) {
              step = "Submitted";
            } else if (Number(userData.proposalGeneratedCount) > 0) {
              step = "Proposal Generated";
            } else if (quote_status) {
              if (quote_status === "uwportal") {
                step = "UW Portal";
              } else if (quote_status === "proceed_uw") {
                step = "Approved";
              } else if (quote_status === "reject") {
                step = "Rejected";
              }
            } else if (Number(userData.quoteGeneratedCount) > 0) {
              step = "Initial Pricing";
            } else if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Information";
                if (landing_email_get_quote_clicked) {
                  step += " (Get Quote)";
                }
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "Locations and Payroll";
                }
              }
            } else if (userData.visitCount && Number(userData.visitCount) > 0) {
              step = "Landing Page";
            } else if (landing_email_status) {
              step =
                landing_email_status === "affinityLogo"
                  ? "Affinity Logo Clicked"
                  : "Fourth Logo Clicked";
            } else {
              step = "-";
            }
            if (email_specific_status) {
              step =
                statusPriorityMap[email_specific_status] <
                statusPriorityMap[step]
                  ? email_specific_status
                  : step;
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
            };

            if (!currProspectDetails?.companyProfile?.fein?.value) {
              if (userData?.fein) {
                currProspectDetails.companyProfile.fein = {
                  value: userData.fein,
                };
              }
            }

            const campaignMapEntries = Object.entries(
              userData.campaignMap || {}
            );
            const lastCampaignEntry =
              campaignMapEntries.length > 0
                ? campaignMapEntries[campaignMapEntries.length - 1]
                : [null, ""];
            const lastCampaignName = lastCampaignEntry[1];

            rowData = {
              visitDate:
                userData.timestamp &&
                moment(Number(userData.timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              userEmail: userData.email,
              quoteGeneratedCount: userData.quoteGeneratedCount || 0,
              proposalGeneratedCount: userData.proposalGeneratedCount || 0,
              uuid: userData.uuid,
              phoneNumber: userData.phoneNumber,
              payroll: userData.payroll,
              indicationCost: userData.indicationCost,
              emod:
                userData.emod && Number(userData.emod)
                  ? parseFloat(userData.emod).toFixed(2)
                  : "-",
              uwEmail: userData.uwEmail,
              effectiveDate: userData.effectiveDate
                ? moment(userData.effectiveDate).format("x")
                : "",
              contactName: userData.contactName || "",
              companyName: userData.companyName || "",
              comment: userData?.comment
                ? typeof userData?.comment === "string"
                  ? [userData?.comment]
                  : userData?.comment
                : [""],
              domain: userData.domain,
              visitCount: userData.visitCount || 0,
              step,
              latestCampaign: userData.latestCampaign,
              email_specific: userData.email_specific,
              messageID: userData.messageID,
              dashboardFiles: userData.dashboardFiles,
              uploadedDate: userData.uploadedDate,
              uploadedTimeStamp: userData.uploadedTimeStamp
                ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                : userData.zywave_uploaded_date
                ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              currProspectDetails,
              salesID: userData.domain,
              formStage: userData.uwFormStage,
              address: userData.address,
              quoteData: userData.quoteData,
              state: userData?.state || "-",
              city: userData?.city || "-",
              total_emp: userData?.total_emp || 0,
              salespersonName:
                userData.salespersonNameAttr || userData.salespersonName,
              uwExpectedResponses: userData.uwExpectedResponses,
              uwQuesList: userData.uwQuesList,
              uwAmtrustQuesList: userData.uwAmtrustQuesList,
              preUwStatus: userData.preUwStatus || "approved",
              preUWName: userData.preUWName,
              isPreUnderwritten: userData.preUwStatus ? true : false,
              clientEligibility: userData.clientEligibility,
              zywaveCompanyProfile: userData.zywaveCompanyProfile,
              website: userData?.companyProfile?.website?.value || "",
              fein: userData.fein,
              preUwFiles: userData.preUwFiles,
              source: userData.source,
              isEngaged: true,
              isSubmitted: userData.isSubmitted,
              fileListToStore: userData.fileListToStore,
              ispeowith: userData?.ispeowith || "",
              preUwDate:
                userData.preUwDate &&
                moment(Number(userData.preUwDate))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              preUwSaved: userData?.preUwSaved === "true" ? "Yes" : "No",
              campaignName: lastCampaignName || userData.latestCampaign,
              _completedDate: userData?.completedDate
                ? userData?.completedDate &&
                  moment(Number(userData.completedDate))
                    .utcOffset("-0500")
                    .format("M/D/YYYY")
                : "-",
              sff: userData.sff ? userData.sff : "-",
              Emod: userData.Emod ? userData.Emod : "-",
            };
            console.log(userData.email_specific, "email_specific");
            createCommentForDownload(rowData, "comment");
            createCommentForDownload(rowData, "admincomment");
            createCommentForDownload(rowData, "salescomment");
            createCommentForDownload(rowData, "smcomment");

            let enableContinueSteps = [
              "-",
              "Landing Page",
              // "Company Profile",
              "Opened Email",
              "Accessed Social Media",
              "Watched Video",
              "Visited Website",
              "Company Information",
              "Locations and Payroll",
              "Initial Pricing",
              "Uploaded Document",
              "Declined Ineligible",
              "Landed on Quote Page",
              "Visited LinkedIn",
              "Visited Facebook",
              "Visited Instagram",
              "Clicked on Affinity logo",
              "Clicked on Fourth logo",
              "Clicked on the image",
              "Clicked on insure comp logo",
              "Not Campaigned",
              "SQL",
            ];

            if (!enableContinueSteps.includes(step)) {
              rowData.disableContinue = true;
              if (step !== "UW Questions") rowData.disableGetQuote = true;
            }

            if (rowData.email_specific) {
              Object.values(rowData.email_specific).map((email) => {
                if (
                  email.companyProfile &&
                  email.companyProfile.contact_number &&
                  email.companyProfile.contact_number.value
                ) {
                  email.companyProfile.contact_number.value = appendRemaining(
                    email.companyProfile.contact_number.value.toString()
                  );
                }
              });
            } else {
              if (rowData.phoneNumber)
                rowData.phoneNumber = appendRemaining(
                  rowData.phoneNumber.toString()
                );
            }

            if (!rowData.email_specific) {
              detailPanelData.data.push({
                contactName: rowData.contactName,
                contactNumber: rowData.phoneNumber
                  ? rowData.phoneNumber.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    )
                  : "-",
                email: rowData.userEmail,
                jobTitle: "",
                count: rowData.visitCount || 0,
                step: step || "-",
                // campaignName: rowData.campaignName,
              });
            }

            rowData.detailPanelData = detailPanelData;

            rowDataList.push(rowData);
            domain_messageid_files[rowData.domain] = {};
            if (rowData.messageID)
              domain_messageid_files[rowData.domain]["messageID"] =
                rowData.messageID;
            if (rowData.dashboardFiles)
              domain_messageid_files[rowData.domain]["dashboardFiles"] =
                rowData.dashboardFiles;
            if (rowData.preUwFiles) {
              let _preUwFiles = [];
              rowData.preUwFiles.forEach((ele) => {
                _preUwFiles.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "preUwFiles"
              ] = _preUwFiles;
            }

            if (rowData.fileListToStore) {
              let _fileListToStore = [];
              rowData.fileListToStore.forEach((ele) => {
                _fileListToStore.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "fileListToStore"
              ] = _fileListToStore;
            }

            if (rowData.comment) {
              engagedHistoryComment[rowData.domain] = rowData.comment;
            }
          }

          let withpeo = [],
            withoutpeo = [];
          for (let i = 0; i < rowDataList.length; i++) {
            if (
              rowDataList[i]?.ispeowith &&
              rowDataList[i]?.ispeowith.toLowerCase() !== "no"
            ) {
              withpeo.push(rowDataList[i]);
            } else {
              withoutpeo.push(rowDataList[i]);
            }
          }
          // console.log("Suraj 1234");

          setThousandPlusEngagedRowDataListpeo(withpeo);
          setThousandPlusEngagedRowDataListwithpeo(withoutpeo);
          setThousandPlusEngagedRowDataList(rowDataList);
          setFiveThousandDomain_messageid_files(domain_messageid_files);
          setHistoryThousandPlusEngagedComment(engagedHistoryComment);
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoadingThousandPlusEngaged(false);
        })
        .finally(() => {
          setIsLoadingThousandPlusEngaged(false);
        });
    };

    const PreUwInterestedTab = () => {
      let requestBody = {
        tab: "tab1",
        avoidIsMoreThanThousandEmp: "true",
      };
      let interestedHistoryComment = {};
      axios
        .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
        .then((res) => {
          let userDataList = [];
          let tempObj;
          let rowData;
          let rowDataList = [];
          let domain_messageid_files = {};

          let dataFromApi = res.data.data;

          for (let key in dataFromApi) {
            tempObj = {};
            for (let dataKey in dataFromApi[key]) {
              tempObj[dataKey] = dataFromApi[key][dataKey];
            }
            userDataList.push(tempObj);
          }

          for (let userData of userDataList) {
            let quote_status;
            let step;
            let excluded_status;

            let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
            let secondFilter =
              userData.client_source === "Hotschedules"
                ? "Hotschedules"
                : "Affinity";

            let email_specific_status;
            let landing_email_status, landing_email_get_quote_clicked;
            let statusPriorityMap = {
              Submitted: 1,
              "User Submitted": 2,
              "Quote Generated": 3,
              "Uploaded Document": 4,
              "UW Questions": 5,
              "Opened Email": 90,
              SQL: 7,
              // "Company Profile(Get Quote)": 8,
              // "Company Profile": 9,
              "Company Information": 9,
              "Initial Pricing": 3,
              "Locations and Payroll": 5,
              "Landing Page": 10,
              "Visited Website": 11,
              "Affinity Logo Clicked": 12,
              "Fourth Logo Clicked": 13,
              "Clicked on Affinity logo": 14,
              "Clicked on Fourth logo": 15,
              "Clicked on the image": 16,
              "Clicked on insure comp logo": 17,
              "Watched Video": 18,
              "Visited LinkedIn": 19,
              "Visited Facebook": 20,
              "Visited Instagram": 21,
              "Accessed Social Media": 22,
              //   MQL: 23,
              "Not Campaigned": 24,
              "-": 999,
            };

            for (let email in userData.email_specific) {
              let db_quote_status = userData.email_specific[email].quote_status;
              let db_excluded =
                userData.email_specific[email].nonEligibilityList;
              if (db_excluded && db_excluded.length > 0) {
                excluded_status = true;
              }

              if (
                userData.email_specific[email].companyProfile &&
                userData.email_specific[email].companyProfile.status &&
                userData.email_specific[email].companyProfile.status.value
              ) {
                let emailStatus =
                  userData.email_specific[email].companyProfile.status.value;
                if (!email_specific_status)
                  email_specific_status =
                    statusNameMapping?.[emailStatus || ""] || emailStatus;
                else if (
                  statusPriorityMap[emailStatus] <
                  statusPriorityMap[email_specific_status]
                ) {
                  email_specific_status =
                    statusNameMapping?.[emailStatus || ""] || emailStatus;
                }
              }

              if (!landing_email_status) {
                landing_email_status = userData.email_specific[email]
                  .landingPage
                  ? userData.email_specific[email].landingPage
                  : null;
              }

              if (!landing_email_get_quote_clicked) {
                landing_email_get_quote_clicked = userData.email_specific[email]
                  .homeGetQuote
                  ? userData.email_specific[email].homeGetQuote
                  : null;
              }

              if (
                (!quote_status && db_quote_status) ||
                (quote_status === "reject" && db_quote_status) ||
                db_quote_status === "proceed_uw"
              ) {
                quote_status = db_quote_status;
              }
            }

            if (userData.isSalespersonSubmitted) {
              step = "Submitted";
            } else if (userData.isUserSubmitted) {
              step = "User Submitted";
            } else if (userData.isSubmitted) {
              step = "Submitted";
            } else if (Number(userData.proposalGeneratedCount) > 0) {
              step = "Proposal Generated";
            } else if (quote_status) {
              if (quote_status === "uwportal") {
                step = "UW Portal";
              } else if (quote_status === "proceed_uw") {
                step = "Approved";
              } else if (quote_status === "reject") {
                step = "Rejected";
              }
            } else if (Number(userData.quoteGeneratedCount) > 0) {
              step = "Initial Pricing";
            } else if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Information";
                if (landing_email_get_quote_clicked) {
                  step += " (Get Quote)";
                }
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "Locations and Payroll";
                }
              }
            } else if (userData.visitCount && Number(userData.visitCount) > 0) {
              step = "Landing Page";
            } else if (landing_email_status) {
              step =
                landing_email_status === "affinityLogo"
                  ? "Affinity Logo Clicked"
                  : "Fourth Logo Clicked";
            } else {
              step = "-";
            }
            if (email_specific_status) {
              step =
                statusPriorityMap[email_specific_status] <
                statusPriorityMap[step]
                  ? email_specific_status
                  : step;
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
            };

            if (!currProspectDetails?.companyProfile?.fein?.value) {
              if (userData?.fein) {
                currProspectDetails.companyProfile.fein = {
                  value: userData.fein,
                };
              }
            }

            rowData = {
              visitDate:
                userData.timestamp &&
                moment(Number(userData.timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              userEmail: userData.email,
              quoteGeneratedCount: userData.quoteGeneratedCount || 0,
              proposalGeneratedCount: userData.proposalGeneratedCount || 0,
              uuid: userData.uuid,
              phoneNumber: userData.phoneNumber,
              payroll: userData.payroll,
              indicationCost: userData.indicationCost,
              emod:
                userData.emod && Number(userData.emod)
                  ? parseFloat(userData.emod).toFixed(2)
                  : "-",
              uwEmail: userData.uwEmail,
              effectiveDate: userData.effectiveDate
                ? moment(userData.effectiveDate).format("x")
                : "",
              contactName: userData.contactName || "",
              companyName: userData.companyName || "",
              comment: userData?.comment
                ? typeof userData?.comment === "string"
                  ? [userData?.comment]
                  : userData?.comment
                : [""],
              domain: userData.domain,
              visitCount: userData.visitCount || 0,
              step,
              latestCampaign: userData.latestCampaign,
              email_specific: userData.email_specific,
              messageID: userData.messageID,
              dashboardFiles: userData.dashboardFiles,
              uploadedDate: userData.uploadedDate,
              uploadedTimeStamp: userData.uploadedTimeStamp
                ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                : userData.zywave_uploaded_date
                ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              currProspectDetails,
              salesID: userData.domain,
              formStage: userData.uwFormStage,
              address: userData.address,
              quoteData: userData.quoteData,
              state: userData?.state || "-",
              city: userData?.city || "-",
              total_emp: userData?.total_emp || 0,
              salespersonName:
                userData.salespersonNameAttr || userData.salespersonName,
              uwExpectedResponses: userData.uwExpectedResponses,
              uwQuesList: userData.uwQuesList,
              uwAmtrustQuesList: userData.uwAmtrustQuesList,
              preUwStatus: userData.preUwStatus || "approved",
              preUWName: userData.preUWName || "",
              isPreUnderwritten: userData.preUwStatus ? true : false,
              clientEligibility: userData.clientEligibility,
              zywaveCompanyProfile: userData.zywaveCompanyProfile,
              website: userData?.companyProfile?.website?.value || "",
              fein: userData.fein,
              preUwFiles: userData.preUwFiles,
              source: userData.source,
              isEngaged: true,
              isSubmitted: userData.isSubmitted,
              fileListToStore: userData.fileListToStore,
              ispeowith: userData?.ispeowith || "",
              preUwDate:
                userData.preUwDate &&
                moment(Number(userData.preUwDate))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              preUwSaved: userData?.preUwSaved === "true" ? "Yes" : "No",
              _completedDate: userData?.completedDate
                ? userData?.completedDate &&
                  moment(Number(userData.completedDate))
                    .utcOffset("-0500")
                    .format("M/D/YYYY")
                : "-",
              sff: userData.sff ? userData.sff : "-",
              Emod: userData.Emod ? userData.Emod : "-",
            };

            createCommentForDownload(rowData, "comment");
            createCommentForDownload(rowData, "admincomment");
            createCommentForDownload(rowData, "salescomment");
            createCommentForDownload(rowData, "smcomment");

            let enableContinueSteps = [
              "-",
              "Landing Page",
              // "Company Profile",
              "Opened Email",
              "Accessed Social Media",
              "Watched Video",
              "Visited Website",
              "Company Information",
              "Locations and Payroll",
              "Initial Pricing",
              "Uploaded Document",
              "Declined Ineligible",
              "Landed on Quote Page",
              "Visited LinkedIn",
              "Visited Facebook",
              "Visited Instagram",
              "Clicked on Affinity logo",
              "Clicked on Fourth logo",
              "Clicked on the image",
              "Clicked on insure comp logo",
              "Not Campaigned",
              // "MQL",
              "SQL",
            ];

            if (!enableContinueSteps.includes(step)) {
              rowData.disableContinue = true;
              if (step !== "UW Questions") rowData.disableGetQuote = true;
            }

            if (rowData.email_specific) {
              Object.values(rowData.email_specific).map((email) => {
                if (
                  email.companyProfile &&
                  email.companyProfile.contact_number &&
                  email.companyProfile.contact_number.value
                ) {
                  email.companyProfile.contact_number.value = appendRemaining(
                    email.companyProfile.contact_number.value.toString()
                  );
                }
              });
            } else {
              if (rowData.phoneNumber)
                rowData.phoneNumber = appendRemaining(
                  rowData.phoneNumber.toString()
                );
            }

            // rowDataList[firstFilter][secondFilter].push(rowData);
            rowDataList.push(rowData);
            domain_messageid_files[rowData.domain] = {};
            if (rowData.messageID)
              domain_messageid_files[rowData.domain]["messageID"] =
                rowData.messageID;
            if (rowData.dashboardFiles)
              domain_messageid_files[rowData.domain]["dashboardFiles"] =
                rowData.dashboardFiles;
            if (rowData.preUwFiles) {
              let _preUwFiles = [];
              rowData.preUwFiles.forEach((ele) => {
                _preUwFiles.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "preUwFiles"
              ] = _preUwFiles;
            }
            // console.log("Suraj fillist", rowData.fileListToStore);
            if (rowData.fileListToStore) {
              let _fileListToStore = [];
              rowData.fileListToStore.forEach((ele) => {
                _fileListToStore.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "fileListToStore"
              ] = _fileListToStore;
            }

            if (rowData.comment) {
              interestedHistoryComment[rowData.domain] = rowData.comment;
            }
          }
          //interested
          let withPeoList = [];
          let withoutPeoList = [];
          for (let i = 0; i < rowDataList.length; i++) {
            if (
              rowDataList[i]?.ispeowith &&
              rowDataList[i]?.ispeowith.toLowerCase() !== "no"
            ) {
              withPeoList.push(rowDataList[i]);
            } else {
              withoutPeoList.push(rowDataList[i]);
            }
          }
          setInterestedRowDataListpeo(withPeoList);
          setInterestedRowDataListwithoupeo(withoutPeoList);
          setInterestedRowDataList(rowDataList);
          setinterestedDomain_messageid_files(domain_messageid_files);
          setInterestedHistoryComment(interestedHistoryComment);
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getLoggedInInfo();
    PreUwInterestedTab();
    PreUwCampaigned1000Plus();
    PreUwEngaged1000Plus();
  }, [selected]);

  const updateComment = (dataToSave) => {
    let requestBody = {};

    requestBody.domain = dataToSave.user_email_id || "";
    requestBody.comment = dataToSave.comment;
    requestBody.tableName = "tab1";
    requestBody.ispreUW = true;
    requestBody.loggedUserName = loggedInInfo;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  const updateEmod = (dataToSave) => {
    let requestBody = {};

    requestBody.domain = dataToSave.domain || "";
    requestBody.emod = dataToSave.emod;
    requestBody.tableName = "tab1";
    requestBody.isEmod = true;
    axios
      .post(awsUrl3 + "/api/updateEmodSff", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };
  const updateSFF = (dataToSave) => {
    let requestBody = {};

    requestBody.domain = dataToSave.domain || "";
    requestBody.sff = dataToSave.sff;
    requestBody.tableName = "tab1";
    requestBody.isSFF = true;
    axios
      .post(awsUrl3 + "/api/updateEmodSff", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  useEffect(() => {
    async function updateUploadFlag() {
      let flag = await getUploadDateFlag();
      setUploadDateFlag(flag);
    }
    updateUploadFlag();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateDomainMessageidFiles = (domain, key, val) => {
    if (!interestedDomain_messageid_file.hasOwnProperty(domain)) {
      interestedDomain_messageid_file[domain] = {};
    }
    interestedDomain_messageid_file[domain][key] = val;
    setinterestedDomain_messageid_files(interestedDomain_messageid_file);

    if (!fiveThousandDomain_messageid_file.hasOwnProperty(domain)) {
      fiveThousandDomain_messageid_file[domain] = {};
    }
    fiveThousandDomain_messageid_file[domain][key] = val;
    setFiveThousandDomain_messageid_files(fiveThousandDomain_messageid_file);
  };

  const styles = (step, rowData) => {
    let style = {
      width: "80%",
      borderRadius: "20px",
      padding: "5px",
      textAlign: "center",
    };
    if (step === "Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "User Submitted") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "Proposal Generated") {
      style.backgroundColor = "#5ADA38";
    } else if (step === "UW Portal") {
      style.backgroundColor = "#ffd737ac";
    } else if (step === "Approved") {
      style.backgroundColor = "#87FA4D";
    } else if (step === "Rejected") {
      style.backgroundColor = "#fa6d3e";
    } else if (step === "Company Information") {
      style.backgroundColor = "red";
    } else if (step === "MQL") {
      style.backgroundColor = "gray";
    } else if (step === "Initial Pricing") {
      style.backgroundColor = "#2ea44f";
    } else if (step === "Locations and Payroll") {
      style.backgroundColor = "#FFD737";
    } else if (Number(rowData.visitCount) > 0) {
      style.backgroundColor = "#FFD737";
    } else {
      style.backgroundColor = "#FFF";
    }
    return style;
  };

  const customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        console.log(
          "firstcharater:",
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];
    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  let thousandPlusColumns = [
    {
      title: "Visit Date (ET)",
      field: "visitDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      defaultSort: "desc",
      customSort: (a, b) => dateSort(a, b, "visitDate"),
      render: (rowData) => {
        if (rowData.visitDate) {
          let date = rowData.visitDate.split("-")[0];
          let time = rowData.visitDate.split("-")[1];
          return (
            <span>
              {date} {"&"}
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "3px 0",
        minWidth: "10rem",
      },
      headerStyle: {
        padding: "3px 0px 3px 4px",
        minWidth: "10rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "Company Name",
      field: "companyName",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "18rem",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "3px 0px 3px 10px",
        minWidth: "12rem",
      },
      filterCellStyle: {
        padding: "2px 0px 2px 4px",
      },
      render: (rowData) => {
        let companyNameClamp = "";
        // return (
        // <Clamp lines={2}>
        //   <span onClick={() => onClickCompanyName(rowData, "1000plus")}>
        //     {rowData.disableCompanyName ? (
        //       <div>{camelCase(rowData.companyName)}</div>
        //     ) : (
        //       <Link to={"/Quote"} style={{ color: "black" }}>
        //         {camelCase(rowData.companyName)}
        //       </Link>
        //     )}
        //   </span>
        // </Clamp>
        // );
        return rowData?.ispeowith &&
          rowData?.ispeowith.toLowerCase() !== "no" ? (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData, "1000plus")}>
              {rowData.disableCompanyName ? (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{camelCase(rowData.companyName)}</div>
                  <Tooltip
                    title={
                      rowData?.ispeowith
                        ? rowData?.ispeowith
                        : "The Company is with PEO Relationship"
                    }
                    arrow
                  >
                    <p>
                      <FaRegHandshake
                        size={15}
                        style={{ marginTop: "15px" }}
                        color="#427cbc"
                      />
                    </p>
                  </Tooltip>
                </span>
              ) : (
                <span
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Link to={"/Quote"} style={{ color: "black" }}>
                    {camelCase(rowData.companyName)}
                  </Link>
                  <Tooltip
                    title={
                      rowData?.ispeowith
                        ? rowData?.ispeowith
                        : "The Company is with PEO Relationship"
                    }
                    arrow
                  >
                    <p>
                      <FaRegHandshake size={15} color="#427cbc" />
                    </p>
                  </Tooltip>
                </span>
              )}
            </span>
          </Clamp>
        ) : (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData, "1000plus")}>
              {rowData.disableCompanyName ? (
                <div>{camelCase(rowData.companyName)}</div>
              ) : (
                <Link to={"/Quote"} style={{ color: "black" }}>
                  {camelCase(rowData.companyName)}
                </Link>
              )}
            </span>
          </Clamp>
        );
      },
    },
    {
      title: "Payroll",
      field: "payroll",
      filterPlaceholder: "Filter",
      // customSort: (a, b) => a.payroll.length - b.payroll.length,
      render: (rowData) => numeral(rowData.payroll).format("$0,0"),
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 20px 6px 10px",
        minWidth: "8rem",
        wordBreak: "break-all",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "7rem",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 0px",
      },
    },
    {
      title: "# Emp",
      field: "total_emp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 6px",
        paddingRight: "20px",
        minWidth: "5rem",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        whiteSpace: "nowrap",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 15px",
      },
    },

    {
      title: "City",
      field: "city",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      render: (rowData) => camelCase(rowData.city),
      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "8rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "8rem",
      },
      filterCellStyle: {
        padding: "2px 7px 2px 1px",
      },
    },
    {
      title: "State",
      field: "state",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "Emod",
      field: "Emod",
      filterPlaceholder: "Filter",
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateEmod(dataToSave);
          }
        };

        return (
          <div className="d-flex">
            <UpdateTextField
              value={rowData.Emod}
              callback={callbackFunction}
              rowDataID={rowData?.domain}
            />
          </div>
        );
      },
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 20px 6px 10px",
        minWidth: "8rem",
        wordBreak: "break-all",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "7rem",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 0px",
      },
    },
    {
      title: "SFF",
      field: "SFF",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateSFF(dataToSave);
          }
        };
        return (
          <div className="d-flex">
            <UpdateTextField
              value={rowData.sff}
              callback={callbackFunction}
              rowDataID={rowData?.domain}
            />
          </div>
        );
      },

      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: (
        <img
          src={require("../../../images/download_black.png")}
          alt=""
          style={{
            width: "20px",
            height: "20px",
            // filter: "grayscale(100%)  opacity(0.4)",
          }}
        />
      ),
      disableSortBy: true,
      sorting: false,
      filtering: true,
      cellStyle: {
        minWidth: "30px",
        width: "30px",
        maxWidth: "30px",
        padding: "0 0",
      },
      headerStyle: {
        paddingLeft: "15px",
      },
      render: (rowData) => {
        if (rowData.sales === "true") {
          return (
            <button className="btn pt-0" type="button">
              <img
                src={require("../../../images/download_black.png")}
                alt=""
                style={{
                  width: "20px",
                  height: "20px",
                  filter: "grayscale(100%)  opacity(0.4)",
                }}
              />
            </button>
          );
        }

        let title = "";

        if (
          fiveThousandDomain_messageid_file &&
          fiveThousandDomain_messageid_file[rowData.domain]?.dashboardFiles
            ?.length
        ) {
          for (let file of fiveThousandDomain_messageid_file[rowData.domain]
            .dashboardFiles) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }
        if (
          fiveThousandDomain_messageid_file[rowData.domain]?.fileListToStore
            ?.length
        ) {
          for (let file of fiveThousandDomain_messageid_file[rowData.domain]
            .fileListToStore) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }
        if (
          fiveThousandDomain_messageid_file[rowData.domain]?.preUwFiles?.length
        ) {
          for (let file of fiveThousandDomain_messageid_file[rowData.domain]
            .preUwFiles) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }

        let totalFiles =
          (fiveThousandDomain_messageid_file[rowData.domain]?.dashboardFiles
            ?.length || 0) +
          (fiveThousandDomain_messageid_file[rowData.domain]?.preUwFiles
            ?.length || 0) +
          (fiveThousandDomain_messageid_file[rowData.domain]?.fileListToStore
            ?.length || 0);

        return (
          <>
            <button
              className="btn pt-0"
              type="button"
              title={parse(title)}
              data-html="true"
              onClick={() => {
                setShowModel(true);
                setModalTitle(<h1>{"Download"}</h1>);
                setModalContent(
                  <>
                    <div className="m-3 d-flex align-items-center justify-content-center">
                      <DropZoneDashboard
                        domainName={rowData.domain}
                        fileList={rowData?.fileListToStore}
                        docUploaded={docUploaded}
                        updatePopUpData={""}
                        setDocUploaded={setDocUploaded}
                        updateDomainMessageidFiles={updateDomainMessageidFiles}
                      />
                    </div>
                    <hr></hr>
                    <DownloadDashboardFiles
                      dashboardFiles={
                        fiveThousandDomain_messageid_file[rowData.domain]
                          ?.dashboardFiles || []
                      }
                      preUwFiles={
                        fiveThousandDomain_messageid_file[rowData.domain]
                          ?.preUwFiles || []
                      }
                      fileListToStore={
                        fiveThousandDomain_messageid_file[rowData.domain]
                          ?.fileListToStore || []
                      }
                      domainName={rowData.domain}
                      updateDomainMessageidFiles={updateDomainMessageidFiles}
                    />
                  </>
                );
              }}
            >
              <img
                src={require("../../../images/download_black.png")}
                alt=""
                style={
                  totalFiles > 0
                    ? {
                        width: "20px",
                        height: "20px  ",
                        filter: "grayscale(0)",
                      }
                    : {
                        width: "20px",
                        height: "20px",
                        filter: "grayscale(100%)  opacity(0.4)",
                      }
                }
              />
            </button>
          </>
        );
      },
    },
    // {
    //   title: "Status",
    //   field: "step",
    //   filterPlaceholder: "Filter",

    //   cellStyle: (e, rowData) => {
    //     let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
    //     if (rowData.step === "Submitted") {
    //       style.backgroundColor = "#5ADA38";
    //     } else if (rowData.step === "User Submitted") {
    //       style.backgroundColor = "#5ADA38";
    //     } else if (rowData.step === "UW Portal") {
    //       style.backgroundColor = "#ffd737ac";
    //     } else if (rowData.step === "Approved") {
    //       style.backgroundColor = "#87FA4D";
    //     } else if (rowData.step === "Rejected") {
    //       style.backgroundColor = "#fa6d3e";
    //     } else if (rowData.step === "Quote Generated") {
    //       style.backgroundColor = "#87FA20";
    //     } else if (rowData.step === "UW Questions") {
    //       style.backgroundColor = "#FFD737";
    //     } else if (Number(rowData.visitCount) > 0) {
    //       style.backgroundColor = "#FFD737";
    //     } else {
    //       style.backgroundColor = "#FFF";
    //     }
    //     return style;
    //   },
    //   headerStyle: {
    //     padding: "6px 0 6px 13px",
    //     minWidth: "8rem",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 5px 2px 5px",
    //   },
    // },
    {
      title: "Comments",
      field: "comment_download",
      filterPlaceholder: "Filter",
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateComment(dataToSave);
          }
        };
        let historyComment = {
          ...historyThousandPlusCampaignedComment,
          ...historyThousandPlusEngagedComment,
        };
        return (
          <div className="d-flex">
            <TextField
              key={`${rowData.domain}_comment`}
              value={
                typeof historyComment?.[rowData?.domain]?.[0] === "object"
                  ? historyComment?.[rowData?.domain]?.[0]?.comment
                  : historyComment?.[rowData?.domain]?.[0]
              }
              callback={callbackFunction}
              rowData={rowData}
              rowDataName={"user_email_id"}
              rowDataID={rowData?.domain}
              rowDataComments={"comment"}
              comment={historyComment[rowData?.domain]}
              date={rowData.submittedDate}
            />
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                onClick={() =>
                  handleUWCommentHistoryButtonClick(
                    rowData,
                    historyComment?.[rowData?.domain],
                    rowData.submittedDate,
                    setModalBody,
                    setOpenModal
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        minWidth: "230px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    },
    // {
    //   title: "Visit Count",
    //   field: "visitCount",
    //   filterPlaceholder: "Filter",
    //   customFilterAndSearch: (term, rowData, field) =>
    //     customFilterfunction(term, rowData, field),
    //   cellStyle: {
    //     padding: "6px 0 6px 23px",
    //     minWidth: "5rem",
    //   },
    //   headerStyle: {
    //     padding: "6px 0px 6px 15px",
    //     minWidth: "5rem",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 15px 2px 10px",
    //   },
    // },
    // {
    //   title: "Campaign Name",
    //   field: "latestCampaign",
    //   filterPlaceholder: "Filter",
    //   cellStyle: {
    //     padding: "6px 0 6px 20px",
    //     minWidth: "150px",
    //   },
    //   headerStyle: {
    //     padding: "6px 0px 6px 13px",
    //     minWidth: "150px",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 15px 2px 10px",
    //   },
    //   render: (rowData) => (
    //     <Clamp lines={2}>{camelCase(rowData?.latestCampaign) || ""}</Clamp>
    //   ),
    // },
    {
      title: "PreUw Saved?",
      field: "preUwSaved",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0 6px 20px",
        minWidth: "150px",
      },
      headerStyle: {
        padding: "6px 0px 6px 13px",
        minWidth: "150px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.preUwSaved}</Clamp>,
    },
    {
      title: "Completed Date",
      field: "_completedDate",
      filterPlaceholder: "Filter",
      render: (rowData) => rowData._completedDate,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        minWidth: "230px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    },
    // {
    //   title: "PreUnderwritten?",
    //   field: "isPreUnderwritten",
    //   headerStyle: {
    //     // padding: "6px 0 6px 0px",
    //     width: "30px",
    //     wordBreak: "break-all",
    //   },
    //   render: (rowData) => rowData.isPreUnderwritten ? "YES" : "NO",
    // },
    {
      title: "Pre-Underwriter",
      field: "preUWName",
      filterPlaceholder: "Filter",
      filtering: true,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        wordBreak: "break-word",
        minWidth: "180px",
      },
      headerStyle: {
        minWidth: "180px",
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 3px",
      },
      render: (rowData) => {
        // console.log(rowData, "princeeee")
        let name = "";
        try {
          if (rowData.preUWName.length > 15) {
            name = `${rowData.preUWName.slice(0, 15)}...`;
          } else {
            name = rowData.preUWName;
          }
        } catch (error) {}
        return name;
      },
    },
    // {
    //   title: "Source",
    //   field: "source",
    //   filterPlaceholder: "Filter",
    //   cellStyle: {
    //     padding: "6px 0 6px 20px",
    //     minWidth: "150px",
    //   },
    //   headerStyle: {
    //     padding: "6px 0px 6px 13px",
    //     minWidth: "150px",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 15px 2px 10px",
    //   },
    //   render: (rowData) => {

    //     return <Clamp lines={1}>{source(rowData?.source) || ""}</Clamp>;
    //   },
    // },
  ];

  let interestedColumn = [
    {
      title: "Submitted Date (ET)",
      field: "preUwDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      customSort: (a, b) => dateSort(a, b, "preUwDate"),
      render: (rowData) => {
        if (rowData.preUwDate) {
          let date = rowData.preUwDate.split("-")[0];
          let time = rowData.preUwDate.split("-")[1];
          return (
            <span>
              {date} {"&"}
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "3px 0",
        minWidth: "12rem",
      },
      headerStyle: {
        padding: "3px 0px 3px 4px",
        minWidth: "12rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "Visit Date (ET)",
      field: "visitDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      customSort: (a, b) => dateSort(a, b, "visitDate"),
      render: (rowData) => {
        if (rowData.visitDate) {
          let date = rowData.visitDate.split("-")[0];
          let time = rowData.visitDate.split("-")[1];
          return (
            <span>
              {date} {"&"}
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "3px 0",
        minWidth: "10rem",
      },
      headerStyle: {
        padding: "3px 0px 3px 4px",
        minWidth: "10rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "Company Name",
      field: "companyName",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "18rem",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "3px 0px 3px 10px",
        minWidth: "11rem",
      },
      filterCellStyle: {
        padding: "2px 0px 2px 4px",
      },
      render: (rowData) => {
        let random_user = Object.keys(rowData.email_specific)[0];

        return (
          <Clamp lines={2}>
            {rowData?.ispeowith && rowData?.ispeowith.toLowerCase() !== "no" ? (
              <span
                onClick={() => onClickCompanyName(rowData, "interested_tab")}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link to={"/Quote"} style={{ color: "black" }}>
                    {camelCase(rowData.companyName)}
                  </Link>
                  <Tooltip
                    title={
                      rowData?.ispeowith
                        ? rowData?.ispeowith
                        : "The Company is with PEO Relationship"
                    }
                    arrow
                  >
                    <p>
                      <FaRegHandshake
                        size={15}
                        color="#427cbc"
                        style={{ marginTop: "15px" }}
                      />
                    </p>
                  </Tooltip>
                </span>
              </span>
            ) : (
              <span
                onClick={() => onClickCompanyName(rowData, "interested_tab")}
              >
                <Link to={"/Quote"} style={{ color: "black" }}>
                  {camelCase(rowData.companyName)}
                </Link>
              </span>
            )}
          </Clamp>
        );
      },
    },
    {
      title: "Payroll",
      field: "payroll",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),

      render: (rowData) => numeral(rowData.payroll).format("$0,0"),
      cellStyle: {
        padding: "6px 20px 6px 10px",
        minWidth: "8rem",
        wordBreak: "break-all",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "7rem",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 0px",
      },
    },
    {
      title: "Emod",
      field: "Emod",
      filterPlaceholder: "Filter",
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateEmod(dataToSave);
          }
        };

        return (
          <div className="d-flex">
            <UpdateTextField
              value={rowData.Emod}
              callback={callbackFunction}
              rowDataID={rowData?.domain}
            />
          </div>
        );
      },
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 20px 6px 10px",
        minWidth: "8rem",
        wordBreak: "break-all",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "7rem",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 0px",
      },
    },
    {
      title: "SFF",
      field: "SFF",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateSFF(dataToSave);
          }
        };
        return (
          <div className="d-flex">
            <UpdateTextField
              value={rowData.sff}
              callback={callbackFunction}
              rowDataID={rowData?.domain}
            />
          </div>
        );
      },

      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "State",
      field: "state",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
    },
    {
      title: "City",
      field: "city",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      render: (rowData) => camelCase(rowData.city),

      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "8rem",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "8rem",
      },
      filterCellStyle: {
        padding: "2px 7px 2px 1px",
      },
    },
    {
      title: "# Emp",
      field: "total_emp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 6px",
        paddingRight: "20px",
        minWidth: "5rem",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        whiteSpace: "nowrap",
        minWidth: "5rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 15px",
      },
    },
    // {
    //   title: "Status",
    //   field: "step",
    //   filterPlaceholder: "Filter",

    //   cellStyle: (e, rowData) => {
    //     let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
    //     // if (rowData.step === "Submitted") {
    //     //   style.background = "#5ADA38";
    //     // } else if (rowData.step === "User Submitted") {
    //     //   style.background = "#5ADA38";
    //     // } else if (rowData.step === "Proposal Generated") {
    //     //   style.backgroundColor = "#5ADA38";
    //     // } else if (rowData.step === "Location's and Payroll") {
    //     //   style.backgroundColor = "#FFD737";
    //     // } else if (rowData.step === "Approved") {
    //     //   style.backgroundColor = "#87FA4D";
    //     // } else if (rowData.step === "Rejected") {
    //     //   style.backgroundColor = "#fa6d3e";
    //     // } else if (rowData.step === "Initial Pricing") {
    //     //   style.backgroundColor = "#2ea44f";
    //     // } else if (rowData.step === "Location's and Payroll") {
    //     //   style.backgroundColor = "#FFD737";
    //     // } else if (Number(rowData.visitCount) > 0) {
    //     //   style.backgroundColor = "#FFD737";
    //     // } else {
    //     //   style.backgroundColor = "#FFF";
    //     // }
    //     return style;
    //   },
    //   headerStyle: {
    //     padding: "6px 0 6px 13px",
    //     minWidth: "160px",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 5px 2px 5px",
    //     minWidth: "160px",
    //   },
    //   render: (rowData) => (
    //     <div style={styles(rowData.step, rowData)}>{rowData.step}</div>
    //   ),
    // },
    {
      title: "Comments",
      field: "comment_download",
      filterPlaceholder: "Filter",
      // defaultSort: "desc",
      // defaultSort: "asc",
      render: (rowData) => {
        const callbackFunction = (dataToSave) => {
          if (dataToSave) {
            updateComment(dataToSave);
          }
        };

        return (
          <div className="d-flex">
            <TextField
              key={`${rowData.domain}_comment`}
              value={
                typeof interestedHistoryComment?.[rowData?.domain]?.[0] ===
                "object"
                  ? interestedHistoryComment?.[rowData?.domain]?.[0]?.comment
                  : interestedHistoryComment?.[rowData?.domain]?.[0]
              }
              callback={callbackFunction}
              rowData={rowData}
              rowDataName={"user_email_id"}
              rowDataID={rowData?.domain}
              uuidCarriers={rowData.sortKeyList}
              rowDataComments={"comment"}
              comment={interestedHistoryComment[rowData?.domain]}
              date={rowData.submittedDate}
            />
            <Tooltip title="Click to view comment history" arrow>
              <button
                className="comment-btn"
                onClick={() =>
                  handleUWCommentHistoryButtonClick(
                    rowData,
                    interestedHistoryComment?.[rowData?.domain],
                    rowData.submittedDate,
                    setModalBody,
                    setOpenModal
                  )
                }
              >
                ...
              </button>
            </Tooltip>
          </div>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        minWidth: "230px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    },
    {
      title: "Completed Date",
      field: "_completedDate",
      filterPlaceholder: "Filter",
      render: (rowData) => rowData._completedDate,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "230px",
      },
      headerStyle: {
        padding: "6px 0 6px 18px",
        minWidth: "230px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    },
    {
      title: "#Visit",
      field: "visitCount",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 23px",
        minWidth: "120px",
        textAlign: "right",
      },
      headerStyle: {
        padding: "6px 0px 6px 15px",
        minWidth: "120px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
    },
    {
      title: (
        <img
          src={require("../../../images/download_black.png")}
          alt=""
          style={{
            width: "20px",
            height: "20px",
            // filter: "grayscale(100%)  opacity(0.4)",
          }}
        />
      ),
      disableSortBy: true,
      sorting: false,
      filtering: true,
      cellStyle: {
        minWidth: "30px",
        width: "30px",
        maxWidth: "30px",
        padding: "0 0",
      },
      headerStyle: {
        paddingLeft: "15px",
      },
      render: (rowData) => {
        if (rowData.sales === "true") {
          return (
            <button className="btn pt-0" type="button">
              <img
                src={require("../../../images/download_black.png")}
                alt=""
                style={{
                  width: "20px",
                  height: "20px",
                  filter: "grayscale(100%)  opacity(0.4)",
                }}
              />
            </button>
          );
        }

        let title = "";

        if (
          interestedDomain_messageid_file &&
          interestedDomain_messageid_file[rowData.domain]?.dashboardFiles
            ?.length
        ) {
          for (let file of interestedDomain_messageid_file[rowData.domain]
            .dashboardFiles) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }
        if (
          interestedDomain_messageid_file[rowData.domain]?.fileListToStore
            ?.length
        ) {
          for (let file of interestedDomain_messageid_file[rowData.domain]
            .fileListToStore) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }
        if (
          interestedDomain_messageid_file[rowData.domain]?.preUwFiles?.length
        ) {
          for (let file of interestedDomain_messageid_file[rowData.domain]
            .preUwFiles) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }

        let totalFiles =
          (interestedDomain_messageid_file[rowData.domain]?.dashboardFiles
            ?.length || 0) +
          (interestedDomain_messageid_file[rowData.domain]?.preUwFiles
            ?.length || 0) +
          (interestedDomain_messageid_file[rowData.domain]?.fileListToStore
            ?.length || 0);

        return (
          <>
            <button
              className="btn pt-0"
              type="button"
              title={parse(title)}
              data-html="true"
              onClick={() => {
                setShowModel(true);
                setModalTitle(<h1>{"Download"}</h1>);
                setModalContent(
                  <>
                    <div className="m-3 d-flex align-items-center justify-content-center">
                      <DropZoneDashboard
                        domainName={rowData.domain}
                        fileList={rowData?.fileListToStore}
                        docUploaded={docUploaded}
                        updatePopUpData={""}
                        setDocUploaded={setDocUploaded}
                        updateDomainMessageidFiles={updateDomainMessageidFiles}
                      />
                    </div>
                    <hr></hr>
                    <DownloadDashboardFiles
                      dashboardFiles={
                        interestedDomain_messageid_file[rowData.domain]
                          ?.dashboardFiles || []
                      }
                      preUwFiles={
                        interestedDomain_messageid_file[rowData.domain]
                          ?.preUwFiles || []
                      }
                      fileListToStore={
                        interestedDomain_messageid_file[rowData.domain]
                          ?.fileListToStore || []
                      }
                      domainName={rowData.domain}
                      updateDomainMessageidFiles={updateDomainMessageidFiles}
                    />
                  </>
                );
              }}
            >
              <img
                src={require("../../../images/download_black.png")}
                alt=""
                style={
                  totalFiles > 0
                    ? {
                        width: "20px",
                        height: "20px  ",
                        filter: "grayscale(0)",
                      }
                    : {
                        width: "20px",
                        height: "20px",
                        filter: "grayscale(100%)  opacity(0.4)",
                      }
                }
              />
            </button>
          </>
        );
      },
    },
    {
      title: "Campaign Name",
      field: "latestCampaign",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0 6px 20px",
        minWidth: "150px",
      },
      headerStyle: {
        padding: "6px 0px 6px 13px",
        minWidth: "150px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
      render: (rowData) => (
        <Clamp lines={2}>{camelCase(rowData?.latestCampaign) || ""}</Clamp>
      ),
    },
    {
      title: "PreUw Saved?",
      field: "preUwSaved",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0 6px 20px",
        minWidth: "150px",
      },
      headerStyle: {
        padding: "6px 0px 6px 13px",
        minWidth: "150px",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 10px",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.preUwSaved}</Clamp>,
    },
    {
      title: "Pre-Underwriter",
      field: "preUWName",
      filterPlaceholder: "Filter",
      filtering: true,
      cellStyle: {
        padding: "6px 15px 6px 10px",
        wordBreak: "break-word",
        minWidth: "180px",
      },
      headerStyle: {
        minWidth: "180px",
        paddingLeft: "10px",
      },
      filterCellStyle: {
        padding: "2px 18px 2px 3px",
      },
      render: (rowData) => {
        let name = "";
        try {
          if (rowData.preUWName.length > 15) {
            name = `${rowData.preUWName.slice(0, 15)}...`;
          } else {
            name = rowData.preUWName;
          }
        } catch (error) {}
        return name;
      },
    },
    // {
    //   title: "Salesperson",
    //   field: "salespersonName",
    //   filterPlaceholder: "Filter",
    //   cellStyle: {
    //     padding: "6px 0 6px 20px",
    //     minWidth: "150px",
    //   },
    //   headerStyle: {
    //     padding: "6px 0px 6px 13px",
    //     minWidth: "150px",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 15px 2px 10px",
    //   },
    //   render: (rowData) => {
    //     let name = "";
    //     try {
    //       if (rowData.salespersonName.length > 15) {
    //         name = `${rowData.salespersonName.slice(0, 15)}...`;
    //       } else {
    //         name = rowData.salespersonName;
    //       }
    //     } catch (error) { }
    //     return <Clamp lines={2}>{name || ""}</Clamp>;
    //   },
    // },
    // {
    //   title: "Source",
    //   field: "source",
    //   filterPlaceholder: "Filter",
    //   cellStyle: {
    //     padding: "6px 0 6px 20px",
    //     minWidth: "150px",
    //   },
    //   headerStyle: {
    //     padding: "6px 0px 6px 13px",
    //     minWidth: "150px",
    //   },
    //   filterCellStyle: {
    //     padding: "2px 15px 2px 10px",
    //   },
    //   render: (rowData) => (
    //     <Clamp lines={1}>{source(rowData?.source) || ""}</Clamp>
    //   ),
    // },
  ];

  if (uploadDateFlag) {
    interestedColumn.unshift({
      title: "Upload Date",
      field: "uploadedTimeStamp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      customSort: (a, b) => dateSort(a, b, "uploadedTimeStamp"),
      cellStyle: {
        wordBreak: "break-all",
        padding: "3px 0",
        minWidth: "6rem",
      },
      headerStyle: {
        padding: "3px 0px 3px 4px",
        minWidth: "6rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>,
    });
    thousandPlusColumns.unshift({
      title: "Upload Date",
      field: "uploadedTimeStamp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      customSort: (a, b) => dateSort(a, b, "uploadedTimeStamp"),
      cellStyle: {
        wordBreak: "break-all",
        padding: "3px 0",
        minWidth: "6rem",
      },
      headerStyle: {
        padding: "3px 0px 3px 4px",
        minWidth: "6rem",
      },
      filterCellStyle: {
        padding: "2px 15px 2px 0px",
      },
      headerStyle: {
        padding: "6px 0",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>,
    });
  }

  return (
    <div className="preUWDashboard m-3   w-100">
      {/* <Header /> */}
      <h1
        className="mt-0 mb-3 d-flex"
        style={{
          textAlign: "center",
          fontFamily: "Montserrat",
          fontSize: "1.9rem",
          margin: "0px auto",
        }}
      >
        Pre-Underwriter Dashboard
      </h1>

      <Nav
        variant="pills"
        defaultActiveKey={selected}
        style={{
          fontSize: "16px",
          margin: "5px",
          fontFamily: "Montserrat",
          marginTop: "30px",
        }}
      >
        {Object.keys(tabsMap).map((tab) => (
          <Nav.Item>
            <Nav.Link eventKey={tab} onClick={() => setSelected(tab)}>
              {tabsMap[tab]}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Nav
        variant="pills"
        defaultActiveKey={selectedPeo}
        style={{
          fontSize: "16px",
          margin: "5px",
          fontFamily: "Montserrat",
          marginTop: "30px",
        }}
      >
        {/* peoTabName */}
        {Object.keys(peoTabName).map((tab) => (
          <Nav.Item>
            <Nav.Link eventKey={tab} onClick={() => setSelectedPeo(tab)}>
              {peoTabName[tab]}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      {selected === "tab1" ? (
        selectedPeo === "peo" ? (
          <EngagedTabMUI
            isLoading={isLoading}
            showDetailPanel={true}
            columns={interestedColumn}
            engagedRowDataList={interestedRowDataListpeo}
          />
        ) : (
          <EngagedTabMUI
            isLoading={isLoading}
            showDetailPanel={true}
            columns={interestedColumn}
            engagedRowDataList={interestedRowDataListwithoupeo}
          />
        )
      ) : (
        " "
      )}

      {selected === "tab2" ? (
        selectedPeo === "peo" ? (
          <MaterialTableContainer
            isLoadingCampaigned={
              isLoadingThousandPlusCampaigned && isLoadingThousandPlusEngaged
            }
            rowDataList={[
              ...thousandPlusCampaignedRowDataListpeo,
              ...thousandPlusEngagedRowDataListpeo,
            ]}
            columns={thousandPlusColumns}
            showDetailPanel={true}
          />
        ) : (
          <MaterialTableContainer
            isLoadingCampaigned={
              isLoadingThousandPlusCampaigned && isLoadingThousandPlusEngaged
            }
            rowDataList={[
              ...thousandPlusCampaignedRowDataListwithoutpeo,
              ...thousandPlusEngagedRowDataListwithoutpeo,
            ]}
            columns={thousandPlusColumns}
            showDetailPanel={true}
          />
        )
      ) : (
        ""
      )}

      <DialogBox
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalBody={modalBody}
      />

      <Modal
        show={showModel}
        onHide={() => {
          setShowModel(false);
          setModalTitle(null);
          setModalContent(null);
        }}
        dialogClassName="model-download modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </div>
  );
}

export default PreUnderWriterDashboard;

import { style } from "d3";
import React, { useState, useEffect } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import styles from "./QuotePageBanner.module.scss";
let calendarImage = require("../../../images/calendar.svg");
let clipboardImage = require("../../../images/clipboard.svg");
let constructionImage = require("../../../images/construction-and-tools.svg");
let humanImage = require("../../../images/human.svg");
let notesImage = require("../../../images/notes.svg");
let toDoImage = require("../../../images/to-do-list.svg");
let payrollImage = require("../../../images/caro_img_1.jpeg");
let bmImage = require("../../../images/caro_img_6.webp");
let complianceImage = require("../../../images/caro_img_4.webp");
let hrImage = require("../../../images/caro_img_2.webp");
let rmImage = require("../../../images/caro_img_5.webp");
let tAndAImage = require("../../../images/caro_img_3.jpeg");
let carouselBanner1 = require("../../../images/carousel_banner_1.png");
let carouselBanner2 = require("../../../images/carousel_banner_2.png");
let carouselBanner3 = require("../../../images/carousel_banner_3.png");
let carouselBanner4 = require("../../../images/carousel_banner_4.png");
let carouselBanner5 = require("../../../images/carousel_banner_5.png");

export default function QuotePageBanner() {
  const [sliderinterVal, SetSliderInterval] = useState(5000);

  useEffect(() => {
    const timer = setTimeout(() => {
      let value = sliderinterVal == 5000 ? 16000 : 5000;
      SetSliderInterval(value);
    }, sliderinterVal);
    return () => clearTimeout(timer);
  }, [sliderinterVal]);

  const services = [
    {
      name: "Employee Benefit Programs",
      content: (
        <>
          <h5>Employee Benefit Programs</h5>
          <div style={{ textAlign: "left" }}>
            Our programs allow you to reduce costs while still providing
            employees with quality benefits. We’ll work with you to define the
            plans that work best for you and your team.
          </div>
        </>
      ),
      icon: notesImage,
      image: payrollImage,
    },
    {
      name: "Employee Discount",
      icon: humanImage,
      image: hrImage,
      content: (
        <>
          <h5>Employee Discount</h5>
          <div style={{ textAlign: "left" }}>
            {`    ${
              process.env.REACT_APP_MODE == "beta"
                ? "We"
                : "With LifeMart&reg; you"
            } can give employees easy access to discounts
            of up to 40% on more than five million products and services.`}
          </div>
        </>
      ),
    },
    {
      name: "Unemployment Claims Administration",
      icon: calendarImage,
      image: tAndAImage,
      content: (
        <>
          <h5>Unemployment Claims Administration</h5>
          <ul>
            <li>Consulting on employee problems as they arise</li>
            <li>Claims Administration</li>
            <li>Appeals Representation</li>
            <li>Auditing</li>
            <li>Education and Training</li>
          </ul>
        </>
      ),
    },
    {
      name: "Compliance and Healthcare Reform Consulting",
      icon: toDoImage,
      image: complianceImage,
      content: (
        <>
          <h5>Compliance and Healthcare Reform Consulting</h5>
          <ul>
            <li>
              Our experts help you understand the true implications of
              healthcare reform, so you stay compliant.
            </li>
            <li>
              This includes, but is not limited to, changes surrounding The
              Affordable Care Act, or ACA
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Worker's Compensation Risk Management",
      icon: constructionImage,
      image: rmImage,
      content: (
        <>
          <h5>Workers’ Compensation &amp; Risk Management</h5>
          <ul>
            <li>Injury loss analysis</li>
            <li>Risk control action plans to improve safety</li>
            <li>Engineering controls training programs to mitigate risk</li>
            <li>Management safety</li>
            <li>Safety programs and employee training</li>
          </ul>
        </>
      ),
    },
    {
      name: "Employee Benefit Administration",
      icon: clipboardImage,
      image: bmImage,
      content: (
        <>
          <h5>Employee Benefit Administration</h5>
          <div style={{ textAlign: "left" }}>
            We offer a Competitive Benefits Package without the Administrative
            Burden.
          </div>
          <ul>
            <li>Provide Benefits, Reduce Costs</li>
            <li>Reduce Administrivia</li>
            <li>Stay Compliant</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <div noGutters className={`${styles.statsBannerContainer} mb-3`}>
      <Carousel interval={sliderinterVal}>
        <Carousel.Item>
          <a
            // href="https://www.fourth.com/feature/get-1-year-of-hotschedules-on-us?utm_source=affinity&utm_medium=website&utm_campaign=email"
            target="_blank"
          >
            <img
              src={carouselBanner1}
              alt="discountBanner"
              width="100%"
              height="280px"
              className="img-fluid"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            // href="https://www.fourth.com/feature/get-1-year-of-hotschedules-on-us?utm_source=affinity&utm_medium=website&utm_campaign=email"
            target="_blank"
          >
            <img
              src={carouselBanner2}
              alt="discountBanner"
              width="100%"
              height="280px"
              className="img-fluid"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            // href="https://www.fourth.com/feature/get-1-year-of-hotschedules-on-us?utm_source=affinity&utm_medium=website&utm_campaign=email"
            target="_blank"
          >
            <img
              src={carouselBanner3}
              alt="discountBanner"
              width="100%"
              height="280px"
              className="img-fluid"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            // href="https://www.fourth.com/feature/get-1-year-of-hotschedules-on-us?utm_source=affinity&utm_medium=website&utm_campaign=email"
            target="_blank"
          >
            <img
              src={carouselBanner4}
              alt="discountBanner"
              width="100%"
              height="280px"
              className="img-fluid"
            />
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a
            // href="https://www.fourth.com/feature/get-1-year-of-hotschedules-on-us?utm_source=affinity&utm_medium=website&utm_campaign=email"
            target="_blank"
          >
            <img
              src={carouselBanner5}
              alt="discountBanner"
              width="100%"
              height="280px"
              className="img-fluid"
            />
          </a>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

import React from "react";

export default function OurServices(props) {
  return (
    <div className="our-services">
      {/* <div className="row row-1 row-no-margin">
        <div className="horizontal-continer">
          <hr />
        </div>
        <div className="our-services-text">Our Services</div>
      </div> */}
      <div className="row row-2  row-no-margin">
        <div className="why-engage-section">
          <div className="why-engage-container">
            <div className="why-engage-container-box">
              <img
                src={props.whyEngageImageURL}
                className="whyEngageImage"
                alt=""
              />
              <div className="whyEngageText">
                <h1>WHY ENGAGE?</h1>
              </div>
              <div className="why-engageImagebox">
                <div className="cardsContainer">
                  <div className="card">
                    <p>
                      One of the <b>largest PEOs</b> in the country & has
                      specialized in Long Term Industry <b>for over 25 years</b>
                      .
                    </p>
                  </div>
                  <div className="card">
                    <p>
                      The <b>ONLY PEO</b> where every dedicated HR Consultant is
                      also a labor attorney
                    </p>
                  </div>
                  <div className="card">
                    <p>
                      We provide{" "}
                      <b>Fortune 500 Group Health, Life, & Retirement Plans</b>{" "}
                      with lower premiums to improve talent recruitment and
                      retention
                    </p>
                  </div>
                  <div className="card">
                    <p>
                      <b>
                        Engage is an IRS Certified PEO (CPEO) and ESAC Certified
                      </b>
                      . Less than 3% of all PEOs have both accreditations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {props.ourServiceList &&
          props.ourServiceList.map((ele) => {
            return (
              <div className="col col-lg-4 col-md-6 col-sm-12">
                <div className="card shadow-sm rounded ">
                  <div className="card-img-container">
                    <img src={ele.img} className="card-img-top"></img>
                  </div>
                  <div className="card-body">
                    <div className="card-text">{ele.text}</div>
                  </div>
                </div>
              </div>
            );
          })} */}
      </div>
    </div>
  );
}

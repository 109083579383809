import { awsUrl, awsUrl2 } from "../config";
import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { acordQues } from "../utils/ques_list";
import {
  deleteAllCookies,
  showErrAlert,
  getHeader,
  blobPdfFromBase64String,
} from "./common";
import { Auth } from "aws-amplify";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";

const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export let salesPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          resolve({
            salespersonName: sessionData.attributes.email,
            salespersonNameAttr: sessionData.attributes.name,
            salespersonCheck: true,
          });
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } else {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salespersonName: "",
        salespersonCheck: false,
      });
    }
  });

  return promise;
};

export let preUwPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          resolve({
            PreUwPersonName: sessionData.attributes.email,
            PreUwPersonNameAttr: sessionData.attributes.name,
            PreUwCheck: true,
          });
        } else {
          resolve({
            PreUwPersonName: "",
            PreUwCheck: false,
          });
        }
      } else {
        resolve({
          PreUwPersonName: "",
          PreUwCheck: false,
        });
      }
    } catch (error) {
      resolve({
        PreUwPersonName: "",
        PreUwCheck: false,
      });
    }
  });
  return promise;
};
export let salesManagerLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
          resolve({
            salesManagerPersonName: sessionData.attributes.email,
            salesManagerPersonNameAttr: sessionData.attributes.name,
            salesManagerCheck: true,
          });
        } else {
          resolve({
            salesManagerPersonName: "",
            salesManagerCheck: false,
          });
        }
      } else {
        resolve({
          salesManagerPersonName: "",
          salesManagerCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salesManagerPersonName: "",
        salesManagerCheck: false,
      });
    }
  });
  return promise;
};
export let adminLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          resolve({
            adminPersonName: sessionData.attributes.email,
            adminPersonNameAttr: sessionData.attributes.name,
            adminCheck: true,
          });
        } else {
          resolve({
            adminPersonName: "",
            adminCheck: false,
          });
        }
      } else {
        resolve({
          adminPersonName: "",
          adminCheck: false,
        });
      }
    } catch (error) {
      resolve({
        adminPersonName: "",
        adminCheck: false,
      });
    }
  });
  return promise;
};

export let brdLogincheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_BRD)) {
          resolve({
            brdPersonName: sessionData.attributes.email,
            brdPersonNameAttr: sessionData.attributes.name,
            brdCheck: true,
          });
        } else {
          resolve({
            brdPersonName: "",
            brdCheck: false,
          });
        }
      } else {
        resolve({
          brdPersonName: "",
          brdCheck: false,
        });
      }
    } catch (error) {
      resolve({
        brdPersonName: "",
        brdCheck: false,
      });
    }
  });
  return promise;
};

export let checkQuestionsValidity = (quesList, amtrustQuesList) => {
  let validQuestions = true;
  for (let question of [...quesList, ...amtrustQuesList]) {
    if (question.response !== true && question.response !== false) {
      validQuestions = false;
      break;
    }
  }

  return validQuestions;
};

export function amtrustResponse(uuid, apiType) {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(
        awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
}

function getAmtrustEligibility(amtrustQuesList, uwExpectedResponses) {
  let eligibility = "approved";
  try {
    let uwExpectedMap = {};
    for (let question of uwExpectedResponses) {
      uwExpectedMap[question.paygoName] = question;
    }

    console.log(amtrustQuesList, uwExpectedMap);
    for (let question of amtrustQuesList) {
      if (
        question.name in uwExpectedMap &&
        uwExpectedMap[question.name]?.type === "hospitality"
      ) {
        let questionEligibility = question.response
          ? uwExpectedMap[question.name]?.ifYes
          : uwExpectedMap[question.name]?.ifNo;
        if (
          (questionEligibility === "referred" && eligibility !== "declined") ||
          questionEligibility === "declined"
        ) {
          eligibility = questionEligibility;
        }
      }
    }
  } catch (error) {
    console.log("error in uw amtrust eligibility");
  }

  return eligibility;
}

function getFourthQuestionsEligibility(uwQuesData) {
  let eligibility = "approved";
  try {
    for (let question of uwQuesData) {
      let questionEligibility =
        question.response === "Y" ? question.ifYes : question.ifNo;
      if (
        (questionEligibility === "referred" && eligibility !== "declined") ||
        questionEligibility === "declined"
      ) {
        eligibility = questionEligibility;
      }
    }
  } catch (error) {
    console.log("error in uw fourth eligibility");
  }

  return eligibility;
}

export function getUwQuestionsEligibility({
  uwQuesData,
  amtrustQuesList,
  uwExpectedResponses,
  acordQuesAvailable,
}) {
  let amtrustEligibility = "approved";
  let fourthQuestionsEligibility = "approved";
  let eligibility = "approved";
  if (amtrustQuesList && amtrustQuesList?.length > 0) {
    amtrustEligibility = getAmtrustEligibility(
      amtrustQuesList,
      uwExpectedResponses
    );
  }

  if (uwQuesData && uwQuesData?.length > 0 && !acordQuesAvailable) {
    fourthQuestionsEligibility = getFourthQuestionsEligibility(uwQuesData);
  }

  for (let questionEligibility of [
    amtrustEligibility,
    fourthQuestionsEligibility,
  ]) {
    if (
      (questionEligibility === "referred" && eligibility !== "declined") ||
      questionEligibility === "declined"
    ) {
      eligibility = questionEligibility;
    }
  }

  return eligibility;
}

export function getClassCodeEligibility(childrenLoc) {
  let eligibility = "approved";
  try {
    let classCodeExceptions = [9084];
    let stateCcExceptions = { ny: [8006], nj: [8006] };
    let stateExceptions = ["ca"];

    for (let locIndex in childrenLoc) {
      let state = childrenLoc[locIndex].state.value.toLowerCase();
      if (stateExceptions.includes(state)) {
        eligibility = "declined";
      }
      let classCodeExceptionList = [...classCodeExceptions];
      if (stateCcExceptions?.[state]?.length > 0) {
        classCodeExceptionList.push(...stateCcExceptions[state]);
      }

      for (let classCodeIndex in childrenLoc[locIndex].classCodesInfo) {
        let classCode = childrenLoc[locIndex].classCodesInfo[
          classCodeIndex
        ].classCodeDescription.value
          .split(":")[0]
          .trim();

        if (classCodeExceptionList.includes(Number(classCode))) {
          eligibility = "declined";
        }
      }
    }
  } catch (error) {
    console.log("error in class code eligibility", error);
  }

  return eligibility;
}

export function getHospitalityEligibility(dataBody) {
  return new Promise(async (resolve, reject) => {
    let eligibility = "approved";
    let hospitalityExists, nonHospitalityExists;
    try {
      let classCodeTypeData, isHospitalityClient;

      let classCodeTypeDataPromise = axios.post(
        awsUrl2 + "/api/getClassCodesIndustry",
        dataBody.offices
      );

      let isHospitalityClientPromise = axios.post(
        awsUrl2 + `/api/getHospitalityClassCodes`,
        dataBody
      );

      classCodeTypeData = await classCodeTypeDataPromise;
      isHospitalityClient = await isHospitalityClientPromise;

      for (let state in classCodeTypeData.data) {
        for (let classCode in classCodeTypeData.data[state]) {
          let type = classCodeTypeData.data[state][classCode];
          if (type !== "hospitality") {
            nonHospitalityExists = true;
          }
        }
      }

      hospitalityExists = isHospitalityClient?.data?.value || false;

      if (!hospitalityExists) {
        eligibility = "declined";
      } else if (nonHospitalityExists) {
        eligibility = "referred";
      }
    } catch (error) {
      console.log(error);
    } finally {
      resolve(eligibility);
    }
  });
}

function sendFailedQuoteMail(userDetails) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl2 + "/api/sendQuoteFailedMail", userDetails)
        .then((res) => resolve())
        .catch((err) => reject());
    } catch (error) {
      reject();
    }
  });
}

function getSalesManagerDetailsifPresent(salesID, latestQuoteKey) {
  return new Promise(async (resolve, reject) => {
    let requestBody = {
      user_email_id: salesID,
      uuid_carrier: latestQuoteKey,
    };

    axios
      .post(awsUrl2 + "/api/salesManagerDetailsCheck", requestBody)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        console.log("error", error);
      });
  });
}
function salesManagerDetailsRetainCheck(
  salesManagerFetchedData,
  currProspectDetails
) {
  const {
    childrenLoc,
    companyProfile,
    requestedPricing,
    brokerDetails,
    companyProfileAddQuestions,
  } = currProspectDetails;
  try {
    let copyCurrentProspectSessionData =
      salesManagerFetchedData?.currProspectDetails;

    if (copyCurrentProspectSessionData) {
      let payrollLocationAdddresCheckList = [
        "classCodesInfo",
        "street",
        "zipCode",
        "cityName",
        "state",
        "clientsuta",
        "suta",
        "classCodesInfo",
      ];
      let payrollDetailsCheckList = [
        "classCodeDescription",
        "ft",
        "pt",
        "payroll",
        "pricing",
      ];
      let brokerDetailsCheckList = [
        "brokerQuestionValue",
        "brokerPercentage",
        "brokerdropdownSelected",
      ];
      let requestingPricingCheckList = [
        "checked",
        "adminPercent",
        "perCKdollar",
        "WeeklySelect",
      ];

      let peoPayCheckList = ["response"];

      // peo Pay Question Check
      for (let key of peoPayCheckList) {
        let peoPaygoStateValue = companyProfileAddQuestions.filter((item) => {
          return item.ques === "Are you interested in PEO/PAYGO/Both Quotes?";
        });

        let peoPaygocurrprospectvalue = copyCurrentProspectSessionData?.[
          "companyProfileAddQuestions"
        ].filter((item) => {
          return item.ques === "Are you interested in PEO/PAYGO/Both Quotes?";
        });

        let PeoPaygoValue1 = peoPaygoStateValue?.["0"]?.[key]
          ? peoPaygoStateValue?.["0"]?.[key].toString().trim()
          : undefined;
        let peoPaygoValue2 = peoPaygocurrprospectvalue?.["0"]?.[key]
          ? peoPaygocurrprospectvalue?.["0"]?.[key].toString().trim()
          : undefined;
        if (PeoPaygoValue1 !== peoPaygoValue2) {
          if (PeoPaygoValue1 === "b") {
            return false;
          }
        }
      }

      // requested Pricing
      for (let requestedPricingFields of requestingPricingCheckList) {
        let requestedPricingValue1 = requestedPricing?.[requestedPricingFields]
          ? requestedPricing?.[requestedPricingFields].toString().trim()
          : undefined;
        let requestedPricingValue2 = copyCurrentProspectSessionData?.[
          "requestedPricing"
        ][requestedPricingFields]
          ? copyCurrentProspectSessionData?.["requestedPricing"][
            requestedPricingFields
          ]
            .toString()
            .trim()
          : undefined;
        if (requestedPricingValue1 !== requestedPricingValue2) {
          return false;
        }
      }

      // borkerList check
      for (let brokerFields of brokerDetailsCheckList) {
        let brokerValue1 = brokerDetails?.[brokerFields]?.["value"]
          ? brokerDetails?.[brokerFields]?.["value"].toString().trim()
          : undefined;
        let brokerValue2 = copyCurrentProspectSessionData?.["brokerDetails"]?.[
          brokerFields
        ]?.["value"]
          ? copyCurrentProspectSessionData?.["brokerDetails"]?.[brokerFields]?.[
            "value"
          ]
            .toString()
            .trim()
          : undefined;
        if (brokerFields === "brokerdropdownSelected") {
          if (brokerValue1 !== brokerValue2) {
            return false;
          }
        } else {
          let brokerValue3 = brokerDetails?.[brokerFields]
            ? brokerDetails?.[brokerFields].toString().trim()
            : undefined;
          let brokerValue4 = copyCurrentProspectSessionData?.[
            "brokerDetails"
          ]?.[brokerFields]
            ? copyCurrentProspectSessionData?.["brokerDetails"]?.[brokerFields]
              .toString()
              .trim()
            : undefined;
          if (brokerValue3 !== brokerValue4) {
            return false;
          }
        }
      }

      let childrenLocOnlyKeysExtract = Object.keys(childrenLoc);
      let currProsChildrenLocOnlyKeysExtract = Object.keys(
        copyCurrentProspectSessionData["childrenLoc"]
      );
      // checking whether the length of the childrenloc and CurrentProsect ChildrenLoc is same or not
      if (
        childrenLocOnlyKeysExtract.length !==
        currProsChildrenLocOnlyKeysExtract.length
      ) {
        return false;
      }
      // checking if the length mathces  childrenloc and CurrentProsect ChildrenLoc,if mathces than we check the all feidls
      for (let addressLoc in childrenLocOnlyKeysExtract) {
        const childrenLocLocationcheck = childrenLoc?.[addressLoc];
        const currProsChildrenLocLocationcheck =
          copyCurrentProspectSessionData?.["childrenLoc"]?.[addressLoc];

        for (let addressFeilds of payrollLocationAdddresCheckList) {
          if (addressFeilds === "classCodesInfo") {
            let classCodesKeyExtract = Object.keys(
              childrenLocLocationcheck?.[addressFeilds]
            );
            let CurrprospclassCodesKeyExtract = Object.keys(
              currProsChildrenLocLocationcheck?.[addressFeilds]
            );

            if (
              classCodesKeyExtract.length !==
              CurrprospclassCodesKeyExtract.length
            ) {
              return false;
            }

            for (let childAddressLoc of classCodesKeyExtract) {
              for (let childAddressFeilds of payrollDetailsCheckList) {
                let childrenValue1 = childrenLocLocationcheck?.[
                  addressFeilds
                ]?.[childAddressLoc]?.[childAddressFeilds]?.["value"]
                  ? childrenLocLocationcheck?.[addressFeilds]?.[
                    childAddressLoc
                  ]?.[childAddressFeilds]?.["value"]
                    .toString()
                    .trim()
                  : undefined;
                let childrenValue2 = currProsChildrenLocLocationcheck?.[
                  addressFeilds
                ]?.[childAddressLoc]?.[childAddressFeilds]?.["value"]
                  ? currProsChildrenLocLocationcheck?.[addressFeilds]?.[
                    childAddressLoc
                  ]?.[childAddressFeilds]?.["value"]
                    .toString()
                    .trim()
                  : undefined;
                if (childrenValue1 !== childrenValue2) {
                  return false;
                }
              }
            }
          } else {
            let childrenValue3 = childrenLocLocationcheck?.[addressFeilds]?.[
              "value"
            ]
              ? childrenLocLocationcheck?.[addressFeilds]?.["value"]
                .toString()
                .trim()
              : undefined;
            let childrenValue4 = currProsChildrenLocLocationcheck?.[
              addressFeilds
            ]?.["value"]
              ? currProsChildrenLocLocationcheck?.[addressFeilds]?.["value"]
                .toString()
                .trim()
              : undefined;
            if (childrenValue3 !== childrenValue4) {
              return false;
            }
          }
        }
      }

      return true;

      // in future need any changes for company profile below code can be used

      // let salesPersonCompanyProfileCheckList = [
      //   "companyName",
      //   "descriptionOfOperations",
      //   "dba",
      //   "fein",
      //   "firstName",
      //   "lastName",
      //   "phoneNumber",
      //   "entityType",
      //   "yearsInBusiness",
      //   "expectedStartDate",
      //   "street1",
      //   "zipCode1",
      //   "cityName1",
      //   "state1",
      //   "street2",
      //   "zipCode2",
      //   "cityName2",
      //   "state2",
      //   "notes",
      // ];

      // if we found the  any difference in Childrenloc and currentProsect childrenLoc then we don't run below code, we will generate new quote
      //  if(!childrenLocMissMatchWithCurrSalesPersonChilderLoc){
      //   for (let feilds of salesPersonCompanyProfileCheckList) {
      //   let value1 =
      //     copyCurrentProspectSessionData?.["companyProfile"]?.[feilds]?.[
      //       "value"
      //     ];
      //   let value2 = companyProfile?.[feilds]?.["value"];

      //   if (feilds === "expectedStartDate") {
      //     value1 = moment(value1).format("YYYY-MM-DD");
      //     value2 = moment(value2).format("YYYY-MM-DD");

      //     if (value1.trim() !== value2.trim()) {
      //        companyProfileMissMatchWithSalesPersonCmpProfile = false;
      //       break;
      //     }
      //   } else {
      //     if (value1.trim() !== value2.trim()) {
      //       companyProfileMissMatchWithSalesPersonCmpProfile = true;
      //       break;
      //     }
      //   }
      // }
      //  }
    }

    return true;
  } catch (error) {
    console.log(error, ": Error in SalesPerson Comparison check");
  }
}

export async function submitQuote(currProspectDetails) {

  $("#loader").css("display", "block");

  try {
    let isSalesforce = sessionStorage.getItem("isSalesforce");
    let sfQuoteId = sessionStorage.getItem("sfQuoteId");

    let uwAmtrustQuesList = sessionStorage.getItem("uwAmtrustQuesList");
    if (uwAmtrustQuesList) {
      uwAmtrustQuesList = JSON.parse(uwAmtrustQuesList);
    }

    let uwQuesList = sessionStorage.getItem("uwQuesList");
    if (uwQuesList) {
      uwQuesList = JSON.parse(uwQuesList);
    }

    let preUwData;
    try {
      preUwData = JSON.parse(sessionStorage.getItem("preUwData"));
    } catch (error) { }

    let salespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    );

    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) {
      amtrustAccountDetails = {};
    }

    let amtrustAccountDetailsCopy = sessionStorage.getItem(
      "amtrustAccountDetailsCopy"
    );
    try {
      amtrustAccountDetailsCopy = JSON.parse(amtrustAccountDetailsCopy);
    } catch (error) { }

    let { salespersonName, salespersonCheck, salespersonNameAttr } =
      await salesPersonLoginCheck();
    let salesID;
    try {
      salesID = sessionStorage.getItem("salesID");
    } catch (error) {
      console.log(error);
    }

    // let uwQuesData = [];
    // let acordQuesData = [];
    let carrierUserData = [];

    // for (let question of quesList) {
    //   let dataQues = {
    //     response: question.response ? "Y" : "N",
    //     name: question.name,
    //     ques: question.ques,
    //     ifYes: question.ifYes,
    //     ifNo: question.ifNo,
    //   };
    //   uwQuesData.push(dataQues);
    // }

    // for (let question of acordQues) {
    //   let acordDataQues = {
    //     response: "",
    //     name: question.name,
    //     ques: question.ques,
    //   };
    //   let answerObj = uwQuesData?.find((e) => e.name === question.name);
    //   if (answerObj?.response) {
    //     acordDataQues.response = answerObj.response;
    //   }
    //   acordQuesData.push(acordDataQues);
    // }

    // currProspectDetails.uwQues = uwQuesData;
    // if (amtrustQuesList && amtrustQuesList?.length > 0) {
    //   currProspectDetails.amtrustQuesList = amtrustQuesList;
    // }
    // if (uwExpectedResponses) {
    //   currProspectDetails.uwExpectedResponses = uwExpectedResponses;
    // }

    // sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

    let admin_coefficient = {
      a: 52,
      b: 26,
      c: 24,
      d: 12,
    };

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      uwQues,
      requestedPricing,
      brokerDetails,
      companyProfileAddQuestions,
    } = currProspectDetails;
    if (childrenLoc && companyProfile && emodStatesData) {
      // let { salespersonName, salespersonCheck } = await salesPersonLoginCheck();

      let requested_admin = {};
      if (requestedPricing?.checked && requestedPricing?.adminPercent) {
        requested_admin = {
          value: requestedPricing.adminPercent,
          type: "percent",
        };
      } else if (!requestedPricing?.checked && requestedPricing?.perCKdollar) {
        requested_admin = {
          value: requestedPricing.perCKdollar.split("$")[1],
          type: "percheck",
        };
      }

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions: [],
          sr_carriers: {},
          requested_admin,
        },
        offices: [],
      };

      if (requestedPricing?.checked === false) {
        dataBody.common.coefficient =
          admin_coefficient[requestedPricing["WeeklySelect"]];
      }

      try {
        if (
          brokerDetails &&
          brokerDetails?.brokerQuestionValue &&
          brokerDetails?.brokerPercentage
        ) {
          dataBody.common.broker_fee =
            brokerDetails.brokerPercentage.split("%")[0];
        }
      } catch (error) {
        console.log(error);
      }

      let emodForMail;

      for (let state in emodStatesData) {
        let emodObj = {};
        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment(emodStatesData[state].date1.value).format(
              "YYYY-MM-DD"
            ),
          };

          if (emodForMail == null && emodStatesData[state].rate1.value)
            emodForMail = emodStatesData[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment(
              emodStatesData[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      let payrollMap = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value
          }_${(childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
          }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          requested_suta: "",
          client_suta: "",
          employees: [],
          carriers: ["carrier_ao"],
        };

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray =
            childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
          officesObj.requested_suta =
            childrenLoc[addressBlockNo].suta &&
              childrenLoc[addressBlockNo].suta.value
              ? childrenLoc[addressBlockNo].suta.value.split("%")[0]
              : "";
          officesObj.client_suta =
            childrenLoc[addressBlockNo].clientsuta &&
              childrenLoc[addressBlockNo].clientsuta.value
              ? childrenLoc[addressBlockNo].clientsuta.value.split("%")[0]
              : "";

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
              .value
              ? String(
                childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
                  .value
              ).trim()
              : "0",
            pt: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
              .value
              ? String(
                childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
                  .value
              ).trim()
              : "0",
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
                childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                  .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                  addressChildNo
                ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let quoteFactors = {
        teap: 0,
        total: 0,
        futaCharge: 0,
        ficaCharge: 0,
        sutaCharge: 0,
        payroll: 0,
        adminFee: 0,
      };

      let eligibilityMap = {
        approved: 1,
        referred: 2,
        declined: 3,
      };

      // let uwQuestionsEligibility = getUwQuestionsEligibility({
      //   uwQuesData,
      //   amtrustQuesList,
      //   uwExpectedResponses,
      //   acordQuesAvailable,
      // });

      let classCodeEligibility = getClassCodeEligibility(
        currProspectDetails.childrenLoc
      );

      let hospitalityEligibility = await getHospitalityEligibility(dataBody);

      let clientEligibility;
      let eligibilityList = [
        classCodeEligibility || "approved",
        hospitalityEligibility || "approved",
      ];
      try {
        clientEligibility = eligibilityList.reduce((a, b) => {
          if (eligibilityMap[a] > eligibilityMap[b]) {
            return a;
          }
          return b;
        });
      } catch (error) {
        clientEligibility = "approved";
      }

      let res;
      try {
        res = await axios.post(
          awsUrl + "/api/getQuote",
          JSON.stringify(dataBody)
        );
      } catch (error) {
        console.log("error", error);
        sessionStorage.setItem("quoteFactors", JSON.stringify(quoteFactors));

        //mail for failed quote generation
        let sessionData;
        try {
          sessionData = await Auth.currentAuthenticatedUser();
        } catch (error) { }

        let userDetails = {
          companyName: companyProfile.companyName.value,
          loggedInUser: sessionData
            ? `${sessionData.attributes.name} (${sessionData.attributes.email})`
            : "",
        };
        await sendFailedQuoteMail(userDetails);

        $("#loader").css("display", "none");
        window.location.reload();
      }
      let dropped = true;
      let data = res.data;
      for (let location in data) {
        if (data[location]["carrier_ao"] !== "Dropped") {
          dropped = false;
          quoteFactors.adminFee = data[location]["carrier_ao"].admin_fee;
          quoteFactors.teap += Number(
            data[location]["carrier_ao"].total_estimated_annual_premium
          );
          quoteFactors.total += Number(data[location]["carrier_ao"].total);
          quoteFactors.futaCharge = Number(data[location]["carrier_ao"].futa);
          quoteFactors.ficaCharge = Number(data[location]["carrier_ao"].fica);
          quoteFactors.sutaCharge = Number(data[location]["carrier_ao"].suta);
        }
      }

      if (!dropped) {
        let prospectAddresses = {};

        // create the prospect address object
        for (let stateIndex in childrenLoc) {
          prospectAddresses[stateIndex] = {
            stateCode: childrenLoc[stateIndex].state.value,
            addressId: childrenLoc[stateIndex].number,
            classCodes: [],
          };
          for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
            let classCodeDescArray =
              childrenLoc[stateIndex].classCodesInfo[
                classCodeIndex
              ].classCodeDescription.value.split(":");

            let code = classCodeDescArray.shift();
            let desc = classCodeDescArray.join(":").trim();

            prospectAddresses[stateIndex].classCodes.push({
              code,
              payroll: numeral(
                childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                  .value
              )
                .value()
                .toString(),
              pt: childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt
                .value
                ? String(
                  childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt
                    .value
                ).trim()
                : "0",
              ft: childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
                .value
                ? String(
                  childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
                    .value
                ).trim()
                : "0",
              description: desc,
            });
            quoteFactors.payroll += numeral(
              childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                .value
            ).value();
          }
        }
        let currentDate = Math.floor(Date.now()).toString();
        let visitTimestamp = sessionStorage.getItem("visitTimestamp");
        let uuid = sessionStorage.getItem("uuid");
        if (!uuid) {
          uuid = uuidv4();
        }
        let sortKeyList = [];
        let address = [];
        let quoteData;

        let domain = sessionStorage.getItem("domain");
        let source = sessionStorage.getItem("source") || "";

        let primay_key = domain
          ? domain
          : salespersonCheck && salesID
            ? salesID.toLowerCase()
            : companyProfile.companyName && companyProfile.companyName.value
              ? companyProfile.companyName.value.toLowerCase()
              : "";

        let dashboardFiles = sessionStorage.getItem("dashboardFiles");
        try {
          if (dashboardFiles) dashboardFiles = JSON.parse(dashboardFiles);
          else dashboardFiles = [];
        } catch (err) { }

        let preUwFiles = sessionStorage.getItem("preUwFiles");
        try {
          if (preUwFiles) preUwFiles = JSON.parse(preUwFiles);
          else preUwFiles = [];
        } catch (err) { }

        for (let place in data) {
          address.push(place);
          for (let carrier in data[place]) {
            if (data[place][carrier] !== "Dropped") {
              let tempData = {
                user_email_id: `${primay_key}`,
                uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
                date: currentDate,
                visitDate: visitTimestamp,
                effective_date: moment(
                  companyProfile.effectiveDate.value
                ).format("YYYY-MM-DD"),
                expiration_date: moment(companyProfile.effectiveDate.value)
                  .add(1, "y")
                  .format("YYYY-MM-DD"),
                prospect_addresses: prospectAddresses,
                phone_number: companyProfile.phoneNumber.value,
                company_name: companyProfile.companyName.value,
                fein: companyProfile.fein.value,
                liability_limitId: "1000-1000-1000",
                uuid: uuid,
                carrier: `${carrier}+${place}`,
                carrier_email_id: data[place][carrier].carrier_email,
                carrier_location_data: data[place][carrier],
                doc_status: "not_uploaded",
                currProspectDetails,
                classCodeEligibility,
                clientEligibility,
              };

              if (isSalesforce) {
                tempData.isSalesforce = true;
                tempData.sfQuoteId = sfQuoteId;
              }

              if (uwQuesList) tempData.uwQuesList = uwQuesList;
              if (uwAmtrustQuesList)
                tempData.uwAmtrustQuesList = uwAmtrustQuesList;

              if (dashboardFiles?.length > 0) {
                tempData.dashboardFiles = dashboardFiles;
              }

              if (preUwFiles?.length > 0) {
                tempData.preUwFiles = preUwFiles;
              }

              if (salespersonName) tempData.salespersonName = salespersonName;

              if (salespersonNameAttr)
                tempData.salespersonNameAttr = salespersonNameAttr;

              if (preUwData) {
                tempData.preUwData = preUwData;
              }

              if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
                tempData.amtrustAccountDetails = amtrustAccountDetails;
              }

              if (
                amtrustAccountDetailsCopy &&
                amtrustAccountDetailsCopy.QuoteId
              ) {
                tempData.amtrustAccountDetailsCopy = amtrustAccountDetailsCopy;
              }

              if (salespersonCheck && !salespersonMailCampaign) {
                tempData.origin = "sales_person";
              } else if (
                checkMailCampaignUser() ||
                (salespersonCheck && salespersonMailCampaign)
              ) {
                tempData.origin = "email_campaign";
                tempData.source = source;
              } else {
                tempData.origin = "direct_user";
              }

              // if (acordQuesAvailable) {
              //   tempData.acordQuesAvailable = acordQuesAvailable;
              // }

              sortKeyList.push(tempData.uuid_carrier);
              carrierUserData.push(tempData);
            }
          }
        }

        quoteData = {
          uuid,
          date: currentDate,
        };

        sessionStorage.setItem("sortKeyList", JSON.stringify(sortKeyList));
        sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
        sessionStorage.setItem("address", JSON.stringify(address));

        // if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
        //   try {
        //     let amtrustSaveQuestionsRequestBody = [];
        //     for (let question of amtrustQuesList) {
        //       amtrustSaveQuestionsRequestBody.push({
        //         QuestionId: question.name,
        //         AnswerValue: question.response ? "yes" : "no",
        //       });
        //     }

        //     let amtrustSaveQuestionsTriggerResponse = await axios.post(
        //       awsUrl2 +
        //         `/api/triggerAmtrustSaveQuestions/${amtrustAccountDetails.QuoteId}`,
        //       amtrustSaveQuestionsRequestBody
        //     );

        //     let saveQuestionsUuid, savePaymentPlansUuid;
        //     if (
        //       amtrustSaveQuestionsTriggerResponse.data.uuid
        //       // &&amtrustSavePaymentPlansTriggerResponse.data.uuid
        //     ) {
        //       saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
        //       // savePaymentPlansUuid =
        //       //   amtrustSavePaymentPlansTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust create quote trigger";
        //     }

        //     let amtrustQuestionsResponse, amtrustSavePaymentPlansResponse;
        //     do {
        //       let promiseList = [
        //         amtrustResponse(saveQuestionsUuid, "saveQuestions"),
        //         // amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
        //       ];

        //       await Promise.all(promiseList)
        //         .then((res) => {
        //           console.log("++res", res);
        //           amtrustQuestionsResponse = res[0];
        //           // amtrustSavePaymentPlansResponse = res[1];
        //         })
        //         .catch((err) => {
        //           console.log("error", err);
        //           throw "error in amtrust quote creation";
        //         });
        //       if (
        //         amtrustQuestionsResponse === "error"
        //         // ||amtrustSavePaymentPlansResponse === "error"
        //       ) {
        //         throw "error in amtrust quote generation";
        //       }
        //     } while (
        //       amtrustQuestionsResponse === "processing"
        //       // ||amtrustSavePaymentPlansResponse === "processing"
        //     );

        //     let amtrustSavePaymentPlansTriggerResponse = await axios.get(
        //       awsUrl2 +
        //         `/api/triggerAmtrustSavePaymentPlans/${amtrustAccountDetails.QuoteId}`
        //     );

        //     if (
        //       // amtrustSaveQuestionsTriggerResponse.data.uuid &&
        //       amtrustSavePaymentPlansTriggerResponse.data.uuid
        //     ) {
        //       // saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
        //       savePaymentPlansUuid =
        //         amtrustSavePaymentPlansTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust create quote trigger";
        //     }

        //     do {
        //       let promiseList = [
        //         // amtrustResponse(saveQuestionsUuid, "saveQuestions"),
        //         amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
        //       ];

        //       await Promise.all(promiseList)
        //         .then((res) => {
        //           console.log("++res", res);
        //           // amtrustQuestionsResponse = res[0];
        //           amtrustSavePaymentPlansResponse = res[0];
        //         })
        //         .catch((err) => {
        //           console.log("error", err);
        //           throw "error in amtrust quote creation";
        //         });
        //       if (
        //         // amtrustQuestionsResponse === "error" ||
        //         amtrustSavePaymentPlansResponse === "error"
        //       ) {
        //         throw "error in amtrust quote generation";
        //       }
        //     } while (
        //       // amtrustQuestionsResponse === "processing" ||
        //       amtrustSavePaymentPlansResponse === "processing"
        //     );
        //   } catch (error) {
        //     console.log("error in amtrust quote generation", error);
        //   }
        // }

        if (sessionStorage.getItem("landingEmail")) {
          let uploadDataa = [];
          let userTrackingData = {};

          if (!salespersonCheck) {
            userTrackingData.email = sessionStorage.getItem("landingEmail");
          }

          let currProspct = JSON.parse(sessionStorage.getItem("currProspect"));
          userTrackingData.timestamp = visitTimestamp;
          userTrackingData.domain = sessionStorage.getItem("domain");
          userTrackingData.companyProfile = currProspct.companyProfile;
          userTrackingData.emodStatesData = currProspct.emodStatesData;
          userTrackingData.indicationCost = quoteFactors.total;
          userTrackingData.payrollData = currProspct.childrenLoc;
          userTrackingData.uuid = sessionStorage.getItem("uuid");
          userTrackingData.address = address;
          userTrackingData.quoteData = quoteData;
          userTrackingData.uwQues = uwQues;

          if (salespersonMailCampaign) {
            userTrackingData.salespersonName = salespersonName;
            userTrackingData.salespersonNameAttr = salespersonNameAttr;
            userTrackingData.salesEngaged = "true";
          }

          uploadDataa.push(userTrackingData);
          // console.log("*************************");
          // console.log("********uploadDataa******");
          // console.log(uploadDataa);
          await axios
            .post(awsUrl2 + "/api/updateUsersTrackingData", uploadDataa)
            .then((res) => {
              console.log("posted data", res);
            })
            .catch((error) => {
              console.log("error in posting data to user tracking", error);
            });
        }

        // geting salesManager details from user status table

        try {
          let latestQuoteKey;

          try {
            latestQuoteKey = sessionStorage.getItem("latestQuoteKey");
          } catch (error) {
            latestQuoteKey = false;
          }

          let salesID = sessionStorage.getItem("salesID");
          //  checking whether to get the Sales Manager and Admin Detials or not and send it to userStatus table
          if (latestQuoteKey) {
            let salesManagerFetchedData = await getSalesManagerDetailsifPresent(
              salesID,
              latestQuoteKey
            );
            console.log(salesManagerFetchedData, "salesManagerFetchedData");

            if (salesManagerFetchedData !== "Not Found") {
              //  salesperson comparision check for retaining the  salesManager Details
              if (salesManagerFetchedData?.specialAdminData) {
                let salesManagerRetainCheck = salesManagerDetailsRetainCheck(
                  salesManagerFetchedData,
                  currProspectDetails
                );
                console.log(salesManagerRetainCheck, "salesManagerRetainCheck");

                if (salesManagerRetainCheck) {
                  let carrierUserDataKeys = Object.keys(carrierUserData);
                  for (let key in carrierUserDataKeys) {
                    if (carrierUserData.hasOwnProperty(key)) {
                      carrierUserData[key]["specialAdminData"] =
                        salesManagerFetchedData?.specialAdminData;
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log(
            error,
            ": Error while fetching the Sales Manager and Admin data"
          );
        }

        try {
          if (salespersonCheck && !salespersonMailCampaign) {
            let salesPersonData = {};
            let type = "update";
            let dateNow = Math.floor(Date.now()).toString();

            salesPersonData.id = salesID;
            salesPersonData.updated_timestamp = dateNow;
            salesPersonData.formStage = "three";
            salesPersonData.salespersonName = salespersonName;
            salesPersonData.uwQues = uwQues;
            salesPersonData.address = address;
            salesPersonData.quoteData = quoteData;
            salesPersonData.sortKeyList = sortKeyList;
            salesPersonData.currProspect = currProspectDetails;
            salesPersonData.latestQuoteKey = sortKeyList[0].split("_")[0];
            await axios
              .post(
                awsUrl2 + `/api/salesPersonData/${type}`,
                JSON.stringify(salesPersonData)
              )
              .then(() => {
                sessionStorage.setItem(
                  "latestQuoteKey",
                  sortKeyList[0].split("_")[0]
                );
              })
              .catch((error) => {
                console.log("error", error);
              });
          }
        } catch (error) {
          console.log("sales person flow error: ", error);
        }

        let prevIndex = 0;
        let nextIndex = 100;
        let uploadData;
        for (let i = 0; i < Math.floor(carrierUserData.length / 100) + 1; i++) {
          uploadData = carrierUserData.slice(prevIndex, nextIndex);
          axios
            .post(awsUrl + "/api/postUsersData", uploadData)
            .then((res) => {
              console.log("posted data", res);
            })
            .catch((error) => {
              console.log("error in posting data", error);
            })
            .finally(() => {
              sessionStorage.setItem("formStage", "three");
              sessionStorage.setItem(
                "quoteFactors",
                JSON.stringify(quoteFactors)
              );
              $("#loader").css("display", "none");
              window.location.reload();
            });
        }
      } else {
        sessionStorage.setItem("formStage", "two");
        $("#loader").css("display", "none");
        window.location.reload();
      }
    }
  } catch (error) {
    console.log("error", error);
    $("#loader").css("display", "none");
  }
}

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;


  let index;
  index = e.target.id.split("-")[1];
  quesList[index][key] = value;

  this.setState({ quesList });
}

export function updateAmtrustQuesList(e, key, value) {
  let { amtrustQuesList } = this.state;
  let index = e.target.id.split("-")[1];
  amtrustQuesList[index][key] = value;
  this.setState({ amtrustQuesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}
